import { useBoolean, useId } from "@fluentui/react-hooks";
import { CommandButton, IButtonProps, IButtonStyles } from "@fluentui/react/lib/Button";
import { DirectionalHint } from "@fluentui/react/lib/ContextualMenu";
import { TeachingBubble } from "@fluentui/react/lib/TeachingBubble";
import { FontSizes, useTheme } from "@fluentui/react/lib/Theme";
import { Text } from "@fluentui/react/lib/Text";
import React from "react";
import { IDocumentPathResult } from "../models";

export interface IDocumentPathButtonProps {
    path: IDocumentPathResult;
    styles?: IButtonStyles;
    isSmallButton?: boolean;
    smallButtonLabel?: string;
}

export const DocumentPathButton: React.FunctionComponent<IDocumentPathButtonProps> = (props) => {
    const theme = useTheme();

    const [isVisible, { setFalse: onHideDocPath, setTrue: onShowDocPath }] = useBoolean(false);
    const id = useId(`documentPathButton`);

    const { path, styles, isSmallButton, smallButtonLabel = '...' } = props;

    const onMouseOver = () => {
        if (isVisible) {
            return;
        }

        onShowDocPath();
    }

    const buttonProps: IButtonProps = {
        styles: styles,
        id: id,
        iconProps: {
            iconName: 'FolderList',
            onMouseOver: onMouseOver,
            onClick: onShowDocPath,
        },
        text: 'Document Path',
    }

    if (isSmallButton) {
        const newStyles = { ...styles }
        newStyles.root = {
            marginRight: 10,
            height: 18,
            width: 'auto',
            padding: '0 0 0 0',
            backgroundColor: theme.palette.themePrimary,
            color: theme.palette.white,
            textAlign: 'center',
            fontSize: FontSizes.mini,
        }
        newStyles.rootHovered = {
            backgroundColor: theme.palette.themePrimary,
            color: theme.palette.white,
        }

        buttonProps.styles = newStyles;
        buttonProps.text = smallButtonLabel;
        buttonProps.iconProps = undefined;
        buttonProps.onMouseOver = onMouseOver;
        buttonProps.onClick = onShowDocPath;
    }

    return (
        <>
            <CommandButton {...buttonProps} />

            {isVisible && (
                <TeachingBubble
                    calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
                    target={`#${id}`}
                    isWide={true}
                    hasCloseButton={true}
                    closeButtonAriaLabel="Close"
                    onDismiss={onHideDocPath}
                >
                    {path.items.map((p, i) =>
                        <Text styles={{ root: { color: theme.palette.white } }} key={i} block>{p}</Text>)
                    }
                </TeachingBubble>
            )}
        </>
    );
};