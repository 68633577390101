import { ApiService } from ".";
import authService from "../components/api-authorization/AuthorizeService";
import { IParameters, ISaveResponse, ISearchParameters, ISearchResult } from "../models";
import { getCookie } from "../tools";

export class FileService implements ApiService<any> {
    get(parameters: IParameters): Promise<any> {
        throw new Error("Method not implemented.");
    }
    search(parameters: ISearchParameters): Promise<ISearchResult<any>> {
        throw new Error("Method not implemented.");
    }

    async uploadFiles(id: string, files: File[], callback?: (response: ISaveResponse<any>) => void): Promise<void> {
        try {
            const token = await authService.getAccessToken();
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append(`file-{}`, files[i]);
            }

            const response = await fetch(`api/file/upload/${id}/files`, {
                method: 'POST',
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
                },
                body: formData,
            });
            const json = await response.json();

            if (!response.ok) {
                throw new Error(`Status: ${json.status} ${json.title}`);
            }

            if (callback) {
                callback(json);
            }
        }
        catch (err: any) {
            if (callback) {
                callback({
                    isError: true,
                    errorMessage: typeof err === 'string' ? err : err.message
                });
            }
        }
    }

    async downloadFile(docId: string, callback?: (message: string) => void): Promise<void> {
        try {
            const token = await authService.getAccessToken();
            const response = await fetch(`api/file/document/${docId}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });

            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                const err: ISaveResponse<any> = await response.json();
                if (callback) {
                    callback(err.errorMessage || 'Unknown error while getting a pdf');
                }

                return;
            }

            const filename = this.getFileName(response, 'files.zip');
            const blob = await response.blob();
            const url = URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode!.removeChild(link);
        } catch (err: any) {
            if (callback) {
                callback(err.ErrorMessage);
            }
        }
    }

    async downloadFilePath(docId: string, pathId: string, requestId?: string, callback?: (message: string) => void): Promise<void> {
        try {
            const token = await authService.getAccessToken();

            let queryString = `?pathId=${pathId}`;
            if (requestId) {
                queryString += `&requestId=${requestId || ''}`;
            }

            const response = await fetch(`api/file/document/${docId}${queryString}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });

            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                const err: ISaveResponse<any> = await response.json();
                if (callback) {
                    callback(err.errorMessage || 'Unknown error while getting a pdf');
                }

                return;
            }

            const filename = this.getFileName(response, 'files.zip');
            const blob = await response.blob();
            const url = URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode!.removeChild(link);
        } catch (err: any) {
            if (callback) {
                callback(err.ErrorMessage);
            }
        }
    }

    getFileName(response: Response, defaultName: string) {
        let filename = defaultName;
        var disposition = response.headers.get('content-disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }

        return filename;
    }

    // async downloadMultipleFiles(docIds: string[], callback?: (message: string) => void): Promise<void> {
    //     try {
    //         const token = await authService.getAccessToken();
    //         const query = new URLSearchParams();
    //         for (let i = 0; i < docIds.length; i++) {
    //             query.append("docIds", docIds[i]);
    //         }

    //         const queryString = query.toString();
    //         const response = await fetch(`api/file/multiple/document?${queryString}`, {
    //             headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
    //         });
    //         const blob = await response.blob();
    //         const url = URL.createObjectURL(new Blob([blob]));
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute(
    //             'download',
    //             `archive.zip`,
    //         );

    //         // Append to html link element page
    //         document.body.appendChild(link);

    //         // Start download
    //         link.click();

    //         // Clean up and remove the link
    //         link.parentNode!.removeChild(link);
    //     }
    //     catch (err: any) {
    //         if (callback) {
    //             callback(err.ErrorMessage);
    //         }
    //     }
    // }
}