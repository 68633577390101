import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";
import { Alignment, IStackProps, IStackStyles, IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { useTheme } from "@fluentui/react/lib/Theme";
import React, { useRef } from "react";
import { sidePadding } from "../constants";

export interface IPageContainerProps {
    wrapperStackProps?: IStackProps;
    renderOptionalHeader?: () => React.ReactNode;
    center?: boolean;
}

const stackTokens: IStackTokens = { childrenGap: 10 };

export const PageContainer: React.FunctionComponent<IPageContainerProps> = (props) => {
    const theme = useTheme();
    const { wrapperStackProps, children, center = true, renderOptionalHeader } = props;

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;
    const isLargeDevice = modalResponsiveMode === ResponsiveMode.large || modalResponsiveMode === ResponsiveMode.xLarge;

    const marginLeft = center || isSmallDevice || isLargeDevice ? 0 : 5;
    const padding = center ? (sidePadding * 2) : (sidePadding - marginLeft);

    const wrapperStyles: (props: IStackProps) => Partial<IStackStyles> = p => ({
        ...p.styles,
        root: {
            minWidth: isSmallDevice || isLargeDevice ? '100%' : 500,
            maxWidth: isSmallDevice || isLargeDevice ? '100%' : 1430,
            backgroundColor: p.theme?.palette.white,
            marginTop: 5,
            marginBottom: 5,
            marginLeft: marginLeft,
            width: `calc(100% - ${padding}px)`,
            padding: 20
        }
    });

    const _wrapperStackProps: IStackProps = {
        tokens: stackTokens,
        styles: wrapperStyles,
        theme: theme,
        verticalAlign: 'center',
        ...wrapperStackProps,
    };

    const horizontalAlign: Alignment | undefined = center ? 'center' : 'baseline';

    const rootStackStyles: Partial<IStackStyles> = {
        root: {
            overflow: center ? 'auto' : 'hidden',
            height: center ? 'auto' : 'calc(100vh - 220px)',
        }
    };

    const innerStackStyles: Partial<IStackStyles> = {
        root: {
            backgroundColor: theme.palette.neutralLight,
            paddingBottom: 20,
            overflow: center ? 'initial' : 'auto',
        }
    };

    return (
        <Stack grow={1} styles={rootStackStyles} >
            {renderOptionalHeader && <>{renderOptionalHeader()}</>}
            <Stack
                horizontalAlign={horizontalAlign}
                grow={1}
                styles={innerStackStyles}
            >
                <Stack {..._wrapperStackProps}>
                    {children}
                </Stack>
            </Stack>
        </Stack>
    );
};