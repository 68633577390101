import { MessageBar, MessageBarType } from "@fluentui/react/lib/MessageBar";
import { Stack } from "@fluentui/react/lib/Stack";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { IPdfViewer } from "../models";
import { PdfService } from "../services";

export interface IPdfViewerProps {
    viewer: IPdfViewer;
}

const pdfViewerPageUrl = '/pdfviewer';

export const PdfViewer: React.FunctionComponent<IPdfViewerProps> = (props) => {
    const [src, setSrc] = useState<string>('');
    const [err, setErr] = useState<string>('');
    const { viewer } = props;

    useEffect(() => {
        let isMounted = true;
        if (viewer) {
            const service = new PdfService();
            const promise = service.getTempPdfFileName(viewer, err => setErr(err));
            promise.then(fileName => {
                if (!isMounted || !fileName || (fileName as any).isError) {
                    setSrc('');
                } else {
                    const permission = viewer.permission ? viewer.permission : 'view';
                    let viewerUrl = `${pdfViewerPageUrl}?fileName=${fileName}&permission=${permission}`;
                    if (viewer.documentId) {
                        viewerUrl += `&docId=${viewer.documentId}`;
                    }
                    if (viewer.pathId) {
                        viewerUrl += `&pathId=${viewer.pathId}`;
                    }
                    if (viewer.requestId) {
                        viewerUrl += `&requestId=${viewer.requestId}`;
                    }

                    setSrc(viewerUrl);
                }
            });
        } else {
            setSrc('');
        }

        return () => {
            isMounted = false;
            setSrc('');
            setErr('');
        }
    }, [viewer]);

    if (err) {
        return <MessageBar messageBarType={MessageBarType.error}>{err}</MessageBar>;
    }

    return (
        <Stack grow>
            {src && <iframe title='pdfviewer' src={src} style={{ width: '100%', height: '100%' }} />}
        </Stack>
    );
};