import { ISearchBox, SearchBox } from "@fluentui/react/lib/SearchBox";
import React, { useRef, useState } from "react";
import { SearchSuggestion } from "./SearchSuggestion";

export interface QuickSearchProps {
    onCancel: () => void
}

export const QuickSearch: React.FunctionComponent<QuickSearchProps> = ({ onCancel }) => {
    const input = useRef<ISearchBox>(null);
    const target = useRef(null);
    const [searchValue, setSearchValue] = useState<string>('');

    const onSearch = (keyword: string) => {
        setSearchValue(keyword);
    };

    const onOpened = () => {
        if (input.current) {
            input.current.focus();
        }
    };

    return (
        <>
            <SearchBox
                ref={target}
                componentRef={input}
                onEscape={onCancel}
                onSearch={onSearch}
            />
            <SearchSuggestion
                target={target}
                keyword={searchValue}
                onOpened={onOpened}
                onCancel={onCancel}
            />
        </>
    );
};