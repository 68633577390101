import { IBreadcrumbItem } from "@fluentui/react/lib/Breadcrumb";
import { IStackStyles, IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import React, { useContext, useMemo, useRef, useState } from "react";
import { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { Debug } from "../../../components/Debug";
import { NavigationPane } from "../../../components/NavigationPane";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { TextField } from "@fluentui/react/lib/TextField";
import { getDisplayValue, isEmpty } from "../../../tools";
import { IActiveStatus, IEmployee, IGroup, ISaveResponse, ISearchParameters, IUser } from "../../../models";
import { EmployeeService, GroupService, UserService } from "../../../services";
import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react/lib/ChoiceGroup";
import { IPickerItem, Picker } from "../../../components/picker";
import { cloneDeep } from "lodash";
import { AppContext } from "../../../contexts";
import { DialogType } from "@fluentui/react/lib/Dialog";
import { IUserSearchValues, listBreadcrumbItems } from "./UserList";
import { MessageBar, MessageBarType } from "@fluentui/react/lib/MessageBar";
import { useBoolean } from "@fluentui/react-hooks";
import { OverlayLoading } from "../../../components/OverlayLoading";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";

export type IFormMode = 'new' | 'edit' | 'readonly';

export interface IUserValues {
    mode: IFormMode;
    data: IUser;
}

const stackTokens: IStackTokens = { childrenGap: 10 };
const wrapStackTokens: IStackTokens = { childrenGap: 10 };

const statusOptions: IChoiceGroupOption[] = [
    { key: 'active', text: getDisplayValue('active') },
    { key: 'inactive', text: getDisplayValue('inactive') },
];

const initialValues: IUserValues = {
    mode: 'readonly',
    data: {
        id: null,
        key: '-1',
        name: null,
        status: 'active',
        fullname: null,
        organization: null,
        position: null,
        email: null,
        lastUpdate: null,
        group: null,
        employee: null,
    }
};

export const UserForm: React.FunctionComponent<RouteComponentProps<{ site: string, id: string, groupId: string }>> = (props) => {
    const { profile, showDialog } = useContext(AppContext);
    const [values, setValues] = useState<IUserValues>(initialValues);
    const [originalValues, setOriginalValues] = useState<IUser | undefined>(undefined);
    const [isLoading, { setFalse: hideLoading, setTrue: showLoading }] = useBoolean(false);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;
    const isLargeDevice = modalResponsiveMode === ResponsiveMode.large || modalResponsiveMode === ResponsiveMode.xLarge;

    const service = useMemo(() => new UserService(), []);
    const employeeService = useMemo(() => new EmployeeService(), []);
    const groupService = useMemo(() => new GroupService(), []);

    const { match: { params: { site, id, groupId } }, history, location: { state } } = props;

    const selectedEmployee: IPickerItem<IEmployee> | null = values.data.employee
        ? { key: values.data.employee.id!, name: values.data.employee.name!, data: values.data.employee }
        : null;

    if (values.mode === 'new' && !values.data.group && groupId) {
        const searchValues = state as IUserSearchValues;
        if (searchValues && searchValues.group) {
            values.data.group = searchValues.group;
        }
    }

    let selectedGroup: IPickerItem<IGroup> | null = values.data.group
        ? { key: values.data.group.id!, name: values.data.group.name!, data: values.data.group }
        : null;

    const readonly = values.mode === 'readonly';

    useEffect(() => {
        let isMounted = true;

        if (!id || id === 'new') {
            setValues({
                ...cloneDeep(initialValues),
                mode: 'new',
            });
            return;
        }

        const promise = service.get({ id });
        promise.then(data => {
            if (!isMounted || !data || !data.id) {
                setValues({ ...cloneDeep(initialValues), mode: 'readonly' });
                setOriginalValues(undefined);
                return;
            }

            setValues({
                ...cloneDeep(initialValues),
                data,
                mode: 'edit',
            });

            setOriginalValues(cloneDeep(data));
        });

        return () => {
            setValues(cloneDeep(initialValues));
            setOriginalValues(undefined);
        };
    }, [id, service]);

    let listLink = `/site/${site}/administrator/user/userlist`;
    if (groupId) {
        listLink = `/site/${site}/administrator/user/groupuser/${groupId}`;
    }

    const onClickListLink = (ev?: React.MouseEvent<HTMLElement>) => {
        ev?.preventDefault();
        history.push(listLink, { from: 'form', values: state });
    };

    const breadcrumbItems: IBreadcrumbItem[] = [
        { ...listBreadcrumbItems, href: listLink, onClick: onClickListLink },
        {
            key: 'form',
            text: originalValues?.name || (values.mode === 'new' ? 'New' : '...'),
        }
    ];

    const fieldStackStyles: IStackStyles = {
        root: {
            width: isSmallDevice ? '100%' : 'calc(50% - 10px)',
        }
    };

    const onChange = <K extends keyof IUser>(newValue?: IUser[K] | null, fieldName?: K) => {
        if (!fieldName) {
            return;
        }
        const newValues: IUserValues = { ...values };
        newValues.data[fieldName] = newValue || null as IUser[K];
        setValues(newValues);
    };

    const onChangeChoiceField = <K extends keyof IUser>(newValue?: IUser[K] | null, fieldName?: K) => {
        if (!fieldName) {
            return;
        }

        const newValues: IUserValues = { ...values };
        newValues.data[fieldName] = newValue as IUser[K];
        setValues(newValues);
    };

    const onEmployeeChange = (employee?: IEmployee | null) => {
        const data = { ...values.data };
        if (employee) {
            setValues(prev => ({
                ...prev,
                data: {
                    ...data,
                    employee,
                    name: employee.name,
                    fullname: employee.fullname,
                    position: employee.position,
                    organization: employee.organization,
                    email: employee.email,
                }
            }));
        } else {
            setValues(prev => ({
                ...prev,
                data: {
                    ...data,
                    employee: null,
                    name: null,
                    fullname: null,
                    position: null,
                    organization: null,
                    email: null,
                }
            }));
        }
    };

    const onClickReset = () => {
        setValues({ ...values, data: cloneDeep(originalValues!) });
    };

    const onClickCancel = () => {
        onClickListLink();
    };

    const onSaveResponse = (action: string, saved: ISaveResponse<IUser>, isBackToList: boolean = true) => {
        if (saved.isError) {
            showDialog({
                title: `${action} failed`,
                dialogContentProps: {
                    type: DialogType.normal,
                }
            }, () => {
                return (
                    <>
                        {saved.errorMessage &&
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {saved.errorMessage}
                            </MessageBar>
                        }
                        {saved.errorMessages && saved.errorMessages.map((err, i) => (
                            <MessageBar key={i}
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {err}
                            </MessageBar>
                        ))}
                    </>
                );
            });

            return;
        } else {
            onClickListLink();
        }
    };

    const onValidateForm = () => {
        const errorMessages: string[] = [];
        if (!values.data.employee) {
            errorMessages.push('User name is required');
        }

        if (!values.data.group) {
            errorMessages.push('User group is required');
        }

        if (!isEmpty(errorMessages)) {
            showDialog({
                title: `Invalid form`,
                dialogContentProps: {
                    type: DialogType.normal,
                }
            }, () => {
                return (
                    <>
                        {errorMessages && errorMessages.map((err, i) => (
                            <MessageBar key={i}
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {err}
                            </MessageBar>
                        ))}
                    </>
                );
            });

            return false;
        }

        return true;
    };

    const onClickSave = async () => {
        if (!onValidateForm()) {
            return;
        }

        showLoading();

        if (values.mode === 'new') {
            const data = { ...values.data, site: profile?.site! }
            await service.create(data, saved => onSaveResponse('Save data', saved));
        }

        if (values.mode === 'edit') {
            await service.update(id, values.data, saved => onSaveResponse('Save data', saved));
        }

        hideLoading();
    };

    const onMapEmployeeFilter = (filter: string, defaultFilter: ISearchParameters): ISearchParameters => {
        return {
            ...defaultFilter,
            excludeUser: true,
            isIncludeOrg: true,
        };
    };

    return (
        <Stack tokens={stackTokens}>
            <NavigationPane items={breadcrumbItems || []} />

            <Stack horizontal wrap={isSmallDevice} tokens={stackTokens} >
                <Stack tokens={stackTokens} styles={{ root: { minWidth: isSmallDevice ? '100%' : isLargeDevice ? '80%' : '1000px' } }}>
                    <Stack horizontal wrap tokens={wrapStackTokens}>
                        <Stack styles={fieldStackStyles}>
                            <Picker
                                pickOnlyOne
                                service={employeeService}
                                onMapFilter={onMapEmployeeFilter}
                                label='User name'
                                placeholder='Search by ID , Name'
                                selectedItems={selectedEmployee ? [selectedEmployee] : []}
                                onChange={data => onEmployeeChange((data && data[0]) ? data[0].data : null)}
                                required
                                minimumTextLength={3}
                            />
                        </Stack>
                        <Stack styles={fieldStackStyles}>
                            <Picker
                                pickOnlyOne
                                service={groupService}
                                label='User group'
                                selectedItems={selectedGroup ? [selectedGroup] : []}
                                onChange={data => onChange((data && data[0]) ? data[0].data : null, 'group')}
                                required
                            />
                        </Stack>
                    </Stack>

                    <Stack horizontal wrap tokens={wrapStackTokens}>
                        <Stack styles={fieldStackStyles}>
                            <TextField
                                label='Fullname'
                                value={values.data.fullname || ''}
                                onChange={e => e.preventDefault()}
                                disabled
                            />
                        </Stack>
                        <Stack styles={fieldStackStyles}>
                            <TextField
                                label='Position'
                                value={values.data.position || ''}
                                onChange={e => e.preventDefault()}
                                disabled
                            />
                        </Stack>
                    </Stack>

                    <Stack horizontal wrap tokens={wrapStackTokens}>
                        <Stack styles={fieldStackStyles}>
                            <TextField
                                label='Organization'
                                value={values.data.organization || ''}
                                onChange={e => e.preventDefault()}
                                disabled
                            />
                        </Stack>
                        <Stack styles={fieldStackStyles}>
                            <TextField
                                label='Email'
                                value={values.data.email || ''}
                                onChange={e => e.preventDefault()}
                                disabled
                            />
                        </Stack>
                    </Stack>

                </Stack>
                <Stack >
                    <ChoiceGroup
                        selectedKey={values.data.status || 'active'}
                        options={statusOptions}
                        disabled={readonly}
                        label="Status"
                        onChange={(e, o) => onChangeChoiceField(o?.key as IActiveStatus, 'status')}
                    />
                </Stack>
            </Stack>

            <Stack horizontal tokens={stackTokens}>
                {!readonly && <PrimaryButton text='Save' onClick={onClickSave} />}
                {values.mode === 'edit' && <DefaultButton text='Reset' onClick={onClickReset} />}
                <DefaultButton text='Cancel' onClick={onClickCancel} />
            </Stack>

            <Debug object={values} />

            <OverlayLoading isVisibled={isLoading} />
        </Stack>
    )
};