import { ActionButton, IconButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react/lib/Dropdown";
import { GroupedList, GroupHeader, IGroup, IGroupHeaderProps, IGroupHeaderStyles, IGroupRenderProps } from "@fluentui/react/lib/GroupedList";
import { SelectionMode } from "@fluentui/react/lib/Selection";
import { IStackProps, IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { TextField } from "@fluentui/react/lib/TextField";
import { FontWeights, useTheme } from "@fluentui/react/lib/Theme";
import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { IRequest, IRequestStatusKeys, ISearchResult } from "../../models";
import { isEmpty, mapRequestDocToSearchDoc, onFormatDate, onFormatDateTime } from "../../tools";
import { useConst, useBoolean } from '@fluentui/react-hooks';
import { RequestService } from "../../services";
import { itemClassNames, SearchSuggestionItem } from "../../components/SearchSuggestionItem";
import { ITextStyles, Text } from "@fluentui/react/lib/Text";
import { Link } from "@fluentui/react/lib/Link";
import { getRequestStatusColor } from "../../tools/color";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Debug } from "../../components/Debug";
import { getDisplayValue } from "../../tools/value";
import { useCallback } from "react";
import { Loading } from "../../components/Loading";
import { useContext } from "react";
import { AppContext } from "../../contexts";
import { ProcessLog } from "../../components/ProcessLog";
import { DialogType } from "@fluentui/react/lib/Dialog";
import { cloneDeep } from "lodash";
import { PageContainer } from "../../components/PageContainer";
import { DatePicker } from "../../components/DatePicker";
import { Pagination } from "../../components/Pagination";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";

export interface IMyRequestSearchValues {
    keyword?: string;
    dateFrom?: string | null | undefined;
    dateTo?: string | null | undefined;
    status?: IRequestStatusKeys;
    pageNumber: number;
    numberPerPage: number;
    expandedIds: { [key: string]: boolean };
    sortedBy: keyof IRequest;
    sortedType: 'asc' | 'desc';
}

const stackTokens: IStackTokens = { childrenGap: 10 };

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { minWidth: 200 },
};

const initialStatus: IRequestStatusKeys = 'all';
const statusOptions: IDropdownOption<IRequestStatusKeys>[] = [
    { key: 'all', text: getDisplayValue('all'), data: 'all' },
    { key: 'draft', text: getDisplayValue('draft'), data: 'draft' },
    { key: 'waiting-vp-approve', text: getDisplayValue('waiting-vp-approve'), data: 'waiting-vp-approve' },
    { key: 'waiting-admin-verify', text: getDisplayValue('waiting-admin-verify'), data: 'waiting-admin-verify' },
    { key: 'waiting-clgvp-approve', text: getDisplayValue('waiting-clgvp-approve'), data: 'waiting-clgvp-approve' },
    { key: 'approved', text: getDisplayValue('approved'), data: 'approved' },
    { key: 'rejected', text: getDisplayValue('rejected'), data: 'rejected' },
    // { key: 'inactive', text: getDisplayValue('inactive'), data: 'inactive' },
];

const numberPerPageOptions: IDropdownOption<number>[] = [
    { key: '20', text: '20', data: 20 },
    { key: '50', text: '50', data: 50 },
    { key: '100', text: '100', data: 100 },
    { key: '200', text: '200', data: 200 },
];

const itemHeaderClassNames = mergeStyleSets(itemClassNames, {
    documentName: { fontWeight: FontWeights.bold },
    parties: { fontWeight: FontWeights.bold },
    date: { fontWeight: FontWeights.bold }
});

const initialValues: IMyRequestSearchValues = {
    status: initialStatus,
    pageNumber: 1,
    numberPerPage: 20,
    expandedIds: {},
    sortedBy: 'requestDate',
    sortedType: 'desc'
};

export const MyRequestList: React.FunctionComponent<RouteComponentProps> = (props) => {
    const { profile, clearDraftDocuments, showDialog } = useContext(AppContext);
    const theme = useTheme();
    const service = useConst(new RequestService());

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;
    const isLargeDevice = modalResponsiveMode === ResponsiveMode.large;

    const { match: { url }, location: { search, state }, history } = props;

    const [searchValues, setSearchValues] = useState<IMyRequestSearchValues>(cloneDeep({ ...initialValues }));
    const [results, setResults] = useState<ISearchResult<IRequest> | undefined>(undefined);
    const [isLoading, { setFalse: hideLoading, setTrue: showLoading }] = useBoolean(false);

    const selectedPageIndex: number = searchValues.pageNumber - 1;
    const newUrl = `${url}/new`;
    const today = new Date(new Date().setHours(0, 0, 0, 0));

    const getEditLink = (id: string) => ({ pathname: `${url}/${id}`, state: searchValues });

    const onGetResults = useCallback((values: IMyRequestSearchValues, page: number, numPerPage: number, getMountStatus?: () => boolean) => {
        setSearchValues({
            ...values,
            pageNumber: page,
            numberPerPage: numPerPage,
        });

        showLoading();

        const submitvalues = {
            myrequest: true,
            keyword: values.keyword,
            dateFrom: values.dateFrom,
            dateTo: values.dateTo,
            status: values.status,
            pageNumber: page,
            numberPerPage: numPerPage,
            siteId: profile?.site?.id,
        };

        const promise = service.search(submitvalues);

        promise.then(results => {
            if (getMountStatus && !getMountStatus()) {
                setResults(undefined);
                hideLoading();
                return;
            }

            setResults(prev => ({ ...prev, ...results }));
            hideLoading();
        });
    }, [profile, service, setSearchValues, setResults, showLoading, hideLoading]);

    useEffect(() => {
        let isMounted = true;

        const queryString = new URLSearchParams(search);
        let queryStringStatus: IRequestStatusKeys | null = queryString.get('status') as IRequestStatusKeys;

        const locationState = state as { from: string, values: IMyRequestSearchValues };
        let newValues: IMyRequestSearchValues = cloneDeep({ ...initialValues, status: initialStatus });
        if (locationState && locationState.from && locationState.from === 'form') {
            newValues = { ...newValues, ...locationState.values };
        }

        if (queryStringStatus && statusOptions.some(o => o.key === queryStringStatus)) {
            newValues.status = queryStringStatus;
        }

        onGetResults(newValues, newValues.pageNumber, newValues.numberPerPage, () => isMounted);

        return () => {
            isMounted = false;
            setSearchValues(initialValues);
            setResults(undefined);
        }
    }, [service, search, state, onGetResults]);

    const onCreateClick = (ev: React.MouseEvent<HTMLHtmlElement>) => {
        ev.preventDefault();
        clearDraftDocuments();
        history.push(newUrl, searchValues);
    };

    const onEditClick = (ev: any, id: string) => {
        ev && ev.preventDefault();
        clearDraftDocuments();
        history.push(getEditLink(id));
    };

    const onSubmit = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.code === 'Enter' || ev.code === 'NumpadEnter') {
            onGetResults(searchValues, 1, searchValues.numberPerPage);
        }
    };

    const onSearchClick = () => {
        onGetResults(searchValues, 1, searchValues.numberPerPage);
    };

    const onSearchChange = (ev: React.FormEvent<HTMLElement>, newValue?: string) => {
        setSearchValues(prev => ({ ...prev, keyword: newValue }))
    }

    const onDateFromChange = (date: Date | null | undefined) => {
        setSearchValues(prev => ({ ...prev, dateFrom: date ? new Date(date.setHours(0, 0, 0, 0)).toISOString() : null }))
    }

    const onDateToChange = (date: Date | null | undefined) => {
        setSearchValues(prev => ({ ...prev, dateTo: date ? new Date(date.setHours(0, 0, 0, 0)).toISOString() : null }))
    }

    const onStatusChange = (ev: React.FormEvent<HTMLElement>, option?: IDropdownOption<IRequestStatusKeys> | undefined) => {
        const newStatus: IRequestStatusKeys = option && option?.data ? option.data : 'all';
        setSearchValues(prev => ({ ...prev, status: newStatus }))
    }

    const suggestionItemStackProps: Partial<IStackProps> = {
        styles: {
            root: {
                margin: '10px 0 10px 0',
                borderBottom: `1px solid ${theme?.palette.neutralQuaternaryAlt}`
            }
        }
    };

    const onRenderCell = (nestingDepth?: number, item?: IRequest, itemIndex?: number): React.ReactNode => {
        if (!item) {
            return null;
        }

        const docs = item.documents;
        const status = item.status;

        const expiredDate = item.expiredDate ? new Date(item.expiredDate!) : null;
        const isOwner = profile?.employee?.id === item.requestFor?.id;
        const isExpired = expiredDate && item.status === 'approved' && expiredDate <= today;

        if (isSmallDevice) {
            return item && typeof itemIndex === 'number' && itemIndex > -1 ? (
                <Stack>
                    {(docs && !isEmpty(docs)) && (
                        docs.map((doc, i) => {
                            const newDoc = { ...doc };
                            if (newDoc.approve && status === 'approved' && isOwner && !isExpired) {
                                if (newDoc.requestType === 'print') {
                                    newDoc.permission?.push('view');
                                    newDoc.permission?.push('print');
                                } else {
                                    newDoc.permission?.push('view');
                                }
                            }

                            return (
                                <SearchSuggestionItem
                                    stackProps={suggestionItemStackProps}
                                    key={i} doc={mapRequestDocToSearchDoc(newDoc)}
                                    requestId={item.id!}
                                />
                            )
                        })
                    )}
                </Stack>
            ) : null;
        } else {
            return item && typeof itemIndex === 'number' && itemIndex > -1 ? (
                <Stack styles={{ root: { margin: '0 10px 0 50px' } }}>
                    <Stack grow={1} horizontal styles={{ root: { height: 32, margin: '5px 0 5px 0' } }}>
                        <Text className={itemHeaderClassNames.documentName}>Document name</Text>
                        <Text className={itemHeaderClassNames.parties}>Parties</Text>
                        <Text className={itemHeaderClassNames.date}>Date</Text>
                    </Stack>
                    {(docs && !isEmpty(docs)) && (
                        docs.map((doc, i) => {
                            const newDoc = { ...doc };
                            if (newDoc.approve && status === 'approved' && isOwner && !isExpired) {
                                if (newDoc.requestType === 'print') {
                                    newDoc.permission?.push('view');
                                    newDoc.permission?.push('print');
                                } else {
                                    newDoc.permission?.push('view');
                                }
                            }

                            return (
                                <SearchSuggestionItem
                                    stackProps={suggestionItemStackProps}
                                    key={i} doc={mapRequestDocToSearchDoc(newDoc)}
                                    requestId={item.id!}
                                />
                            )
                        })
                    )}
                </Stack>
            ) : null;
        }
    };

    const items = results ? results.items : [];
    const groups: IGroup[] = isEmpty(items) ? [] : items.map((item, i) => {
        return {
            count: 1,
            key: i.toString(),
            name: item.requestNo ?? 'unknonw',
            startIndex: i,
            isCollapsed: !!!searchValues.expandedIds[item.id!],
            data: item,
        }
    });

    const getTextLabelStyles: Partial<ITextStyles> = {
        root: {
            color: theme.palette.neutralSecondaryAlt,
            minWidth: 50,
        }
    }

    const getRequestForTextLabelStyles: Partial<ITextStyles> = {
        root: {
            color: theme.palette.neutralSecondaryAlt,
            minWidth: 100,
        }
    }

    const getRequestDateTextLabelStyles: Partial<ITextStyles> = {
        root: {
            color: theme.palette.neutralSecondaryAlt,
            minWidth: 100,
        }
    }

    const onClickViewLog = (item: IRequest) => {
        showDialog({
            title: 'Proccess log',
            minWidth: 500,
            dialogContentProps: {
                type: DialogType.normal,
            }
        }, () => {
            return <ProcessLog item={item} displayOnly />;
        });
    };

    const onRenderGroupTitle = (props?: IGroupHeaderProps) => {
        if (!props) {
            return null;
        }

        const { groupIndex } = props;

        if (!groupIndex && groupIndex !== 0) {
            return null;
        }

        if (!items[groupIndex]) {
            return null;
        }

        const request = items[groupIndex];

        const statusStyles: Partial<ITextStyles> = {
            root: {
                color: getRequestStatusColor(theme, request.status)
            }
        }

        const requestFor = request.requestFor
            ? request.requestFor.name || ''
            : request.requestFors && !isEmpty(request.requestFors)
                ? request.requestFors.map(rf => rf.name).join(", ")
                : '';

        if (isSmallDevice) {
            return (
                <Stack tokens={stackTokens}>
                    <Stack tokens={stackTokens} horizontal>
                        <Text styles={getTextLabelStyles}>Request No. :</Text>
                        {request.id && (<Link onClick={e => onEditClick(e, request.id!)}>{request.requestNo}</Link>)}
                    </Stack>
                    <Stack tokens={stackTokens} horizontal>
                        <Text styles={getTextLabelStyles}>Request Date. :</Text>
                        <Text>{request.requestDate && onFormatDateTime(new Date(request.requestDate))}</Text>
                    </Stack>
                    <Stack tokens={stackTokens} horizontal>
                        <Text styles={getRequestForTextLabelStyles}>Request for :</Text>
                        {<Text>{requestFor}</Text>}
                    </Stack>
                    <Stack tokens={stackTokens} horizontal>
                        <Text styles={getTextLabelStyles}>Start - End Date. :</Text>
                        <Text>{request.startDate && onFormatDate(new Date(request.startDate))} - {request.endDate && onFormatDate(new Date(request.endDate))}</Text>
                    </Stack>
                    <Stack tokens={stackTokens} horizontal verticalAlign='center'>
                        {request.status !== 'draft' &&
                            <IconButton iconProps={{ iconName: 'WaitlistConfirm' }} onClick={() => onClickViewLog(request)} />
                        }
                        <Text styles={getTextLabelStyles}>Status :</Text>
                        <Text styles={statusStyles}>{getDisplayValue(request.status)}</Text>
                    </Stack>
                </Stack>
            );
        } else {
            return (
                <Stack grow={1} tokens={stackTokens} horizontal styles={{ root: { minHeight: 60, padding: 10 } }}>
                    <Stack tokens={stackTokens} styles={{ root: { width: '30%' } }}>
                        <Stack tokens={stackTokens} horizontal>
                            <Text styles={getTextLabelStyles}>Request No. :</Text>
                            {request.id && (<Link onClick={e => onEditClick(e, request.id!)}>{request.requestNo}</Link>)}
                        </Stack>
                        <Stack tokens={stackTokens} horizontal>
                            <Text styles={getRequestDateTextLabelStyles}>Request Date. :</Text>
                            <Text>{request.requestDate && onFormatDateTime(new Date(request.requestDate))}</Text>
                        </Stack>
                    </Stack>
                    <Stack tokens={stackTokens} styles={{ root: { width: '45%' } }}>
                        <Stack tokens={stackTokens} horizontal>
                            <Text styles={getRequestForTextLabelStyles}>Request for :</Text>
                            {<Text>{requestFor}</Text>}
                        </Stack>
                        <Stack tokens={stackTokens} horizontal>
                            <Text styles={getTextLabelStyles}>Start - End Date. :</Text>
                            <Text>{request.startDate && onFormatDate(new Date(request.startDate))} - {request.endDate && onFormatDate(new Date(request.endDate))}</Text>
                        </Stack>
                    </Stack>
                    <Stack tokens={stackTokens} styles={{ root: { width: '25%' } }}>
                        <Stack tokens={stackTokens} horizontal verticalAlign='center'>
                            {request.status !== 'draft' &&
                                <IconButton iconProps={{ iconName: 'WaitlistConfirm' }} onClick={() => onClickViewLog(request)} />
                            }
                            <Text styles={getTextLabelStyles}>Status :</Text>
                            <Text styles={statusStyles}>{getDisplayValue(request.status)}</Text>
                        </Stack>
                    </Stack>
                </Stack>
            );
        }
    }

    const onToggleCollapse = (group: IGroup) => {
        const expandedIds = { ...searchValues.expandedIds };
        const item = group.data! as IRequest;

        expandedIds[item.id!] = group.isCollapsed ? true : false;

        setSearchValues(prev => ({ ...prev, expandedIds }))
    };

    const groupProps: IGroupRenderProps = {
        onRenderHeader: (props?: IGroupHeaderProps) => {
            const headerStyles: Partial<IGroupHeaderStyles> = {
                root: {
                    backgroundColor: props
                        ? props.groupIndex! % 2 === 0
                            ? 'rgb(223 246 221 / 29%)'
                            : ''
                        : '',
                },
                groupHeaderContainer: {
                    height: 'auto',
                },
            };
            return (
                <GroupHeader {...props} styles={headerStyles} onRenderTitle={onRenderGroupTitle} />
            )
        },
        headerProps: {
            onToggleCollapse: onToggleCollapse
        }
    };

    const onNumberPerPageChange = (ev: React.FormEvent<HTMLElement>, option?: IDropdownOption<number> | undefined) => {
        const newNumberPerPage: number = option && option?.data ? option.data : searchValues.numberPerPage;
        onGetResults(searchValues, 1, newNumberPerPage);
    };

    const onSelectedPageIndex = (index: number) => {
        onGetResults(searchValues, index + 1, searchValues.numberPerPage);
    };

    return (
        <PageContainer>
            <Stack horizontal wrap={isSmallDevice || isLargeDevice} tokens={stackTokens} verticalAlign='start' horizontalAlign='start'>
                <ActionButton iconProps={{ iconName: 'Add' }} href={newUrl} onClick={onCreateClick}>
                    Create
                </ActionButton>
                <DatePicker
                    placeholder="Date from"
                    value={searchValues.dateFrom ? new Date(searchValues.dateFrom) : undefined}
                    onSelectDate={onDateFromChange}
                />
                <DatePicker
                    placeholder="Date to"
                    value={searchValues.dateTo ? new Date(searchValues.dateTo) : undefined}
                    onSelectDate={onDateToChange}
                />
                <Dropdown
                    placeholder="Status"
                    options={statusOptions}
                    styles={dropdownStyles}
                    selectedKey={searchValues.status}
                    onChange={onStatusChange}
                />
                <Stack grow={1}>
                    <TextField
                        onChange={onSearchChange}
                        placeholder="Search request no, document name, parties and hashtag"
                        iconProps={{ iconName: 'Search' }}
                        onKeyDown={onSubmit}
                        value={searchValues.keyword || ''}
                    />
                </Stack>

                <PrimaryButton text="Search" onClick={onSearchClick} />
            </Stack>

            {(!isLoading && results && items && !isEmpty(items)) ? (
                <>
                    <Stack grow={1}>
                        <GroupedList
                            items={items}
                            onRenderCell={onRenderCell}
                            selectionMode={SelectionMode.none}
                            groups={groups}
                            compact={true}
                            groupProps={groupProps}
                        />
                    </Stack>

                    <Stack tokens={stackTokens} horizontal>
                        <Pagination
                            selectedPageIndex={selectedPageIndex}
                            onPageChange={onSelectedPageIndex}
                            pageCount={results.pageTotal}
                            itemsPerPage={searchValues.numberPerPage}
                            totalItemCount={results.total}
                            numberPerPageOptions={numberPerPageOptions}
                            defaultSelectedKey={searchValues.numberPerPage?.toString()}
                            onNumberPerPageChange={onNumberPerPageChange}
                        />
                    </Stack>
                </>
            ) : isLoading ? <Loading /> : <Text>No Result</Text>}

            <Debug object={{ ...searchValues }} />
        </PageContainer>
    );
};