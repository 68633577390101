import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import { MyApproveForm } from "./MyApproveForm";
import { MyApproveList } from "./MyApproveList";

export const MyApprove: React.FunctionComponent<RouteComponentProps> = props => {
    const { match: { path } } = props

    return (
        <Switch>
            <Switch>
                <Route exact path={path} component={MyApproveList} />
                <Route path={`${path}/:id`} component={MyApproveForm} />
            </Switch>
        </Switch>
    )
}