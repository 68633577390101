import { ApiService } from ".";
import authService from "../components/api-authorization/AuthorizeService";
import { ISearchParameters, ISearchResult, IParameters, ISaveResponse, IActivitiesLogReport, IUserReport, IDocumentReport, IRequestReport } from "../models";

export class ReportService implements ApiService<any> {
    get(parameters: IParameters): Promise<any> {
        throw new Error("Method not implemented.");
    }
    search(parameters: ISearchParameters): Promise<ISearchResult<any>> {
        throw new Error("Method not implemented.");
    }

    async activitiesSearch(parameters: ISearchParameters): Promise<ISearchResult<IActivitiesLogReport>> {
        const { numberPerPage, pageNumber } = parameters;
        try {
            const token = await authService.getAccessToken();
            const search: IParameters = {};
            Object.keys(parameters).forEach(key => {
                if (parameters[key] === undefined || parameters[key] === null) {
                    return;
                }

                search[`parameters.${key}`] = parameters[key];
            });

            const queryString = new URLSearchParams(search).toString();
            const response = await fetch(`api/Report/activities/search?${queryString}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });
            const json = await response.json();
            return json;
        } catch {
            return await Promise.resolve({
                total: 0,
                items: [],
                pageNumber,
                pageTotal: 0,
                numberPerPage,
                startIndex: 0,
                endIndex: 0,
            });
        }
    }

    async requestSearch(parameters: ISearchParameters): Promise<ISearchResult<IRequestReport>> {
        const { numberPerPage, pageNumber } = parameters;
        try {
            const token = await authService.getAccessToken();
            const search: IParameters = {};
            Object.keys(parameters).forEach(key => {
                if (parameters[key] === undefined || parameters[key] === null) {
                    return;
                }

                search[`parameters.${key}`] = parameters[key];
            });

            const queryString = new URLSearchParams(search).toString();
            const response = await fetch(`api/Report/request/search?${queryString}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });
            const json = await response.json();
            return json;
        } catch {
            return await Promise.resolve({
                total: 0,
                items: [],
                pageNumber,
                pageTotal: 0,
                numberPerPage,
                startIndex: 0,
                endIndex: 0,
            });
        }
    }

    async userSearch(parameters: ISearchParameters): Promise<ISearchResult<IUserReport>> {
        const { numberPerPage, pageNumber } = parameters;
        try {
            const token = await authService.getAccessToken();
            const search: IParameters = {};
            Object.keys(parameters).forEach(key => {
                if (parameters[key] === undefined || parameters[key] === null) {
                    return;
                }

                search[`parameters.${key}`] = parameters[key];
            });

            const queryString = new URLSearchParams(search).toString();
            const response = await fetch(`api/Report/user/search?${queryString}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });
            const json = await response.json();
            return json;
        } catch {
            return await Promise.resolve({
                total: 0,
                items: [],
                pageNumber,
                pageTotal: 0,
                numberPerPage,
                startIndex: 0,
                endIndex: 0,
            });
        }
    }

    async documentSearch(parameters: ISearchParameters): Promise<ISearchResult<IDocumentReport>> {
        const { numberPerPage, pageNumber } = parameters;
        try {
            const token = await authService.getAccessToken();
            const search: IParameters = {};
            Object.keys(parameters).forEach(key => {
                if (parameters[key] === undefined || parameters[key] === null) {
                    return;
                }

                search[`parameters.${key}`] = parameters[key];
            });

            const queryString = new URLSearchParams(search).toString();
            const response = await fetch(`api/Report/document/search?${queryString}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });
            const json = await response.json();
            return json;
        } catch {
            return await Promise.resolve({
                total: 0,
                items: [],
                pageNumber,
                pageTotal: 0,
                numberPerPage,
                startIndex: 0,
                endIndex: 0,
            });
        }
    }

    async activitiesExport(parameters: IParameters, callback?: (message: string) => void): Promise<void> {
        try {
            const token = await authService.getAccessToken();
            const search: IParameters = {};
            Object.keys(parameters).forEach(key => {
                if (parameters[key] === undefined || parameters[key] === null) {
                    return;
                }

                search[`parameters.${key}`] = parameters[key];
            });

            const queryString = new URLSearchParams(search).toString();
            const response = await fetch(`api/Report/activities/export?${queryString}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });

            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                const err: ISaveResponse<any> = await response.json();
                if (callback) {
                    callback(err.errorMessage || 'Unknown error while downloading report');
                }

                return;
            }

            const filename = this.getFileName(response, 'ActivityLogReport.xlsx');
            const blob = await response.blob();
            const url = URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode!.removeChild(link);
        } catch (err: any) {
            if (callback) {
                callback("Unknow error");
            }
        }

    }

    async requestExport(parameters: IParameters, callback?: (message: string) => void): Promise<void> {
        try {
            const token = await authService.getAccessToken();
            const search: IParameters = {};
            Object.keys(parameters).forEach(key => {
                if (parameters[key] === undefined || parameters[key] === null) {
                    return;
                }

                search[`parameters.${key}`] = parameters[key];
            });

            const queryString = new URLSearchParams(search).toString();
            const response = await fetch(`api/Report/request/export?${queryString}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });

            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                const err: ISaveResponse<any> = await response.json();
                if (callback) {
                    callback(err.errorMessage || 'Unknown error while downloading report');
                }

                return;
            }

            const filename = this.getFileName(response, 'RequestReport.xlsx');
            const blob = await response.blob();
            const url = URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode!.removeChild(link);
        } catch (err: any) {
            if (callback) {
                callback("Unknow error");
            }
        }

    }

    async userExport(parameters: IParameters, callback?: (message: string) => void): Promise<void> {
        try {
            const token = await authService.getAccessToken();
            const search: IParameters = {};
            Object.keys(parameters).forEach(key => {
                if (parameters[key] === undefined || parameters[key] === null) {
                    return;
                }

                search[`parameters.${key}`] = parameters[key];
            });

            const queryString = new URLSearchParams(search).toString();
            const response = await fetch(`api/Report/user/export?${queryString}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });

            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                const err: ISaveResponse<any> = await response.json();
                if (callback) {
                    callback(err.errorMessage || 'Unknown error while downloading report');
                }

                return;
            }

            const filename = this.getFileName(response, 'UserReport.xlsx');
            const blob = await response.blob();
            const url = URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode!.removeChild(link);
        } catch (err: any) {
            if (callback) {
                callback("Unknow error");
            }
        }

    }

    async documentExport(parameters: IParameters, callback?: (message: string) => void): Promise<void> {
        try {
            const token = await authService.getAccessToken();
            const search: IParameters = {};
            Object.keys(parameters).forEach(key => {
                if (parameters[key] === undefined || parameters[key] === null) {
                    return;
                }

                search[`parameters.${key}`] = parameters[key];
            });

            const queryString = new URLSearchParams(search).toString();
            const response = await fetch(`api/Report/document/export?${queryString}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });

            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                const err: ISaveResponse<any> = await response.json();
                if (callback) {
                    callback(err.errorMessage || 'Unknown error while downloading report');
                }

                return;
            }

            const filename = this.getFileName(response, 'DocumentReport.xlsx');
            const blob = await response.blob();
            const url = URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode!.removeChild(link);
        } catch (err: any) {
            if (callback) {
                callback("Unknow error");
            }
        }

    }

    async expirationExport(parameters: IParameters, callback?: (message: string) => void): Promise<void> {
        try {
            const token = await authService.getAccessToken();
            const search: IParameters = {};
            Object.keys(parameters).forEach(key => {
                if (parameters[key] === undefined || parameters[key] === null) {
                    return;
                }

                search[`parameters.${key}`] = parameters[key];
            });

            const queryString = new URLSearchParams(search).toString();
            const response = await fetch(`api/Report/expiration/export?${queryString}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });

            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                const err: ISaveResponse<any> = await response.json();
                if (callback) {
                    callback(err.errorMessage || 'Unknown error while downloading report');
                }

                return;
            }

            const filename = this.getFileName(response, 'ExpirationReport.xlsx');
            const blob = await response.blob();
            const url = URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode!.removeChild(link);
        } catch (err: any) {
            if (callback) {
                callback("Unknow error");
            }
        }

    }

    getFileName(response: Response, defaultName: string) {
        let filename = defaultName;
        var disposition = response.headers.get('content-disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }

        return filename;
    }
}
