import { ActionButton } from "@fluentui/react/lib/Button";
import { Icon } from "@fluentui/react/lib/Icon";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { useTheme } from "@fluentui/react/lib/Theme";
import Collapse from "@material-ui/core/Collapse";
import { alpha, createStyles, createTheme, Theme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import TreeItem, { TreeItemProps } from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import { cloneDeep, orderBy } from "lodash";
import React, { useMemo } from "react";
import { IRenderTree } from "../models";
import { isEmpty } from "../tools";
import { DocumentPathLabel } from "./DocumentPathLabel";

export interface IDocumentPathTreeProps {
    trees: IRenderTree[];
    expanded: string[];
    selected: string[];
    handleToggle?: (event: React.ChangeEvent<{}>, nodeIds: string[]) => void;
    handleSelect?: (event: React.ChangeEvent<{}>, nodeIds: string[]) => void;
    onClickShowDocument: (nodes: IRenderTree) => void;
}

export interface ITreeItemProps extends TreeItemProps {
    nodes?: IRenderTree;
}

const stackTokens: IStackTokens = { childrenGap: 10 };

const sortTree = (nodes: IRenderTree) => {
    const parent = cloneDeep(nodes);
    parent.children = orderBy(parent.children, [
        child => parseInt(child.dataType!.lineno!, 10),
        child => parseInt(child.dataName!.lineno!, 10),
        child => child.dataName?.name,
    ], ["asc", "asc", "asc"]);
    return parent;
};

export const DocumentPathTree: React.FunctionComponent<IDocumentPathTreeProps> = (props) => {
    const theme = useTheme();
    const { trees, expanded = [], selected = [], handleSelect, handleToggle, onClickShowDocument } = props;

    const root = useMemo(() => sortTree(trees[0]), [trees]);

    const treeTheme = createTheme({
        typography: {
            fontFamily: theme.fonts.medium.fontFamily,
        },
        palette: {
            primary: {
                main: theme.palette.themePrimary,
            },
        }
    });

    const _onClickShowDocument = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, nodes: IRenderTree) => {
        if (e.cancelable) {
            e.preventDefault();
        }

        const element = e.target as HTMLButtonElement;
        if (element && element.className.includes('ms-Button-flexContainer')) {
            if (onClickShowDocument) {
                onClickShowDocument(nodes);
            }
        }
    };

    const renderTree = (nodes: IRenderTree) => {
        const parent = sortTree(nodes);;
        return (
            <StyledTreeItem
                key={parent.nodeId}
                nodeId={parent.nodeId}
                label={parent.name}
                nodes={parent}
                onClick={(e) => _onClickShowDocument(e, parent)}
            >
                {Array.isArray(parent.children) ? parent.children.map((node) => renderTree(node)) : null}
            </StyledTreeItem>
        );
    };

    return (
        <ThemeProvider theme={treeTheme}>
            <TreeView
                expanded={expanded}
                selected={selected}
                onNodeToggle={handleToggle}
                onNodeSelect={handleSelect}
                defaultCollapseIcon={<Icon iconName='CollapseContentSingle' />}
                defaultExpandIcon={<Icon iconName='ExploreContentSingle' />}
                defaultEndIcon={<Icon iconName='RemoveFilter' />}
            >
                {root.children!.map(d => renderTree(d))}
            </TreeView>
        </ThemeProvider>
    );
};

const CollapseComponent = (props: TransitionProps) => <Collapse {...props} />;
const StyledTreeItem = withStyles((theme: Theme) =>
    createStyles({
        iconContainer: {
            '& .close': {
                opacity: 0.3,
            },
        },
        group: {
            marginLeft: 7,
            paddingLeft: 18,
            borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,

        },
    }),
)((props: ITreeItemProps) => {
    const { nodes } = props;

    if (!nodes) {
        return <></>;
    }

    const newLable = (
        <Stack tokens={stackTokens} horizontal verticalAlign='center' styles={{ root: { minHeight: 40 } }}>
            <DocumentPathLabel dataNameName={nodes.dataName?.name!} dataTypeName={nodes.dataType?.name!} />
            {nodes.docIds && !isEmpty(nodes.docIds) && (

                <ActionButton>
                    <span style={{ margin: '0 10px 0 10px' }}>
                        <Icon iconName='Documentation' />
                    </span>
                    Show documents ({nodes.docIds.length})
                </ActionButton>
            )}
        </Stack>
    );

    return <TreeItem {...props} TransitionComponent={CollapseComponent} label={newLable} />;
});
