import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import { PageContainer } from "../../../components/PageContainer";
import { TemplateForm } from "./TemplateForm";
import { TemplateList } from "./TemplateList";

export const Template: React.FunctionComponent<RouteComponentProps> = props => {
    const { match: { path } } = props;

    return (
        <PageContainer center={false} >
            <Switch>
                <Route exact path={path} component={TemplateList} />
                <Route path={`${path}/new`} component={TemplateForm} />
                <Route path={`${path}/:id`} component={TemplateForm} />
            </Switch>
        </PageContainer>
    );
};