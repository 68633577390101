import { ApiService } from ".";
import authService from "../components/api-authorization/AuthorizeService";
import { ISearchParameters, ISearchResult, IParameters, IEmployee, IAppConfiguration } from "../models";

export class EmployeeService implements ApiService<IEmployee> {
    async get(parameters: IParameters): Promise<IEmployee | null> {
        try {
            const token = await authService.getAccessToken();
            const id = parameters['id'] as string;
            delete parameters['id'];
            const search: IParameters = {};
            Object.keys(parameters).forEach(key => {
                if (parameters[key] === undefined || parameters[key] === null) {
                    return;
                }
                search[`parameters.${key}`] = parameters[key];
            });

            const queryString = new URLSearchParams(search).toString();
            const response = await fetch(`api/Employee/${id}?parameters=${queryString}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });
            const json = await response.json();
            return json;
        } catch {
            return await Promise.resolve(null);
        }

    }
    async search(parameters: ISearchParameters): Promise<ISearchResult<IEmployee>> {
        const { numberPerPage, pageNumber } = parameters;
        try {
            const token = await authService.getAccessToken();
            const search: IParameters = {};
            Object.keys(parameters).forEach(key => {
                if (parameters[key] === undefined || parameters[key] === null) {
                    return;
                }
                search[`parameters.${key}`] = parameters[key];
            });

            const queryString = new URLSearchParams(search).toString();
            const response = await fetch(`api/Employee/search?${queryString}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });
            const json = await response.json();
            return json;
        } catch {
            return await Promise.resolve({
                total: 0,
                items: [],
                pageNumber,
                pageTotal: 0,
                numberPerPage,
                startIndex: 0,
                endIndex: 0,
            });
        }
    }

    async getConfiguration(returnUrl?: string): Promise<IAppConfiguration | null> {
        try {
            const token = await authService.getAccessToken();
            const queryString = returnUrl ? `?returnUrl=${encodeURIComponent(returnUrl)}` : '';
            const response = await fetch(`api/Employee/configuration${queryString}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });
            const json = await response.json();
            return json;
        } catch (err: any) {
            return await Promise.resolve(null);
        }
    }
}
