import { IconButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react/lib/Dropdown";
import { SelectionMode } from "@fluentui/react/lib/Selection";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { TextField } from "@fluentui/react/lib/TextField";
import { useTheme } from "@fluentui/react/lib/Theme";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link as RouterLink, RouteComponentProps } from "react-router-dom";
import { IEmployee, IRequestDocumentItem, IRequest, IRequestStatusKeys, ISearchResult } from "../../models";
import { isEmpty, onFormatDate, onFormatDateTime } from "../../tools";
import { useConst, useBoolean } from '@fluentui/react-hooks';
import { EmployeeService, RequestService } from "../../services";
import { Text } from "@fluentui/react/lib/Text";
import { Link } from "@fluentui/react/lib/Link";
import { getRequestStatusColor } from "../../tools/color";
import { Debug } from "../../components/Debug";
import { getDisplayValue } from "../../tools/value";
import { useCallback } from "react";
import { Loading } from "../../components/Loading";
import { IPickerItem, Picker } from "../../components/picker";
import { DetailsList, IColumn } from "@fluentui/react/lib/DetailsList";
import { useContext } from "react";
import { AppContext } from "../../contexts";
import { DialogType } from "@fluentui/react/lib/Dialog";
import { ProcessLog } from "../../components/ProcessLog";
import { cloneDeep } from "lodash";
import { PageContainer } from "../../components/PageContainer";
import { DatePicker } from "../../components/DatePicker";
import { Pagination } from "../../components/Pagination";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";

export interface IMyApproveSearchValues {
    keyword?: string;
    dateFrom?: string | null;
    dateTo?: string | null;
    status?: IRequestStatusKeys;
    requestFor: IEmployee | null;
    requestBy: IEmployee | null;
    pageNumber: number;
    numberPerPage: number;
    sortedBy: keyof IRequest;
    sortedType: 'asc' | 'desc';
}

const stackTokens: IStackTokens = { childrenGap: 10 };

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { minWidth: 200 },
};

const initialStatus: IRequestStatusKeys = 'waiting-vp-approve';
const statusOptions: IDropdownOption<IRequestStatusKeys>[] = [
    { key: 'all', text: getDisplayValue('all'), data: 'all' },
    { key: 'waiting-vp-approve', text: getDisplayValue('waiting-vp-approve'), data: 'waiting-vp-approve' },
    { key: 'waiting-admin-verify', text: getDisplayValue('waiting-admin-verify'), data: 'waiting-admin-verify' },
    { key: 'waiting-clgvp-approve', text: getDisplayValue('waiting-clgvp-approve'), data: 'waiting-clgvp-approve' },
    { key: 'approved', text: getDisplayValue('approved'), data: 'approved' },
    { key: 'rejected', text: getDisplayValue('rejected'), data: 'rejected' },
    // { key: 'inactive', text: getDisplayValue('inactive'), data: 'inactive' },
];

const numberPerPageOptions: IDropdownOption<number>[] = [
    { key: '10', text: '10', data: 10 },
    { key: '50', text: '50', data: 50 },
    { key: '100', text: '100', data: 100 },
    { key: '200', text: '200', data: 200 },
];

const initialValues: IMyApproveSearchValues = {
    status: initialStatus,
    pageNumber: 1,
    numberPerPage: 10,
    requestFor: null,
    requestBy: null,
    sortedBy: 'requestDate',
    sortedType: 'desc'
};

export const MyApproveList: React.FunctionComponent<RouteComponentProps> = (props) => {
    const theme = useTheme();
    const { profile, showDialog } = useContext(AppContext);
    const service = useConst(new RequestService());

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;
    const isLargeDevice = modalResponsiveMode === ResponsiveMode.large;

    const { match: { url }, location: { search, state } } = props;

    const [searchValues, setSearchValues] = useState<IMyApproveSearchValues>(initialValues);
    const [results, setResults] = useState<ISearchResult<IRequest> | undefined>(undefined);
    const [isLoading, { setFalse: hideLoading, setTrue: showLoading }] = useBoolean(false);

    const employeeService = useMemo(() => new EmployeeService(), []);
    const items = results ? results.items : [];
    const selectedPageIndex: number = searchValues.pageNumber - 1;

    const selectedRequestFor: IPickerItem<IEmployee> | null = searchValues.requestFor
        ? { key: searchValues.requestFor.id!, name: searchValues.requestFor.name!, data: searchValues.requestFor }
        : null;

    const selectedRequestBy: IPickerItem<IEmployee> | null = searchValues.requestBy
        ? { key: searchValues.requestBy.id!, name: searchValues.requestBy.name!, data: searchValues.requestBy }
        : null;

    const getEditLink = (id: string) => ({ pathname: `${url}/${id}`, state: searchValues });

    const onGetResults = useCallback((values: IMyApproveSearchValues, isMounted?: () => boolean) => {
        setSearchValues({ ...values });

        showLoading();

        const submitvalues = {
            myapprove: true,
            keyword: values.keyword,
            dateFrom: values.dateFrom,
            dateTo: values.dateTo,
            status: values.status,
            requestForId: values.requestFor ? values.requestFor.id : null,
            requestById: values.requestBy ? values.requestBy.id : null,
            pageNumber: values.pageNumber,
            numberPerPage: values.numberPerPage,
            sortedBy: values.sortedBy,
            sortedType: values.sortedType,
            siteId: profile?.site?.id,
        };

        const promise = service.search(submitvalues);

        promise.then(results => {
            if (isMounted && !isMounted()) {
                setResults(undefined);
                hideLoading();
                return;
            }

            setResults(prev => ({ ...prev, ...results }));
            hideLoading();
        });
    }, [profile, service, setSearchValues, setResults, showLoading, hideLoading]);

    useEffect(() => {
        let isMounted = true;
        let newValues: IMyApproveSearchValues = cloneDeep({ ...initialValues, status: initialStatus });

        const queryString = new URLSearchParams(search);
        let queryStringStatus: IRequestStatusKeys | null = queryString.get('status') as IRequestStatusKeys;

        if (profile && profile.user && profile.role) {
            if (profile.role === 'admin-verify') {
                newValues.status = 'waiting-admin-verify';
            }
            if (profile.role === 'clg-vp') {
                newValues.status = 'waiting-clgvp-approve';
            }
        }

        if (queryStringStatus && statusOptions.some(o => o.key === queryStringStatus)) {
            newValues.status = queryStringStatus;
        }

        const locationState = state as { from: string, values: IMyApproveSearchValues };
        if (locationState && locationState.from && locationState.from === 'form') {
            newValues = { ...newValues, ...locationState.values };
        }

        onGetResults({ ...newValues }, () => isMounted);

        return () => {
            isMounted = false;
            setSearchValues(initialValues);
            setResults(undefined);
        }
    }, [service, search, profile, state, onGetResults]);

    const onSubmit = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.code === 'Enter' || ev.code === 'NumpadEnter') {
            onGetResults({ ...searchValues, pageNumber: 1 });
        }
    };

    const onSearchClick = () => {
        onGetResults({ ...searchValues, pageNumber: 1 });
    };

    const onSearchChange = (ev: React.FormEvent<HTMLElement>, newValue?: string) => {
        setSearchValues(prev => ({ ...prev, keyword: newValue }))
    }

    const onDateFromChange = (date: Date | null | undefined) => {
        setSearchValues(prev => ({ ...prev, dateFrom: date ? new Date(date.setHours(0, 0, 0, 0)).toISOString() : null }))
    }

    const onDateToChange = (date: Date | null | undefined) => {
        setSearchValues(prev => ({ ...prev, dateTo: date ? new Date(date.setHours(0, 0, 0, 0)).toISOString() : null }))
    }

    const onStatusChange = (ev: React.FormEvent<HTMLElement>, option?: IDropdownOption<IRequestStatusKeys> | undefined) => {
        const newStatus: IRequestStatusKeys = option && option?.data ? option.data : initialStatus;
        setSearchValues(prev => ({ ...prev, status: newStatus }))
    }

    const onPickerChange = <K extends keyof IMyApproveSearchValues>(newValue?: IMyApproveSearchValues[K] | null, fieldName?: K) => {
        if (!fieldName) {
            return;
        }
        const newValues: IMyApproveSearchValues = { ...searchValues };
        newValues[fieldName] = newValue as IMyApproveSearchValues[K];
        setSearchValues(newValues);
    };

    const onSelectedPageIndex = (index: number) => {
        onGetResults({ ...searchValues, pageNumber: index + 1 });
    }

    const onNumberPerPageChange = (ev: React.FormEvent<HTMLElement>, option?: IDropdownOption<number> | undefined) => {
        const newNumberPerPage: number = option && option?.data ? option.data : searchValues.numberPerPage;
        onGetResults({ ...searchValues, pageNumber: 1, numberPerPage: newNumberPerPage });
    }

    const getDocKey = (item: IRequestDocumentItem): string => {
        return item.key;
    }

    const onClickViewLog = (item: IRequest) => {
        showDialog({
            title: 'Proccess log',
            minWidth: 500,
            dialogContentProps: {
                type: DialogType.normal,
            }
        }, () => {
            return <ProcessLog item={item} displayOnly />;
        });
    };

    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const sortedBy = column.key as keyof IRequest;
        const sortedType = sortedBy !== searchValues.sortedBy
            ? 'asc'
            : column.isSortedDescending ? 'asc' : 'desc';

        onGetResults({ ...searchValues, sortedBy, sortedType });
    };

    const _columns: IColumn[] = [
        {
            key: 'requestDate',
            name: 'Request Date',
            fieldName: 'requestDate',
            minWidth: 130,
            maxWidth: 130,
            onColumnClick: onColumnClick,
            onRender: (item: IRequest) => {
                return <>{item.requestDate && onFormatDateTime(new Date(item.requestDate))}</>;
            }
        },
        {
            key: 'requestNo',
            name: 'Request No.',
            fieldName: 'requestNo',
            minWidth: 130,
            maxWidth: 130,
            onColumnClick: onColumnClick,
            onRender: (item: IRequest) => {
                return item.id && (<Link as={RouterLink} to={getEditLink(item.id)}>{item.requestNo}</Link>)
            }
        },
        {
            key: 'requestBy',
            name: 'Request by',
            fieldName: 'requestBy',
            minWidth: 200,
            isMultiline: true,
            onColumnClick: onColumnClick,
            onRender: (item: IRequest) => {
                return <>{item.requestBy?.name}</>;
            }
        },
        {
            key: 'requestFor',
            name: 'Request for',
            fieldName: 'requestFor',
            minWidth: 200,
            isMultiline: true,
            onColumnClick: onColumnClick,
            onRender: (item: IRequest) => {
                return <>{item.requestFor?.name}</>;
            }
        },
        {
            key: 'startDate',
            name: 'Start',
            fieldName: 'startDate',
            minWidth: 130,
            maxWidth: 130,
            onColumnClick: onColumnClick,
            onRender: (item: IRequest) => {
                return <>{item.startDate && onFormatDate(new Date(item.startDate))}</>;
            }
        },
        {
            key: 'endDate',
            name: 'End',
            fieldName: 'endDate',
            minWidth: 130,
            maxWidth: 130,
            onColumnClick: onColumnClick,
            onRender: (item: IRequest) => {
                return <>{item.endDate && onFormatDate(new Date(item.endDate))}</>;
            }
        },
        {
            key: 'status',
            name: 'Status',
            fieldName: 'status',
            minWidth: 200,
            maxWidth: 200,
            onColumnClick: onColumnClick,
            onRender: (item: IRequest) => {
                const status = getDisplayValue(item.status);
                const color = getRequestStatusColor(theme, item.status);
                return (
                    <Stack tokens={stackTokens} horizontal verticalAlign='center'>
                        <IconButton
                            iconProps={{ iconName: 'WaitlistConfirm' }}
                            onClick={() => onClickViewLog(item)}
                            styles={{ root: { height: 'auto' } }}
                        />
                        <span style={{ color: color }}>{status}</span>
                    </Stack>
                );
            }
        }
    ];

    let columns = cloneDeep(_columns);
    for (const column of columns) {
        if (column.key === searchValues.sortedBy) {
            column.isSorted = true;
            column.isSortedDescending = searchValues.sortedType === 'desc' ? true : false;
        } else {
            column.isSorted = false;
            column.isSortedDescending = false;
        }
    }

    return (
        <PageContainer>
            <Stack horizontal wrap={isSmallDevice} tokens={stackTokens}>
                <Stack tokens={stackTokens} styles={{ root: { width: isSmallDevice ? undefined : '50%' } }}>
                    <Stack horizontal wrap={isSmallDevice || isLargeDevice} tokens={stackTokens} verticalAlign='start'>
                        <Stack grow={1}>
                            <DatePicker
                                placeholder="Date from"
                                value={searchValues.dateFrom ? new Date(searchValues.dateFrom) : undefined}
                                onSelectDate={onDateFromChange}
                            />
                        </Stack>
                        <Stack grow={1}>
                            <DatePicker
                                placeholder="Date to"
                                value={searchValues.dateTo ? new Date(searchValues.dateTo) : undefined}
                                onSelectDate={onDateToChange}
                            />
                        </Stack>
                        <Stack grow={1}>
                            <Dropdown
                                placeholder="Status"
                                options={statusOptions}
                                styles={dropdownStyles}
                                selectedKey={searchValues.status}
                                onChange={onStatusChange}
                            />
                        </Stack>
                    </Stack>
                    <Stack horizontal wrap={isSmallDevice} tokens={stackTokens} verticalAlign='center'>
                        <Stack grow={1}>
                            <Picker
                                pickOnlyOne
                                service={employeeService}
                                placeholder='Request for (Search by ID , Name)'
                                selectedItems={selectedRequestFor ? [selectedRequestFor] : []}
                                pickerStyles={{ root: { width: '100%' } }}
                                onChange={data => onPickerChange((data && data[0]) ? data[0].data : null, 'requestFor')}
                                minimumTextLength={3}
                            />
                        </Stack>
                        <Stack grow={1}>
                            <Picker
                                pickOnlyOne
                                service={employeeService}
                                placeholder='Request by (Search by ID , Name)'
                                pickerStyles={{ root: { width: '100%' } }}
                                selectedItems={selectedRequestBy ? [selectedRequestBy] : []}
                                onChange={data => onPickerChange((data && data[0]) ? data[0].data : null, 'requestBy')}
                                minimumTextLength={3}
                            />
                        </Stack>
                    </Stack>
                </Stack>
                <Stack grow={1} styles={{ root: { width: '50%' } }}>
                    <TextField
                        onChange={onSearchChange}
                        placeholder="Search request no"
                        iconProps={{ iconName: 'Search' }}
                        onKeyDown={onSubmit}
                        value={searchValues.keyword || ''}
                    />
                </Stack>

                <PrimaryButton text="Search" onClick={onSearchClick} />
            </Stack>

            {(!isLoading && results && items && !isEmpty(items)) ? (
                <>
                    <Stack grow={1}>
                        <DetailsList
                            items={items}
                            columns={columns}
                            getKey={getDocKey}
                            setKey="multiple"
                            selectionMode={SelectionMode.none}
                        />
                    </Stack>
                    <Stack tokens={stackTokens} horizontal>
                        <Pagination
                            selectedPageIndex={selectedPageIndex}
                            onPageChange={onSelectedPageIndex}
                            pageCount={results.pageTotal}
                            itemsPerPage={searchValues.numberPerPage}
                            totalItemCount={results.total}
                            numberPerPageOptions={numberPerPageOptions}
                            defaultSelectedKey={searchValues.numberPerPage?.toString()}
                            onNumberPerPageChange={onNumberPerPageChange}
                        />
                    </Stack>

                </>
            ) : isLoading ? <Loading /> : <Text>No Result</Text>}

            <Debug object={{ ...searchValues }} />
        </PageContainer>
    );
};