import { ApiService } from ".";
import authService from "../components/api-authorization/AuthorizeService";
import { IParameters, IRequest, IRequestResult, ISaveResponse, ISearchParameters, ISearchResult } from "../models";
import { getCookie, getDateRange } from "../tools";

export class RequestService implements ApiService<IRequest> {
    async get(parameters: IParameters): Promise<IRequest | null> {
        try {
            const token = await authService.getAccessToken();
            const id = parameters['id'] as string;
            delete parameters['id'];
            const search: IParameters = {};
            Object.keys(parameters).forEach(key => {
                if (parameters[key] === undefined || parameters[key] === null) {
                    return;
                }
                search[`parameters.${key}`] = parameters[key];
            });

            const queryString = new URLSearchParams(search).toString();
            const response = await fetch(`api/Request/${id}?parameters=${queryString}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });
            const json = await response.json();
            return json;
        } catch {
            return await Promise.resolve(null);
        }

    }
    async search(parameters: ISearchParameters): Promise<ISearchResult<IRequest>> {
        const { numberPerPage, pageNumber } = parameters;
        try {
            const token = await authService.getAccessToken();
            const search: IParameters = {};
            Object.keys(parameters).forEach(key => {
                if (parameters[key] === undefined || parameters[key] === null) {
                    return;
                }
                search[`parameters.${key}`] = parameters[key];
            });

            const queryString = new URLSearchParams(search).toString();
            const response = await fetch(`api/Request/search?${queryString}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });
            const json = await response.json();
            return json;
        } catch {
            return await Promise.resolve({
                total: 0,
                items: [],
                pageNumber,
                pageTotal: 0,
                numberPerPage,
                startIndex: 0,
                endIndex: 0,
            });
        }
    }

    async create(data: IRequest, callback?: (response: ISaveResponse<IRequest>) => void): Promise<void> {
        try {
            const token = await authService.getAccessToken();
            const response = await fetch(`api/Request`, {
                method: 'POST',
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
                },
                body: JSON.stringify(data),
            });
            const json = await response.json();

            if (!response.ok) {
                throw new Error(`Status: ${json.status} ${json.title}`);
            }

            if (callback) {
                callback(json);
            }
        }
        catch (err: any) {
            if (callback) {
                callback({
                    isError: true,
                    errorMessage: typeof err === 'string' ? err : err.message
                });
            }
        }
    }

    async update(id: string, data: IRequest, callback?: (response: ISaveResponse<IRequest>) => void): Promise<void> {
        try {
            const token = await authService.getAccessToken();
            const response = await fetch(`api/Request/${id}`, {
                method: 'POST',
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
                },
                body: JSON.stringify(data),
            });
            const json = await response.json();

            if (!response.ok) {
                throw new Error(`Status: ${json.status} ${json.title}`);
            }

            if (callback) {
                callback(json);
            }
        }
        catch (err: any) {
            if (callback) {
                callback({
                    isError: true,
                    errorMessage: typeof err === 'string' ? err : err.message
                });
            }
        }
    }

    async searchResult(parameters: ISearchParameters): Promise<ISearchResult<IRequestResult>> {
        const results = await this.search(parameters);
        const items: IRequestResult[] = [...results.items].map(i => ({
            id: i.id,
            key: i.key,
            requestNo: i.requestNo,
            requestDate: i.requestDate,
            requestBy: i.requestBy?.name || '',
            requestFor: i.requestFor?.name || '',
            days: i.requestDate ? getDateRange(new Date(i.requestDate), new Date())?.toString() || null : null,
            department: i.department!,
            startDate: i.startDate,
            endDate: i.endDate,
            status: i.status,
        }));

        return { ...results, items };
    }

    async validate(data: IRequest, callback?: (response: ISaveResponse<IRequest>) => void): Promise<void> {
        try {
            const token = await authService.getAccessToken();
            const response = await fetch(`api/Request/validate`, {
                method: 'POST',
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
                },
                body: JSON.stringify(data),
            });
            const json = await response.json();

            if (!response.ok) {
                throw new Error(`Status: ${json.status} ${json.title}`);
            }

            if (callback) {
                callback(json);
            }
        }
        catch (err: any) {
            if (callback) {
                callback({
                    isError: true,
                    errorMessage: typeof err === 'string' ? err : err.message
                });
            }
        }
    }

    async remind(id: string, data: IRequest, callback?: (response: ISaveResponse<any>) => void): Promise<void> {
        try {
            const token = await authService.getAccessToken();
            const response = await fetch(`api/Request/remind/${id}`, {
                method: 'POST',
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
                },
                body: JSON.stringify(data),
            });
            const json = await response.json();

            if (!response.ok) {
                throw new Error(`Status: ${json.status} ${json.title}`);
            }

            if (callback) {
                callback(json);
            }
        }
        catch (err: any) {
            if (callback) {
                callback({
                    isError: true,
                    errorMessage: typeof err === 'string' ? err : err.message
                });
            }
        }
    }
}
