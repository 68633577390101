import { IEmailTemplate, ISaveResponse } from "../../../models";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { isEmpty } from "../../../tools";
import { RouteComponentProps } from "react-router";
import { useMemo } from "react";
import { EmailTemplateService } from "../../../services";
import { cloneDeep } from "lodash";
import { IBreadcrumbItem } from "@fluentui/react/lib/Breadcrumb";
import { NavigationPane } from "../../../components/NavigationPane";
import { TextField } from "@fluentui/react/lib/TextField";
import { ITextStyles, Text } from "@fluentui/react/lib/Text";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { Debug } from "../../../components/Debug";
import { AppContext } from "../../../contexts";
import { DialogType } from "@fluentui/react/lib/Dialog";
import { listBreadcrumbItems } from "./EmailTemplateList";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import * as Editor from 'ckeditor5-custom-build/build/ckeditor';
// import './EmailTemplate.css';
import { Label } from "@fluentui/react/lib/Label";
import { MessageBar, MessageBarType } from "@fluentui/react/lib/MessageBar";
import { useBoolean } from "@fluentui/react-hooks";
import { OverlayLoading } from "../../../components/OverlayLoading";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";

export type IFormMode = 'new' | 'edit' | 'readonly';

export interface IEmailTemplateValues {
    mode: IFormMode;
    data: IEmailTemplate;
}

const stackTokens: IStackTokens = { childrenGap: 10 };
const variableTextStyles: Partial<ITextStyles> = {
    root: {
        minWidth: 200,
    }
}

const styleEmail =
    '<hhtml><head><style>body {font-family: "Tahoma" !important; font-size:14px !important;} td {font-family: "Tahoma" !important; font-size:14px !important;}  table {width:90%; border-collapse: collapse !important;} td{border-collapse: collapse !important;} </style></head></hhtml>';

const editorConfiguration = {
    toolbar: {
        items: [
            'heading',
            '|',
            'fontFamily',
            'fontColor',
            'fontSize',
            'fontBackgroundColor',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'outdent',
            'indent',
            '|',
            'imageInsert',
            'blockQuote',
            'insertTable',
            'mediaEmbed',
            'undo',
            'redo',
            '|',
            'htmlEmbed',
            'sourceEditing',
        ],
    },
    language: 'en',
    table: {
        contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableCellProperties',
            'tableProperties',
        ],
    },
};

// const variables = {
//     'approved': {
//         '{{request_id}}': 'หมายเลข Request',
//         '{{today_date}}': 'วันที่ปัจจุบันที่ทำการส่ง email',
//         '{{request_details}}': 'ตารางแสดงรายละเอียดของ request documents',
//         '{{request_document_list}}': 'ตารางแสดงรายการ documents',
//         '{{process_log}}': 'แสดงสถานะของ Request ว่าอยู่ที่ขั้นตอนไหน',
//         '{{my_request_link}}': 'แสดงข้อความ Click และเป็น link ที่จะไปยังหน้า Request details ของ request นี้',
//     },
//     'rejected': {
//         '{{today_date}}': ' วันที่ปัจจุบันที่ทำการส่ง email',
//         '{{request_id}}': 'หมายเลข Request',
//         '{{request_details}}': 'ตารางแสดงรายละเอียดของ request documents ',
//         '{{request_document_list}}': 'ตารางแสดงรายการ documents',
//         '{{process_log}}': 'แสดงสถานะของ Request ว่าอยู่ที่ขั้นตอนไหน',
//         '{{my_request_link}}': 'แสดงข้อความ Click และเป็น link ที่จะไปยังหน้า Request details ของ request นี้',
//         '{{rejected_reason}}': 'กล่องแสดงเหตุผลในการ Reject request',
//     },
//     'summary': {
//         '{{today_date}}': 'วันที่ปัจจุบันที่ทำการส่ง email',
//         '{{request_list}}': 'ตารางแสดงรายการ request โดยรูปแบบตาราง',
//         '{{my_approve_link}}': 'แสดงข้อความ Click และเป็น link ที่จะไปยังหน้า My approve',
//     },
//     'remind': {
//         '{{today_date}}': 'วันที่ปัจจุบันที่ทำการส่ง email',
//         '{{request_id}}': 'หมายเลข Request',
//         '{{request_details}}': 'ตารางแสดงรายละเอียดของ request documents',
//         '{{request_document_list}}': 'ตารางแสดงรายการ documents',
//         '{{process_log}}': 'แสดงสถานะของ Request ว่าอยู่ที่ขั้นตอนไหน',
//         '{{my_approve_request_link}}': 'แสดงข้อความ Click และเป็น link ที่จะไปยังหน้า My Approve > Request details ของ request นี้',
//     },
//     'user-change': {
//         '{{monthyear_date}}': 'แสดงเดือนและปีของรายงานรูปแบบ mm/yyyy',
//         '{{changed_list}}': 'แสดงตารางรายการเปลี่ยนหน่วยงานของ user รวมถึง request ที่ user กำลังเข้าถึง',
//     }
// };

const initialValues: IEmailTemplateValues = {
    mode: 'readonly',
    data: {
        id: null,
        key: '-1',
        name: null,
        description: null,
        subject: null,
        emailBody: null,
        emailStyle: cloneDeep(styleEmail),
        variables: null,
        type: null,
    }
};

export const EmailTemplateForm: React.FunctionComponent<RouteComponentProps<{ site: string, id: string }>> = (props) => {
    const { profile, showDialog } = useContext(AppContext);
    const [values, setValues] = useState<IEmailTemplateValues>(cloneDeep(initialValues));
    const [originalValues, setOriginalValues] = useState<IEmailTemplate | undefined>(undefined);
    const [isLoading, { setFalse: hideLoading, setTrue: showLoading }] = useBoolean(false);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;
    const isLargeDevice = modalResponsiveMode === ResponsiveMode.large || modalResponsiveMode === ResponsiveMode.xLarge;

    const service = useMemo(() => new EmailTemplateService(), []);
    const { match: { params: { site, id } }, history, location: { state } } = props;
    const listLink = `/site/${site}/administrator/email`;
    const readonly = values.mode === 'readonly';

    useEffect(() => {
        let isMounted = true;

        if (!id || id === 'new') {
            setValues({
                ...cloneDeep(initialValues),
                mode: 'new',
            });
            return;
        }

        const promise = service.get({ id });
        promise.then(data => {
            if (!isMounted || !data || !data.id) {
                setValues({ ...cloneDeep(initialValues), mode: 'readonly' });
                setOriginalValues(undefined);
                return;
            }

            setValues({
                ...cloneDeep(initialValues),
                data,
                mode: 'edit',
            });

            setOriginalValues(cloneDeep(data));
        });

        return () => {
            setValues(cloneDeep(initialValues));
            setOriginalValues(undefined);
        };
    }, [id, service]);

    const onClickListLink = (ev?: React.MouseEvent<HTMLElement>) => {
        ev?.preventDefault();
        history.push(listLink, { from: 'form', values: state });
    };

    const breadcrumbItems: IBreadcrumbItem[] = [
        { ...listBreadcrumbItems, href: listLink, onClick: onClickListLink },
        {
            key: values.mode,
            text: originalValues?.name || (values.mode === 'new' ? 'New' : '...'),
        }
    ];

    const onChangeTextField = useCallback(<K extends keyof IEmailTemplate>(newValue: IEmailTemplate[K] | null, fieldName: K) => {
        const newValues: IEmailTemplateValues = { ...values };
        newValues.data[fieldName] = newValue as IEmailTemplate[K];
        setValues(newValues);
    }, [values]);


    const onClickReset = () => {
        if (!originalValues) {
            return;
        }

        setValues({ ...values, data: cloneDeep(originalValues) });
    };

    const onClickCancel = () => {
        onClickListLink();
    };

    const onSaveResponse = (action: string, saved: ISaveResponse<IEmailTemplate>, isBackToList: boolean = true) => {
        if (saved.isError) {
            showDialog({
                title: `${action} failed`,
                dialogContentProps: {
                    type: DialogType.normal,
                }
            }, () => {
                return (
                    <>
                        {saved.errorMessage &&
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {saved.errorMessage}
                            </MessageBar>
                        }
                        {saved.errorMessages && saved.errorMessages.map((err, i) => (
                            <MessageBar key={i}
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {err}
                            </MessageBar>
                        ))}
                    </>
                );
            });

            return;
        } else {
            onClickListLink();
        }
    };

    const onValidateForm = () => {
        const errorMessages: string[] = [];
        if (!values.data.name) {
            errorMessages.push('Name is required');
        }

        if (!values.data.description) {
            errorMessages.push('Description is required');
        }

        if (!values.data.subject) {
            errorMessages.push('Subject is required');
        }

        if (!values.data.emailBody) {
            errorMessages.push('Email Body is required');
        }

        if (!isEmpty(errorMessages)) {
            showDialog({
                title: `Invalid form`,
                dialogContentProps: {
                    type: DialogType.normal,
                }
            }, () => {
                return (
                    <>
                        {errorMessages && errorMessages.map((err, i) => (
                            <MessageBar key={i}
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {err}
                            </MessageBar>
                        ))}
                    </>
                );
            });

            return false;
        }

        return true;
    };

    const onClickSave = async () => {
        if (!onValidateForm()) {
            return;
        }

        showLoading();

        if (values.mode === 'new') {
            const data = { ...values.data, site: profile?.site };
            await service.create(data, saved => onSaveResponse('Save data', saved));
        }

        if (values.mode === 'edit') {
            await service.update(id, values.data, saved => onSaveResponse('Save data', saved));
        }

        hideLoading();
    };

    const onChangeEmail = (editor: any) => {
        setValues(prev => {
            const data = { ...prev.data };
            const email = editor.getData();
            data.emailBody = email as string;
            return { ...prev, data };
        });
    };

    return (
        <Stack tokens={stackTokens}>
            <NavigationPane items={breadcrumbItems || []} />

            <Stack horizontal wrap={isSmallDevice || isLargeDevice} tokens={stackTokens} >
                <Stack tokens={stackTokens} grow>
                    <Stack tokens={stackTokens} horizontal wrap={isSmallDevice || isLargeDevice}>
                        <Stack styles={{ root: { width: isSmallDevice || isLargeDevice ? '100%' : '50%' } }}>
                            <TextField
                                label='Email name'
                                disabled={readonly}
                                value={values.data.name || ''}
                                onChange={(e, value) => onChangeTextField(value || null, 'name')}
                                required
                            />
                            <TextField
                                label='Description'
                                disabled={readonly}
                                value={values.data.description || ''}
                                multiline
                                onChange={(e, value) => onChangeTextField(value || null, 'description')}
                                required
                            />

                        </Stack>
                        <Stack tokens={stackTokens}>
                            <Label>Variables</Label>
                            {values.data.variables && Object.keys(values.data.variables).map((key, i) => {
                                return (
                                    <Stack key={i} tokens={stackTokens} horizontal>
                                        <Text styles={variableTextStyles}>{key} :</Text>
                                        <Text>{values.data.variables![key]}</Text>
                                    </Stack>
                                );
                            })}
                        </Stack>
                    </Stack>

                    <TextField
                        label='Subject'
                        disabled={readonly}
                        value={values.data.subject || ''}
                        onChange={(e, value) => onChangeTextField(value || null, 'subject')}
                        required
                    />

                    <Stack styles={{ root: { minHeight: 200 } }}>
                        <Label required>Email</Label>
                        <CKEditor
                            editor={Editor}
                            data={values.data.emailBody || ''}
                            config={editorConfiguration}
                            onChange={(e, editor) => onChangeEmail(editor)}
                        />
                    </Stack>
                </Stack>
            </Stack>

            <Stack horizontal tokens={stackTokens}>
                {!readonly && <PrimaryButton text='Save' onClick={onClickSave} />}
                {values.mode === 'edit' && <DefaultButton text='Reset' onClick={onClickReset} />}
                <DefaultButton text='Cancel' onClick={onClickCancel} />
            </Stack>

            <Debug object={values} />

            <OverlayLoading isVisibled={isLoading} />
        </Stack>
    )
};