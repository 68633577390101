import React, { useContext, useRef } from 'react';
import { ITextStyles, Text } from '@fluentui/react/lib/Text';
import { IStackStyles, Stack } from '@fluentui/react/lib/Stack';
import { appSidePadding } from '../constants';
import { FontWeights } from '@fluentui/react/lib/Styling';
import { AppContext } from '../contexts';
import { NeutralColors, useTheme } from '@fluentui/react/lib/Theme';
import { useLocation } from 'react-router';
import { isEmpty, isMatchPage } from '../tools';
import { IMenuItem } from '../models';
import { IButtonStyles, IconButton } from '@fluentui/react/lib/Button';
import { ResponsiveMode, useResponsiveMode } from '@fluentui/react/lib/ResponsiveMode';

const SIDE_MENU_PAGES: string[] = [
    'administrator',
    'report',
    'sitesetup',
];

const buttonStyles: IButtonStyles = {
    root: {
        marginLeft: -5,
        color: NeutralColors.white,
    }
};

export const Header: React.FunctionComponent = () => {
    const { profile, menuItems, showSideMenu } = useContext(AppContext);
    const { palette } = useTheme();
    const { pathname } = useLocation();

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;
    const isLargeDevice = modalResponsiveMode === ResponsiveMode.large;

    let page: IMenuItem | undefined = undefined;
    if (profile && menuItems && !isEmpty(menuItems)) {
        page = menuItems.find(m => isMatchPage(pathname, profile.site?.name!, m.name));
    }

    const stackStyles: Partial<IStackStyles> = {
        root: { height: 44, backgroundColor: palette.themePrimary, padding: appSidePadding }
    }

    const textStyles: Partial<ITextStyles> = {
        root: { color: palette.white, fontWeight: FontWeights.bold }
    };

    let hasSideMenu = (isSmallDevice || isLargeDevice) && page && SIDE_MENU_PAGES.includes(page?.name);

    return (
        <Stack horizontal verticalAlign='center' styles={stackStyles}>
            {hasSideMenu && (
                <IconButton
                    styles={buttonStyles}
                    onClick={showSideMenu}
                    iconProps={{ iconName: 'CollapseMenu' }}
                />
            )}
            <Text variant='xLarge' styles={textStyles}>
                {profile?.site?.name} : {page?.title || ''}
            </Text>
        </Stack>
    );
};
