import { DayOfWeek } from "@fluentui/react/lib/Calendar";
import { DatePicker as FuiDatePicker, defaultDatePickerStrings, IDatePickerProps } from "@fluentui/react/lib/DatePicker";
import React from "react";
import { onFormatDate, onParseDateFromString } from "../tools";

export const DatePicker: React.FunctionComponent<IDatePickerProps> = (props) => {
    return (
        <FuiDatePicker
            {...props}
            allowTextInput
            firstDayOfWeek={DayOfWeek.Monday}
            formatDate={onFormatDate}
            strings={defaultDatePickerStrings}
            parseDateFromString={value => onParseDateFromString(value, props.value)}
            today={new Date()}
        />
    );
};