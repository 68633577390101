import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import { MyRequestForm } from "./MyRequestForm";
import { MyRequestList } from "./MyRequestList";

export const MyRequest: React.FunctionComponent<RouteComponentProps> = props => {
    const { match: { path } } = props

    return (
        <Switch>
            <Switch>
                <Route exact path={path} component={MyRequestList} />
                <Route path={`${path}/new`} component={MyRequestForm} />
                <Route path={`${path}/:id`} component={MyRequestForm} />
            </Switch>
        </Switch>
    )
}