import { ApiService } from ".";
import authService from "../components/api-authorization/AuthorizeService";
import { ICards, IParameters, ISearchParameters, ISearchResult } from "../models";

export class CardService implements ApiService<ICards> {
    async get(parameters: IParameters): Promise<ICards | null> {
        try {
            const token = await authService.getAccessToken();
            const search: IParameters = {};
            Object.keys(parameters).forEach(key => {
                if (parameters[key] === undefined || parameters[key] === null) {
                    return;
                }
                search[`parameters.${key}`] = parameters[key];
            });

            const queryString = new URLSearchParams(search).toString();

            const response = await fetch(`api/Dashboard/cards?${queryString}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });
            const json = await response.json();
            return json;
        } catch {
            return await Promise.resolve(null);
        }
        
    }
    search(parameters: ISearchParameters): Promise<ISearchResult<ICards>> {
        throw new Error("Method not implemented.");
    }
}