import { CommandButton } from "@fluentui/react/lib/Button";
import { IStackProps, IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import React, { useContext, useMemo, useRef } from "react";
import { ITextStyles, Text } from "@fluentui/react/lib/Text";
import { FontWeights, IStyle, mergeStyles, mergeStyleSets } from "@fluentui/react/lib/Styling";
import { IPermission, ISearchDocumentResult } from "../models";
import { AppContext } from "../contexts";
import { DialogType } from "@fluentui/react/lib/Dialog";
import { DocumentPathButton } from "./DocumentPathButton";
import { onFormatDate } from "../tools";
import { FileService } from "../services";
import { matchPath, useHistory, useLocation } from "react-router";
import { useBoolean } from "@fluentui/react-hooks";
import { Overlay } from "@fluentui/react/lib/Overlay";
import { Loading } from "./Loading";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";

export type ISuggestionItemDisplayFrom = 'quickSearch' | 'advanceSearch' | 'database';

export interface ISearchSuggestionItemClassNames {
    sequence: string;
    documentName: string;
    parties: string;
    date: string
}

export interface IColumnStyles {
    sequence: IStyle;
    documentName: IStyle;
    parties: IStyle;
    date: IStyle
};

export const getClassNames = (columnStyles: IColumnStyles): ISearchSuggestionItemClassNames => {
    return mergeStyleSets({
        sequence: columnStyles.sequence,
        documentName: columnStyles.documentName,
        parties: columnStyles.parties,
        date: columnStyles.date,
    });
};

export interface ISuggestionItemProps {
    doc: ISearchDocumentResult;
    stackProps?: Partial<IStackProps>;
    sequence?: number;
    isShowSequence?: boolean;
    from?: ISuggestionItemDisplayFrom;
    styles?: IColumnStyles;
    requestId?: string;
}

const paddingRight = 8;
const paddingLeft = 12;
const padding = `0 ${paddingRight}px 0 ${paddingLeft}px`;

const sequenceWidth = 60;
const dateWidth = 200;
const otherWidth = `calc((100% - ${dateWidth}px - ${sequenceWidth}px) / 2)`;

export const itemClassNames: IColumnStyles = {
    sequence: { width: (sequenceWidth - paddingRight - paddingLeft), textAlign: 'Right', padding: padding },
    documentName: { width: otherWidth, maxWidth: otherWidth, padding: padding },
    parties: { width: otherWidth, maxWidth: otherWidth, padding: padding },
    date: { width: dateWidth, maxWidth: dateWidth, padding: padding },
};

const labelTextStyles: ITextStyles = {
    root: {
        fontWeight: FontWeights.bold,
        minWidth: 70,
    }
};

const stackTokens: IStackTokens = { childrenGap: 10 };

const isHasPermission = (list: IPermission[] | null, value: IPermission) => {
    return list ? list.some(v => v === value) : false;
}

export const SearchSuggestionItem: React.FunctionComponent<ISuggestionItemProps> = (props) => {
    const history = useHistory();
    const { pathname } = useLocation();
    const {
        profile,
        documentDraftItems,
        isSearchPanelOpen,
        updateDraftDocument,
        showDialog,
        showPdfViewer,
        dismissSearchPanel,
    } = useContext(AppContext);

    const [isFileLoading, { setFalse: hideFileLoading, setTrue: showFileLoading }] = useBoolean(false);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;

    const service = useMemo(() => new FileService(), []);

    const { doc, stackProps, sequence, isShowSequence = false, styles, requestId = null } = props;
    const { name, parties, date, permission, path, abstract } = doc;

    const classNames = styles ? getClassNames(styles) : getClassNames(itemClassNames);

    const canView = isHasPermission(permission, 'view');
    const canPrint = isHasPermission(permission, 'print');
    const canDownload = isHasPermission(permission, 'download');
    const canPreview = isHasPermission(permission, 'preview');
    const canRequest = isHasPermission(permission, 'request');

    const isDraftSelected = documentDraftItems.some(draft => draft.id === doc.id && draft.pathId === doc.pathId);

    const onClickAddDraft = () => {
        if (isDraftSelected) {
            showDialog({
                title: 'Found duplicate selected',
                dialogContentProps: {
                    type: DialogType.normal,
                    subText: `Document "${doc.name}" already selected`,
                }
            });

            return;
        }

        const newDocumentDraftItems = [...documentDraftItems, doc];
        updateDraftDocument(newDocumentDraftItems);

        const isMatchMyRequestNewPage = !!matchPath<{ site: string }>(
            pathname,
            '/site/:site/myrequest/new'
        );

        if (isMatchMyRequestNewPage) {
            return;
        }

        const isMatchMyRequestEditPage = !!matchPath<{ site: string, id: string }>(
            pathname,
            '/site/:site/myrequest/:id'
        );

        if (isMatchMyRequestEditPage) {
            return;
        }

        if (isSearchPanelOpen) {
            dismissSearchPanel();
        }

        const site = profile?.site!;
        const myRequestNewPageUrl = `/site/${site.name}/myrequest/new`;
        history.push(myRequestNewPageUrl);
    };

    const onClickView = (ev: React.MouseEvent<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement | HTMLSpanElement>) => {
        ev.preventDefault();
        ev.stopPropagation();
        showPdfViewer({
            documentId: doc.id!,
            pathId: doc.pathId!,
            mode: 'view',
            requestId,
            permission: 'view',
        });
    };

    const onClickPrint = (ev: React.MouseEvent<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement | HTMLSpanElement>) => {
        ev.preventDefault();
        ev.stopPropagation();
        showPdfViewer({
            documentId: doc.id!,
            pathId: doc.pathId!,
            mode: 'print',
            requestId,
            permission: 'view-print',
        });
    };

    const onClickPreview = (ev: React.MouseEvent<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement | HTMLSpanElement>) => {
        ev.preventDefault();
        ev.stopPropagation();
        showPdfViewer({
            documentId: doc.id!,
            pathId: doc.pathId!,
            requestId,
            mode: 'preview',
        });
    };

    const onClickDownload = async () => {
        showFileLoading();

        await service.downloadFilePath(doc.id!, doc.pathId!, requestId!, message => {
            showDialog({
                title: 'Error',
                dialogContentProps: {
                    type: DialogType.normal,
                    subText: message,
                }
            });
        });

        hideFileLoading();
    };

    let stackClassName: string | undefined = undefined;
    if (isSmallDevice) {
        stackClassName = mergeStyles(stackProps?.styles, {
            width: '100%'
        })
    }

    const renderDocumentDetail = () => {
        if (isSmallDevice) {
            return (
                <Stack tokens={stackTokens}>
                    {(isShowSequence && sequence) &&
                        <Stack horizontal horizontalAlign='start'>
                            <Text styles={labelTextStyles}>No :</Text>
                            <Text>{sequence}</Text>
                        </Stack>
                    }
                    <Stack horizontal horizontalAlign='start'>
                        <Text styles={labelTextStyles}>Name :</Text>
                        <Text>{name}</Text>
                    </Stack>
                    <Stack horizontal horizontalAlign='start'>
                        <Text styles={labelTextStyles}>Parties :</Text>
                        <Text>{parties || '-'}</Text>
                    </Stack>
                    <Stack horizontal horizontalAlign='start'>
                        <Text styles={labelTextStyles}>Date :</Text>
                        <Text>{date ? onFormatDate(new Date(date)) : '-'}</Text>
                    </Stack>
                </Stack>
            );
        } else {
            return (
                <Stack horizontal styles={{ root: { minHeight: 32 } }}>
                    {(isShowSequence && sequence) && (
                        <Text className={classNames.sequence}>{sequence}</Text>
                    )}
                    <Text title={abstract || name!} className={classNames.documentName}>{name}</Text>
                    <Text className={classNames.parties}>{parties || '-'}</Text>
                    <Text className={classNames.date}>{date ? onFormatDate(new Date(date)) : '-'}</Text>
                </Stack>
            );
        }
    };

    return (
        <Stack grow={1} {...stackProps} className={stackClassName}>
            {renderDocumentDetail()}
            <Stack horizontal wrap={isSmallDevice}>
                <Stack grow={1} horizontal wrap={isSmallDevice}>
                    {(isShowSequence && sequence && !isSmallDevice) && (
                        <div className={classNames.sequence}></div>
                    )}
                    {permission && (
                        <>
                            {canRequest &&
                                <CommandButton
                                    iconProps={{ iconName: 'Add' }}
                                    text={isDraftSelected ? "Added" : "Add Request"}
                                    onClick={onClickAddDraft}
                                    disabled={isDraftSelected}
                                />
                            }
                            {canPreview &&
                                <CommandButton
                                    iconProps={{ iconName: 'EntryView' }}
                                    text="Preview"
                                    onClick={onClickPreview}
                                    id='search-suggestion-item-preview-button'
                                />
                            }
                            {canView &&
                                <CommandButton
                                    iconProps={{ iconName: 'View' }}
                                    text="View"
                                    onClick={onClickView}
                                    id='search-suggestion-item-view-button'
                                />
                            }
                            {canPrint &&
                                <CommandButton
                                    iconProps={{ iconName: 'Print' }}
                                    text="Print"
                                    onClick={onClickPrint}
                                    id='search-suggestion-item-print-button'
                                />
                            }
                            {canDownload &&
                                <CommandButton
                                    iconProps={{ iconName: 'Download' }}
                                    text="Download Soft Files"
                                    onClick={onClickDownload}
                                    id='search-suggestion-item-download-button'
                                />}
                        </>
                    )}
                </Stack>
                <Stack>
                    {path && <DocumentPathButton path={path} />}
                </Stack>
            </Stack>

            {isFileLoading &&
                <Overlay>
                    <Stack styles={{ root: { height: '100%' } }} verticalAlign='center'>
                        <Loading />
                    </Stack>
                </Overlay>
            }
        </Stack>
    );
};