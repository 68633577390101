import { IBreadcrumbItem } from "@fluentui/react/lib/Breadcrumb";
import { ActionButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { TextField } from "@fluentui/react/lib/TextField";
import { Text } from "@fluentui/react/lib/Text";
import React, { useCallback, useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { Debug } from "../../../components/Debug";
import { NavigationPane } from "../../../components/NavigationPane";
import { IRequestResult, ISearchResult, ITemplate, ITemplateResult } from "../../../models";
import { isEmpty, preparePath } from "../../../tools";
import { useConst, useBoolean } from '@fluentui/react-hooks';
import { Loading } from "../../../components/Loading";
import { DetailsList, IColumn, SelectionMode } from "@fluentui/react/lib/DetailsList";
import { Link as RouterLink, RouteComponentProps } from "react-router-dom";
import { Link } from "@fluentui/react/lib/Link";
import { Pagination } from "../../../components/Pagination";
import { Icon } from "@fluentui/react/lib/Icon";
import { cloneDeep } from "lodash";
import { TemplateService } from "../../../services";
import { AppContext } from "../../../contexts";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";

export interface ITemplateSearchValues {
    keyword?: string;
    pageNumber: number;
    numberPerPage: number;
    sortedBy: keyof ITemplate;
    sortedType: 'asc' | 'desc';
}

export const listBreadcrumbItems: IBreadcrumbItem = {
    key: 'list',
    text: 'Templates',
};

const stackTokens: IStackTokens = { childrenGap: 10 };

const numberPerPageOptions: IDropdownOption<number>[] = [
    { key: '20', text: '20', data: 20 },
    { key: '50', text: '50', data: 50 },
    { key: '100', text: '100', data: 100 },
    { key: '200', text: '200', data: 200 },
];

const initialValues: ITemplateSearchValues = {
    pageNumber: 1,
    numberPerPage: 20,
    sortedBy: 'name',
    sortedType: 'asc'
};

export const TemplateList: React.FunctionComponent<RouteComponentProps> = (props) => {
    const { profile } = useContext(AppContext);
    const [searchValues, setSearchValues] = useState<ITemplateSearchValues>(initialValues);
    const [results, setResults] = useState<ISearchResult<ITemplateResult> | undefined>(undefined);
    const [isLoading, { setFalse: hideLoading, setTrue: showLoading }] = useBoolean(false);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;

    const { match: { url }, history, location: { state } } = props;

    const service = useConst(new TemplateService());
    const items = results ? results.items : [];
    const selectedPageIndex: number = searchValues.pageNumber - 1;
    const _url = preparePath(url);
    const newUrl = `${_url}/new`;

    const onGetResults = useCallback((values: ITemplateSearchValues, isMounted?: () => boolean) => {
        setSearchValues({ ...values });

        showLoading();

        const submitValue = {
            keyword: values.keyword || null,
            pageNumber: values.pageNumber,
            numberPerPage: values.numberPerPage,
            sortedBy: values.sortedBy,
            sortedType: values.sortedType,
            siteId: profile?.site?.id,
        };

        const promise = service.searchResult(submitValue);

        promise.then(results => {
            if (isMounted && !isMounted()) {
                setResults(undefined);
                hideLoading();
                return
            }
            setResults(prev => ({ ...prev, ...results }));
            hideLoading();
        });
    }, [profile, service, setSearchValues, setResults, showLoading, hideLoading]);

    useEffect(() => {
        let isMounted = true;
        const locationState = state as { from: string, values: ITemplateSearchValues };
        let newValues = cloneDeep({ ...initialValues });
        if (locationState && locationState.from && locationState.from === 'form') {
            newValues = { ...newValues, ...locationState.values };
        }

        onGetResults(newValues, () => isMounted);

        return () => {
            isMounted = false;
            setSearchValues(initialValues);
            setResults(undefined);
        }
    }, [state, onGetResults]);

    const onChange = <K extends keyof ITemplateSearchValues>(newValue?: ITemplateSearchValues[K] | null, fieldName?: K) => {
        if (!fieldName) {
            return;
        }

        const newValues: ITemplateSearchValues = { ...searchValues };
        newValues[fieldName] = newValue as ITemplateSearchValues[K];
        setSearchValues(newValues);
    };

    const onSearchEnter = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.code === 'Enter' || ev.code === 'NumpadEnter') {
            onGetResults({ ...searchValues, pageNumber: 1 });
        }
    };

    const onSearchClick = () => {
        onGetResults({ ...searchValues, pageNumber: 1 });
    };

    const onNumberPerPageChange = (ev: React.FormEvent<HTMLElement>, option?: IDropdownOption<number> | undefined) => {
        const newNumberPerPage: number = option && option?.data ? option.data : searchValues.numberPerPage;
        onGetResults({ ...searchValues, pageNumber: 1, numberPerPage: newNumberPerPage });
    };

    const onSelectedPageIndex = (index: number) => {
        onGetResults({ ...searchValues, pageNumber: index + 1 });
    };

    const getDocKey = (item: IRequestResult, index?: number): string => {
        return item.key;
    };

    const getEditLink = (id: string) => ({ pathname: `${_url}/${id}`, state: searchValues });
    const onCreateClick = (ev: React.MouseEvent<HTMLHtmlElement>) => {
        ev.preventDefault();
        history.push(newUrl, searchValues);
    };

    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const sortedBy = column.key as keyof ITemplate;
        const sortedType = sortedBy !== searchValues.sortedBy
            ? 'asc'
            : column.isSortedDescending ? 'asc' : 'desc';

        onGetResults({ ...searchValues, sortedBy, sortedType });
    }

    const _columns: IColumn[] = [
        {
            key: 'name',
            name: 'Template name',
            fieldName: 'name',
            minWidth: 250,
            maxWidth: 250,
            onColumnClick: onColumnClick,
            onRender: (item: ITemplateResult) => {
                return item.id && (<Link as={RouterLink} to={getEditLink(item.id)}>{item.name}</Link>)

            }
        },
        {
            key: 'dataTypes',
            name: 'Document path',
            fieldName: 'dataTypes',
            minWidth: 250,
            isMultiline: true,
            onRender: (item: ITemplateResult) => {
                const dataTypes = item.dataTypes || [];
                return dataTypes.map((dataTypeName, i) => (
                    <span key={i}>
                        <span>{dataTypeName}</span>
                        {(i !== dataTypes.length - 1) &&
                            <Icon styles={{ root: { padding: '0 3px 0 3px' } }} iconName='ChevronRight' />
                        }
                    </span>
                ));
            }
        },
    ];

    let columns = cloneDeep(_columns);
    for (const column of columns) {
        if (column.key === searchValues.sortedBy) {
            column.isSorted = true;
            column.isSortedDescending = searchValues.sortedType === 'desc' ? true : false;
        } else {
            column.isSorted = false;
            column.isSortedDescending = false;
        }
    }

    return (
        <Stack>
            <NavigationPane items={[listBreadcrumbItems] || []} />
            <Stack horizontal wrap={isSmallDevice} tokens={stackTokens}>
                <Stack tokens={stackTokens} styles={{ root: { width: isSmallDevice ? undefined : '50%' } }}>
                    <ActionButton iconProps={{ iconName: 'Add' }} href={newUrl} onClick={onCreateClick}>
                        Create
                    </ActionButton>
                </Stack>
                <Stack grow={1} styles={{ root: { width: isSmallDevice ? undefined : '50%' } }}>
                    <TextField
                        placeholder="Search template name"
                        iconProps={{ iconName: 'Search' }}
                        onKeyDown={onSearchEnter}
                        onChange={(e, value) => onChange(value, 'keyword')}
                        value={searchValues.keyword || ''}
                    />
                </Stack>

                <PrimaryButton text="Search" onClick={onSearchClick} />
            </Stack>

            {(!isLoading && results && items && !isEmpty(items)) ? (
                <>
                    <Stack grow={1}>
                        <DetailsList
                            items={items}
                            columns={columns}
                            getKey={getDocKey}
                            setKey="multiple"
                            selectionMode={SelectionMode.none}
                        />
                    </Stack>

                    <Stack tokens={stackTokens} horizontal>
                        <Pagination
                            selectedPageIndex={selectedPageIndex}
                            onPageChange={onSelectedPageIndex}
                            pageCount={results.pageTotal}
                            itemsPerPage={searchValues.numberPerPage}
                            totalItemCount={results.total}
                            numberPerPageOptions={numberPerPageOptions}
                            defaultSelectedKey={searchValues.numberPerPage?.toString()}
                            onNumberPerPageChange={onNumberPerPageChange}
                        />
                    </Stack>
                </>
            ) : isLoading ? <Loading /> : <Text>No Result</Text>}

            <Debug object={searchValues} />
        </Stack>
    );
};