import { IRequestDocumentItem, ISearchDocumentResult } from "../models";

export const mapRequestDocToSearchDoc = (reqDoc: IRequestDocumentItem): ISearchDocumentResult => {
    const { docId, pathId, name, parties, date, path, permission } = reqDoc;
    return {
        id: docId,
        key: pathId!,
        pathId: pathId,
        name,
        parties,
        date,
        path,
        permission,
    }
};
