import { IBreadcrumbItem } from "@fluentui/react/lib/Breadcrumb";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { FontSizes, FontWeights, useTheme } from "@fluentui/react/lib/Theme";
import { ITextStyles, Text } from "@fluentui/react/lib/Text";
import React, { useMemo, useRef, useState } from "react";
import { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { Debug } from "../../../components/Debug";
import { NavigationPane } from "../../../components/NavigationPane";
import { IRequestDocumentItem, IRequest, IRequestStatusKeys, ISaveResponse } from "../../../models";
import { EmployeeService, RequestService } from "../../../services";
import { listBreadcrumbItems } from "./AllRequestList";
import { DefaultButton, IButtonStyles, IconButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { getDateRange, getDisplayValue, getPrevStatus, getRequestStatusColor, isEmpty, onFormatDate } from "../../../tools";
import { DatePicker } from "../../../components/DatePicker";
import { TextField } from "@fluentui/react/lib/TextField";
import { DetailsList, IColumn, SelectionMode } from "@fluentui/react/lib/DetailsList";
import { useContext } from "react";
import { AppContext } from "../../../contexts";
import { DialogType } from "@fluentui/react/lib/Dialog";
import { ProcessLog } from "../../../components/ProcessLog";
import { Checkbox } from "@fluentui/react/lib/Checkbox";
import { DocumentPathButton } from "../../../components/DocumentPathButton";
import { cloneDeep } from "lodash";
import { useBoolean } from "@fluentui/react-hooks";
import { OverlayLoading } from "../../../components/OverlayLoading";
import { MessageBar, MessageBarType } from "@fluentui/react/lib/MessageBar";
import { InactiveRequestDialog } from "../../../components/InactiveRequestDialog";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";

export type IFormMode = 'readonly' | 'edit';

export interface IRequestValues {
    mode: IFormMode;
    data: IRequest;
}

const stackTokens: IStackTokens = { childrenGap: 10 };
const textBoldStyles: Partial<ITextStyles> = {
    root: {
        fontWeight: FontWeights.bold,
        minWidth: 80
    }
};

const buttonStyles: Partial<IButtonStyles> = {
    root: {
        width: 22,
        minWidth: 22,
        maxWidth: 22,
        height: 22,
        minHeight: 22,
        maxHeight: 22,
        padding: 0,
    },
    icon: {
        fontSize: FontSizes.size12,
    }
};

const initialValues: IRequestValues = {
    mode: 'readonly',
    data: {
        id: null,
        key: '-1',
        requestDate: null,
        requestNo: null,
        requestBy: null,
        requestFor: null,
        startDate: null,
        endDate: null,
        documents: [],
        objective: null,
        status: null,
        vpApprover: null,
        adminApprover: null,
        clgVpApprover: null,
    }
};

export const AllRequestForm: React.FunctionComponent<RouteComponentProps<{ site: string, id: string }>> = (props) => {
    const theme = useTheme();
    const {
        profile,
        showDialog,
        hideDialog,
    } = useContext(AppContext);

    const [values, setValues] = useState<IRequestValues>(cloneDeep({ ...initialValues }));
    const [originalValues, setOriginalValues] = useState<IRequest | undefined>(undefined);
    const [reload, setReload] = useState<boolean>(false);
    const [isLoading, { setFalse: hideLoading, setTrue: showLoading }] = useBoolean(false);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;

    const service = useMemo(() => new RequestService(), []);
    const employeeService = useMemo(() => new EmployeeService(), []);

    const { match: { params: { site, id } }, history, location: { state } } = props;
    const listLink = `/site/${site}/administrator/allrequest`;

    useEffect(() => {
        const user = profile?.user;
        if (!user || !id) {
            setValues(cloneDeep({ ...initialValues }));
            setOriginalValues(undefined);
            return;
        }

        async function initial() {
            const data = await service.get({ id });
            if (!data || !user) {
                setValues(cloneDeep({ ...initialValues }));
                setOriginalValues(undefined);
                return;
            }

            let mode: IFormMode = 'readonly';

            if (
                data.status === 'waiting-admin-verify' &&
                profile.role === 'admin-verify' &&
                !data.adminApproveDate) {
                mode = 'edit';
                data.adminApprover = profile.employee;
            }

            setValues(cloneDeep({ ...initialValues, data: data, mode }));
            setOriginalValues(cloneDeep(data));
        }

        initial();
        setReload(false);

        return () => {
            setValues(cloneDeep({ ...initialValues }));
            setOriginalValues(undefined);
            setReload(false);
        }
    }, [id, service, profile, employeeService, reload]);

    const starDate = values.data.startDate ? new Date(values.data.startDate) : null;
    const endDate = values.data.endDate ? new Date(values.data.endDate) : null;

    const dateRange = getDateRange(starDate, endDate);
    const days = dateRange?.toString() ?? '';

    let isTableReadonly = false;
    if (values.data.status === 'waiting-clgvp-approve' ||
        values.data.status === 'waiting-vp-approve' ||
        values.data.status === 'approved' ||
        values.data.status === 'rejected' ||
        values.data.status === 'inactive'
    ) {
        isTableReadonly = true;
    }

    const onClickListLink = (ev?: React.MouseEvent<HTMLElement>) => {
        ev?.preventDefault();
        history.push(listLink, { from: 'form', values: state });
    };

    const breadcrumbItems: IBreadcrumbItem[] = [
        { ...listBreadcrumbItems, href: listLink, onClick: onClickListLink },
        {
            key: 'edit',
            text: originalValues?.requestNo || '...',
        }
    ];

    const textStatusStyles = (status: IRequestStatusKeys | null): Partial<ITextStyles> => ({
        root: {
            fontWeight: FontWeights.bold,
            color: getRequestStatusColor(theme, status),
        }
    });

    const getDocKey = (item: IRequestDocumentItem): string => {
        return item.pathId!;
    };

    const onClickViewLog = () => {
        showDialog({
            title: 'Proccess log',
            minWidth: 500,
            dialogContentProps: {
                type: DialogType.normal,
            }
        }, () => {
            return <ProcessLog item={values.data} displayOnly />;
        });
    };

    const onSaveResponse = (action: string, saved: ISaveResponse<IRequest>, isBackToList: boolean = true) => {
        if (saved.isError) {
            showDialog({
                title: `${action} failed`,
                dialogContentProps: {
                    type: DialogType.normal,
                }
            }, () => {
                return (
                    <>
                        {saved.errorMessage &&
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {saved.errorMessage}
                            </MessageBar>
                        }
                        {saved.errorMessages && saved.errorMessages.map((err, i) => (
                            <MessageBar key={i}
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {err}
                            </MessageBar>
                        ))}
                    </>
                );
            });

            return;
        } else {
            if (isBackToList) {
                onClickListLink();
                return;
            }

            if (id) {
                setReload(true);
            } else {
                onClickListLink();
            }
        }
    };

    const onSave = async (data: IRequest) => {
        const approveDate = new Date();
        const submitValue: IRequest = {
            ...data,
            status: 'waiting-clgvp-approve',
            adminApproveDate: approveDate.toISOString(),
            isClgVpSendMail: false,
        };

        showLoading();

        await service.update(data.id!, submitValue, saved => onSaveResponse('Approve', saved));

        hideLoading();
    };

    const onSendApprove = async () => {
        const { data } = { ...values };
        setValues(prev => ({ ...prev, data }));
        showDialog({
            title: 'Proccess log',
            minWidth: 500,
            dialogContentProps: {
                type: DialogType.normal,
            }
        }, () => {
            return <ProcessLog disabled={isLoading} item={values.data} onDismiss={hideDialog} onSubmit={data => onSave(data)} />;
        });
    };

    const onRejected = async (data: IRequest) => {
        const rejectDate = new Date();
        const submitValue: IRequest = {
            ...data,
            status: 'rejected',
            adminApproveDate: rejectDate.toISOString(),
        };

        showLoading();

        await service.update(data.id!, submitValue, saved => onSaveResponse('Reject', saved));

        hideLoading();
    };

    const onRemindClick = async () => {
        showLoading();

        await service.remind(id, { ...values.data }, sent => {
            var isError = sent.isError;
            showDialog({
                title: `Remind email ${isError ? 'failed' : 'success'}`,
                dialogContentProps: {
                    type: DialogType.normal,
                }
            }, () => {
                return (
                    <>
                        {isError && sent.errorMessage &&
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {sent.errorMessage}
                            </MessageBar>
                        }
                        {!isError && sent.successMessage &&
                            <MessageBar
                                messageBarType={MessageBarType.success}
                                isMultiline={true}
                            >
                                {sent.successMessage}
                            </MessageBar>
                        }
                    </>
                );
            });
        });

        hideLoading();
    };

    const onRejectClick = () => {
        const { data } = { ...values };
        setValues(prev => ({ ...prev, data }));
        showDialog({
            title: 'Proccess log',
            minWidth: 500,
            dialogContentProps: {
                type: DialogType.normal,
            }
        }, () => {
            return <ProcessLog item={values.data} onDismiss={hideDialog} onSubmit={data => onRejected(data)} action='reject' />;
        });
    };

    const onRemoveAuthorization = async (data: IRequest) => {
        const inactiveDate = new Date();

        const submitValue: IRequest = {
            ...data,
            status: 'inactive',
            inactiveBy: profile?.employee?.id,
            inactiveDate: inactiveDate.toISOString(),
        };

        showLoading();

        await service.update(data.id!, submitValue, saved => {
            hideDialog();
            onSaveResponse('Remove authorization', saved);
        });

        hideLoading();
    };

    const onClickRemoveAuthorization = () => {
        showDialog({
            title: 'Please confirm',
            dialogContentProps: {
                type: DialogType.normal,
                subText: 'Do you want to remove authorization the request ?'
            }
        }, () => {
            const _onRemoveAuthorization = (data: IRequest) => {
                hideDialog();
                onRemoveAuthorization(data);
            }
            return (
                <InactiveRequestDialog
                    item={values.data}
                    onDismiss={hideDialog}
                    disabled={isLoading}
                    onSubmit={_onRemoveAuthorization}
                />
            );
        });
    };

    const onRecallClick = async () => {
        const { data } = values;
        const prevStatus = getPrevStatus(data.status!);

        const submitValue: IRequest = {
            ...data,
            status: prevStatus,
        };

        if (data.status === 'waiting-clgvp-approve') {
            submitValue.adminApproveDate = null;
            submitValue.adminApprover = null;
            submitValue.clgVpApprover = null;
        }

        if (data.status === 'rejected') {
            if (data.adminApproveDate) {
                submitValue.status = 'waiting-admin-verify';
                submitValue.adminApproveDate = null;
                submitValue.adminApprover = null;
            } else if (data.vpApproveDate) {
                submitValue.status = 'waiting-vp-approve';
                submitValue.vpApproveDate = null;
            }
        }

        showLoading();

        await service.update(id, submitValue, saved => onSaveResponse('Recall', saved, false));

        hideLoading();
    };

    const onChageVp = async (data: IRequest) => {
        const newData: IRequest = { ...data, isVpSendMail: false };

        showLoading();

        await service.update(data.id!, newData, saved => onSaveResponse('Change VP', saved));

        hideLoading();
    };

    const onClickChangeVP = () => {
        const { data } = { ...values };
        setValues(prev => ({ ...prev, data }));
        showDialog({
            title: 'Proccess log',
            minWidth: 500,
            dialogContentProps: {
                type: DialogType.normal,
            }
        }, () => {
            return (
                <ProcessLog
                    item={values.data}
                    onDismiss={hideDialog}
                    onSubmit={data => onChageVp(data)}
                    action='change-vp'
                />
            );
        });
    };

    const onPrintClick = (item: IRequestDocumentItem, index: number) => {
        const nextType = !item.requestType || item.requestType === 'view' ? 'print' : 'view';
        setValues(prev => {
            const data = { ...prev.data };
            const docs = data.documents ? [...data.documents] : []
            if (docs[index]) {
                docs[index].requestType = nextType;
                data.documents = [...docs];
                return { ...prev, data };
            }

            return { ...prev, };
        });
    };

    const onCheckApprove = (index: number, check: boolean | undefined) => {
        const checked: boolean | null = check ? true : false;
        setValues(prev => {
            const data = { ...prev.data };
            const docs = data.documents ? [...data.documents] : []
            if (docs[index]) {
                docs[index].approve = checked;
                data.documents = [...docs];
                return { ...prev, data };
            }

            return { ...prev, };
        });
    };

    const initialColumns: IColumn[] = [
        {
            key: 'type',
            name: 'Request Type',
            fieldName: 'requestType',
            minWidth: 130,
            maxWidth: 130,
            styles: {
                root: {
                    padding: 0
                }
            },
            onRender: (item: IRequestDocumentItem, index?: number) => {
                if ((values.mode === 'edit' && !item.approve) || (!index && index !== 0)) {
                    return <></>
                }

                const print = item && item.requestType === 'print';
                const view = item && (item.requestType === 'print' || item.requestType === 'view');

                if (!item.approve) {
                    return <Text styles={{ root: { color: theme.semanticColors.errorIcon } }}>Rejected</Text>
                }

                const isShowPrint = (isTableReadonly && !print) ? false : true;

                return (
                    <Stack horizontal tokens={stackTokens}>
                        <DefaultButton
                            primary={view}
                            iconProps={{ iconName: 'View' }}
                            disabled={isTableReadonly}
                            styles={buttonStyles}
                        />
                        {isShowPrint && <DefaultButton
                            primary={print}
                            toggle
                            iconProps={{ iconName: 'Print' }}
                            onClick={() => onPrintClick(item, index)}
                            disabled={isTableReadonly}
                            styles={buttonStyles}
                        />}
                    </Stack>
                );
            }
        },
        {
            key: 'approve',
            name: 'Approve',
            fieldName: 'approve',
            minWidth: 70,
            maxWidth: 70,
            onRender: (item: IRequestDocumentItem, index?: number) => {
                if (!index && index !== 0) {
                    return <></>;
                }

                const checked = item.approve || false;

                return <Checkbox checked={checked} onChange={(e, check) => onCheckApprove(index, check)} />;
            }
        },
        {
            key: 'name',
            name: 'Document name',
            fieldName: 'name',
            minWidth: 250,
            isMultiline: true,
            onRender: (item: IRequestDocumentItem) => {
                return <span title={item.abstract || item.name!}>{item.name}</span>;
            }
        },
        {
            key: 'parties',
            name: 'Parties',
            fieldName: 'parties',
            minWidth: 250,
            isMultiline: true,
        },
        {
            key: 'date',
            name: 'Date',
            fieldName: 'date',
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: IRequestDocumentItem) => {
                return <>{item.date && onFormatDate(new Date(item.date))}</>;
            }
        },
        {
            key: 'path',
            name: 'Document path',
            fieldName: 'path',
            minWidth: 140,
            maxWidth: 140,
            onRender: (item: IRequestDocumentItem) => {
                if (!item.path) {
                    return <></>;
                }

                return (
                    <DocumentPathButton path={item.path} styles={{ root: { height: 'auto' } }} />
                )
            },
        },
    ];

    const canApprove = values.data.status === 'waiting-admin-verify';
    const canReject = values.data.status === 'waiting-admin-verify';
    const canRemind = values.data.status === 'waiting-vp-approve' || values.data.status === 'waiting-clgvp-approve';
    const canChangVp = values.data.status === 'waiting-vp-approve';
    const canRemoveAuth = values.data.status === 'approved';

    const isAdmin = profile && profile.user && profile.role === 'admin-verify';
    let canRecall = false;
    if (
        !canApprove &&
        (
            (isAdmin && values.data.adminApproveDate && !values.data.clgVpApproveDate)
        ) &&
        (
            values.data.status === 'rejected' ||
            values.data.status === 'waiting-clgvp-approve'
        )
    ) {
        canRecall = true;
    }

    let columns = [...initialColumns];

    if (isTableReadonly) {
        columns = columns.filter(c => c.fieldName !== 'approve');
    }

    const onChangeRemark = (ev: React.FormEvent<HTMLElement>, newValue?: string) => {
        const data = { ...values.data, remark: newValue ? newValue : null };
        setValues(prev => ({ ...prev, data }))
    };

    return (
        <Stack tokens={stackTokens}>
            <NavigationPane items={breadcrumbItems || []} />
            <Stack tokens={stackTokens} styles={{ root: { margin: '0 10px 0 0', minHeight: 25, borderBottom: `1px solid ${theme.palette.themePrimary}` } }}>
                <Stack tokens={stackTokens} horizontal wrap={isSmallDevice} verticalAlign='center' styles={{ root: { marginLeft: 5 } }}>
                    <Stack>
                        <Text variant='large' styles={textBoldStyles} >REQUEST DETAIL</Text>
                    </Stack>
                    <Stack tokens={stackTokens} grow={1} horizontal>
                        <Text styles={textBoldStyles} >Request by</Text>
                        <Text>{values.data.requestBy?.name}</Text>
                    </Stack>
                    <Stack tokens={stackTokens} horizontal verticalAlign='center'>
                        <IconButton iconProps={{ iconName: 'WaitlistConfirm' }} onClick={onClickViewLog} />
                        <Text styles={textStatusStyles(values.data.status)}>{getDisplayValue(values.data.status)}</Text>
                    </Stack>
                </Stack>
            </Stack>

            <Stack styles={{ root: { margin: '0 10px 10px 0' } }}>
                <Stack tokens={stackTokens} horizontal wrap={isSmallDevice} styles={{ root: { marginLeft: 5 } }}>
                    <Stack grow={1} tokens={stackTokens} styles={{ root: { maxWidth: isSmallDevice ? undefined : '50%' } }}>
                        <Stack tokens={stackTokens} horizontal wrap={isSmallDevice}>
                            <Stack grow={1}>
                                <DatePicker
                                    label='Date from'
                                    placeholder="Date from"
                                    value={starDate || undefined}
                                    disabled
                                />
                            </Stack>
                            <Stack grow={1}>
                                <DatePicker
                                    label='Date to'
                                    placeholder="Date to"
                                    value={endDate || undefined}
                                    disabled
                                />
                            </Stack>
                            <TextField label='Day(s)'
                                disabled
                                styles={{ root: { maxWidth: 100 } }}
                                value={days}
                                readOnly
                                onChange={e => e.preventDefault()}
                            />
                        </Stack>
                        <Stack>
                            <TextField
                                label='Request for'
                                disabled
                                value={values.data.requestFor?.name || ''}
                                onChange={e => e.preventDefault()}
                            />
                        </Stack>
                    </Stack>
                    <Stack tokens={stackTokens} grow={1} styles={{ root: { maxWidth: isSmallDevice ? undefined : '50%' } }}>
                        <TextField
                            disabled
                            label="Objective of Request"
                            multiline rows={5}
                            value={values.data.objective || ''}
                            onChange={e => e.preventDefault()}
                        />
                    </Stack>
                </Stack>
            </Stack>

            <Stack tokens={stackTokens} styles={{ root: { margin: '0 10px 0 0', minHeight: 25, borderBottom: `1px solid ${theme.palette.themePrimary}` } }}>
                <Stack tokens={stackTokens} horizontal verticalAlign='center' styles={{ root: { marginLeft: 5 } }}>
                    <Stack>
                        <Text variant='large' styles={textBoldStyles} >DOCUMENT REQUEST LIST</Text>
                    </Stack>
                </Stack>
            </Stack>

            <Stack styles={{ root: { margin: '0 10px 0 0' } }}>
                {values.data.documents && !isEmpty(values.data.documents)
                    ? (
                        <DetailsList
                            items={values.data.documents}
                            columns={columns}
                            selectionMode={SelectionMode.none}
                            getKey={getDocKey}
                            setKey="multiple"
                        />
                    )
                    : (
                        <Text>No document found</Text>
                    )}
            </Stack>

            {values.data.remark &&
                <Stack styles={{ root: { maxWidth: isSmallDevice ? undefined : '50%' } }}>
                    <TextField
                        label='Reason / Remark (VP/SVP/EVP)'
                        multiline
                        value={values.data.remark || ''}
                        onChange={onChangeRemark}
                        disabled
                    />
                </Stack>
            }

            {values.data.adminRemark &&
                <Stack styles={{ root: { maxWidth: isSmallDevice ? undefined : '50%' } }}>
                    <TextField
                        label='Reason / Remark (Administrator)'
                        multiline
                        value={values.data.adminRemark || ''}
                        onChange={e => e.preventDefault()}
                        disabled
                    />
                </Stack>
            }

            {values.data.clgVpRemark &&
                <Stack styles={{ root: { maxWidth: isSmallDevice ? undefined : '50%' } }}>
                    <TextField
                        label='Reason / Remark (Legal VP)'
                        multiline
                        value={values.data.clgVpRemark || ''}
                        onChange={e => e.preventDefault()}
                        disabled
                    />
                </Stack>
            }

            <Stack tokens={stackTokens} horizontal wrap={isSmallDevice} styles={{ root: { margin: '0 10px 5px 0', paddingTop: 20 } }}>
                {canApprove && <PrimaryButton text='Verfiy' onClick={onSendApprove} />}
                {canReject && <DefaultButton text="Reject" onClick={onRejectClick} />}
                {canRemind && <DefaultButton text="Remind" onClick={onRemindClick} />}
                {canRecall && <DefaultButton text='Recall' onClick={onRecallClick} />}
                <DefaultButton text="Cancel" onClick={() => onClickListLink()} />
                {canChangVp && <DefaultButton text="Change VP" onClick={onClickChangeVP} />}
                {canRemoveAuth && <DefaultButton text="Remove Authorization" onClick={onClickRemoveAuthorization} />}
            </Stack>

            <Debug object={values} />

            <OverlayLoading isVisibled={isLoading} />
        </Stack>
    )
};