import { IActiveStatus, ISaveResponse, ISite } from "../../../models";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react/lib/ChoiceGroup";
import { getDisplayValue, isEmpty } from "../../../tools";
import { RouteComponentProps } from "react-router";
import { useMemo } from "react";
import { SiteService } from "../../../services";
import { cloneDeep } from "lodash";
import { IBreadcrumbItem } from "@fluentui/react/lib/Breadcrumb";
import { listBreadcrumbItems } from "./SiteList";
import { NavigationPane } from "../../../components/NavigationPane";
import { TextField } from "@fluentui/react/lib/TextField";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { Debug } from "../../../components/Debug";
import { AppContext } from "../../../contexts";
import { DialogType } from "@fluentui/react/lib/Dialog";
import { MessageBar, MessageBarType } from "@fluentui/react/lib/MessageBar";
import { useBoolean } from "@fluentui/react-hooks";
import { OverlayLoading } from "../../../components/OverlayLoading";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";

export type IFormMode = 'new' | 'edit' | 'readonly';

export interface ISiteValues {
    mode: IFormMode;
    data: ISite;
}

const stackTokens: IStackTokens = { childrenGap: 10 };

const statusOptions: IChoiceGroupOption[] = [
    { key: 'active', text: getDisplayValue('active') },
    { key: 'inactive', text: getDisplayValue('inactive') },
];

const initialValues: ISiteValues = {
    mode: 'readonly',
    data: {
        id: null,
        key: '-1',
        name: null,
        description: null,
        status: 'active',
        adminEmail: null,
    }
};

const validateEmails = (email: string): boolean => {
    // eslint-disable-next-line no-useless-escape
    var regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    var result = email.replace(/\s/g, "").split(/,|;/);
    for (var i = 0; i < result.length; i++) {
        if (!regex.test(result[i])) {
            return false;
        }
    }
    return true;
}

export const SiteForm: React.FunctionComponent<RouteComponentProps<{ site: string, id: string }>> = (props) => {
    const { showDialog } = useContext(AppContext);
    const [values, setValues] = useState<ISiteValues>(initialValues);
    const [originalValues, setOriginalValues] = useState<ISite | undefined>(undefined);
    const [isLoading, { setFalse: hideLoading, setTrue: showLoading }] = useBoolean(false);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;
    const isLargeDevice = modalResponsiveMode === ResponsiveMode.large || modalResponsiveMode === ResponsiveMode.xLarge;

    const service = useMemo(() => new SiteService(), []);
    const { match: { params: { site, id } }, history, location: { state } } = props;
    const listLink = `/site/${site}/sitesetup/site`;
    const readonly = values.mode === 'readonly';

    useEffect(() => {
        let isMounted = true;

        if (!id || id === 'new') {
            setValues({
                ...cloneDeep(initialValues),
                mode: 'new',
            });
            return;
        }

        const promise = service.get({ id });
        promise.then(data => {
            if (!isMounted || !data || !data.id) {
                setValues({ ...cloneDeep(initialValues), mode: 'readonly' });
                setOriginalValues(undefined);
                return;
            }

            setValues({
                ...cloneDeep(initialValues),
                data,
                mode: 'edit',
            });

            setOriginalValues({ ...data });
        });

        return () => {
            setValues(cloneDeep(initialValues));
            setOriginalValues(undefined);
        };
    }, [id, service]);

    const onClickListLink = (ev?: React.MouseEvent<HTMLElement>) => {
        ev?.preventDefault();
        history.push(listLink, { from: 'form', values: state });
    };

    const breadcrumbItems: IBreadcrumbItem[] = [
        { ...listBreadcrumbItems, href: listLink, onClick: onClickListLink },
        {
            key: values.mode,
            text: originalValues?.name || (values.mode === 'new' ? 'New' : '...'),
        }
    ];

    const onChangeTextField = useCallback(<K extends keyof ISite>(newValue: ISite[K] | null, fieldName: K) => {
        const newValues: ISiteValues = { ...values };
        newValues.data[fieldName] = newValue as ISite[K];
        setValues(newValues);
    }, [values]);

    const onChangeChoiceField = <K extends keyof ISite>(newValue?: ISite[K] | null, fieldName?: K) => {
        if (!fieldName) {
            return;
        }

        const newValues: ISiteValues = { ...values };
        newValues.data[fieldName] = newValue as ISite[K];
        setValues(newValues);
    };

    const onClickReset = () => {
        if (!originalValues) {
            return;
        }

        setValues({ ...values, data: originalValues });
    };

    const onClickCancel = () => {
        onClickListLink();
    };

    const onSaveResponse = (action: string, saved: ISaveResponse<ISite>, isBackToList: boolean = true) => {
        if (saved.isError) {
            showDialog({
                title: `${action} failed`,
                dialogContentProps: {
                    type: DialogType.normal,
                }
            }, () => {
                return (
                    <>
                        {saved.errorMessage &&
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {saved.errorMessage}
                            </MessageBar>
                        }
                        {saved.errorMessages && saved.errorMessages.map((err, i) => (
                            <MessageBar key={i}
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {err}
                            </MessageBar>
                        ))}
                    </>
                );
            });

            return;
        } else {
            onClickListLink();
        }
    };

    const onValidateForm = () => {
        const errorMessages: string[] = [];
        if (!values.data.name) {
            errorMessages.push('Site name is required');
        }

        if (!values.data.description) {
            errorMessages.push('Description is required');
        }

        if (!values.data.adminEmail) {
            errorMessages.push('Admin email is required');
        }

        if (values.data.adminEmail && !validateEmails(values.data.adminEmail)) {
            errorMessages.push('Admin email is incorrect pattern');
        }

        if (!isEmpty(errorMessages)) {
            showDialog({
                title: `Invalid form`,
                dialogContentProps: {
                    type: DialogType.normal,
                }
            }, () => {
                return (
                    <>
                        {errorMessages && errorMessages.map((err, i) => (
                            <MessageBar key={i}
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {err}
                            </MessageBar>
                        ))}
                    </>
                );
            });

            return false;
        }

        return true;
    };

    const onClickSave = async () => {
        if (!onValidateForm()) {
            return;
        }

        showLoading();

        if (values.mode === 'new') {
            await service.create(values.data, saved => onSaveResponse('Save data', saved));
        }

        if (values.mode === 'edit') {
            await service.update(id, values.data, saved => onSaveResponse('Save data', saved));
        }

        hideLoading();
    };

    return (
        <Stack tokens={stackTokens}>
            <NavigationPane items={breadcrumbItems || []} />

            <Stack horizontal wrap={isSmallDevice} tokens={stackTokens} >
                <Stack tokens={stackTokens} styles={{ root: { minWidth: isSmallDevice ? '100%' : isLargeDevice ? '80%' : '1000px' } }}>
                    <Stack styles={{ root: { maxWidth: isSmallDevice ? undefined : 500 } }}>
                        <TextField
                            label='Site name'
                            disabled={readonly}
                            value={values.data.name || ''}
                            onChange={(e, value) => onChangeTextField(value || null, 'name')}
                            required
                        />
                        <TextField
                            label='Description'
                            disabled={readonly}
                            value={values.data.description || ''}
                            multiline
                            onChange={(e, value) => onChangeTextField(value || null, 'description')}
                            required
                        />
                        <TextField
                            label='Admin email'
                            disabled={readonly}
                            value={values.data.adminEmail || ''}
                            onChange={(e, value) => onChangeTextField(value || null, 'adminEmail')}
                            required
                        />
                    </Stack>
                </Stack>
                <Stack >
                    <ChoiceGroup
                        selectedKey={values.data.status || 'active'}
                        options={statusOptions}
                        disabled={readonly}
                        label="Status"
                        onChange={(e, o) => onChangeChoiceField(o?.key as IActiveStatus, 'status')}
                    />
                </Stack>
            </Stack>

            <Stack horizontal tokens={stackTokens}>
                {!readonly && <PrimaryButton text='Save' onClick={onClickSave} />}
                {values.mode === 'edit' && <DefaultButton text='Reset' onClick={onClickReset} />}
                <DefaultButton text='Cancel' onClick={onClickCancel} />
            </Stack>

            <Debug object={values} />

            <OverlayLoading isVisibled={isLoading} />
        </Stack>
    )
};