import ReactDOM from 'react-dom';
import { App } from './App';
import { ThemeProvider } from '@fluentui/react/lib/Theme';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import { theme } from './styles';

// Inject some global styles
mergeStyles({
  ':global(body,html,#root)': {
    margin: 0,
    padding: 0,
    height: '100vh',
  },
});

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl!}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </BrowserRouter>,
  rootElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
