import { IconButton } from "@fluentui/react/lib/Button"
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";
import { IStackProps, IStackStyles, IStackTokens, Stack } from "@fluentui/react/lib/Stack"
import { ITextStyles, Text } from "@fluentui/react/lib/Text"
import { useTheme } from "@fluentui/react/lib/Theme";
import React, { useRef } from "react"
import { useContext } from "react";
import { CardHeader } from ".";
import { AppContext } from "../../contexts";
import { IPermission, IRecentlyCard, IRecentlyCardItem } from "../../models";
import { isEmpty, isHasPermission } from "../../tools";

export interface IRecentlyCardProps extends IStackProps {
    data?: IRecentlyCard;
}

const stackTokens: IStackTokens = { childrenGap: 5 };

export const RecentlyCard: React.FunctionComponent<IRecentlyCardProps> = (props) => {
    const theme = useTheme();
    const { showPdfViewer } = useContext(AppContext);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small
    const isMediumDevice = modalResponsiveMode === ResponsiveMode.medium;
    const isLargeDevice = modalResponsiveMode === ResponsiveMode.large;

    const { data } = props;

    const textStyles: Partial<ITextStyles> = {
        root: {
            color: theme.palette.themePrimary,
        }
    };

    const onClickViewPdf = (item: IRecentlyCardItem, canPrint?: boolean) => {
        showPdfViewer({
            mode: 'view',
            documentId: item.docId,
            pathId: item.pathId,
            permission: 'view',
        });
    };

    const onClickPrintPdf = (item: IRecentlyCardItem) => {
        showPdfViewer({
            mode: 'print',
            documentId: item.docId,
            pathId: item.pathId,
            permission: 'view-print',
        });
    };

    const renderIcons = (item: IRecentlyCardItem) => {
        const canView = isHasPermission<IPermission>(item.permissions, 'view');
        const canPrint = isHasPermission<IPermission>(item.permissions, 'print');
        return (
            <>
                {canView &&
                    <IconButton iconProps={{ iconName: 'View' }} onClick={() => onClickViewPdf(item, canPrint)} />
                }
                {canPrint &&
                    <IconButton iconProps={{ iconName: 'Print' }} onClick={() => onClickPrintPdf(item)} />
                }
            </>
        );
    };

    const stackStyles: IStackStyles = {
        root: isSmallDevice
            ? { overflow: 'hidden', width: 280 }
            : isLargeDevice ? { overflow: 'hidden', width: 280 } : {}
    };

    const innerStackStyles: IStackStyles = {
        root: isSmallDevice || isLargeDevice ? { overflowX: 'scroll' } : {}
    };

    const docNameWidth = isSmallDevice
        ? 250
        : isMediumDevice || isLargeDevice ? 300 : undefined

    return (
        <Stack {...props}>
            <CardHeader text='Recently Viewed' />
            <Stack styles={stackStyles}>
                <Stack grow={1} tokens={stackTokens} styles={innerStackStyles}>
                    {(data && !isEmpty(data.items)) && data.items?.map((item, i) => {
                        return (
                            <Stack key={i} tokens={stackTokens}>
                                <Stack horizontal>
                                    <Stack grow={1} horizontal tokens={stackTokens} verticalAlign='center'>
                                        <Text>{item.date}</Text>
                                        <Stack styles={{ root: { maxWidth: 250, width: docNameWidth } }}>
                                            <Text
                                                styles={textStyles}
                                                block nowrap
                                                title={item.name}
                                            >
                                                {item.name}
                                            </Text>
                                        </Stack>
                                    </Stack>
                                    <Stack horizontal tokens={stackTokens}>
                                        {renderIcons(item)}
                                    </Stack>
                                </Stack>
                            </Stack>
                        );
                    })}
                </Stack>
            </Stack>
        </Stack>
    )
};