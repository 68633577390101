import { Breadcrumb, IBreadcrumbProps, IBreadcrumbStyles } from "@fluentui/react/lib/Breadcrumb";
import { FontSizes, FontWeights } from "@fluentui/react/lib/Styling";
import React from "react";

export interface INavigationPane extends IBreadcrumbProps { }

const breadcrumbStyles: Partial<IBreadcrumbStyles> = {
    root: {
        margin: '0 0 10px 0',
    },
    itemLink: {
        fontSize: FontSizes.medium,
        fontWeight: FontWeights.regular,
    },
    item: {
        fontSize: FontSizes.medium,
        '& .ms-TooltipHost': {
            fontWeight: FontWeights.regular,
        }
    },
};

export const NavigationPane: React.FunctionComponent<INavigationPane> = (props) => {

    return (
        <Breadcrumb {...props} styles={breadcrumbStyles} />
    );
};