import { IBreadcrumbItem } from "@fluentui/react/lib/Breadcrumb";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { Debug } from "../../../components/Debug";
import { NavigationPane } from "../../../components/NavigationPane";
import { listBreadcrumbItems } from "./DataTypeList";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { TextField } from "@fluentui/react/lib/TextField";
import { getDisplayValue, isEmpty } from "../../../tools";
import { IActiveStatus, IDataName, ISaveResponse } from "../../../models";
import { DataNameService, DataTypeService } from "../../../services";
import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react/lib/ChoiceGroup";
import { useMemo } from "react";
import { cloneDeep } from "lodash";
import { AppContext } from "../../../contexts";
import { DialogType } from "@fluentui/react/lib/Dialog";
import { MessageBar, MessageBarType } from "@fluentui/react/lib/MessageBar";
import { useBoolean } from "@fluentui/react-hooks";
import { OverlayLoading } from "../../../components/OverlayLoading";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";

export type IFormMode = 'new' | 'edit' | 'readonly';

export interface IDataNameValues {
    mode: IFormMode;
    data: IDataName;
}

const stackTokens: IStackTokens = { childrenGap: 10 };

const statusOptions: IChoiceGroupOption[] = [
    { key: 'active', text: getDisplayValue('active') },
    { key: 'inactive', text: getDisplayValue('inactive') },
];

const initialValues: IDataNameValues = {
    mode: 'readonly',
    data: {
        id: null,
        key: '-1',
        name: null,
        typeId: null,
        status: 'active',
        lineno: '99999'
    }
};

export const DataNameForm: React.FunctionComponent<RouteComponentProps<{ site: string, typeId: string, id: string }>> = (props) => {
    const { profile, showDialog } = useContext(AppContext);
    const [values, setValues] = useState<IDataNameValues>(cloneDeep(initialValues));
    const [originalValues, setOriginalValues] = useState<IDataName | undefined>(undefined);
    const [isLoading, { setFalse: hideLoading, setTrue: showLoading }] = useBoolean(false);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;
    const isLargeDevice = modalResponsiveMode === ResponsiveMode.large || modalResponsiveMode === ResponsiveMode.xLarge;

    const service = useMemo(() => new DataNameService(), []);
    const dataTypeService = useMemo(() => new DataTypeService(), []);
    const { match: { params: { site, typeId, id } }, history, location: { state } } = props;

    const readonly = values.mode === 'readonly';

    useEffect(() => {
        let isMounted = true;

        async function initial() {
            try {
                const dataType = await dataTypeService.get({ id: typeId });

                if (!dataType) {
                    setValues({ ...cloneDeep(initialValues) });
                } else {
                    if (!id || id === 'new') {
                        const data = { ...cloneDeep(initialValues).data, dataType: dataType };
                        setValues({
                            ...cloneDeep(initialValues),
                            data,
                            mode: 'new',
                        });
                        return;
                    }

                    const promise = service.get({ id, typeId });
                    promise.then(data => {
                        if (!isMounted || !data || !data.id) {
                            setValues({ ...cloneDeep(initialValues), mode: 'readonly' });
                            setOriginalValues(undefined);
                            return;
                        }

                        setValues({
                            ...cloneDeep(initialValues),
                            data,
                            mode: 'edit',
                        });

                        setOriginalValues(cloneDeep(data));
                    });
                }

            } catch (error) {
                console.error(error);
            }
        }

        initial();

        return () => {
            setValues(cloneDeep(initialValues));
            setOriginalValues(undefined);
        };
    }, [typeId, id, service, dataTypeService]);

    const dataTypeListLink = `/site/${site}/administrator/datatype`;
    const dataNameListLink = `/site/${site}/administrator/datatype/${typeId}/dataname`;

    const onClickDataTypeListLink = (ev?: React.MouseEvent<HTMLElement>) => {
        ev?.preventDefault();
        history.push(dataTypeListLink);
    };

    const onClickDataNameListLink = (ev?: React.MouseEvent<HTMLElement>) => {
        ev?.preventDefault();
        history.push(dataNameListLink, { from: 'nameForm', values: state });
    };

    const breadcrumbItems: IBreadcrumbItem[] = [
        { ...listBreadcrumbItems, href: dataTypeListLink, onClick: onClickDataTypeListLink },
        {
            key: 'dataNameList',
            href: dataNameListLink,
            onClick: onClickDataNameListLink,
            text: values.data.dataType ? values.data.dataType?.name || '...' : '...',
        },
        {
            key: 'form',
            text: originalValues?.name || (values.mode === 'new' ? 'New' : '...'),
        }
    ];

    const onChangeTextField = <K extends keyof IDataName>(newValue?: IDataName[K] | null, fieldName?: K) => {
        if (!fieldName) {
            return;
        }

        const newValues: IDataNameValues = { ...values };
        newValues.data[fieldName] = newValue as IDataName[K];
        setValues(newValues);
    };

    const onChangeChoiceField = <K extends keyof IDataName>(newValue?: IDataName[K] | null, fieldName?: K) => {
        if (!fieldName) {
            return;
        }

        const newValues: IDataNameValues = { ...values };
        newValues.data[fieldName] = newValue as IDataName[K];
        setValues(newValues);
    };

    const onClickReset = () => {
        setValues({ ...values, data: cloneDeep(originalValues!) });
    };

    const onClickCancel = () => {
        onClickDataNameListLink();
    };

    const onSaveResponse = (action: string, saved: ISaveResponse<IDataName>, isBackToList: boolean = true) => {
        if (saved.isError) {
            showDialog({
                title: `${action} failed`,
                dialogContentProps: {
                    type: DialogType.normal,
                }
            }, () => {
                return (
                    <>
                        {saved.errorMessage &&
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {saved.errorMessage}
                            </MessageBar>
                        }
                        {saved.errorMessages && saved.errorMessages.map((err, i) => (
                            <MessageBar key={i}
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {err}
                            </MessageBar>
                        ))}
                    </>
                );
            });

            return;
        } else {
            onClickDataNameListLink();
        }
    };

    const onValidateForm = () => {
        const errorMessages: string[] = [];
        if (!values.data.name) {
            errorMessages.push('Name is required');
        }

        if (!isEmpty(errorMessages)) {
            showDialog({
                title: `Invalid form`,
                dialogContentProps: {
                    type: DialogType.normal,
                }
            }, () => {
                return (
                    <>
                        {errorMessages && errorMessages.map((err, i) => (
                            <MessageBar key={i}
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {err}
                            </MessageBar>
                        ))}
                    </>
                );
            });

            return false;
        }

        return true;
    };

    const onClickSave = async () => {
        if (!onValidateForm()) {
            return;
        }

        showLoading()

        if (values.mode === 'new') {
            const data = { ...values.data, site: profile?.site };
            await service.create(data, saved => onSaveResponse('Save data', saved));
        }

        if (values.mode === 'edit') {
            await service.update(id, values.data, saved => onSaveResponse('Save data', saved));
        }

        hideLoading();
    };

    return (
        <Stack tokens={stackTokens}>
            <NavigationPane items={breadcrumbItems || []} />

            <Stack horizontal wrap={isSmallDevice} tokens={stackTokens} >
                <Stack tokens={stackTokens} styles={{ root: { minWidth: isSmallDevice ? '100%' : isLargeDevice ? '80%' : '1000px' } }}>
                    <Stack styles={{ root: { maxWidth: isSmallDevice ? '100%' : 500 } }}>
                        <TextField
                            label='Name'
                            disabled={readonly}
                            value={values.data.name || ''}
                            onChange={(e, value) => onChangeTextField(value, 'name')}
                            required
                        />
                    </Stack>
                </Stack>
                <Stack >
                    <ChoiceGroup
                        selectedKey={values.data.status || ''}
                        options={statusOptions}
                        disabled={readonly}
                        label="Status"
                        onChange={(e, o) => onChangeChoiceField((o?.key as IActiveStatus) || 'active', 'status')}
                    />
                </Stack>
            </Stack>

            <Stack horizontal tokens={stackTokens}>
                {!readonly && <PrimaryButton text='Save' onClick={onClickSave} />}
                {values.mode === 'edit' && <DefaultButton text='Reset' onClick={onClickReset} />}
                <DefaultButton text='Cancel' onClick={onClickCancel} />
            </Stack>

            <Debug object={values} />

            <OverlayLoading isVisibled={isLoading} />
        </Stack>
    )
};