import React from "react";
import { Route, RouteComponentProps } from "react-router";
import { PageContainer } from "../../../components/PageContainer";
import { AllRequestForm } from "./AllRequestForm";
import { AllRequestList } from "./AllRequestList";

export const AllRequest: React.FunctionComponent<RouteComponentProps> = props => {
    const { match: { path } } = props;

    return (
        <PageContainer center={false} >
            <Route exact path={path} component={AllRequestList} />
            <Route path={`${path}/:id`} component={AllRequestForm} />
        </PageContainer>
    );
};