import { Breadcrumb, IBreadcrumbItem, IBreadcrumbStyles } from "@fluentui/react/lib/Breadcrumb";
import { DefaultButton, IButtonStyles, IconButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { ITextStyles, Text } from "@fluentui/react/lib/Text";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { FontSizes, FontWeights, useTheme } from "@fluentui/react/lib/Theme";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";
import { getRequestStatusColor } from "../../tools/color";
import { getDateRange, getNextStatus, getPrevStatus, isEmpty, onFormatDate } from "../../tools";
import { TextField } from "@fluentui/react/lib/TextField";
import { RequestService } from "../../services";
import { IRequestDocumentItem, IRequest, IRequestStatusKeys, ISaveResponse } from "../../models";
import { DetailsList, IColumn, SelectionMode } from "@fluentui/react/lib/DetailsList";
import { AppContext } from "../../contexts";
import { DocumentPathButton } from "../../components/DocumentPathButton";
import { DialogType } from "@fluentui/react/lib/Dialog";
import { Debug } from "../../components/Debug";
import { getDisplayValue } from "../../tools/value";
import { ProcessLog } from "../../components/ProcessLog";
import { cloneDeep } from "lodash";
import { Checkbox } from "@fluentui/react/lib/Checkbox";
import { PageContainer } from "../../components/PageContainer";
import { DatePicker } from "../../components/DatePicker";
import { MessageBar, MessageBarType } from "@fluentui/react/lib/MessageBar";
import { useBoolean } from "@fluentui/react-hooks";
import { OverlayLoading } from "../../components/OverlayLoading";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";

export type IFormMode = 'readonly' | 'edit';

export interface IMyApproveValues {
    mode: IFormMode;
    data: IRequest;
}

const stackTokens: IStackTokens = { childrenGap: 10 };

const breadcrumbStyles: Partial<IBreadcrumbStyles> = {
    root: {
        margin: '0 0 10px 0',
    },
    itemLink: {
        fontSize: FontSizes.medium,
        fontWeight: FontWeights.regular,
    },
    item: {
        fontSize: FontSizes.medium,
        '& .ms-TooltipHost': {
            fontWeight: FontWeights.regular,
        }
    },
};

const textBoldStyles: Partial<ITextStyles> = {
    root: {
        fontWeight: FontWeights.bold,
        minWidth: 80
    }
};

const initialValues: IMyApproveValues = {
    mode: 'readonly',
    data: {
        id: null,
        key: '-1',
        requestDate: null,
        requestNo: null,
        requestBy: null,
        requestFor: null,
        requestFors: null,
        startDate: null,
        endDate: null,
        documents: [],
        objective: null,
        status: null,
        vpApprover: null,
        adminApprover: null,
        clgVpApprover: null,
    }
};

export const MyApproveForm: React.FunctionComponent<RouteComponentProps<{ site: string, id: string }>> = (props) => {
    const theme = useTheme();
    const {
        profile,
        clearDraftDocuments,
        showDialog,
        hideDialog,
    } = useContext(AppContext);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;

    const [values, setValues] = useState<IMyApproveValues>(cloneDeep({ ...initialValues }));
    const [originalValues, setOriginalValues] = useState<IRequest | undefined>(undefined);
    const [reload, setReload] = useState<boolean>(false);
    const [isLoading, { setFalse: hideLoading, setTrue: showLoading }] = useBoolean(false);

    const service = useMemo(() => new RequestService(), []);
    const { match: { params: { site, id } }, history, location: { state } } = props;
    const listLink = `/site/${site}/myapprove`;

    const detailDisabled = true;
    const startDate = values.data.startDate ? new Date(values.data.startDate!) : null;
    const endDate = values.data.endDate ? new Date(values.data.endDate!) : null;
    const dateRange = getDateRange(startDate, endDate);
    const days = dateRange?.toString() ?? '';

    useEffect(() => {
        const user = profile?.user;
        if (!user || !id) {
            setValues(cloneDeep({ ...initialValues }));
            setOriginalValues(undefined);
            return;
        }

        async function initial() {
            const data = await service.get({ id });
            if (!data || !user) {
                setValues(cloneDeep({ ...initialValues }));
                setOriginalValues(undefined);
                return;
            }

            let mode: IFormMode = 'readonly';
            if (
                data.status === 'waiting-vp-approve' &&
                data.vpApprover?.id === profile.employee?.id &&
                !data.vpApproveDate) {
                mode = 'edit';
            }

            if (
                data.status === 'waiting-admin-verify' &&
                profile.role === 'admin-verify' &&
                !data.adminApproveDate) {
                mode = 'edit';
                data.adminApprover = profile?.employee!;
            }

            if (
                data.status === 'waiting-clgvp-approve' &&
                data.clgVpApprover?.id === profile.employee?.id &&
                !data.clgVpApproveDate) {
                mode = 'edit';
            }

            setValues(cloneDeep({ ...initialValues, data: data, mode }));
            setOriginalValues(cloneDeep({ ...data }));
        }

        initial();

        setReload(false);

        return () => {
            setValues(cloneDeep({ ...initialValues }));
            setOriginalValues(undefined);
            setReload(false);
        };

    }, [id, service, profile, reload]);

    const backToList = (ev?: any) => {
        if (ev) {
            ev.preventDefault();
        }

        hideDialog();
        clearDraftDocuments();
        history.push(listLink, { from: 'form', values: state });
    };

    const onLeavePage = (ev: React.MouseEvent<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement | HTMLSpanElement>) => {
        backToList(ev);
    };

    const breadcrumbItems: IBreadcrumbItem[] = [
        {
            key: 'list',
            text: 'My Approve',
            href: listLink,
            onClick: backToList,
        },
        {
            key: values.mode,
            text: originalValues?.requestNo || '...',
        }
    ];

    const textStatusStyles = (status: IRequestStatusKeys | null): Partial<ITextStyles> => ({
        root: {
            fontWeight: FontWeights.bold,
            color: getRequestStatusColor(theme, status),
        }
    });

    const onDateFromChange = (date: Date | null | undefined) => {
        const data = { ...values.data, startDate: date ? new Date(date.setHours(0, 0, 0, 0)).toISOString() : null };
        setValues(prev => ({ ...prev, data }));
    }

    const onDateToChange = (date: Date | null | undefined) => {
        const data = { ...values.data, endDate: date ? new Date(date.setHours(0, 0, 0, 0)).toISOString() : null };
        setValues(prev => ({ ...prev, data }));
    }

    const onObjectiveChange = (ev: React.FormEvent<HTMLElement>, newValue?: string) => {
        const data = { ...values.data, objective: newValue ? newValue : null };
        setValues(prev => ({ ...prev, data }));
    }

    const getDocKey = (item: IRequestDocumentItem): string => {
        return item.pathId!;
    }

    const buttonStyles: Partial<IButtonStyles> = {
        root: {
            width: 22,
            minWidth: 22,
            maxWidth: 22,
            height: 22,
            minHeight: 22,
            maxHeight: 22,
            padding: 0,
        },
        icon: {
            fontSize: FontSizes.size12,
        }
    };

    const onPrintClick = (item: IRequestDocumentItem, index: number) => {
        const nextType = !item.requestType || item.requestType === 'view' ? 'print' : 'view';
        setValues(prev => {
            const data = { ...prev.data };
            const docs = data.documents ? [...data.documents] : []
            if (docs[index]) {
                docs[index].requestType = nextType;
                data.documents = [...docs];
                return { ...prev, data };
            }

            return { ...prev, };
        });
    };

    const onCheckApprove = (index: number, check: boolean | undefined) => {
        const checked: boolean | null = check ? true : false;
        setValues(prev => {
            const data = { ...prev.data };
            const docs = data.documents ? [...data.documents] : []
            if (docs[index]) {
                docs[index].approve = checked;
                data.documents = [...docs];
                return { ...prev, data };
            }

            return { ...prev, };
        });
    };

    let columns: IColumn[] = [
        {
            key: 'type',
            name: 'Request Type',
            fieldName: 'requestType',
            minWidth: 130,
            maxWidth: 130,
            styles: {
                root: {
                    padding: 0
                }
            },
            onRender: (item: IRequestDocumentItem, index?: number) => {
                if ((values.mode === 'edit' && !item.approve) || (!index && index !== 0)) {
                    return <></>
                }

                const print = item && item.requestType === 'print';
                const view = item && (item.requestType === 'print' || item.requestType === 'view');

                if (!item.approve) {
                    return <Text styles={{ root: { color: theme.semanticColors.errorIcon } }}>Rejected</Text>
                }

                if (values.mode === 'readonly') {
                    return (
                        <Stack horizontal tokens={stackTokens}>
                            {view && <DefaultButton
                                disabled
                                iconProps={{ iconName: 'View' }}
                                styles={buttonStyles}
                            />}
                            {print && <DefaultButton
                                disabled
                                toggle
                                iconProps={{ iconName: 'Print' }}
                                onClick={() => onPrintClick(item, index)}
                                styles={buttonStyles}
                            />}
                        </Stack>
                    );
                }

                return (
                    <Stack horizontal tokens={stackTokens}>
                        <DefaultButton
                            primary={view}
                            iconProps={{ iconName: 'View' }}
                            styles={buttonStyles}
                        />
                        <DefaultButton
                            primary={print}
                            toggle
                            iconProps={{ iconName: 'Print' }}
                            onClick={() => onPrintClick(item, index)}
                            styles={buttonStyles}
                        />
                    </Stack>
                );
            }
        },
        {
            key: 'approve',
            name: 'Approve',
            fieldName: 'approve',
            minWidth: 70,
            maxWidth: 70,
            onRender: (item: IRequestDocumentItem, index?: number) => {
                if (!index && index !== 0) {
                    return <></>;
                }

                const checked = item.approve || false;

                return (
                    <Checkbox
                        disabled={values.mode === 'readonly'}
                        checked={checked}
                        onChange={(e, check) => onCheckApprove(index, check)}
                    />
                );
            }
        },
        {
            key: 'name',
            name: 'Document name',
            fieldName: 'name',
            minWidth: 250,
            isMultiline: true,
            onRender: (item: IRequestDocumentItem) => {
                return <span title={item.abstract || item.name!}>{item.name}</span>;
            }
        },
        {
            key: 'parties',
            name: 'Parties',
            fieldName: 'parties',
            minWidth: 250,
            isMultiline: true,
        },
        {
            key: 'date',
            name: 'Date',
            fieldName: 'date',
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: IRequestDocumentItem) => {
                return <>{item.date && onFormatDate(new Date(item.date))}</>;
            }
        },
        {
            key: 'path',
            name: 'Document path',
            fieldName: 'path',
            minWidth: 140,
            maxWidth: 140,
            onRender: (item: IRequestDocumentItem) => {
                if (!item.path) {
                    return <></>;
                }

                return (
                    <DocumentPathButton path={item.path} styles={{ root: { height: 'auto' } }} />
                )
            },
        },
    ];

    if (values.mode === 'readonly') {
        columns = columns.filter(c => c.fieldName !== 'approve')
    }

    const onSaveResponse = (action: string, saved: ISaveResponse<IRequest>, isBackToList: boolean = true) => {
        if (saved.isError) {
            showDialog({
                title: `${action} failed`,
                dialogContentProps: {
                    type: DialogType.normal,
                }
            }, () => {
                return (
                    <>
                        {saved.errorMessage &&
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {saved.errorMessage}
                            </MessageBar>
                        }
                        {saved.errorMessages && saved.errorMessages.map((err, i) => (
                            <MessageBar key={i}
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {err}
                            </MessageBar>
                        ))}
                    </>
                );
            });

            return;
        } else {
            if (isBackToList) {
                backToList();
                return;
            }

            if (id) {
                setReload(true);
            } else {
                backToList();
            }
        }
    };

    const onSave = async (data: IRequest) => {
        const approveDate = new Date();
        const submitValue: IRequest = {
            ...data,
            status: getNextStatus(data.status!),
        };

        if (data.status === 'waiting-vp-approve') {
            submitValue.vpApproveDate = approveDate.toISOString();
            submitValue.isVpSendMail = false;
        }

        if (data.status === 'waiting-admin-verify') {
            submitValue.adminApproveDate = approveDate.toISOString();
            submitValue.isAdminSendMail = false;
        }

        if (data.status === 'waiting-clgvp-approve') {
            submitValue.clgVpApproveDate = approveDate.toISOString();
            submitValue.isClgVpSendMail = false;
        }

        showLoading();

        await service.update(data.id!, submitValue, saved => onSaveResponse('Approve request', saved));

        hideLoading();
    };

    const onClickViewLog = () => {
        showDialog({
            title: 'Proccess log',
            minWidth: 500,
            dialogContentProps: {
                type: DialogType.normal,
            }
        }, () => {
            return <ProcessLog item={values.data} displayOnly />;
        });
    };

    const onSendApprove = () => {
        const { data } = { ...values };
        setValues(prev => ({ ...prev, data }));
        showDialog({
            title: 'Proccess log',
            minWidth: 500,
            dialogContentProps: {
                type: DialogType.normal,
            }
        }, () => {
            return <ProcessLog disabled={isLoading} item={values.data} onDismiss={hideDialog} onSubmit={data => onSave(data)} />;
        });
    };

    const onRecallClick = async () => {
        const { data } = values;
        const prevStatus = getPrevStatus(data.status!);

        const submitValue: IRequest = {
            ...data,
            status: prevStatus,
        };

        if (data.status === 'waiting-clgvp-approve') {
            submitValue.adminApproveDate = null;
            submitValue.adminApprover = null;
            submitValue.clgVpApprover = null;
        }

        if (data.status === 'approved') {
            submitValue.clgVpApproveDate = null;
        }

        if (data.status === 'rejected') {
            if (data.clgVpApproveDate) {
                submitValue.status = 'waiting-clgvp-approve';
                submitValue.clgVpApproveDate = null;
            } else if (data.adminApproveDate) {
                submitValue.status = 'waiting-admin-verify';
                submitValue.adminApproveDate = null;
            } else if (data.vpApproveDate) {
                submitValue.status = 'waiting-vp-approve';
                submitValue.vpApproveDate = null;
            }
        }

        showLoading();

        await service.update(id, submitValue, saved => onSaveResponse('Recall', saved, false));

        hideLoading();
    };

    const onRemindClick = async () => {
        showLoading();

        await service.remind(id, { ...values.data }, sent => {
            var isError = sent.isError;
            showDialog({
                title: `Remind email ${isError ? 'failed' : 'success'}`,
                dialogContentProps: {
                    type: DialogType.normal,
                }
            }, () => {
                return (
                    <>
                        {isError && sent.errorMessage &&
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {sent.errorMessage}
                            </MessageBar>
                        }
                        {!isError && sent.successMessage &&
                            <MessageBar
                                messageBarType={MessageBarType.success}
                                isMultiline={true}
                            >
                                {sent.successMessage}
                            </MessageBar>
                        }
                    </>
                );
            });
        });

        hideLoading();
    };

    const onRejected = async (data: IRequest) => {
        const rejectDate = new Date();
        const submitValue: IRequest = {
            ...data,
            status: 'rejected',
        };

        if (data.status === 'waiting-vp-approve') {
            submitValue.vpApproveDate = rejectDate.toISOString();
        }

        if (data.status === 'waiting-admin-verify') {
            submitValue.adminApproveDate = rejectDate.toISOString();
        }

        if (data.status === 'waiting-clgvp-approve') {
            submitValue.clgVpApproveDate = rejectDate.toISOString();
        }

        showLoading();

        await service.update(data.id!, submitValue, saved => onSaveResponse('Reject', saved));

        hideLoading();
    };

    const onRejectClick = () => {
        const { data } = { ...values };
        setValues(prev => ({ ...prev, data }));
        showDialog({
            title: 'Proccess log',
            minWidth: 500,
            dialogContentProps: {
                type: DialogType.normal,
            }
        }, () => {
            return <ProcessLog item={values.data} onDismiss={hideDialog} onSubmit={data => onRejected(data)} action='reject' />;
        });
    };

    const canApprove = values.mode === 'edit';
    const canReject = values.mode === 'edit';
    let canRemind = false;
    let canRecall = false;

    const isAdmin = profile && profile.user && profile.role === 'admin-verify';
    const isClgVp = profile && profile.user && profile.role === 'clg-vp';

    let approveText = isAdmin ? 'Verify' : 'Approve';
    let recallText = isClgVp ? 'Revise' : 'Recall';

    if (!canApprove && isAdmin && values.data.status !== 'rejected' && values.data.status !== 'approved') {
        canRemind = true;
    }

    if (
        !canApprove &&
        (
            (isAdmin && values.data.adminApproveDate) || (isClgVp && values.data.clgVpApproveDate)
        ) &&
        (
            values.data.status === 'approved' ||
            values.data.status === 'rejected' ||
            values.data.status === 'waiting-clgvp-approve'
        )
    ) {
        canRecall = true;
    }

    return (
        <PageContainer>
            <Breadcrumb items={breadcrumbItems} styles={breadcrumbStyles} />
            <Stack tokens={stackTokens} styles={{ root: { margin: '0 10px 0 0', minHeight: 25, borderBottom: `1px solid ${theme.palette.themePrimary}` } }}>
                <Stack tokens={stackTokens} horizontal wrap={isSmallDevice} verticalAlign='center' styles={{ root: { marginLeft: 5 } }}>
                    <Stack>
                        <Text variant='large' styles={textBoldStyles} >REQUEST DETAIL</Text>
                    </Stack>
                    <Stack tokens={stackTokens} grow={1} horizontal>
                        <Text styles={textBoldStyles} >Request by</Text>
                        <Text>{values.data.requestBy?.name}</Text>
                    </Stack>
                    <Stack tokens={stackTokens} horizontal verticalAlign='center'>
                        <IconButton iconProps={{ iconName: 'WaitlistConfirm' }} onClick={onClickViewLog} />
                        <Text styles={textStatusStyles(values.data.status)}>{getDisplayValue(values.data.status)}</Text>
                    </Stack>
                </Stack>
            </Stack>

            <Stack styles={{ root: { margin: '0 10px 10px 0' } }}>
                <Stack tokens={stackTokens} horizontal wrap={isSmallDevice} styles={{ root: { marginLeft: 5 } }}>
                    <Stack grow={1} tokens={stackTokens} styles={{ root: { maxWidth: isSmallDevice ? undefined : '50%' } }}>
                        <Stack tokens={stackTokens} horizontal wrap={isSmallDevice}>
                            <Stack grow={1}>
                                <DatePicker
                                    label='Date from'
                                    placeholder="Date from"
                                    onSelectDate={onDateFromChange}
                                    value={startDate ? startDate : undefined}
                                    disabled={detailDisabled}
                                />
                            </Stack>
                            <Stack grow={1}>
                                <DatePicker
                                    label='Date to'
                                    placeholder="Date to"
                                    onSelectDate={onDateToChange}
                                    value={endDate ? endDate : undefined}
                                    disabled={detailDisabled}
                                />
                            </Stack>
                            <TextField label='Day(s)'
                                disabled
                                styles={{ root: { maxWidth: 100 } }}
                                value={days}
                                readOnly
                                onChange={e => e.preventDefault()}
                            />
                        </Stack>
                        <Stack>
                            <TextField label='Request for'
                                onChange={e => e.preventDefault()}
                                disabled
                                value={values.data.requestFor?.name || ''}
                            />

                        </Stack>
                    </Stack>
                    <Stack tokens={stackTokens} grow={1} styles={{ root: { maxWidth: isSmallDevice ? undefined : '50%' } }}>
                        <TextField
                            disabled={detailDisabled}
                            label="Objective of Request"
                            multiline rows={5}
                            onChange={onObjectiveChange}
                            value={values.data.objective || ''}
                        />
                    </Stack>
                </Stack>
            </Stack>

            <Stack tokens={stackTokens} styles={{ root: { margin: '0 10px 0 0', minHeight: 25, borderBottom: `1px solid ${theme.palette.themePrimary}` } }}>
                <Stack tokens={stackTokens} horizontal verticalAlign='center' styles={{ root: { marginLeft: 5 } }}>
                    <Stack>
                        <Text variant='large' styles={textBoldStyles} >DOCUMENT REQUEST LIST</Text>
                    </Stack>
                </Stack>
            </Stack>

            <Stack styles={{ root: { margin: '0 10px 0 0' } }}>
                {values.data.documents && !isEmpty(values.data.documents)
                    ? (
                        <DetailsList
                            items={values.data.documents}
                            columns={columns}
                            selectionMode={SelectionMode.none}
                            getKey={getDocKey}
                            setKey="multiple"
                            isHeaderVisible={true}
                        />
                    )
                    : (
                        <Text>No document found</Text>
                    )}
            </Stack>

            {values.data.remark &&
                <Stack styles={{ root: { maxWidth: isSmallDevice ? undefined : '50%' } }}>
                    <TextField
                        label='Reason / Remark (VP/SVP/EVP)'
                        multiline
                        value={values.data.remark || ''}
                        onChange={e => e.preventDefault()}
                        disabled
                    />
                </Stack>
            }

            {values.data.adminRemark &&
                <Stack styles={{ root: { maxWidth: isSmallDevice ? undefined : '50%' } }}>
                    <TextField
                        label='Reason / Remark (Administrator)'
                        multiline
                        value={values.data.adminRemark || ''}
                        onChange={e => e.preventDefault()}
                        disabled
                    />
                </Stack>
            }

            {values.data.clgVpRemark &&
                <Stack styles={{ root: { maxWidth: isSmallDevice ? undefined : '50%' } }}>
                    <TextField
                        label='Reason / Remark (Legal VP)'
                        multiline
                        value={values.data.clgVpRemark || ''}
                        onChange={e => e.preventDefault()}
                        disabled
                    />
                </Stack>
            }

            <Stack tokens={stackTokens} horizontal wrap={isSmallDevice} styles={{ root: { margin: '0 10px 5px 0', paddingTop: 20 } }}>
                {canApprove &&
                    <PrimaryButton text={approveText} onClick={onSendApprove} />
                }

                {canReject &&
                    <DefaultButton text="Reject" onClick={onRejectClick} />
                }

                {canRecall &&
                    <DefaultButton text={recallText} onClick={onRecallClick} />
                }

                {canRemind &&
                    <DefaultButton text="Remind" onClick={onRemindClick} />
                }

                <DefaultButton text="Cancel" onClick={onLeavePage} />
            </Stack>

            <Debug object={values} label='values' />

            <OverlayLoading isVisibled={isLoading} />
        </PageContainer>
    );
};
