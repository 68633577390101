import { IconButton } from "@fluentui/react/lib/Button";
import { Label } from "@fluentui/react/lib/Label";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { ITextFieldStyles, TextField } from "@fluentui/react/lib/TextField";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { IFileSize, IFileUpload } from "../models";
import { readableFileSize } from "../tools";

export interface ISingleAttachFileProps {
    label: string;
    value?: string | undefined
    isShowView?: boolean;
    onFileSelected?: (value: string, fileUpload: IFileUpload) => void;
    onRemoveFile?: () => void;
    onViewFile?: () => void;
    disabled?: boolean;
    required?: boolean;
    fileSize?: IFileSize;
}

const stackTokens: IStackTokens = { childrenGap: 10 };
const textFieldStyles: Partial<ITextFieldStyles> = {
    root: {
        flexGrow: 1,
    }
};

export const SingleAttachFile: React.FunctionComponent<ISingleAttachFileProps> = (props) => {
    const fileInput = useRef<HTMLInputElement>(null);
    const [fileUpload, setFileUpload] = useState<IFileUpload | undefined>(undefined);

    const { label, value, isShowView, disabled, required, fileSize, onFileSelected, onRemoveFile, onViewFile } = props;

    const onClickSelectFile = (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();
        if (fileInput && fileInput.current) {
            fileInput.current.click();
        }
    };

    const fileChangedHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (!ev || !ev.currentTarget || !ev.currentTarget.files) {
            return;
        }

        ev.preventDefault();

        const file = ev.currentTarget.files[0];
        const reader = new FileReader();

        reader.onloadend = function (e) {
            const newUploadFile: IFileUpload = {
                file: file,
                data: reader.result,
            };

            setFileUpload(newUploadFile);

            if (onFileSelected) {
                onFileSelected(file.name, newUploadFile);
            }
        };

        reader.readAsDataURL(file);
    };

    const onClickRemoveFile = (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();

        if (fileUpload) {
            setFileUpload(undefined);
        }

        if (fileInput && fileInput.current) {
            fileInput.current.value = '';
        }

        if (onRemoveFile) {
            onRemoveFile();
        }
    };

    const onClickViewFile = (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();
        if (onViewFile) {
            onViewFile();
        }
    };

    let text = value;
    if (fileSize && fileSize.name === text) {
        text = `${fileSize.name} (${readableFileSize(fileSize.size)})`;
    }

    return (
        <Stack >
            <Label disabled={disabled} required={required}>{label}</Label>
            <Stack grow horizontal tokens={stackTokens}>
                {!value && <IconButton iconProps={{ iconName: 'PDF' }} onClick={onClickSelectFile} disabled={disabled} />}
                {(value && isShowView) && <IconButton iconProps={{ iconName: 'View' }} onClick={onClickViewFile} disabled={disabled} />}
                {value && <IconButton iconProps={{ iconName: 'Delete' }} onClick={onClickRemoveFile} disabled={disabled} />}
                <TextField
                    disabled
                    styles={textFieldStyles}
                    value={text}
                    placeholder='No file chosen'
                    onChange={e => e.preventDefault()}
                />
            </Stack>
            <input
                ref={fileInput}
                type='file'
                style={{ display: 'none' }}
                accept='application/pdf'
                onChange={fileChangedHandler}
            />
        </Stack>
    );
};