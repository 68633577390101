import { Stack } from "@fluentui/react/lib/Stack";
import { Text } from "@fluentui/react/lib/Text";
import React from "react";
import { RouteComponentProps } from "react-router";

export const PageNotFound: React.FunctionComponent<RouteComponentProps<{ messages?: string }>> = props => {
    const { match: { params: { messages }} } = props;
    return (
        <Stack grow verticalAlign='center' horizontalAlign='center'>
            <Text variant='xxLarge'>Page not found</Text>
            {messages && <Text>{messages}</Text>}
        </Stack>
    );
};