import { ILinkStyles, Link } from "@fluentui/react/lib/Link";
import { IStackProps, IStackStyles, IStackTokens, Stack } from "@fluentui/react/lib/Stack"
import { FontSizes, FontWeights } from "@fluentui/react/lib/Styling";
import { Text } from "@fluentui/react/lib/Text"
import { useTheme } from "@fluentui/react/lib/Theme";
import React from "react"
import { Link as RouterLink, useRouteMatch } from "react-router-dom";
import { CardHeader } from ".";
import { IMyRequestCard, IRequestStatusKeys } from "../../models";
import { getDisplayValue, getRequestStatusColor } from "../../tools";

export interface IMyRequestCardProps extends IStackProps {
    data?: IMyRequestCard;
}

const totalLinkStyles: Partial<ILinkStyles> = {
    root: {
        fontWeight: FontWeights.bold,
        fontSize: FontSizes.size68,
    }
};

const stackItemStyles: Partial<IStackStyles> = {
    root: {
        minHeight: 20,
    }
}

const stackTokens: IStackTokens = { childrenGap: 5 };

export const MyRequestCard: React.FunctionComponent<IMyRequestCardProps> = (props) => {
    const theme = useTheme();
    const { params: { site } } = useRouteMatch<{ site: string }>();

    const { data } = props;

    const renderItem = (value: number, status: IRequestStatusKeys) => {
        if (value === 0) {
            return null;
        }

        const color = getRequestStatusColor(theme, status);
        const displayValue = getDisplayValue(status);
        return (
            <Stack horizontal verticalAlign='start' styles={stackItemStyles}>
                <Stack grow={1}>
                    <Text styles={{ root: { color } }}>{displayValue}</Text>
                </Stack>
                <Stack horizontal>
                    <Link as={RouterLink} to={`/site/${site}/myrequest?status=${status}`} >{value}</Link>
                </Stack>
            </Stack>
        );
    };

    return (
        <Stack {...props}>
            <Stack grow={1} horizontal>
                <Stack styles={{ root: { width: 150 } }}>
                    <CardHeader text='My Request' />
                    <Stack grow={1} tokens={stackTokens}>
                        <Stack grow={1}>
                            <Link as={RouterLink} to={`/site/${site}/myrequest/new`}>Create new</Link>
                        </Stack>
                        <Stack grow={1} verticalAlign='end' styles={{ root: { marginBottom: 30 } }} >
                            <Link styles={totalLinkStyles} as={RouterLink} to={`/site/${site}/myrequest`}>{data?.total || 0}</Link>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack grow={1} tokens={stackTokens} >
                    <Stack tokens={stackTokens} styles={{ root: { padding: '5px 20px 5px 10px', marginTop: 10 } }}>
                        {renderItem(data?.draft || 0, 'draft')}
                        {renderItem(data?.waitingVPApprove || 0, 'waiting-vp-approve')}
                        {renderItem(data?.waitingAdminVerify || 0, 'waiting-admin-verify')}
                        {renderItem(data?.waitingClgVpApprove || 0, 'waiting-clgvp-approve')}
                        {renderItem(data?.approved || 0, 'approved')}
                        {renderItem(data?.rejected || 0, 'rejected')}
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};