import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";
import { IStackProps, IStackStyles, Stack } from "@fluentui/react/lib/Stack";
import { FontWeights, mergeStyles } from "@fluentui/react/lib/Styling";
import { Text } from "@fluentui/react/lib/Text"
import { useTheme } from "@fluentui/react/lib/Theme";
import React, { useContext, useEffect, useRef, useState } from "react";
import { DatabaseCard, GetStartCard, MyApproveCard, MyRequestCard, RecentlyCard } from "../components/card";
import { PageContainer } from "../components/PageContainer";
import { appSidePadding } from "../constants";
import { AppContext } from "../contexts";
import { ICardPermission, ICards } from "../models";
import { CardService } from "../services";
import { isEmpty, isHasPermission } from "../tools";

const cardStackStyles: Partial<IStackStyles> = {
    root: {
        margin: '0 10px 0 20px',
    }
}

const className1 = mergeStyles({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%'
});

export const Dashboard: React.FunctionComponent = () => {
    const theme = useTheme();
    const { profile } = useContext(AppContext);
    const [cards, setCards] = useState<ICards | undefined>(undefined);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;
    const isLargeDevice = modalResponsiveMode === ResponsiveMode.large || modalResponsiveMode === ResponsiveMode.xLarge;

    const cardPerRow = isSmallDevice ? 1 : isLargeDevice ? 2 : 3;
    const cardHeight = 250;
    const cardWidth = isSmallDevice ? 80 : isLargeDevice ? 49.33 : 32.33; // as %

    const totalVisibleCard = cards ? cards.permissions.length : 0;
    const rows = Math.ceil(totalVisibleCard / cardPerRow);
    const totalBlock = cardPerRow * rows;
    const emptyBlock = totalBlock - totalVisibleCard;

    let emptyBlocks: string[] = [];
    for (let i = 0; i < emptyBlock; i++) {
        emptyBlocks.push(`empty-block-${i}`);
    }

    useEffect(() => {
        let isMounted = true;
        var service = new CardService();
        var promise = service.get({
            siteId: profile?.site?.id,
        });
        promise.then(newCards => {
            if (!isMounted || !newCards) {
                setCards(undefined);
                return;
            }

            setCards(newCards);
        });
        return () => {
            isMounted = false;
            setCards(undefined);
        };
    }, [profile]);

    const isShowGetStarted = isHasPermission<ICardPermission>(cards?.permissions, 'get-started');
    const isShowDatabase = isHasPermission<ICardPermission>(cards?.permissions, 'database');
    const isShowRecently = isHasPermission<ICardPermission>(cards?.permissions, 'recently');
    const isShowMyRequest = isHasPermission<ICardPermission>(cards?.permissions, 'my-request');
    const isShowMyApprove = isHasPermission<ICardPermission>(cards?.permissions, 'my-approve');

    const renderOtionalHeader = () => (
        <Stack styles={{ root: { padding: appSidePadding, paddingTop: 15, paddingBottom: 15 } }}>
            <Text variant='large' styles={{ root: { fontWeight: FontWeights.bold } }}>WELCOME TO iLEGAL DATABASE</Text>
            <Text>Legal Database is the system designed to collect and centralize all essential legal documents, and contains user friendly functionality with efficient search engine. We hope our Legal Database is a utilized tool that would facilitate and support your business per our motto:</Text>
            <Text variant='large' styles={{ root: { fontWeight: FontWeights.bold } }}>"LEGAL IS INTEGRATED IN ALL STAGES OF PTTEP’S BUSINESS LIFE CYCLE."</Text>
        </Stack>
    );

    const wrapperStackProps: IStackProps = {
        horizontal: true,
    };

    const className2 = mergeStyles({
        display: 'flex',
        height: cardHeight,
        marginBottom: 10,
        boxShadow: theme.effects.elevation8
    });

    const className3 = mergeStyles({
        display: 'flex',
        height: cardHeight,
        marginBottom: 10,
        boxShadow: theme.effects.elevation8
    });

    const className4 = mergeStyles({
        display: 'flex',
        flex: `0 ${cardWidth}%`,
        height: cardHeight,
        marginBottom: 10,
        boxShadow: theme.effects.elevation8
    });

    const className5 = mergeStyles({
        flex: `0 ${cardWidth}%`,
        height: cardHeight,
        marginBottom: 10,
    });

    const onRenderCards = (cards: ICards) => {
        if (isSmallDevice) {
            return (
                <Stack>
                    {isShowGetStarted && <div className={className2}>
                        <GetStartCard grow={1} styles={cardStackStyles} />
                    </div>}

                    {isShowDatabase && <div className={className3}>
                        <DatabaseCard grow={1} styles={cardStackStyles} />
                    </div>}

                    {isShowRecently && <div className={className3}>
                        <RecentlyCard grow={1} styles={cardStackStyles} data={cards.recently} />
                    </div>}

                    {isShowMyRequest && <div className={className3}>
                        <MyRequestCard grow={1} styles={cardStackStyles} data={cards.myRequest} />
                    </div>}

                    {isShowMyApprove && <div className={className3}>
                        <MyApproveCard grow={1} styles={cardStackStyles} data={cards.myApprove} />
                    </div>}
                </Stack>
            );
        }
        return (
            <div className={className1}>
                {isShowGetStarted && <div className={className4}>
                    <GetStartCard grow={1} styles={cardStackStyles} />
                </div>}

                {isShowDatabase && <div className={className4}>
                    <DatabaseCard grow={1} styles={cardStackStyles} />
                </div>}

                {isShowRecently && <div className={className4}>
                    <RecentlyCard grow={1} styles={cardStackStyles} data={cards.recently} />
                </div>}

                {isShowMyRequest && <div className={className4}>
                    <MyRequestCard grow={1} styles={cardStackStyles} data={cards.myRequest} />
                </div>}

                {isShowMyApprove && <div className={className4}>
                    <MyApproveCard grow={1} styles={cardStackStyles} data={cards.myApprove} />
                </div>}

                {!isEmpty(emptyBlocks) && emptyBlocks.map((b, i) => (
                    <div key={i} className={className5} />
                ))}
            </div>
        );
    };

    return (
        <PageContainer renderOptionalHeader={renderOtionalHeader} wrapperStackProps={wrapperStackProps} >
            {(cards && !isEmpty(cards.permissions)) && onRenderCards(cards)}
        </PageContainer>
    );
}
