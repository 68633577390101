import { IBreadcrumbItem } from "@fluentui/react/lib/Breadcrumb";
import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react/lib/Dropdown";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { useBoolean } from "@fluentui/react-hooks";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";
import { IActivitiesLogReport, IActivityType, IGroup, IGroupFlowRole, ISearchResult, IUserResult } from "../../../models";
import { getDisplayValue, isEmpty, onFormatDateTime } from "../../../tools";
import { GroupService, ReportService } from "../../../services";
import { DetailsList, IColumn, SelectionMode } from "@fluentui/react/lib/DetailsList";
import { NavigationPane } from "../../../components/NavigationPane";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { Text } from "@fluentui/react/lib/Text";
import { Debug } from "../../../components/Debug";
import { Pagination } from "../../../components/Pagination";
import { Loading } from "../../../components/Loading";
import { cloneDeep } from "lodash";
import { AppContext } from "../../../contexts";
import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react/lib/ChoiceGroup";
import { TextField } from "@fluentui/react/lib/TextField";
import { DatePicker } from "../../../components/DatePicker";
import { DialogType } from "@fluentui/react/lib/Dialog";
import { Link } from "@fluentui/react/lib/Link";
import { IPickerItem, Picker } from "../../../components/picker";
import { OverlayLoading } from "../../../components/OverlayLoading";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";

export interface IUserActivitySearchValues {
    keyword?: string | null;
    userType?: IGroupFlowRole | null;
    activityType?: IActivityType | null;
    logFromDate?: string | null;
    logToDate?: string | null;
    group?: IGroup | null;
    pageNumber: number;
    numberPerPage: number;
    sortedBy: keyof IActivitiesLogReport;
    sortedType: 'asc' | 'desc';
}

export const listBreadcrumbItems: IBreadcrumbItem = {
    key: 'list',
    text: 'Activity Log Report',
};

const stackTokens: IStackTokens = { childrenGap: 10 };
const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { minWidth: 200 },
};

const activityTypeOptions: IDropdownOption<IActivityType>[] = [
    { key: 'all', text: 'Activities - All', data: 'all' },
    { key: 'sign-in', text: getDisplayValue('sign-in'), data: 'sign-in' },
    { key: 'save-draft', text: getDisplayValue('save-draft'), data: 'save-draft' },
    { key: 'sent-request', text: getDisplayValue('sent-request'), data: 'sent-request' },
    { key: 'approved', text: getDisplayValue('approved'), data: 'approved' },
    { key: 'rejected', text: getDisplayValue('rejected'), data: 'rejected' },
    { key: 'remove-auth', text: getDisplayValue('remove-auth'), data: 'remove-auth' },
    { key: 'view-document', text: getDisplayValue('view-document'), data: 'view-document' },
    { key: 'print-document', text: getDisplayValue('print-document'), data: 'print-document' },
    { key: 'download-document', text: getDisplayValue('download-document'), data: 'download-document' },
    { key: 'download-pdf', text: getDisplayValue('download-pdf'), data: 'download-pdf' },
];

const userRoleOptions: IChoiceGroupOption[] = [
    { key: 'all', text: getDisplayValue('all') },
    { key: 'user', text: getDisplayValue('user') },
    { key: 'clg-vp', text: getDisplayValue('clg-vp') },
    { key: 'admin-verify', text: getDisplayValue('admin-verify') },
];

const numberPerPageOptions: IDropdownOption<number>[] = [
    { key: '20', text: '20', data: 20 },
    { key: '50', text: '50', data: 50 },
    { key: '100', text: '100', data: 100 },
    { key: '200', text: '200', data: 200 },
];

const initialValues: IUserActivitySearchValues = {
    userType: 'all',
    activityType: 'all',
    group: null,
    pageNumber: 1,
    numberPerPage: 20,
    sortedBy: 'date',
    sortedType: 'desc'
};

export const ActivitiesReport: React.FunctionComponent<RouteComponentProps> = (props) => {
    const { profile, showDialog } = useContext(AppContext);
    const [searchValues, setSearchValues] = useState<IUserActivitySearchValues>(initialValues);
    const [results, setResults] = useState<ISearchResult<IActivitiesLogReport> | undefined>(undefined);
    const [isLoading, { setFalse: hideLoading, setTrue: showLoading }] = useBoolean(false);
    const [isFileLoading, { setFalse: hideFileLoading, setTrue: showFileLoading }] = useBoolean(false);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;
    const isLargeDevice = modalResponsiveMode === ResponsiveMode.large || modalResponsiveMode === ResponsiveMode.xLarge;

    const service = useMemo(() => new ReportService(), []);
    const groupService = useMemo(() => new GroupService(), []);
    const items = results ? results.items : [];
    const selectedPageIndex: number = searchValues.pageNumber - 1;

    const selectedGroup: IPickerItem<IGroup> | null = searchValues.group
        ? { key: searchValues.group.id!, name: searchValues.group.name!, data: searchValues.group }
        : null;

    const onGetResults = useCallback((values: IUserActivitySearchValues, isMounted?: () => boolean) => {
        setSearchValues({ ...values });

        showLoading();

        const submitValue = {
            keyword: values.keyword || null,
            userType: values.userType,
            logFromDate: values.logFromDate,
            logToDate: values.logToDate,
            activityType: values.activityType,
            groupId: values.group ? values.group.id : null,
            pageNumber: values.pageNumber,
            numberPerPage: values.numberPerPage,
            sortedBy: values.sortedBy,
            sortedType: values.sortedType,
            siteId: profile?.site?.id,
        };

        const promise = service.activitiesSearch(submitValue);

        promise.then(results => {
            if (isMounted && !isMounted()) {
                setResults(undefined);
                hideLoading();
                return
            }

            setResults(prev => ({ ...prev, ...results }));
            hideLoading();
        });
    }, [profile, service, setSearchValues, setResults, showLoading, hideLoading]);

    useEffect(() => {
        let isMounted = true;
        let newValues = cloneDeep({ ...initialValues });
        onGetResults({ ...newValues }, () => isMounted);

        return () => {
            isMounted = false;
            setSearchValues(initialValues);
            setResults(undefined);
        }
    }, [onGetResults]);

    const onChange = <K extends keyof IUserActivitySearchValues>(newValue?: IUserActivitySearchValues[K] | null, fieldName?: K) => {
        if (!fieldName) {
            return;
        }

        const newValues: IUserActivitySearchValues = { ...searchValues };
        newValues[fieldName] = newValue as IUserActivitySearchValues[K];
        setSearchValues(newValues);
    };

    const onChangeChoiceField = useCallback(<K extends keyof IUserActivitySearchValues>(newValue: IUserActivitySearchValues[K] | null, fieldName: K) => {
        const newValues: IUserActivitySearchValues = { ...searchValues };
        newValues[fieldName] = newValue as IUserActivitySearchValues[K];
        setSearchValues(newValues);
    }, [searchValues]);

    const onSearchEnter = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.code === 'Enter' || ev.code === 'NumpadEnter') {
            onGetResults({ ...searchValues, pageNumber: 1 });
        }
    };

    const onSearchClick = () => {
        onGetResults({ ...searchValues, pageNumber: 1 });
    };

    const onDateFromChange = (date: Date | null | undefined) => {
        setSearchValues(prev => ({ ...prev, logFromDate: date ? new Date(date.setHours(0, 0, 0, 0)).toISOString() : null }))
    }

    const onDateToChange = (date: Date | null | undefined) => {
        setSearchValues(prev => ({ ...prev, logToDate: date ? new Date(date.setHours(0, 0, 0, 0)).toISOString() : null }))
    }

    const onNumberPerPageChange = (ev: React.FormEvent<HTMLElement>, option?: IDropdownOption<number> | undefined) => {
        const newNumberPerPage: number = option && option?.data ? option.data : searchValues.numberPerPage;
        onGetResults({ ...searchValues, pageNumber: 1, numberPerPage: newNumberPerPage });
    };

    const onSelectedPageIndex = (index: number) => {
        onGetResults({ ...searchValues, pageNumber: index + 1 });
    };

    const getKey = (item: IUserResult, index?: number): string => {
        return item.key;
    };

    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const sortedBy = column.key as keyof IActivitiesLogReport;
        const sortedType = sortedBy !== searchValues.sortedBy
            ? 'asc'
            : column.isSortedDescending ? 'asc' : 'desc';

        onGetResults({ ...searchValues, sortedBy, sortedType });
    }

    const onClickRequestLink = (e: React.MouseEvent<HTMLElement | HTMLAnchorElement | HTMLButtonElement, MouseEvent>, id: string) => {
        e.preventDefault();
        window.open(`/site/${profile?.site?.name}/administrator/allrequest/${id}`, '_blank');
    };

    const _columns: IColumn[] = [
        {
            key: 'userName',
            name: 'User name',
            fieldName: 'userName',
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onColumnClick: onColumnClick,
        },
        {
            key: 'groupName',
            name: 'Group',
            fieldName: 'groupName',
            minWidth: 120,
            maxWidth: 120,
            isMultiline: true,
            onColumnClick: onColumnClick,
        },
        {
            key: 'activities',
            name: 'Activities',
            fieldName: 'activities',
            minWidth: 120,
            maxWidth: 120,
            isMultiline: true,
            onColumnClick: onColumnClick,
            onRender: (item: IActivitiesLogReport) => {
                return getDisplayValue(item.activities);
            }
        },
        {
            key: 'documentName',
            name: 'Document Name',
            fieldName: 'documentName',
            minWidth: 200,
            isMultiline: true,
            onColumnClick: onColumnClick,
        },
        {
            key: 'requestNo',
            name: 'Request No',
            fieldName: 'requestNo',
            minWidth: 120,
            maxWidth: 120,
            onColumnClick: onColumnClick,
            onRender: (item: IActivitiesLogReport) => {
                return item.id && (<Link onClick={e => onClickRequestLink(e, item.requestId!)}>{item.requestNo}</Link>);
            }
        },
        {
            key: 'date',
            name: 'Date - Time',
            fieldName: 'date',
            minWidth: 120,
            maxWidth: 120,
            onColumnClick: onColumnClick,
            onRender: (item: IActivitiesLogReport) => {
                return <>{item.date && onFormatDateTime(new Date(item.date))}</>;
            }
        },
        {
            key: 'documentPath',
            name: 'Document Path',
            fieldName: 'documentPath',
            minWidth: 200,
            maxWidth: 200,
            onRender: (item: IActivitiesLogReport) => {
                var pathText = item.documentPath ? item.documentPath.join(" > ") : '';
                return <span title={pathText}>{pathText}</span>;
            }
        },
    ];

    let columns = cloneDeep(_columns);
    for (const column of columns) {
        if (column.key === searchValues.sortedBy) {
            column.isSorted = true;
            column.isSortedDescending = searchValues.sortedType === 'desc' ? true : false;
        } else {
            column.isSorted = false;
            column.isSortedDescending = false;
        }
    }

    const onExportClick = async () => {
        const submitValue = {
            keyword: searchValues.keyword || null,
            userType: searchValues.userType,
            logFromDate: searchValues.logFromDate,
            logToDate: searchValues.logToDate,
            activityType: searchValues.activityType,
            groupId: searchValues.group ? searchValues.group.id : null,
            sortedBy: searchValues.sortedBy,
            sortedType: searchValues.sortedType,
            siteId: profile?.site?.id,
        };

        showFileLoading();

        await service.activitiesExport(submitValue, errorMessage => {
            showDialog({
                title: 'Error',
                dialogContentProps: {
                    type: DialogType.normal,
                    subText: errorMessage,
                }
            });
        });

        hideFileLoading();
    };

    return (
        <Stack>
            <NavigationPane items={[listBreadcrumbItems] || []} />
            <Stack horizontal wrap={isSmallDevice || isLargeDevice} tokens={stackTokens}>
                <Stack grow tokens={stackTokens} horizontal wrap={isSmallDevice || isLargeDevice}>
                    <Stack grow>
                        <ChoiceGroup
                            selectedKey={searchValues.userType || 'all'}
                            options={userRoleOptions}
                            label="User Role"
                            onChange={(e, o) => onChangeChoiceField((o?.key as IGroupFlowRole) || 'all', 'userType')}
                        />
                    </Stack>
                    <Stack grow>
                        <Picker
                            pickOnlyOne
                            service={groupService}
                            placeholder='Group'
                            selectedItems={selectedGroup ? [selectedGroup] : []}
                            onChange={data => onChange((data && data[0]) ? data[0].data : null, 'group')}
                        />
                    </Stack>

                </Stack>
                <Stack grow tokens={stackTokens}>
                    <Stack>
                        <TextField
                            onChange={(e, value) => onChange(value, 'keyword')}
                            placeholder="Search by user name / ID"
                            iconProps={{ iconName: 'Search' }}
                            onKeyDown={onSearchEnter}
                        />
                    </Stack>
                    <Stack horizontal tokens={stackTokens} wrap={isSmallDevice}>
                        <DatePicker
                            placeholder="Log from date"
                            value={searchValues.logFromDate ? new Date(searchValues.logFromDate) : undefined}
                            onSelectDate={onDateFromChange}
                        />
                        <DatePicker
                            placeholder="Log to date"
                            value={searchValues.logToDate ? new Date(searchValues.logToDate) : undefined}
                            onSelectDate={onDateToChange}
                        />
                    </Stack>
                    <Stack horizontal wrap={isSmallDevice || isLargeDevice}>
                        <Dropdown
                            options={activityTypeOptions}
                            styles={dropdownStyles}
                            selectedKey={searchValues.activityType}
                            onChange={(e, o) => onChange(o?.data, 'activityType')}
                        />
                    </Stack>
                </Stack>
                <PrimaryButton text="Search" onClick={onSearchClick} />
                <PrimaryButton text="Export" onClick={onExportClick} disabled={isEmpty(items)} />
            </Stack>

            {(!isLoading && results && items && !isEmpty(items)) ? (
                <>
                    <Stack grow={1}>
                        <DetailsList
                            items={items}
                            columns={columns}
                            getKey={getKey}
                            setKey="multiple"
                            selectionMode={SelectionMode.none}
                        />
                    </Stack>

                    <Stack tokens={stackTokens} horizontal>
                        <Pagination
                            selectedPageIndex={selectedPageIndex}
                            onPageChange={onSelectedPageIndex}
                            pageCount={results.pageTotal}
                            itemsPerPage={searchValues.numberPerPage}
                            totalItemCount={results.total}
                            numberPerPageOptions={numberPerPageOptions}
                            defaultSelectedKey={searchValues.numberPerPage?.toString()}
                            onNumberPerPageChange={onNumberPerPageChange}
                        />
                    </Stack>
                </>
            ) : isLoading ? <Loading /> : <Text>No Result</Text>}

            <Debug object={searchValues} />

            <OverlayLoading isVisibled={isFileLoading} />
        </Stack>
    );
};