import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router";
import { PageContainer } from "../../../components/PageContainer";
import { preparePath } from "../../../tools";
import { GroupForm } from "./GroupForm";
import { GroupList } from "./GroupList";
import { UserForm } from "./UserForm";
import { UserList } from "./UserList";

export const User: React.FunctionComponent<RouteComponentProps> = props => {
    const { match: { path }, location: { pathname } } = props;
    const _pathname = preparePath(pathname);

    return (
        <PageContainer center={false} >
            <Switch>
                <Route exact path={path} render={() => <Redirect to={`${_pathname}/userlist`} />} />
                <Route exact path={`${path}/userlist`} component={UserList} />
                <Route path={`${path}/userlist/new`} component={UserForm} />
                <Route path={`${path}/userlist/:id`} component={UserForm} />

                <Route exact path={`${path}/groupuser`} component={GroupList} />
                <Route exact path={`${path}/groupuser/new`} component={GroupForm} />
                <Route exact path={`${path}/groupuser/:id`} component={GroupForm} />
                <Route path={`${path}/groupuser/:groupId/new`} component={UserForm} />
                <Route path={`${path}/groupuser/:groupId/:id`} component={UserForm} />
            </Switch>
        </PageContainer>
    );
};