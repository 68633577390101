import { IconButton } from "@fluentui/react/lib/Button";
import { Label } from "@fluentui/react/lib/Label";
import { IBasePickerStyles, ITag, TagPicker } from "@fluentui/react/lib/Pickers";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useRef } from "react";
import { AppContext } from "../contexts";
import { IFileSize, IFileUpload } from "../models";
import { readableFileSize } from "../tools";

export interface IMultipleAttachFile {
    label: string;
    values?: string[];
    isShowDownload?: boolean;
    onChangeFiles?: (values: string[], fileUpload: IFileUpload[]) => void;
    onClickDownload?: () => void;
    disabled?: boolean;
    required?: boolean;
    fileSizes?: IFileSize[];
}

const stackTokens: IStackTokens = { childrenGap: 10 };
const pickerStyles: Partial<IBasePickerStyles> = {
    root: {
        width: '100%',
    },
    itemsWrapper: {
        width: '100%',
        '& .ms-TagItem': {
            width: '100%',
            maxWidth: '100%',
            '& .ms-TagItem-text': {
                flexGrow: 1
            }
        }
    }
};

export const MultipleAttachFile: React.FunctionComponent<IMultipleAttachFile> = (props) => {
    const { showDialog } = useContext(AppContext);

    const fileInput = useRef<HTMLInputElement>(null);
    const [fileUploads, setFileUploads] = useState<IFileUpload[]>([]);

    const { label, values, isShowDownload, disabled, required, fileSizes, onChangeFiles, onClickDownload } = props;

    const getName = (name: string) => {
        if (fileSizes) {
            const index = fileSizes.findIndex(fs => fs.name === name);
            if (index !== -1) {
                return `${fileSizes[index].name} (${readableFileSize(fileSizes[index].size)})`;
            }
        }

        return name;
    };

    const selectedItems: ITag[] = !values ? [] : values?.map(text => ({ key: text, name: getName(text) }));

    const onClickSelectFile = (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();
        if (fileInput && fileInput.current) {
            fileInput.current.click();
        }
    };

    const fileChangedHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (!ev || !ev.currentTarget || !ev.currentTarget.files) {
            return;
        }

        ev.preventDefault();

        const file = ev.currentTarget.files[0];

        if (!file || !file.name) {
            return false;
        }

        if (values?.includes(file.name)) {
            showDialog({
                dialogContentProps: {
                    title: 'File name incorrect',
                    subText: `"${file.name}" already selected`,
                }
            });
            return false;
        }

        const reader = new FileReader();
        reader.onloadend = function (e) {
            const newUploadFile: IFileUpload = {
                file: file,
                data: reader.result,
            };

            const newUploadFiles = [...fileUploads, newUploadFile];
            const newValues = [...(values || []), file.name];

            setFileUploads(newUploadFiles);

            if (onChangeFiles) {
                onChangeFiles(newValues, newUploadFiles);
            }

            if (fileInput && fileInput.current) {
                fileInput.current.value = '';
            }
        };

        reader.readAsDataURL(file);
    };

    const onClickRemoveFiles = (items?: ITag[]) => {
        const newUploadFiles = fileUploads.filter(f => items?.some(i => i.key === f.file.name));
        const newValues = values?.filter(v => items?.some(i => i.key === v));

        setFileUploads(newUploadFiles);

        if (onChangeFiles) {
            onChangeFiles(newValues || [], newUploadFiles);
        }

        if (fileInput && fileInput.current) {
            fileInput.current.value = '';
        }
    };

    return (
        <Stack >
            <Label disabled={disabled} required={required}>{label}</Label>
            <Stack grow horizontal tokens={stackTokens}>
                <IconButton disabled={disabled} iconProps={{ iconName: 'Documentation' }} onClick={onClickSelectFile} />
                {isShowDownload && <IconButton disabled={disabled} iconProps={{ iconName: 'Download' }} onClick={onClickDownload} />}
                <TagPicker
                    onResolveSuggestions={() => []}
                    selectedItems={selectedItems}
                    onChange={onClickRemoveFiles}
                    itemLimit={values?.length || 1}
                    disabled={values?.length === 0 || disabled}
                    styles={pickerStyles}
                />
            </Stack>
            <input
                ref={fileInput}
                type='file'
                style={{ display: 'none' }}
                onChange={fileChangedHandler}
            />
        </Stack>
    );
};