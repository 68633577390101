import { IconButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { Label } from "@fluentui/react/lib/Label";
import { ITag, TagPicker } from "@fluentui/react/lib/Pickers";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import React from "react";
import { isEmpty } from "../tools";

export interface ITagData extends ITag {
    data?: { id: string | null };
}

export interface IMultiplePickerProps {
    buttonText?: string;
    items: (ITagData)[];
    selectedItems: (ITagData | null)[];
    disabled?: boolean;
    onChange: (items: (ITagData | null)[]) => void;
}

const stackTokens: IStackTokens = { childrenGap: 10 };
const pickerClassName = mergeStyles({
    '& span.ms-BasePicker-itemsWrapper': {
        width: '100%',
    },
    '& .ms-TagItem': {
        width: '100%',
        maxWidth: '100%',
        '& .ms-TagItem-text': {
            flexGrow: 1
        }
    }
});

const listContainsTagList = (item: ITagData, selectedItems?: ITagData[]) => {
    if (!selectedItems || !selectedItems.length || selectedItems.length === 0) {
        return false;
    }

    return selectedItems.some(compareTag => compareTag.key === item.key);
};

export const MultiplePicker: React.FunctionComponent<IMultiplePickerProps> = (props) => {
    const { buttonText = 'Add', items, selectedItems, onChange, disabled } = props;

    const onClickAdd = () => {
        onChange([...selectedItems, null]);
    };

    const onClickRemove = (sequence: number, index: number) => {
        const newSelectedItems = selectedItems.filter((i, idx) => idx !== index);
        onChange(newSelectedItems);
    };

    const onChangePicker = (items: ITagData[] | undefined, index: number) => {
        const newSelectedItems = [...selectedItems];
        if (!items || isEmpty(items)) {
            if (newSelectedItems[index]) {
                newSelectedItems[index] = null;
                onChange(newSelectedItems);
            }
        } else {
            if (newSelectedItems[index] || newSelectedItems[index] === null) {
                newSelectedItems[index] = items[0];
                onChange(newSelectedItems);
            }
        }
    };

    const onResolveSuggestions = (filter: string, selfSelectedItems?: ITagData[]) => {
        const filteredByName = filter
            ? items.filter(tag => tag.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
            : [...items];
            
        const filteredBySelfSelected = filteredByName.filter(tag => !listContainsTagList(tag, selfSelectedItems));
        const notNullSelected: ITagData[] = [];
        for (const item of selectedItems) {
            if (item) {
                notNullSelected.push(item);
            }
        }

        const filtered = filteredBySelfSelected.filter(tag => !listContainsTagList(tag, notNullSelected));

        return filtered;
    };

    const onRenderFilters = (item: ITagData | null, index: number) => {
        const sequence: number = index + 1;
        return (
            <Stack tokens={stackTokens} key={sequence} horizontal>
                <Stack styles={{ root: { width: 30 } }}>
                    <Label disabled={disabled}>{sequence})</Label>
                </Stack>
                <Stack grow>
                    <TagPicker
                        disabled={disabled}
                        onResolveSuggestions={onResolveSuggestions}
                        selectedItems={item ? [item] : []}
                        onChange={items => onChangePicker(items, index)}
                        itemLimit={1}
                        resolveDelay={300}
                        className={pickerClassName}
                        onEmptyResolveSuggestions={selftSelectedItems => onResolveSuggestions('', selftSelectedItems)}
                    />
                </Stack>
                {!disabled &&
                    (<Stack styles={{ root: { width: 30 } }}>
                        <IconButton
                            iconProps={{ iconName: 'Delete' }}
                            onClick={() => onClickRemove(sequence, index)}
                        />
                    </Stack>)
                }
            </Stack>
        );
    };

    return (
        <Stack tokens={stackTokens}>
            {selectedItems.map((item, i) => onRenderFilters(item, i))}
            {!disabled && (
                <div>
                    <PrimaryButton text={buttonText} onClick={onClickAdd} />
                </div>)
            }
        </Stack>
    );
};