import { Label } from "@fluentui/react/lib/Label";
import { IBasePickerStyles, ISuggestionModel, ITag, TagPicker, ValidationState } from "@fluentui/react/lib/Pickers";
import { Stack } from "@fluentui/react/lib/Stack";
import React from "react";

export interface IHashTagPickerProps {
    label?: string;
    selectedItems?: ITag[];
    onChange?: (items?: ITag[]) => void;
    disabled?: boolean;
    required?: boolean;
}

const pickerStyles: Partial<IBasePickerStyles> = {
    root: {
        width: 'auto',
    }
};

export const HashTagPicker: React.FunctionComponent<IHashTagPickerProps> = (props) => {
    const { label, selectedItems, disabled, required, onChange } = props;

    const onResolveSuggestions = (filter: string, selectedItems?: ITag[]): ITag[] => {
        if (!filter || selectedItems?.some(i => i.key === filter)) {
            return [];
        }

        return [{ key: filter, name: filter }];
    };

    const createGenericItem = (value: string, ValidationState: ValidationState): ISuggestionModel<ITag> | ITag => {
        console.log('createGenericItem', { value, ValidationState });
        return {
            item: {
                key: value,
                name: value,
            },
            selected: true
        };
    }

    return (
        <Stack>
            <Label required={required}>{label}</Label>
            <TagPicker
                onResolveSuggestions={onResolveSuggestions}
                selectedItems={selectedItems}
                onChange={onChange}
                styles={pickerStyles}
                createGenericItem={createGenericItem}
                resolveDelay={300}
                disabled={disabled}
            />
        </Stack>
    );
};