export const isInt = (value: string | null | undefined, min?: number, max?: number) => {
    // Value must not empty
    if (!value || !value.trim()) {
        return false;
    }

    // Value must be number
    if (isNaN(value as any)) {
        return false;
    }

    if (value.length > 1 && value.startsWith("0")) {
        return false;
    }

    if (value.includes(".")) {
        return false;
    }

    var intValue = parseInt(value, 10);

    // Value must less than min
    if ((min || min === 0) && intValue < min) {
        return false;
    }

    // Value must not greater than max number
    if ((max || max === 0) && intValue > max) {
        return false;
    }

    // Value must be interger
    if (Number(value) % 1 !== 0) {
        return false;
    }

    return true;
}

export const numberWithCommas = (x: string | number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};