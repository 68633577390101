import { createTheme } from '@fluentui/react/lib/Theme';
import { DefaultButtonStyles } from '.';

const theme = createTheme({
    palette: {
        themePrimary: '#009fda',
        themeLighterAlt: '#f3fbfd',
        themeLighter: '#d1eef9',
        themeLight: '#aae0f4',
        themeTertiary: '#5dc3e8',
        themeSecondary: '#1ba9dd',
        themeDarkAlt: '#008fc3',
        themeDark: '#00338d',
        themeDarker: '#005979',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
        yellow: '#978d01',
    },
    defaultFontStyle: {
        fontFamily: 'Tahoma'
    },
    semanticColors: {
        successIcon: '#7AB800',
        errorIcon: '#D52B1E',
    }
});

theme.components = {
    DefaultButton: {
        styles: DefaultButtonStyles(theme),
    },
};

export { theme };