import { Stack } from "@fluentui/react/lib/Stack";
import React, { useContext } from "react";
import { Redirect } from "react-router";
import { AppContext } from "../contexts";

export const UserGuide: React.FunctionComponent = () => {
    const { profile } = useContext(AppContext);

    const permission = profile?.permission;
    if (!permission) {
        return <Redirect to="/nopermission" />;
    }

    let backendAccess = false;

    const permissionReports: string[] = [];
    if (permission.requestReport) {
        permissionReports.push('requestreport');
    }

    if (permission.userReport) {
        permissionReports.push('userreport');
    }

    if (permission.documentReport) {
        permissionReports.push('documentreport');
    }

    if (permission.activitiesLogReport) {
        permissionReports.push('activitiesreport');
    }

    if (permission.expirationReport) {
        permissionReports.push('expirationreport');
    }

    if (
        permission.documentManagement ||
        permission.requestManagement ||
        permission.dataTypeManagement ||
        permission.templateManagement ||
        permission.userManagement ||
        permission.emailTemplateManagement ||
        permissionReports.length
    ) {
        backendAccess = true;
    }

    const title = backendAccess ? 'Admin User Guide' : 'User Guide';
    const html = backendAccess ? '/AdminGuide' : '/Guide';

    return (
        <Stack grow>
            <iframe title={title} src={html} style={{ width: '100%', height: '100%' }} />
        </Stack>
    );
};