import { IconButton } from '@fluentui/react/lib/Button';
import Dialog from '@fluentui/react/lib/Dialog';
import Modal, { IModalProps, IModalStyles } from '@fluentui/react/lib/Modal';
import { Stack } from '@fluentui/react/lib/Stack';
import React, { useContext, useMemo } from 'react';
import { AppContext } from '../contexts';
import { AdvanceSearch } from './AdvanceSearch';
import { Footer } from './Footer';
import { Header } from './Header';
import { NavMenu } from './NavMenu';
import { OverlayLoading } from './OverlayLoading';
import { PdfViewer } from './PdfViewer';

const viewModalStyles: Partial<IModalStyles> = {
    main: {
        width: '100vw',
        overflow: 'hidden'
    },
    scrollableContent: {
        overflow: 'hidden',
        height: '100vh',
    }
};

export const Layout: React.FunctionComponent = ({ children }) => {
    const {
        isDialogVisible,
        dialogProps,
        dialogContent,
        pdfViewer,
        isPdfViewerVisible,
        isAppLoading,
        hideDialog,
        hidePdfViewer,
    } = useContext(AppContext);

    const modalProps: IModalProps = useMemo(() => ({
        isBlocking: true,
        allowTouchBodyScroll: true,
    }), []);

    return (
        <Stack styles={{ root: { height: '100vh', overflow: 'hidden' } }}>
            <Stack>
                <NavMenu />
                <Header />
            </Stack>

            <Stack grow={1} styles={{ root: { height: '100vh', overflow: 'hidden' } }} >
                {children}
            </Stack>
            <Footer />
            <AdvanceSearch />
            <Dialog
                {...dialogProps}
                hidden={!isDialogVisible}
                onDismiss={hideDialog}
                modalProps={modalProps}
            >
                {dialogContent}
            </Dialog>
            <Modal
                isOpen={isPdfViewerVisible}
                onDismiss={hidePdfViewer}
                isBlocking={true}
                styles={viewModalStyles}
                allowTouchBodyScroll
            >
                <Stack horizontalAlign='center'>
                    <IconButton iconProps={{ iconName: 'Cancel' }} onClick={hidePdfViewer} />
                </Stack>
                <Stack styles={{ root: { height: '90%', width: '100%', overflow: 'hidden' } }}>
                    {pdfViewer && <PdfViewer viewer={pdfViewer} />}
                </Stack>
            </Modal>

            <OverlayLoading isVisibled={isAppLoading} />

        </Stack>
    );
};
