import { IBreadcrumbItem } from "@fluentui/react/lib/Breadcrumb";
import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react/lib/Dropdown";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { useBoolean } from "@fluentui/react-hooks";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";
import { IRequest, IRequestStatusKeys, ISearchResult } from "../../../models";
import { getDateRange, getDaysColor, getDisplayValue, getRequestStatusColor, isEmpty, onFormatDate, onFormatDateTime } from "../../../tools";
import { ReportService } from "../../../services";
import { DetailsList, IColumn, SelectionMode } from "@fluentui/react/lib/DetailsList";
import { NavigationPane } from "../../../components/NavigationPane";
import { IconButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { Text } from "@fluentui/react/lib/Text";
import { Debug } from "../../../components/Debug";
import { Pagination } from "../../../components/Pagination";
import { Loading } from "../../../components/Loading";
import { cloneDeep } from "lodash";
import { useContext } from "react";
import { AppContext } from "../../../contexts";
import { DatePicker } from "../../../components/DatePicker";
import { useTheme } from "@fluentui/react/lib/Theme";
import { ProcessLog } from "../../../components/ProcessLog";
import { DialogType } from "@fluentui/react/lib/Dialog";
import { Link } from "@fluentui/react/lib/Link";
import { OverlayLoading } from "../../../components/OverlayLoading";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";

export interface IRequestSearchValues {
    keyword?: string;
    dateFrom?: string | null;
    dateTo?: string | null;
    status?: IRequestStatusKeys;
    pageNumber: number;
    numberPerPage: number;
    sortedBy: keyof IRequest;
    sortedType: 'asc' | 'desc';
}

export const listBreadcrumbItems: IBreadcrumbItem = {
    key: 'list',
    text: 'Request Report',
};

const stackTokens: IStackTokens = { childrenGap: 10 };
const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { minWidth: 200 },
};

const initialStatus: IRequestStatusKeys = 'approved';
const statusOptions: IDropdownOption<IRequestStatusKeys>[] = [
    { key: 'all', text: getDisplayValue('all'), data: 'all' },
    { key: 'waiting-vp-approve', text: getDisplayValue('waiting-vp-approve'), data: 'waiting-vp-approve' },
    { key: 'waiting-admin-verify', text: getDisplayValue('waiting-admin-verify'), data: 'waiting-admin-verify' },
    { key: 'waiting-clgvp-approve', text: getDisplayValue('waiting-clgvp-approve'), data: 'waiting-clgvp-approve' },
    { key: 'approved', text: getDisplayValue('approved'), data: 'approved' },
    { key: 'rejected', text: getDisplayValue('rejected'), data: 'rejected' },
    // { key: 'inactive', text: getDisplayValue('inactive'), data: 'inactive' },
];

const numberPerPageOptions: IDropdownOption<number>[] = [
    { key: '20', text: '20', data: 20 },
    { key: '50', text: '50', data: 50 },
    { key: '100', text: '100', data: 100 },
    { key: '200', text: '200', data: 200 },
];

const initialValues: IRequestSearchValues = {
    status: initialStatus,
    pageNumber: 1,
    numberPerPage: 20,
    sortedBy: 'requestDate',
    sortedType: 'desc'
};

export const RequestReport: React.FunctionComponent<RouteComponentProps> = (props) => {
    const theme = useTheme();
    const { profile, showDialog } = useContext(AppContext);
    const [searchValues, setSearchValues] = useState<IRequestSearchValues>(initialValues);
    const [results, setResults] = useState<ISearchResult<IRequest> | undefined>(undefined);
    const [isLoading, { setFalse: hideLoading, setTrue: showLoading }] = useBoolean(false);
    const [isFileLoading, { setFalse: hideFileLoading, setTrue: showFileLoading }] = useBoolean(false);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;

    const service = useMemo(() => new ReportService(), []);
    const items = results ? results.items : [];
    const selectedPageIndex: number = searchValues.pageNumber - 1;

    const onGetResults = useCallback((values: IRequestSearchValues, isMounted?: () => boolean) => {
        setSearchValues({ ...values });

        showLoading();

        const submitvalues = {
            report: true,
            keyword: values.keyword,
            dateFrom: values.dateFrom,
            dateTo: values.dateTo,
            status: values.status,
            pageNumber: values.pageNumber,
            numberPerPage: values.numberPerPage,
            sortedBy: values.sortedBy,
            sortedType: values.sortedType,
            siteId: profile?.site?.id,
        };

        const promise = service.requestSearch(submitvalues);

        promise.then(results => {
            if (isMounted && !isMounted()) {
                setResults(undefined);
                hideLoading();
                return
            }

            setResults(prev => ({ ...prev, ...results }));
            hideLoading();
        });
    }, [profile, service, setSearchValues, setResults, showLoading, hideLoading]);

    useEffect(() => {
        let isMounted = true;
        let newValues = cloneDeep({ ...initialValues });
        onGetResults({ ...newValues }, () => isMounted);

        return () => {
            isMounted = false;
            setSearchValues(initialValues);
            setResults(undefined);
        }
    }, [onGetResults]);

    const onStatusChange = (ev: React.FormEvent<HTMLElement>, option?: IDropdownOption<IRequestStatusKeys> | undefined) => {
        const newStatus: IRequestStatusKeys = option && option?.data ? option.data : 'all';
        setSearchValues(prev => ({ ...prev, status: newStatus }))
    };

    const onDateFromChange = (date: Date | null | undefined) => {
        setSearchValues(prev => ({ ...prev, dateFrom: date ? new Date(date.setHours(0, 0, 0, 0)).toISOString() : null }))
    }

    const onDateToChange = (date: Date | null | undefined) => {
        setSearchValues(prev => ({ ...prev, dateTo: date ? new Date(date.setHours(0, 0, 0, 0)).toISOString() : null }))
    }

    const onSearchClick = () => {
        onGetResults({ ...searchValues, pageNumber: 1 });
    };

    const onNumberPerPageChange = (ev: React.FormEvent<HTMLElement>, option?: IDropdownOption<number> | undefined) => {
        const newNumberPerPage: number = option && option?.data ? option.data : searchValues.numberPerPage;
        onGetResults({ ...searchValues, pageNumber: 1, numberPerPage: newNumberPerPage });
    };

    const onSelectedPageIndex = (index: number) => {
        onGetResults({ ...searchValues, pageNumber: index + 1 });
    };

    const getDocKey = (item: IRequest, index?: number): string => {
        return item.key;
    };

    const onClickViewLog = (item: IRequest) => {
        showDialog({
            title: 'Proccess log',
            minWidth: 500,
            dialogContentProps: {
                type: DialogType.normal,
            }
        }, () => {
            return <ProcessLog item={item} displayOnly />;
        });
    };

    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const sortedBy = column.key as keyof IRequest;
        const sortedType = sortedBy !== searchValues.sortedBy
            ? 'asc'
            : column.isSortedDescending ? 'asc' : 'desc';

        onGetResults({ ...searchValues, sortedBy, sortedType });
    };

    const onClickRequestLink = (e: React.MouseEvent<HTMLElement | HTMLAnchorElement | HTMLButtonElement, MouseEvent>, id: string) => {
        e.preventDefault();
        window.open(`/site/${profile?.site?.name}/administrator/allrequest/${id}`, '_blank');
    };

    const _columns: IColumn[] = [
        {
            key: 'requestNo',
            name: 'Request No.',
            fieldName: 'requestNo',
            minWidth: 120,
            maxWidth: 120,
            onColumnClick: onColumnClick,
            onRender: (item: IRequest) => {
                return item.id && (<Link onClick={e => onClickRequestLink(e, item.id!)}>{item.requestNo}</Link>);
            }
        },
        {
            key: 'requestDate',
            name: 'Request Date',
            fieldName: 'requestDate',
            minWidth: 130,
            maxWidth: 130,
            onColumnClick: onColumnClick,
            onRender: (item: IRequest) => {
                return <>{item.requestDate && onFormatDateTime(new Date(item.requestDate))}</>;
            }
        },

        {
            key: 'requestBy',
            name: 'Request by',
            fieldName: 'requestBy',
            minWidth: 130,
            maxWidth: 130,
            isMultiline: true,
            onColumnClick: onColumnClick,
            onRender: (item: IRequest) => {
                return <>{item.requestBy?.name}</>;
            }
        },
        {
            key: 'requestFor',
            name: 'Request for',
            fieldName: 'requestFor',
            minWidth: 130,
            maxWidth: 130,
            isMultiline: true,
            onColumnClick: onColumnClick,
            onRender: (item: IRequest) => {
                return <>{item.requestFor?.name}</>;
            }
        },
        {
            key: 'department',
            name: 'Department',
            fieldName: 'department',
            minWidth: 130,
            maxWidth: 130,
            isMultiline: true,
            onColumnClick: onColumnClick,
        },
        {
            key: 'startDate',
            name: 'Start',
            fieldName: 'startDate',
            minWidth: 80,
            maxWidth: 80,
            onColumnClick: onColumnClick,
            onRender: (item: IRequest) => {
                return <>{item.startDate && onFormatDate(new Date(item.startDate))}</>;
            }
        },
        {
            key: 'endDate',
            name: 'End',
            fieldName: 'endDate',
            minWidth: 80,
            maxWidth: 80,
            onColumnClick: onColumnClick,
            onRender: (item: IRequest) => {
                return <>{item.endDate && onFormatDate(new Date(item.endDate))}</>;
            }
        },
        {
            key: 'days',
            name: 'Day(s)',
            fieldName: 'days',
            minWidth: 60,
            maxWidth: 60,
            onRender: (item: IRequest) => {
                const days = item.requestDate
                    ? getDateRange(new Date(item.requestDate), new Date())?.toString() || null
                    : null
                if (!days) {
                    return null;
                }

                const color = getDaysColor(theme, parseInt(days || '0'));
                return <span style={{ color: color }}>{days}</span>;
            }
        },
        {
            key: 'status',
            name: 'Status',
            fieldName: 'status',
            minWidth: 150,
            maxWidth: 150,
            onColumnClick: onColumnClick,
            onRender: (item: IRequest) => {
                const status = getDisplayValue(item.status);
                const color = getRequestStatusColor(theme, item.status);
                return (
                    <Stack tokens={stackTokens} horizontal verticalAlign='center'>
                        <IconButton
                            iconProps={{ iconName: 'WaitlistConfirm' }}
                            onClick={() => onClickViewLog(item)}
                            styles={{ root: { height: 'auto' } }}
                        />
                        <span style={{ color: color }}>{status}</span>
                    </Stack>
                );
            }
        }
    ];

    let columns = cloneDeep(_columns);
    for (const column of columns) {
        if (column.key === searchValues.sortedBy) {
            column.isSorted = true;
            column.isSortedDescending = searchValues.sortedType === 'desc' ? true : false;
        } else {
            column.isSorted = false;
            column.isSortedDescending = false;
        }
    }

    const dateFrom = searchValues.dateFrom ? new Date(searchValues.dateFrom) : null;
    const dateTo = searchValues.dateTo ? new Date(searchValues.dateTo) : null;

    const onExportClick = async () => {
        const submitvalues = {
            report: true,
            keyword: searchValues.keyword,
            dateFrom: searchValues.dateFrom,
            dateTo: searchValues.dateTo,
            status: searchValues.status,
            sortedBy: searchValues.sortedBy,
            sortedType: searchValues.sortedType,
            siteId: profile?.site?.id,
        };

        showFileLoading();

        await service.requestExport(submitvalues, errorMessage => {
            showDialog({
                title: 'Error',
                dialogContentProps: {
                    type: DialogType.normal,
                    subText: errorMessage,
                }
            });
        });

        hideFileLoading();
    };

    return (
        <Stack>
            <NavigationPane items={[listBreadcrumbItems] || []} />
            <Stack horizontal wrap={isSmallDevice} tokens={stackTokens} verticalAlign='start'>
                <Stack grow={1}>
                    <DatePicker
                        placeholder="Date from"
                        onSelectDate={onDateFromChange}
                        value={dateFrom || undefined}
                    />
                </Stack>
                <Stack grow={1}>
                    <DatePicker
                        placeholder="Date to"
                        onSelectDate={onDateToChange}
                        value={dateTo || undefined}
                    />
                </Stack>
                <Stack grow={1}>
                    <Dropdown
                        placeholder="Status"
                        options={statusOptions}
                        styles={dropdownStyles}
                        selectedKey={searchValues.status}
                        onChange={onStatusChange}
                    />
                </Stack>

                <PrimaryButton text="Search" onClick={onSearchClick} />
                <PrimaryButton text="Export" onClick={onExportClick} disabled={isEmpty(items)} />
            </Stack>

            {(!isLoading && results && items && !isEmpty(items)) ? (
                <>
                    <Stack grow={1}>
                        <DetailsList
                            items={items}
                            columns={columns}
                            getKey={getDocKey}
                            setKey="multiple"
                            selectionMode={SelectionMode.none}
                        />
                    </Stack>

                    <Stack tokens={stackTokens} horizontal>
                        <Pagination
                            selectedPageIndex={selectedPageIndex}
                            onPageChange={onSelectedPageIndex}
                            pageCount={results.pageTotal}
                            itemsPerPage={searchValues.numberPerPage}
                            totalItemCount={results.total}
                            numberPerPageOptions={numberPerPageOptions}
                            defaultSelectedKey={searchValues.numberPerPage?.toString()}
                            onNumberPerPageChange={onNumberPerPageChange}
                        />
                    </Stack>
                </>
            ) : isLoading ? <Loading /> : <Text>No Result</Text>}

            <Debug object={searchValues} />

            <OverlayLoading isVisibled={isFileLoading} />
        </Stack>
    );
};