import { PrimaryButton } from "@fluentui/react/lib/Button";
import { Stack } from "@fluentui/react/lib/Stack";
import { Text } from "@fluentui/react/lib/Text";
import React from "react";
import { useHistory, useLocation } from "react-router";
import { ApplicationPaths, QueryParameterNames } from "../components/api-authorization/ApiAuthorizationConstants";

function setCookie(name: string, value: string, minutes: number) {
    var expires = "";
    if (minutes) {
        var date = new Date();
        date.setTime(date.getTime() + (minutes * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name: string) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

const reasons: { [key: string]: string } = {
    noconf: 'Not found user configuration',
    nomenu: 'Not found menu access for current user',
}

export const NoPermission: React.FunctionComponent = () => {
    const { search } = useLocation();
    const history = useHistory();
    const queryString = new URLSearchParams(search);
    const returnUrl = queryString.get('url') ?? '/';
    const reasonKey = queryString.get('reason');
    const loginPath = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`;

    let reason = 'Some thing wrong';
    if (reasonKey) {
        reason = reasons[reasonKey];
    }

    const retry_login_cnt = getCookie("retry_login_cnt");
    let count: number | null = null;
    if (retry_login_cnt == null) {
        count = parseInt('0');
    } else {
        count = parseInt(retry_login_cnt);
    }

    setCookie("retry_login_cnt", (count + 1).toString(), 5);

    if (count < 5) {
        history.push(loginPath);
        return (
            <Stack grow verticalAlign='center' horizontalAlign='center'>
                <Text variant='xLarge'>Waiting for refresh session</Text>
            </Stack>
        )
    }

    const onClickLogin = () => {
        history.push(loginPath);
    };

    return (
        <Stack grow verticalAlign='center' horizontalAlign='center'>
            <Text variant='xxLarge'>You do not have permission</Text>
            <Text variant='xLarge'>{reason}</Text>
            <Text variant='large'>Session may be expired please click the button to try again</Text>
            <PrimaryButton text="Try again" onClick={onClickLogin} />
        </Stack>
    );
};