import { IBreadcrumbItem } from "@fluentui/react/lib/Breadcrumb";
import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react/lib/Dropdown";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { useBoolean } from "@fluentui/react-hooks";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";
import { IActiveStatus, IGroup, ISearchResult, IUser, IUserResult } from "../../../models";
import { getDisplayValue, isEmpty, onFormatDateTime, preparePath } from "../../../tools";
import { GroupService, UserService } from "../../../services";
import { DetailsList, IColumn, SelectionMode } from "@fluentui/react/lib/DetailsList";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@fluentui/react/lib/Link";
import { NavigationPane } from "../../../components/NavigationPane";
import { ActionButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { TextField } from "@fluentui/react/lib/TextField";
import { Text } from "@fluentui/react/lib/Text";
import { Debug } from "../../../components/Debug";
import { Pagination } from "../../../components/Pagination";
import { Loading } from "../../../components/Loading";
import { IPickerItem, Picker } from "../../../components/picker";
import { cloneDeep } from "lodash";
import { AppContext } from "../../../contexts";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";

export interface IUserSearchValues {
    keyword?: string | null;
    status?: IActiveStatus;
    group?: IGroup | null;
    pageNumber: number;
    numberPerPage: number;
    sortedBy: keyof IUser;
    sortedType: 'asc' | 'desc';
}

export interface IUserListProps {
    defaultValues?: Partial<IUserSearchValues>;
    hideGroup?: boolean;
    hideStatus?: boolean;
}

export const listBreadcrumbItems: IBreadcrumbItem = {
    key: 'list',
    text: 'User List',
};

const stackTokens: IStackTokens = { childrenGap: 10 };
const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { minWidth: 200 },
};

const initialStatus: IActiveStatus = 'all';
const statusOptions: IDropdownOption<IActiveStatus>[] = [
    { key: 'all', text: getDisplayValue('all'), data: 'all' },
    { key: 'active', text: getDisplayValue('active'), data: 'active' },
    { key: 'inactive', text: getDisplayValue('inactive'), data: 'inactive' },
];

const numberPerPageOptions: IDropdownOption<number>[] = [
    { key: '20', text: '20', data: 20 },
    { key: '50', text: '50', data: 50 },
    { key: '100', text: '100', data: 100 },
    { key: '200', text: '200', data: 200 },
];

export const initialValues: IUserSearchValues = {
    status: initialStatus,
    pageNumber: 1,
    numberPerPage: 20,
    group: null,
    sortedBy: 'name',
    sortedType: 'asc'
};

export const UserList: React.FunctionComponent<RouteComponentProps & IUserListProps> = (props) => {
    const { profile } = useContext(AppContext);
    const [searchValues, setSearchValues] = useState<IUserSearchValues>(initialValues);
    const [results, setResults] = useState<ISearchResult<IUserResult> | undefined>(undefined);
    const [isLoading, { setFalse: hideLoading, setTrue: showLoading }] = useBoolean(false);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;

    const { match: { url }, history, location: { state }, defaultValues, hideGroup, hideStatus } = props;

    const _url = preparePath(url);
    const newUrl = `${_url}/new`;
    const service = useMemo(() => new UserService(), []);
    const groupService = useMemo(() => new GroupService(), []);
    const items = results ? results.items : [];
    const selectedPageIndex: number = searchValues.pageNumber - 1;
    const selectedGroup: IPickerItem<IGroup> | null = searchValues.group
        ? { key: searchValues.group.id!, name: searchValues.group.name!, data: searchValues.group }
        : null;

    const onGetResults = useCallback((values: IUserSearchValues, isMounted?: () => boolean) => {
        setSearchValues({ ...values });

        showLoading();

        const submitValues = {
            keyword: values.keyword,
            groupId: values.group ? values.group.id : null,
            status: values.status,
            pageNumber: values.pageNumber,
            numberPerPage: values.numberPerPage,
            sortedBy: values.sortedBy,
            sortedType: values.sortedType,
            siteId: profile?.site?.id,
        };

        const promise = service.searchResult(submitValues);

        promise.then(results => {
            if (isMounted && !isMounted()) {
                setResults(undefined);
                hideLoading();
                return
            }

            setResults(prev => ({ ...prev, ...results }));
            hideLoading();
        });
    }, [profile, service, setSearchValues, setResults, showLoading, hideLoading]);

    useEffect(() => {
        let isMounted = true;

        const fromFormState = state as { from: string, values: IUserSearchValues };
        const fromGroupListState = state as { from: string, values: IGroup };

        let newValues = cloneDeep({ ...initialValues });
        if (fromFormState && fromFormState.from && fromFormState.from === 'form') {
            newValues = { ...newValues, ...fromFormState.values };
        }

        if (fromGroupListState && fromGroupListState.from && fromGroupListState.from === 'groupList') {
            newValues = { ...newValues, group: { ...fromGroupListState.values } };
        }

        if (defaultValues) {
            newValues.status = defaultValues.status;
            newValues.group = defaultValues.group!;
        }

        onGetResults({ ...newValues }, () => isMounted);

        return () => {
            isMounted = false;
            setSearchValues(initialValues);
            setResults(undefined);
        }
    }, [state, defaultValues, onGetResults]);

    const onChange = <K extends keyof IUserSearchValues>(newValue?: IUserSearchValues[K] | null, fieldName?: K) => {
        if (!fieldName) {
            return;
        }

        const newValues: IUserSearchValues = { ...searchValues };
        newValues[fieldName] = newValue as IUserSearchValues[K];
        setSearchValues(newValues);
    };

    const onSearchEnter = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.code === 'Enter' || ev.code === 'NumpadEnter') {
            onGetResults({ ...searchValues, pageNumber: 1 });
        }
    };

    const onSearchClick = () => {
        onGetResults({ ...searchValues, pageNumber: 1 });
    };

    const onNumberPerPageChange = (ev: React.FormEvent<HTMLElement>, option?: IDropdownOption<number> | undefined) => {
        const newNumberPerPage: number = option && option?.data ? option.data : searchValues.numberPerPage;
        onGetResults({ ...searchValues, pageNumber: 1, numberPerPage: newNumberPerPage });
    };

    const onSelectedPageIndex = (index: number) => {
        onGetResults({ ...searchValues, pageNumber: index + 1 });
    };

    const getKey = (item: IUserResult, index?: number): string => {
        return item.key;
    };

    const getEditLink = (id: string) => ({ pathname: `${_url}/${id}`, state: searchValues });
    const onCreateClick = (ev: React.MouseEvent<HTMLHtmlElement>) => {
        ev.preventDefault();
        history.push(newUrl, searchValues);
    };

    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const sortedBy = column.key as keyof IUser;
        const sortedType = sortedBy !== searchValues.sortedBy
            ? 'asc'
            : column.isSortedDescending ? 'asc' : 'desc';

        onGetResults({ ...searchValues, sortedBy, sortedType });
    };

    const _columns: IColumn[] = [
        {
            key: 'name',
            name: 'User name',
            fieldName: 'name',
            minWidth: 200,
            onColumnClick: onColumnClick,
            onRender: (item: IUserResult) => {
                return item.id && (<Link as={RouterLink} to={getEditLink(item.id)}>{item.name}</Link>)

            }
        },
        {
            key: 'group',
            name: 'Group',
            fieldName: 'groupName',
            minWidth: 200,
            onColumnClick: onColumnClick,
        },
        {
            key: 'organization',
            name: 'Organization',
            fieldName: 'organization',
            minWidth: 200,
            maxWidth: 200,
            onColumnClick: onColumnClick,
        },
        {
            key: 'lastUpdate',
            name: 'Last updated',
            fieldName: 'lastUpdate',
            minWidth: 130,
            maxWidth: 130,
            onRender: (item: IUserResult) => {
                return <>{item.lastUpdate && onFormatDateTime(new Date(item.lastUpdate))}</>;
            }
        },
        {
            key: 'status',
            name: 'Status',
            fieldName: 'status',
            minWidth: 130,
            maxWidth: 130,
            isMultiline: true,
            onColumnClick: onColumnClick,
            onRender: (item: IGroup) => {
                return getDisplayValue(item.status);
            }
        },
    ];

    let columns = cloneDeep(_columns);
    for (const column of columns) {
        if (column.key === searchValues.sortedBy) {
            column.isSorted = true;
            column.isSortedDescending = searchValues.sortedType === 'desc' ? true : false;
        } else {
            column.isSorted = false;
            column.isSortedDescending = false;
        }
    }

    return (
        <Stack grow>
            <NavigationPane items={[listBreadcrumbItems] || []} />
            <Stack horizontal wrap={isSmallDevice} tokens={stackTokens}>
                <Stack tokens={stackTokens} styles={{ root: { width: isSmallDevice ? '100%' : 500 } }} >
                    <ActionButton iconProps={{ iconName: 'Add' }} href={newUrl} onClick={onCreateClick}>
                        Create
                    </ActionButton>
                </Stack>
                <Stack grow tokens={stackTokens}>
                    <TextField
                        placeholder="Search user name or user ID"
                        iconProps={{ iconName: 'Search' }}
                        onKeyDown={onSearchEnter}
                        onChange={(e, value) => onChange(value, 'keyword')}
                        value={searchValues.keyword || ''}
                    />
                    <Stack horizontal wrap={isSmallDevice} tokens={stackTokens}>
                        {!hideGroup &&
                            <Picker
                                pickOnlyOne
                                service={groupService}
                                placeholder='Group'
                                selectedItems={selectedGroup ? [selectedGroup] : []}
                                onChange={data => onChange((data && data[0]) ? data[0].data : null, 'group')}
                            />
                        }
                        {!hideStatus &&
                            <Dropdown
                                placeholder="Status"
                                options={statusOptions}
                                styles={dropdownStyles}
                                selectedKey={searchValues.status}
                                onChange={(e, o) => onChange(o?.data, 'status')}
                            />
                        }
                    </Stack>
                </Stack>

                <PrimaryButton text="Search" onClick={onSearchClick} />
            </Stack>

            {(!isLoading && results && items && !isEmpty(items)) ? (
                <>
                    <Stack grow={1}>
                        <DetailsList
                            items={items}
                            columns={columns}
                            getKey={getKey}
                            setKey="multiple"
                            selectionMode={SelectionMode.none}
                        />
                    </Stack>

                    <Stack tokens={stackTokens} horizontal>
                        <Pagination
                            selectedPageIndex={selectedPageIndex}
                            onPageChange={onSelectedPageIndex}
                            pageCount={results.pageTotal}
                            itemsPerPage={searchValues.numberPerPage}
                            totalItemCount={results.total}
                            numberPerPageOptions={numberPerPageOptions}
                            defaultSelectedKey={searchValues.numberPerPage?.toString()}
                            onNumberPerPageChange={onNumberPerPageChange}
                        />
                    </Stack>
                </>
            ) : isLoading ? <Loading /> : <Text>No Result</Text>}

            <Debug object={searchValues} />
        </Stack>
    );
};