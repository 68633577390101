import React from "react";
import { ITextProps, Text } from "@fluentui/react/lib/Text"
import { FontWeights } from "@fluentui/react/lib/Styling";
import { IStackProps, IStackStyles, Stack } from "@fluentui/react/lib/Stack";

export interface ICardHeaderProps extends IStackProps {
    text: string;
}

const stackStyles: Partial<IStackStyles> = {
    root: {
        minHeight: 40,
        marginTop: 10,
    }
}

export const CardHeader: React.FunctionComponent<ICardHeaderProps> = (props) => {
    const { text } = props;

    const textProps: ITextProps = {
        variant: 'large',
        styles: {
            root: { fontWeight: FontWeights.bold }
        }
    };

    return (
        <Stack {...props} styles={stackStyles}>
            <Text {...textProps}>{text}</Text>
        </Stack>
    );
};