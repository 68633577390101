import { ApiService } from ".";
import authService from "../components/api-authorization/AuthorizeService";
import { IParameters, ISearchParameters, ISearchResult, ISaveResponse, ISiteAdmin } from "../models";
import { getCookie } from "../tools";

export class SiteAdminService implements ApiService<ISiteAdmin> {
    async get(parameters: IParameters): Promise<ISiteAdmin | null> {
        try {
            const token = await authService.getAccessToken();
            const id = parameters['id'] as string;
            delete parameters['id'];
            const search: IParameters = {};
            Object.keys(parameters).forEach(key => {
                if (parameters[key] === undefined || parameters[key] === null) {
                    return;
                }
                search[`parameters.${key}`] = parameters[key];
            });

            const queryString = new URLSearchParams(search).toString();
            const response = await fetch(`api/SiteAdmin/${id}?parameters=${queryString}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });
            const json = await response.json();
            return json;
        } catch {
            return await Promise.resolve(null);
        }

    }
    async search(parameters: ISearchParameters): Promise<ISearchResult<ISiteAdmin>> {
        const { numberPerPage, pageNumber } = parameters;
        try {
            const token = await authService.getAccessToken();
            const search: IParameters = {};
            Object.keys(parameters).forEach(key => {
                if (parameters[key] === undefined || parameters[key] === null) {
                    return;
                }
                search[`parameters.${key}`] = parameters[key];
            });

            const queryString = new URLSearchParams(search).toString();
            const response = await fetch(`api/SiteAdmin/search?${queryString}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });
            const json = await response.json();
            return json;
        } catch {
            return await Promise.resolve({
                total: 0,
                items: [],
                pageNumber,
                pageTotal: 0,
                numberPerPage,
                startIndex: 0,
                endIndex: 0,
            });
        }
    }

    async create(data: ISiteAdmin, callback?: (response: ISaveResponse<ISiteAdmin>) => void): Promise<void> {
        try {
            const token = await authService.getAccessToken();
            const response = await fetch(`api/SiteAdmin`, {
                method: 'POST',
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
                },
                body: JSON.stringify(data),
            });
            const json = await response.json();

            if (!response.ok) {
                throw new Error(`Status: ${json.status} ${json.title}`);
            }

            if (callback) {
                callback(json);
            }
        }
        catch (err: any) {
            if (callback) {
                callback({
                    isError: true,
                    errorMessage: typeof err === 'string' ? err : err.message
                });
            }
        }
    }

    async update(id: string, data: ISiteAdmin, callback?: (response: ISaveResponse<ISiteAdmin>) => void): Promise<void> {
        try {
            const token = await authService.getAccessToken();
            const response = await fetch(`api/SiteAdmin/${id}`, {
                method: 'POST',
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
                },
                body: JSON.stringify(data),
            });
            const json = await response.json();

            if (!response.ok) {
                throw new Error(`Status: ${json.status} ${json.title}`);
            }

            if (callback) {
                callback(json);
            }
        }
        catch (err: any) {
            if (callback) {
                callback({
                    isError: true,
                    errorMessage: typeof err === 'string' ? err : err.message
                });
            }
        }
    }
}
