import { IBreadcrumbItem } from "@fluentui/react/lib/Breadcrumb";
import { DefaultButton, IconButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react/lib/ChoiceGroup";
import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import { IStackStyles, IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { ITextFieldProps, TextField } from "@fluentui/react/lib/TextField";
import { Text } from "@fluentui/react/lib/Text";
import React, { useMemo, useRef } from "react";
import { useState } from "react";
import { RouteComponentProps } from "react-router";
import { DatePicker } from "../../../components/DatePicker";
import { NavigationPane } from "../../../components/NavigationPane";
import { IPickerItem, Picker } from "../../../components/picker";
import { IActiveStatus, IDataName, IDataType, IDocument, IDocumentPathGroup, IDocumentPathItem, IDocumentPermission, IDocumentPublicWatermark, IFileUpload, ISaveResponse, ITemplate } from "../../../models";
import { DataNameService, DocumentService, FileService, PdfService, TemplateService } from "../../../services";
import { getDisplayValue, isEmpty, isInt, readableFileSize } from "../../../tools";
import { listBreadcrumbItems } from "./DocumentList";
import { Label } from "@fluentui/react/lib/Label";
import { GroupedList, GroupHeader, IGroup, IGroupHeaderProps, IGroupRenderProps } from "@fluentui/react/lib/GroupedList";
import { SelectionMode } from "@fluentui/react/lib/Selection";
import { DefaultEffects } from "@fluentui/react/lib/Styling";
import { SingleAttachFile } from "../../../components/SingleAttachFile";
import { MultipleAttachFile } from "../../../components/MultipleAttachFile";
import { HashTagPicker } from "../../../components/HashTagPicker";
import { useEffect } from "react";
import { Debug } from "../../../components/Debug";
import { useCallback } from "react";
import { cloneDeep } from "lodash";
import { useContext } from "react";
import { AppContext } from "../../../contexts";
import { ITag } from "@fluentui/react/lib/Pickers";
import { DialogFooter, DialogType } from "@fluentui/react/lib/Dialog";
import { MessageBar, MessageBarType } from "@fluentui/react/lib/MessageBar";
import { useBoolean } from "@fluentui/react-hooks";
import { OverlayLoading } from "../../../components/OverlayLoading";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";

export type IFormMode = 'new' | 'edit' | 'readonly';

export interface IDocumentValues {
    mode: IFormMode;
    files: File[];
    pdf: File | null;
    canDownloadFile?: boolean;
    canViewFile?: boolean;
    data: IDocument;
}

const containerStackStyles: IStackStyles = {
    root: {
        marginBottom: 20,
    },
};

const wrapStackStyles: IStackStyles = {
    root: {
        width: `100%`,
    },
};

const pathItemStackStyles: IStackStyles = {
    root: {
        paddingLeft: 34,
    },
};

const MAXIMUM_FILE_SIZE: number = 52428800;

const stackTokens: IStackTokens = { childrenGap: 10, padding: 5 };
const wrapStackTokens: IStackTokens = { childrenGap: 10 };
const requiredTextFieldProps: ITextFieldProps = {
    onRenderLabel: (textFieldProps, render) => render
        ? render({ ...textFieldProps, required: true })
        : null,
}

const statusOptions: IChoiceGroupOption[] = [
    { key: 'active', text: getDisplayValue('active') },
    { key: 'inactive', text: getDisplayValue('inactive') },
];

const permissionOptions: IChoiceGroupOption[] = [
    { key: 'request', text: getDisplayValue('request') },
    { key: 'admin', text: getDisplayValue('admin') },
    { key: 'public', text: getDisplayValue('public') },
];

const watermarkOptions: IChoiceGroupOption[] = [
    { key: 'no', text: getDisplayValue('no') },
    { key: 'yes', text: getDisplayValue('yes') },
];

const initialValues: IDocumentValues = {
    mode: 'new',
    files: [],
    canDownloadFile: false,
    canViewFile: false,
    pdf: null,
    data: {
        id: null,
        key: '-1',
        name: null,
        template: null,
        lineno: null,
        type: null,
        status: 'active',
        publicWatermark: 'no',
        pathGroups: null,
        permission: 'request',
        signContractDate: null,
        numberofOriginal: null,
        numberofCopy: null,
        documentLocation: null,
        province: null,
        barcode: null,
        docNo: null,
        signingDate: null,
        terms: null,
        startDate: null,
        endDate: null,
        expiredDate: null,
        contractParties: null,
        disclosingParty: null,
        receivingParty: null,
        abstract: null,
        remark: null,
        owner: null,
        fileNames: null,
        pdfFileName: null,
        hashtags: null,
        docGroup: null,
        remarkGroup: null,
        fileSizes: null,
        pdfFileSize: null,
    }
};

export const DocumentForm: React.FunctionComponent<RouteComponentProps<{ site: string, id: string }>> = props => {
    const {
        profile,
        showPdfViewer,
        showDialog,
        hideDialog,
    } = useContext(AppContext);

    const [values, setValues] = useState<IDocumentValues>(cloneDeep(initialValues));
    const [originalValues, setOriginalValues] = useState<IDocument | undefined>(undefined);
    const [isLoading, { setFalse: hideLoading, setTrue: showLoading }] = useBoolean(false);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;
    const isLargeDevice = modalResponsiveMode === ResponsiveMode.large || modalResponsiveMode === ResponsiveMode.xLarge;

    const service = useMemo(() => new DocumentService(), []);
    const templateService = useMemo(() => new TemplateService(), []);
    const dataNameService = useMemo(() => new DataNameService(), []);
    const fileService = useMemo(() => new FileService(), []);
    const pdfService = useMemo(() => new PdfService(), []);
    const { match: { params: { site, id } }, history, location: { state } } = props;

    const disabledForm = values.mode === 'readonly';

    useEffect(() => {
        let isMounted = true;

        if (!id || id === 'new') {
            let newDate: IDocument = cloneDeep({
                ...initialValues.data,
            });

            const copyState = state as { from: string, values: IDocument }
            if (copyState && copyState.from && copyState.from === 'copy') {
                newDate = cloneDeep(copyState.values);
            }

            newDate.owner = profile?.employee?.fullname!;

            setValues({
                ...cloneDeep(initialValues),
                data: newDate,
                mode: 'new',
            });
            return;
        }

        const promise = service.get({ id });
        promise.then(data => {
            if (!isMounted || !data || !data.id) {
                setValues({ ...cloneDeep(initialValues), mode: 'readonly' });
                setOriginalValues(undefined);
                return;
            }

            setValues({
                ...cloneDeep(initialValues),
                canDownloadFile: data.fileNames ? data.fileNames.length > 0 : false,
                canViewFile: data.pdfFileName ? true : false,
                data,
                mode: data.status === 'delete' ? 'readonly' : 'edit',
            });

            setOriginalValues(cloneDeep(data));
        });

        return () => {
            setValues(cloneDeep(initialValues));
            setOriginalValues(undefined);
        };

    }, [id, service, state, profile]);

    const listLink = `/site/${site}/administrator/document`;
    const newLink = `${listLink}/new`;

    const onClickListLink = (ev?: React.MouseEvent<HTMLElement>) => {
        ev?.preventDefault();
        const linkState = state as { from: string };
        history.push(listLink, linkState && linkState.from ? undefined : { from: 'form', values: state });
    };

    const breadcrumbItems: IBreadcrumbItem[] = [
        { ...listBreadcrumbItems, href: listLink, onClick: onClickListLink },
        {
            key: values.mode,
            text: values.mode === 'new' ? 'New' : originalValues?.name || '...',
        }
    ];

    const fieldStackStyles: IStackStyles = {
        root: {
            width: isSmallDevice ? '100%' : 'calc(50% - 10px)',
        }
    };

    const pathGroupStackStyles: IStackStyles = {
        root: {
            maxWidth: isSmallDevice || isLargeDevice ? '100%' : '70%',
            boxShadow: DefaultEffects.elevation4,
            padding: 10,
        },
    };

    const hashTagItems: ITag[] = values.data.hashtags
        ? values.data.hashtags.map(tag => ({ key: tag, name: tag }))
        : [];

    const groups: IGroup[][] = [];
    if (values.data.pathGroups) {
        let groupCount = 0;
        for (const groupItem of values.data.pathGroups) {
            if (!groupItem.sequence) {
                continue;
            }

            groups.push([
                {
                    key: groupCount.toString(),
                    name: groupItem.sequence?.toString(),
                    startIndex: 0,
                    count: groupItem.items.length,
                    data: cloneDeep(groupItem)
                }
            ]);

            groupCount++;
        }
    }

    const documentFields = values.data.template ? values.data.template.documentFields : null;

    const onChangeTextField = useCallback(<K extends keyof IDocument>(newValue: IDocument[K] | null, fieldName: K) => {
        const newValues: IDocumentValues = { ...values };
        newValues.data[fieldName] = newValue as IDocument[K];
        setValues(newValues);
    }, [values]);

    const onChangeChoiceField = useCallback(<K extends keyof IDocument>(newValue: IDocument[K] | null, fieldName: K) => {
        const newValues: IDocumentValues = { ...values };
        newValues.data[fieldName] = newValue as IDocument[K];
        setValues(newValues);
    }, [values]);

    const onChangePickerField = useCallback(<K extends keyof IDocument>(newItems: IPickerItem<IDocument[K]>[] | null, fieldName: K) => {
        const newValues: IDocumentValues = { ...values };
        if (!newItems || isEmpty(newItems)) {
            newValues.data[fieldName] = null as IDocument[K];
        } else {
            newValues.data[fieldName] = newItems[0].data as IDocument[K];
        }
        setValues(newValues);
    }, [values]);

    const onTemplateChange = useCallback((newItems: IPickerItem<ITemplate>[] | null) => {
        const newValues: IDocumentValues = cloneDeep(values);
        if (!newItems || isEmpty(newItems)) {
            newValues.data.template = null;
            newValues.data.pathGroups = null;
            newValues.data.documentLocation = null;
            newValues.data.province = null;
        } else {
            const template = newItems[0].data!
            const dataTypes = template.dataTypes || [];
            const items: IDocumentPathItem[] = dataTypes.map(dt => ({ dataType: dt, dataName: null }));
            const groupItem: IDocumentPathGroup = {
                id: null,
                key: '-1',
                items: [...items],
                isMaster: true,
                sequence: 1,
                status: 'active',
            };

            newValues.data.template = template;
            newValues.data.pathGroups = [groupItem];

            if (template.documentFields!["documentLocation"] && template.documentFields?.defaultDocumentLocation) {
                newValues.data.documentLocation = template.documentFields?.defaultDocumentLocation;
            }

            if (template.documentFields!["province"] && template.documentFields?.defaultProvince) {
                newValues.data.province = template.documentFields?.defaultProvince;
            }
        }
        setValues(newValues);
    }, [values]);

    const onSignContractDateChange = (date: Date | null | undefined) => {
        const newData = { ...values.data, signContractDate: date ? new Date(date.setHours(0, 0, 0, 0)).toISOString() : null };
        setValues(prev => ({ ...prev, data: newData }));
    }

    const onSigningDateChange = (date: Date | null | undefined) => {
        const newData = { ...values.data, signingDate: date ? new Date(date.setHours(0, 0, 0, 0)).toISOString() : null };
        setValues(prev => ({ ...prev, data: newData }));
    }

    const onStartDateChange = (date: Date | null | undefined) => {
        const newData = { ...values.data, terms: null, startDate: date ? new Date(date.setHours(0, 0, 0, 0)).toISOString() : null };
        setValues(prev => ({ ...prev, data: newData }));
    }

    const onEndDateChange = (date: Date | null | undefined) => {
        const newData = { ...values.data, terms: null, endDate: date ? new Date(date.setHours(0, 0, 0, 0)).toISOString() : null };
        setValues(prev => ({ ...prev, data: newData }));
    }

    const onExpiredDateChange = (date: Date | null | undefined) => {
        const newData = { ...values.data, expiredDate: date ? new Date(date.setHours(0, 0, 0, 0)).toISOString() : null };
        setValues(prev => ({ ...prev, data: newData }));
    }

    const onChangeTerms = (value: string | null) => {
        const { startDate } = values.data;
        const validTemplate = documentFields && documentFields['startDate'] && documentFields['endDate'];

        let number = null;
        if (!isNaN((value as any)) && isInt(value, 1)) {
            number = Number(value);
            number = parseInt(number.toString());
        }

        if (validTemplate && startDate && number) {
            const newStartDate = new Date(startDate);
            const newEndDate = new Date(startDate);
            newEndDate.setDate(newStartDate.getDate() + number);

            const terms = number.toString();
            const endDate = newEndDate.toISOString();
            const data = { ...values.data, terms, endDate };
            setValues(prev => ({ ...prev, data }));
        } else {
            const data = { ...values.data, terms: value, endDate: null };
            setValues(prev => ({ ...prev, data }));
        }
    };

    const onClickDuplicatePathGroup = (data: IDocumentPathGroup) => {
        const newData = cloneDeep(values.data);
        const nextSequence = newData.pathGroups!.length + 1;
        const groupItem: IDocumentPathGroup = {
            ...data,
            id: null,
            key: '-1',
            isMaster: false,
            items: [...data.items],
            sequence: nextSequence,
            status: 'active',
        };

        newData.pathGroups!.push(groupItem);
        setValues(prev => ({ ...prev, data: newData }));
    };

    const onSelectPDF = (newValue: string, newPdfFile: IFileUpload) => {
        let fileSize = null;
        if (newPdfFile) {
            fileSize = {
                name: newPdfFile.file.name,
                size: newPdfFile.file.size,
            }
        }
        const newData = { ...values.data, pdfFileName: newValue, pdfFileSize: fileSize };
        setValues(prev => ({ ...prev, data: newData, pdf: newPdfFile.file }));
    };

    const onRemovePDF = () => {
        const newData = { ...values.data, pdfFileName: null, pdfFileSize: null };
        setValues(prev => ({ ...prev, data: newData, pdf: null }));
    };

    const onViewPDF = () => {
        if (!id) {
            return;
        }

        showPdfViewer({
            mode: 'all',
            documentId: id,
            pathId: null,
            permission: 'view-print',
        });
    };

    const onChangeFiles = (newFileNames: string[], newFiles: IFileUpload[]) => {
        let fileNames: string[] | null = cloneDeep(newFileNames);
        let fileSizes = cloneDeep(values.data.fileSizes);
        fileSizes = fileSizes ? fileSizes.filter(fs => newFileNames.some(n => n === fs.name)) : [];
        if (!isEmpty(fileNames)) {
            for (const name of fileNames) {
                const newFileIndex = newFiles.findIndex(fu => fu.file.name === name);
                if (newFileIndex !== -1) {
                    const fileSizeIndex = fileSizes.findIndex(fx => fx.name === name);
                    const size = newFiles[newFileIndex].file.size;
                    if (fileSizeIndex !== -1) {
                        fileSizes[fileSizeIndex].name = name;
                        fileSizes[fileSizeIndex].size = size;
                    } else {
                        fileSizes.push({ name, size });
                    }
                }
            }
        } else {
            fileNames = null;
            fileSizes = null;
        }

        const newData = { ...values.data, fileNames, fileSizes };
        setValues(prev => ({ ...prev, data: newData, files: newFiles.map(f => f.file) }));
    };

    const onClickDownloadFiles = async () => {
        if (!id) {
            return;
        }

        showLoading();

        await fileService.downloadFile(id, message => {
            showDialog({
                title: 'Error',
                dialogContentProps: {
                    type: DialogType.normal,
                    subText: message,
                }
            });
        });

        hideLoading();
    };

    const onDocumentPathChange = (
        index: number,
        group: IDocumentPathGroup,
        selectedItems: IPickerItem<IDataName>[] | null,
        dataType: IDataType,
    ) => {
        const newData = cloneDeep(values.data);

        if (!newData.pathGroups) {
            return;
        }

        const selectedItem = (selectedItems && !isEmpty(selectedItems)) ? { ...selectedItems[0] } : null
        const dataName = selectedItem ? { ...selectedItem.data! } : null;
        const groupIndex = newData.pathGroups.findIndex(p => p.sequence === group.sequence);

        if (groupIndex !== -1) {
            newData.pathGroups[groupIndex].items[index] = { dataType, dataName };
            setValues(prev => ({ ...prev, data: newData }));
        }
    };

    const onRenderPathItem = (nestingDepth?: number, item?: IDocumentPathItem, itemIndex?: number, group?: IGroup): React.ReactNode => {
        if (!item) {
            return null;
        }

        if (!group) {
            return null;
        }

        const { dataType, dataName } = item;
        const selectedDataName = dataName
            ? {
                key: dataName.key,
                name: dataName.name!,
                data: dataName,
            }
            : null;

        return item && typeof itemIndex === 'number' && itemIndex > -1 ? (
            <Stack horizontal wrap={isSmallDevice} tokens={stackTokens} styles={pathItemStackStyles}>
                <Label disabled={disabledForm} styles={{ root: { minWidth: isSmallDevice ? '100%' : 300 } }}>{dataType.name} :</Label>
                <Picker
                    service={dataNameService}
                    pickOnlyOne
                    selectedItems={selectedDataName ? [selectedDataName] : []}
                    onMapFilter={(filter, defaultFilter) => ({ ...defaultFilter, typeId: dataType.id })}
                    onChange={selectItems => onDocumentPathChange(itemIndex, group.data, selectItems || null, dataType)}
                    disabled={disabledForm}
                />

                {
                    !isSmallDevice && itemIndex === 0 &&
                    <DefaultButton
                        text='Duplicate Path'
                        iconProps={{ iconName: 'Copy' }}
                        onClick={() => onClickDuplicatePathGroup(group.data)}
                        disabled={disabledForm}
                    />
                }

                {
                    isSmallDevice && itemIndex === group.count - 1 &&
                    <DefaultButton
                        text='Duplicate Path'
                        iconProps={{ iconName: 'Copy' }}
                        onClick={() => onClickDuplicatePathGroup(group.data)}
                        disabled={disabledForm}
                    />
                }
            </Stack >
        ) : null;
    };

    const onRemovePathGroup = (group: IDocumentPathGroup) => {
        const newData = cloneDeep(values.data);
        newData.pathGroups = newData.pathGroups!
            .filter(g => g.sequence !== group.sequence)
            .map(g => {
                if (g.sequence! > group.sequence!) {
                    g.sequence = g.sequence! - 1;
                }

                return g;
            });
        setValues(prev => ({ ...prev, data: newData }));
    };

    const onRenderGroupTitle = (props?: IGroupHeaderProps, defaultRender?: (props: IGroupHeaderProps) => JSX.Element | null) => {
        if (!props || !defaultRender) {
            return <></>;
        }

        const { group } = props;

        if (!group) {
            return <></>;
        }

        return (
            <Stack grow horizontal verticalAlign='center' horizontalAlign='space-between'>
                <Label>{group.name}</Label>
                {!group.data.isMaster &&
                    <IconButton
                        iconProps={{ iconName: 'Cancel' }}
                        onClick={() => onRemovePathGroup(group.data)}
                    />
                }

            </Stack>
        );
    };

    const groupProps: IGroupRenderProps = {
        onRenderHeader: (props?: IGroupHeaderProps) =>
            <GroupHeader {...props} onRenderTitle={onRenderGroupTitle} />
    };

    const onClickAddPath = () => {
        const newData = { ...values.data };
        const newPathGroups = cloneDeep(newData.pathGroups)!;
        const nextSequence = newPathGroups.length + 1;
        const masterPathGroup = newPathGroups.filter(g => g.isMaster)[0] || null;
        newPathGroups.push({
            ...masterPathGroup,
            id: null,
            key: '-1',
            isMaster: false,
            items: [...masterPathGroup.items],
            sequence: nextSequence,
            status: 'active',
        });

        newData.pathGroups = [...newPathGroups];

        setValues(prev => ({ ...prev, data: newData }));
    };

    const onClickReset = () => {
        setValues({ ...values, data: cloneDeep(originalValues!) });
    };

    const onSaveResponse = (action: string, saved: ISaveResponse<IDocument>, isBackToList: boolean = true) => {
        if (saved.isError) {
            showDialog({
                title: `${action} failed`,
                dialogContentProps: {
                    type: DialogType.normal,
                }
            }, () => {
                return (
                    <>
                        {saved.errorMessage &&
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {saved.errorMessage}
                            </MessageBar>
                        }
                        {saved.errorMessages && saved.errorMessages.map((err, i) => (
                            <MessageBar key={i}
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {err}
                            </MessageBar>
                        ))}
                    </>
                );
            });

            return;
        } else {
            onClickListLink();
        }
    };

    const onDetailSaved = async (saved: ISaveResponse<IDocument>) => {
        if (saved.isError) {
            onSaveResponse('Save data', saved);
        } else {
            const newValues = saved.data!;
            saved.errorMessages = [];
            saved.successMessages = [];

            if (values.files.length) {
                showDialog({
                    title: `File(s) uploading...`,
                    isBlocking: true,
                    dialogContentProps: {
                        showCloseButton: false,
                    }
                });
                await fileService.uploadFiles(newValues.id!, values.files, filesSaved => {
                    if (filesSaved.isError) {
                        saved.isError = true;
                        saved.errorMessages?.push(filesSaved.errorMessage || '');
                    }
                    hideDialog();
                });
            }

            if (values.pdf) {
                showDialog({
                    title: `PDF uploading...`,
                    isBlocking: true,
                    dialogContentProps: {
                        showCloseButton: false,
                    }
                });
                await pdfService.uploadPdf(newValues.id!, values.pdf, pdfSaved => {
                    if (pdfSaved.isError) {
                        saved.isError = true;
                        saved.errorMessages?.push(pdfSaved.successMessage || '');
                    }
                    hideDialog();
                });
            }

            setValues(prev => ({ ...prev, data: newValues, mode: 'edit' }));
            onSaveResponse('Save data', saved);
        }
    };

    const onValidateForm = () => {
        const errorMessages: string[] = [];
        if (!values.data.template) {
            errorMessages.push('Template is required');
        }

        if (!values.data.type) {
            errorMessages.push('Document type is required');
        }

        if (!values.data.name) {
            errorMessages.push('Document name is required');
        }

        if (!values.data.pdfFileName) {
            errorMessages.push('PDF file is required');
        }

        if (!values.data.lineno) {
            errorMessages.push('Line no is required');
        }

        if (values.data.lineno) {
            if (isNaN(values.data.lineno as any)) {
                errorMessages.push('Line no is required number only');
            } else if (!isInt(values.data.lineno, 1)) {
                errorMessages.push('Invalid line no');
            }
        }

        if (!values.data.hashtags) {
            errorMessages.push('Hashtags is required');
        }

        if (documentFields && documentFields["signContractDate"] && !values.data.signContractDate) {
            errorMessages.push('Sign Contract Date is required');
        }

        if (documentFields && documentFields["contractParties"] && !values.data.contractParties) {
            errorMessages.push('Contract Parties is required');
        }

        if (!values.data.pathGroups) {
            errorMessages.push('Document Path is required');
        }

        if (values.data.pathGroups) {
            const uniqDataNameIds: string[] = [];
            let foundDuplicate = false;

            for (const group of values.data.pathGroups) {
                const items = cloneDeep(group.items);
                let dataNameIds: string[] = [];
                for (const pathItem of items) {
                    if (!pathItem.dataName) {
                        errorMessages.push(`Value of Data type "${pathItem.dataType.name}" is required`);
                    } else {
                        dataNameIds.push(`${pathItem.dataType.id}||${pathItem.dataName.id!}`);
                    }
                }

                if (!isEmpty(dataNameIds)) {
                    const uniqeKey = dataNameIds.join('-');
                    if (!uniqDataNameIds.includes(uniqeKey)) {
                        uniqDataNameIds.push(uniqeKey);
                    } else {
                        foundDuplicate = true;
                    }
                }
            }

            if (foundDuplicate) {
                errorMessages.push(`Found duplicate document path(s)`);
            }
        }

        if (values.pdf) {
            if (values.pdf.type !== 'application/pdf') {
                errorMessages.push(`Invalid pdf file type : ${values.pdf.type}`);
            }

            if (values.pdf.size > MAXIMUM_FILE_SIZE) {
                errorMessages.push(`Invalid pdf file size : ${readableFileSize(values.pdf.size)} (maximumn 50 MB.)`);
            }
        }

        if (values.files && !isEmpty(values.files)) {
            for (const file of values.files) {
                if (file.size > MAXIMUM_FILE_SIZE) {
                    errorMessages.push(`Invalid file(${file.name}) size : ${readableFileSize(file.size)} (maximumn 50 MB.)`);
                }
            }
        }

        if (documentFields && documentFields["terms"] && values.data.terms) {
            if (isNaN(values.data.terms as any)) {
                errorMessages.push('Terms is required number only');
            } else if (!isInt(values.data.terms, 1)) {
                errorMessages.push('Invalid terms');
            }
        }

        if (!isEmpty(errorMessages)) {
            showDialog({
                title: `Invalid form`,
                dialogContentProps: {
                    type: DialogType.normal,
                }
            }, () => {
                return (
                    <>
                        {errorMessages && errorMessages.map((err, i) => (
                            <MessageBar key={i}
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {err}
                            </MessageBar>
                        ))}
                    </>
                );
            });

            return false;
        }

        return true;
    };

    const onClickSave = async () => {
        if (!onValidateForm()) {
            return;
        }

        showLoading();

        if (values.mode === 'new') {
            const data = { ...values.data, site: profile?.site };
            await service.create(data, saved => onDetailSaved(saved));
        }

        if (values.mode === 'edit') {
            await service.update(id, values.data, saved => onDetailSaved(saved));
        }

        hideLoading();
    };

    const onchangeHashTags = (items?: ITag[] | undefined) => {
        const newData = { ...values.data };
        newData.hashtags = !items || isEmpty(items) ? null : items.map(tag => tag.name);
        setValues(prev => ({ ...prev, data: newData }));
    };

    const onClickDuplicateDocument = () => {
        const copyData = cloneDeep(values.data);

        if (copyData.pathGroups && !isEmpty(copyData.pathGroups)) {
            copyData.pathGroups.forEach(group => {
                group.id = null;
                group.key = '-1';
            });
        }

        const copy: IDocument = {
            ...copyData,
            id: null,
            key: '-1',
            name: `(Copy) ${values.data.name}`,
            pdfFileName: null,
            fileNames: null,
            pdfFileSize: null,
            fileSizes: null,
        };

        history.push(newLink, { from: 'copy', values: copy });
    };

    const onDeleteDocument = async () => {
        const data: IDocument = { ...values.data, status: 'delete' };

        showLoading();

        await service.update(id, data, saved => onDetailSaved(saved));

        hideLoading();
    };

    const onClickDeleteDocument = () => {
        showDialog({
            title: 'Please confirm',
            dialogContentProps: {
                type: DialogType.normal,
                subText: 'Do you want to delete the document ?'
            }
        }, () => {
            const onClickComfirmDelete = () => {
                onDeleteDocument();
                hideDialog();
            };
            return (
                <>
                    <DialogFooter styles={{ actionsRight: { textAlign: 'left' } }}>
                        <DefaultButton onClick={onClickComfirmDelete} text="Yes" />
                        <DefaultButton onClick={hideDialog} text="No" />
                    </DialogFooter>
                </>
            );
        });
    };

    const leftStackStyles: IStackStyles = {
        root: {
            width: isSmallDevice
                ? undefined
                : isLargeDevice ? '700px' : '1000px'
        }
    };

    return (
        <Stack>
            <NavigationPane items={breadcrumbItems || []} overflowIndex={breadcrumbItems.length - 1} />
            <Pivot>
                <PivotItem headerText="Document detail">
                    <Stack horizontal wrap={isSmallDevice} tokens={stackTokens} styles={containerStackStyles} >
                        <Stack tokens={stackTokens} styles={leftStackStyles}>
                            <Stack horizontal wrap tokens={wrapStackTokens}>
                                <Stack styles={fieldStackStyles}>
                                    <Picker
                                        pickOnlyOne
                                        service={templateService}
                                        label='Template'
                                        onChange={items => onTemplateChange(items || null)}
                                        selectedItems={
                                            values.data.template
                                                ? [
                                                    {
                                                        key: values.data.template.key,
                                                        name: values.data.template.name!,
                                                        data: values.data.template
                                                    }
                                                ]
                                                : []
                                        }
                                        disabled={disabledForm}
                                        required
                                    />
                                </Stack>
                                <Stack styles={fieldStackStyles}>
                                    <Picker
                                        pickOnlyOne
                                        service={dataNameService}
                                        label='Document type'
                                        onMapFilter={(filter, defaultFilter) => ({ ...defaultFilter, docType: true })}
                                        onChange={items => onChangePickerField(items || null, 'type')}
                                        selectedItems={
                                            values.data.type
                                                ? [
                                                    {
                                                        key: values.data.type.key,
                                                        name: values.data.type.name!,
                                                        data: values.data.type
                                                    }
                                                ]
                                                : []
                                        }
                                        disabled={disabledForm}
                                        required
                                    />
                                </Stack>
                            </Stack>
                            <Stack tokens={wrapStackTokens}>
                                <TextField
                                    label='Document name'
                                    value={values.data.name || ''}
                                    onChange={(e, value) => onChangeTextField(value || null, 'name')}
                                    disabled={disabledForm}
                                    required
                                />
                            </Stack>
                            <Stack tokens={wrapStackTokens}>
                                <SingleAttachFile
                                    label='Attach PDF (maximun 50 MB.)'
                                    value={values.data.pdfFileName || ''}
                                    onFileSelected={onSelectPDF}
                                    onRemoveFile={onRemovePDF}
                                    onViewFile={onViewPDF}
                                    isShowView={values.canViewFile}
                                    disabled={disabledForm}
                                    required
                                    fileSize={values.data.pdfFileSize || undefined}
                                />
                            </Stack>

                            <Stack tokens={wrapStackTokens}>
                                <MultipleAttachFile
                                    label='Attach files'
                                    values={values.data.fileNames || []}
                                    onChangeFiles={onChangeFiles}
                                    isShowDownload={values.canDownloadFile}
                                    onClickDownload={onClickDownloadFiles}
                                    disabled={disabledForm}
                                    fileSizes={values.data.fileSizes || undefined}
                                />
                            </Stack>

                            <Stack horizontal wrap tokens={wrapStackTokens}>
                                <Stack styles={fieldStackStyles}>
                                    <TextField
                                        label='Line No.'
                                        value={values.data.lineno || ''}
                                        onChange={(e, value) => onChangeTextField(value || null, 'lineno')}
                                        disabled={disabledForm}
                                        required
                                    />
                                </Stack>
                                <Stack styles={fieldStackStyles}>
                                    <HashTagPicker
                                        label='Hashtag'
                                        onChange={onchangeHashTags}
                                        selectedItems={hashTagItems}
                                        disabled={disabledForm}
                                        required
                                    />
                                </Stack>
                            </Stack>

                            {documentFields &&
                                <Stack horizontal wrap tokens={wrapStackTokens} styles={wrapStackStyles}>
                                    {documentFields["signContractDate"] &&
                                        <Stack styles={fieldStackStyles}>
                                            <DatePicker
                                                label={getDisplayValue('signContractDate')}
                                                value={values.data.signContractDate ? new Date(values.data.signContractDate!) : undefined}
                                                onSelectDate={onSignContractDateChange}
                                                disabled={disabledForm}
                                                textField={requiredTextFieldProps}
                                            />
                                        </Stack>
                                    }
                                    {documentFields["numberofOriginal"] &&
                                        <Stack styles={fieldStackStyles}>
                                            <TextField
                                                label={getDisplayValue('numberofOriginal')}
                                                value={values.data.numberofOriginal || ''}
                                                onChange={(e, value) => onChangeTextField(value || null, 'numberofOriginal')}
                                                disabled={disabledForm}
                                            />
                                        </Stack>
                                    }
                                    {documentFields["numberofCopy"] &&
                                        <Stack styles={fieldStackStyles}>
                                            <TextField
                                                label={getDisplayValue('numberofCopy')}
                                                value={values.data.numberofCopy || ''}
                                                onChange={(e, value) => onChangeTextField(value || null, 'numberofCopy')}
                                                disabled={disabledForm}
                                            />
                                        </Stack>
                                    }
                                    {documentFields["documentLocation"] &&
                                        <Stack styles={fieldStackStyles}>
                                            <TextField
                                                label={getDisplayValue('documentLocation')}
                                                value={values.data.documentLocation || ''}
                                                onChange={(e, value) => onChangeTextField(value || null, 'documentLocation')}
                                                disabled={disabledForm}
                                            />
                                        </Stack>
                                    }
                                    {documentFields["province"] &&
                                        <Stack styles={fieldStackStyles}>
                                            <TextField
                                                label={getDisplayValue('province')}
                                                value={values.data.province || ''}
                                                onChange={(e, value) => onChangeTextField(value || null, 'province')}
                                                disabled={disabledForm}
                                            />
                                        </Stack>
                                    }
                                    {documentFields["barcode"] &&
                                        <Stack styles={fieldStackStyles}>
                                            <TextField
                                                label={getDisplayValue('barcode')}
                                                value={values.data.barcode || ''}
                                                onChange={(e, value) => onChangeTextField(value || null, 'barcode')}
                                                disabled={disabledForm}
                                            />
                                        </Stack>
                                    }
                                    {documentFields["docGroup"] &&
                                        <Stack styles={fieldStackStyles}>
                                            <TextField
                                                label={getDisplayValue('docGroup')}
                                                value={values.data.docGroup || ''}
                                                onChange={(e, value) => onChangeTextField(value || null, 'docGroup')}
                                                disabled={disabledForm}
                                            />
                                        </Stack>
                                    }
                                    {documentFields["docNo"] &&
                                        <Stack styles={fieldStackStyles}>
                                            <TextField
                                                label={getDisplayValue('docNo')}
                                                value={values.data.docNo || ''}
                                                onChange={(e, value) => onChangeTextField(value || null, 'docNo')}
                                                disabled={disabledForm}
                                            />
                                        </Stack>
                                    }
                                    {documentFields["signingDate"] &&
                                        <Stack styles={fieldStackStyles}>
                                            <DatePicker
                                                label={getDisplayValue('signingDate')}
                                                value={values.data.signingDate ? new Date(values.data.signingDate) : undefined}
                                                onSelectDate={onSigningDateChange}
                                                disabled={disabledForm}
                                            />
                                        </Stack>
                                    }
                                    {documentFields["startDate"] &&
                                        <Stack styles={fieldStackStyles}>
                                            <DatePicker
                                                label={getDisplayValue('startDate')}
                                                value={values.data.startDate ? new Date(values.data.startDate) : undefined}
                                                onSelectDate={onStartDateChange}
                                                disabled={disabledForm}
                                            />
                                        </Stack>
                                    }
                                    {documentFields["endDate"] &&
                                        <Stack styles={fieldStackStyles}>
                                            <DatePicker
                                                label={getDisplayValue('endDate')}
                                                value={values.data.endDate ? new Date(values.data.endDate) : undefined}
                                                onSelectDate={onEndDateChange}
                                                disabled={disabledForm}
                                            />
                                        </Stack>
                                    }
                                    {documentFields["terms"] &&
                                        <Stack styles={fieldStackStyles}>
                                            <TextField
                                                label={getDisplayValue('terms')}
                                                value={values.data.terms || ''}
                                                onChange={(e, value) => onChangeTerms(value || '')}
                                                disabled={disabledForm}
                                            />
                                        </Stack>
                                    }
                                    {documentFields["expiredDate"] &&
                                        <Stack styles={fieldStackStyles}>
                                            <DatePicker
                                                label={getDisplayValue('expiredDate')}
                                                value={values.data.expiredDate ? new Date(values.data.expiredDate) : undefined}
                                                onSelectDate={onExpiredDateChange}
                                                disabled={disabledForm}
                                            />
                                        </Stack>
                                    }

                                    <Stack styles={fieldStackStyles}>
                                        <TextField
                                            label={getDisplayValue('owner')}
                                            value={values.data.owner || ''}
                                            onChange={(e, value) => onChangeTextField(value || '', 'owner')}
                                            disabled
                                        />
                                    </Stack>
                                </Stack>
                            }

                            {documentFields && documentFields["contractParties"] &&
                                <Stack tokens={wrapStackTokens}>
                                    <TextField
                                        multiline
                                        value={values.data.contractParties || ''}
                                        label={getDisplayValue('contractParties')}
                                        onChange={(e, value) => onChangeTextField(value || '', 'contractParties')}
                                        disabled={disabledForm}
                                        required
                                    />
                                </Stack>
                            }
                            {documentFields && documentFields["remark"] &&
                                <Stack tokens={wrapStackTokens}>
                                    <TextField
                                        multiline
                                        value={values.data.remark || ''}
                                        label={getDisplayValue('remark')}
                                        onChange={(e, value) => onChangeTextField(value || '', 'remark')}
                                        disabled={disabledForm}
                                    />
                                </Stack>
                            }
                            {documentFields && documentFields["abstract"] &&
                                <Stack tokens={wrapStackTokens}>
                                    <TextField
                                        multiline
                                        value={values.data.abstract || ''}
                                        label={getDisplayValue('abstract')}
                                        onChange={(e, value) => onChangeTextField(value || '', 'abstract')}
                                        disabled={disabledForm}
                                    />
                                </Stack>
                            }
                            {documentFields && documentFields["disclosingParty"] &&
                                <Stack tokens={wrapStackTokens}>
                                    <TextField
                                        multiline
                                        value={values.data.disclosingParty || ''}
                                        label={getDisplayValue('disclosingParty')}
                                        onChange={(e, value) => onChangeTextField(value || '', 'disclosingParty')}
                                        disabled={disabledForm}
                                    />
                                </Stack>
                            }
                            {documentFields && documentFields["receivingParty"] &&
                                <Stack tokens={wrapStackTokens}>
                                    <TextField
                                        multiline
                                        value={values.data.receivingParty || ''}
                                        label={getDisplayValue('receivingParty')}
                                        onChange={(e, value) => onChangeTextField(value || '', 'receivingParty')}
                                        disabled={disabledForm}
                                    />
                                </Stack>
                            }
                            {documentFields && documentFields["remarkGroup"] &&
                                <Stack tokens={wrapStackTokens}>
                                    <TextField
                                        multiline
                                        value={values.data.remarkGroup || ''}
                                        label={getDisplayValue('remarkGroup')}
                                        onChange={(e, value) => onChangeTextField(value || '', 'remarkGroup')}
                                        disabled={disabledForm}
                                    />
                                </Stack>
                            }

                        </Stack>

                        <Stack >
                            <ChoiceGroup
                                selectedKey={values.data.status || 'active'}
                                options={statusOptions}
                                label="Status"
                                onChange={(e, o) => onChangeChoiceField((o?.key as IActiveStatus) || 'active', 'status')}
                                disabled={disabledForm}
                            />
                            <ChoiceGroup
                                selectedKey={values.data.permission || 'request'}
                                options={permissionOptions}
                                label="Permission"
                                onChange={(e, o) => onChangeChoiceField((o?.key as IDocumentPermission) || 'request', 'permission')}
                                disabled={disabledForm}
                            />
                            <ChoiceGroup
                                selectedKey={values.data.publicWatermark || 'no'}
                                options={watermarkOptions}
                                label="Public watermark"
                                onChange={(e, o) => onChangeChoiceField((o?.key as IDocumentPublicWatermark) || 'no', 'publicWatermark')}
                                disabled={disabledForm}
                            />
                        </Stack>

                    </Stack>
                </PivotItem>
                {values.data.template &&
                    <PivotItem headerText="Document path">
                        <Stack tokens={stackTokens} styles={containerStackStyles} >
                            <Stack horizontal wrap={isSmallDevice} tokens={stackTokens} verticalAlign='center'>
                                <Label>Template: </Label>
                                <Text>Template 1</Text>
                            </Stack>
                            {groups.map((groupItems, i) => (
                                <Stack key={i} styles={pathGroupStackStyles}>
                                    <GroupedList
                                        key={i}
                                        items={groupItems[0] ? groupItems[0].data.items : []}
                                        onRenderCell={onRenderPathItem}
                                        selectionMode={SelectionMode.none}
                                        groups={groupItems}
                                        groupProps={groupProps}
                                    />
                                </Stack>
                            ))}
                            <Stack horizontal>
                                <PrimaryButton text='Add path' onClick={onClickAddPath} disabled={disabledForm} />
                            </Stack>
                        </Stack>
                    </PivotItem>
                }
            </Pivot>

            <Stack horizontal wrap={isSmallDevice} tokens={stackTokens}>
                {values.mode !== 'readonly' && <PrimaryButton text='Save' onClick={onClickSave} disabled={disabledForm} />}
                {values.mode === 'edit' && <DefaultButton text='Reset' onClick={onClickReset} disabled={disabledForm} />}
                <DefaultButton text='Cancel' onClick={() => onClickListLink()} />
                {values.mode === 'edit' &&
                    <>
                        <DefaultButton
                            text='Duplicate Document'
                            iconProps={{ iconName: 'Copy' }}
                            onClick={onClickDuplicateDocument}
                            disabled={disabledForm}
                        />
                        <DefaultButton
                            text='Delete'
                            iconProps={{ iconName: 'Delete' }}
                            onClick={onClickDeleteDocument}
                            disabled={disabledForm}
                        />
                    </>
                }
            </Stack>

            <Debug object={values} label='Form Values' />

            <OverlayLoading isVisibled={isLoading} />
        </Stack>
    )
}