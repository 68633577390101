import { Panel, PanelType } from "@fluentui/react/lib/Panel";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AppContext } from "../contexts";
import { ITextProps, Text } from "@fluentui/react/lib/Text";
import { IStackProps, IStackStyles, IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { SearchSuggestionItem, ISuggestionItemProps, IColumnStyles } from "./SearchSuggestionItem";
import { IDocument, ISearchDocumentResult, ISearchResult } from "../models";
import { ISearchBox, SearchBox } from "@fluentui/react/lib/SearchBox";
import { useBoolean } from "@fluentui/react-hooks";
import { ThemeContext } from "@fluentui/react/lib/Theme";
import { ActionButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { Debug } from "./Debug";
import { DataTypeFilterSet, IFilterValues } from "./DataTypeFilterSet";
import { SearchDocumentService } from "../services";
import { isEmpty, numberWithCommas } from "../tools";
import { Loading } from "./Loading";
import { cloneDeep } from "lodash";
import { DetailsList, IColumn, SelectionMode } from "@fluentui/react/lib/DetailsList";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";
import { mergeStyles } from "@fluentui/react/lib/Styling";

export interface ISearchValues {
    keyword?: string | null;
    filters?: string[] | null;
    pageNumber: number;
    numberPerPage: number;
    sortedBy: keyof IDocument;
    sortedType: 'asc' | 'desc';
}

const stackTokens: IStackTokens = { childrenGap: 10 };
const stackBorderStyles: Partial<IStackStyles> = {
    root: {
        height: 44,
        border: '1px dashed black'
    }
};

export const columnStyles: IColumnStyles = {
    sequence: { width: 40, textAlign: 'Right', padding: '0 8px 0 12px' },
    documentName: { width: `calc(100% - ${60 + 520 + 200 + 20}px)`, padding: '0 8px 0 12px' },
    parties: { width: 500, padding: '0 8px 0 12px' },
    date: { width: 180, padding: '0 8px 0 12px' },
};

const initialValues: ISearchValues = {
    pageNumber: 1,
    numberPerPage: 20,
    sortedBy: 'lineno',
    sortedType: 'asc'
};

const className1 = mergeStyles({ width: '100%' });
const className2 = mergeStyles({ overflowY: 'hidden', overflowX: 'auto' });
const className3 = mergeStyles(className1, { minWidth: (40 + 500 + 500 + 180 + (4 * 20)) });

export const AdvanceSearch: React.FunctionComponent = () => {
    const theme = useContext(ThemeContext);
    const {
        profile,
        isSearchPanelOpen,
        searchKeyword,
        isDialogVisible,
        isPdfViewerVisible,
        dismissSearchPanel
    } = useContext(AppContext);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;

    const [searchValues, setSearchValues] = useState<ISearchValues>(cloneDeep({ ...initialValues }));
    const [results, setResults] = useState<ISearchResult<ISearchDocumentResult> | undefined>(undefined);
    const [isLoading, { setTrue: showLoading, setFalse: hideLoading }] = useBoolean(false);

    const searchBox = useRef<ISearchBox>(null);
    const service = useMemo(() => new SearchDocumentService(), []);

    const onGetResults = useCallback((
        values: ISearchValues,
        isMounted?: (() => boolean) | undefined,
        prev?: ISearchResult<ISearchDocumentResult> | undefined,
        pageNumber?: number | undefined,
        numberPerPage?: number | undefined) => {

        setSearchValues({ ...values });

        if (!prev) {
            setResults(undefined);
        }

        showLoading();

        const submitValue = {
            keyword: values.keyword || null,
            filters: values.filters ? encodeURIComponent(JSON.stringify(values.filters)) : null,
            siteId: profile?.site?.id,
            pageNumber: pageNumber ? pageNumber : values.pageNumber,
            numberPerPage: numberPerPage ? numberPerPage : values.numberPerPage,
            sortedBy: values.sortedBy,
            sortedType: values.sortedType,
        };

        const promise = service.search(submitValue);

        promise.then(results => {
            if (isMounted && !isMounted()) {
                setResults(undefined);
                hideLoading();
                return
            }

            if (prev) {
                const items = cloneDeep([...prev.items, ...results.items]);
                results.items = items;
            }

            setResults(prev => ({ ...prev, ...results }));
            hideLoading();
        });
    }, [service, profile, setSearchValues, setResults, showLoading, hideLoading]);

    useEffect(() => {
        let isMounted = true;
        if (!isSearchPanelOpen) {
            isMounted = false;
            setSearchValues(cloneDeep({ ...initialValues }));
            setResults(undefined);
            return;

        }

        if (searchKeyword) {
            onGetResults(cloneDeep({ ...initialValues, keyword: searchKeyword }), () => isMounted);
        }

        return () => {
            isMounted = false;
            setSearchValues(cloneDeep({ ...initialValues }));
            setResults(undefined);
        }

    }, [isSearchPanelOpen, searchKeyword, setResults, onGetResults]);

    if (!isSearchPanelOpen) {
        return <></>;
    }

    const getTextStyles = (p: ITextProps) => {
        return {
            root: {
                marginRight: 5,
                color: theme!.palette.themePrimary,
            }
        };
    };

    const onFiltersChange = (filterValues: IFilterValues | null) => {
        const arrays = !filterValues
            ? null
            : Object.values(filterValues).map(value => value);

        const filters = !arrays ? null : ([] as string[]).concat.apply([], arrays);

        setSearchValues(prev => ({ ...prev, filters }));
    };

    const onInputChange = (event?: React.ChangeEvent<HTMLInputElement> | undefined, newValue?: string | undefined) => {
        setSearchValues(prev => ({ ...prev, keyword: newValue }))
    }

    const onSubmit = () => {
        onGetResults({ ...searchValues, pageNumber: 1 });
    }

    const renderNoResult = () => <Text>No Result</Text>;
    const renderLoading = () => <Loading />;

    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const sortedBy = column.key as keyof IDocument;
        const sortedType = sortedBy !== searchValues.sortedBy
            ? 'asc'
            : column.isSortedDescending ? 'asc' : 'desc';

        onGetResults({ ...searchValues, sortedBy, sortedType }, undefined, undefined, 1, results?.items.length);
    };

    const _columns: IColumn[] = [
        {
            key: 'no',
            name: 'No.',
            fieldName: 'no',
            minWidth: 40,
            maxWidth: 40,
        },
        {
            key: 'name',
            name: 'Document name',
            fieldName: 'name',
            minWidth: 500,
            onColumnClick: onColumnClick,
        }, {
            key: 'parties',
            name: 'Parties.',
            fieldName: 'parties',
            minWidth: 500,
            maxWidth: 500,
        }, {
            key: 'signContractDate',
            name: 'Date',
            fieldName: 'date',
            minWidth: 180,
            maxWidth: 180,
            onColumnClick: onColumnClick,
        },
    ];

    let columns = cloneDeep(_columns);
    for (const column of columns) {
        if (column.key === searchValues.sortedBy) {
            column.isSorted = true;
            column.isSortedDescending = searchValues.sortedType === 'desc' ? true : false;
        } else {
            column.isSorted = false;
            column.isSortedDescending = false;
        }
    }

    const renderResult = (items: ISearchDocumentResult[]) => {
        if (isEmpty(items)) {
            return renderNoResult();
        }

        const itemStackProps: IStackProps = {
            styles: {
                root: {
                    margin: '10px 0 10px 0',
                    borderBottom: `1px solid ${theme?.palette.neutralQuaternaryAlt}`
                }
            }
        };

        const itemProps: ISuggestionItemProps[] = items.map((doc, i) => {
            return {
                key: i,
                isShowSequence: true,
                sequence: (i + 1),
                stackProps: itemStackProps,
                doc
            }
        });

        if (isSmallDevice) {
            return (
                <div className={className1}>
                    {itemProps.map((p, i) => <SearchSuggestionItem key={i} {...p} styles={columnStyles} />)}
                    {isLoading && renderLoading()}
                </div>
            );
        } else {
            return (
                <div className={className2}>
                    <div className={className3}>
                        <DetailsList
                            items={[]}
                            columns={columns}
                            setKey="multiple"
                            selectionMode={SelectionMode.none}
                        />

                        {itemProps.map((p, i) => <SearchSuggestionItem key={i} {...p} styles={columnStyles} />)}
                        {isLoading && renderLoading()}
                    </div>
                </div>
            );
        }
    };

    const _dismissSearchPanel = (ev?: React.SyntheticEvent<HTMLElement, Event> | KeyboardEvent | undefined) => {
        if (!ev || isDialogVisible || isPdfViewerVisible) {
            // Panel dismissed.
            return;
        }

        dismissSearchPanel();
    }

    const onClickMoreButton = () => {
        // onGetResults(searchValues, searchValues.pageNumber + 1, searchValues.numberPerPage, true, results);
        const newPageNumber = searchValues.pageNumber + 1;
        onGetResults({ ...searchValues, pageNumber: newPageNumber }, undefined, results);
    };

    const renderButtonOptional = () => <PrimaryButton text="Search" onClick={() => onSubmit()} />;

    return (
        <Panel
            headerText="Advance Search"
            isOpen={isSearchPanelOpen}
            onDismiss={_dismissSearchPanel}
            type={PanelType.custom}
            customWidth={isSmallDevice ? '100%' : '90%'}
            onScroll={e => e.cancelable && e.preventDefault()}
            allowTouchBodyScroll
        >
            <Stack tokens={stackTokens}>
                <Stack tokens={stackTokens} >
                    <Stack tokens={stackTokens} horizontal>
                        <SearchBox
                            styles={{ root: { flexGrow: 1 } }}
                            componentRef={searchBox} onSearch={() => onSubmit()}
                            value={searchValues.keyword || ''}
                            onChange={onInputChange}
                            placeholder='Search document name, parties and hashtag'
                        />
                    </Stack>
                    <DataTypeFilterSet
                        onChange={onFiltersChange}
                        stackProps={{ tokens: stackTokens }}
                        renderButtonOptional={renderButtonOptional}
                    />
                </Stack>

                <Debug object={searchValues} />

                <Stack grow={1}>
                    {results ? renderResult(results.items) : (isLoading ? renderLoading() : renderNoResult())}
                </Stack>

                {(results && !isEmpty(results.items)) && (
                    <Stack styles={stackBorderStyles} verticalAlign='center' horizontalAlign='center'>
                        {results.pageNumber < results.pageTotal ? (
                            <>
                                <ActionButton onClick={onClickMoreButton}>Total {numberWithCommas(results.total)} Load more</ActionButton>
                            </>
                        ) : (
                            <>
                                <Text styles={p => getTextStyles(p)}>Found {numberWithCommas(results.total)} results</Text>
                            </>
                        )}
                    </Stack>
                )}
            </Stack>
        </Panel>
    )
};
