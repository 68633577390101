import { INavLink, INavLinkGroup, INavStyles, Nav } from "@fluentui/react/lib/Nav";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { DefaultPalette } from "@fluentui/react/lib/Styling";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router";
import React, { useContext, useRef } from "react";
import { preparePath } from "../../tools";
import { PageContainer } from "../../components/PageContainer";
import { AppContext } from "../../contexts";
import { cloneDeep } from "lodash";
import { RequestReport } from "../Administrator/Report/RequestReport";
import { UserReport } from "../Administrator/Report/UserReport";
import { DocumentReport } from "../Administrator/Report/DocumentReport";
import { ActivitiesReport } from "../Administrator/Report/ActivitiesReport";
import { ExpirationReport } from "../Administrator/Report/ExpirationReport";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";
import { Panel, PanelType } from "@fluentui/react/lib/Panel";

const navStyles: Partial<INavStyles> = {
    root: {
        overflowY: 'auto',
    },
    chevronIcon: {
        transform: 'rotate(-90deg)', // rotate(-180deg)
        '.ms-Nav-chevronButton[aria-expanded="true"] &': {
            transform: 'rotate(0deg)',
        }
    },
    compositeLink: {
        '& button.ms-Button--action': {
            color: DefaultPalette.black,
        }
    }
};

const stackTokens: IStackTokens = { childrenGap: 10 };

export const Report: React.FunctionComponent<RouteComponentProps<{ site: string }>> = props => {
    const { profile, isAppLoading, isSideMenuOpen, hideSideMenu } = useContext(AppContext);
    const { match: { path, params: { site } }, location: { pathname }, history } = props;

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;
    const isLargeDevice = modalResponsiveMode === ResponsiveMode.large;

    if (isAppLoading) {
        return null;
    }

    const permission = profile?.permission;
    if (!permission) {
        return <Redirect to="/nopermission" />;
    }

    if (
        !permission.requestReport &&
        !permission.userReport &&
        !permission.documentReport &&
        !permission.activitiesLogReport &&
        !permission.expirationReport
    ) {
        return <Redirect to="/nopermission" />;
    }

    const pageLink = `/site/${site}/report`;
    const _pathname = preparePath(pathname);
    const pathArray = _pathname.split('/');
    const selectedKey = pathArray[4];

    const navLinkGroups: INavLinkGroup[] = [
        {
            links: [
                {
                    key: 'requestreport',
                    name: 'Request Report',
                    url: `${pageLink}/requestreport`,
                },
                {
                    key: 'userreport',
                    name: 'User Report',
                    url: `${pageLink}/userreport`,
                },
                {
                    key: 'documentreport',
                    name: 'Document Report',
                    url: `${pageLink}/documentreport`,
                },
                {
                    key: 'activitiesreport',
                    name: 'Activities Log Report',
                    url: `${pageLink}/activitiesreport`,
                },
                {
                    key: 'expirationreport',
                    name: 'Expiration Report',
                    url: `${pageLink}/expirationreport`,
                },
            ],
        },
    ];

    let newLinks = cloneDeep(navLinkGroups[0].links);

    if (!permission.requestReport) {
        newLinks = newLinks.filter(l => l.key !== 'requestreport');
    }

    if (!permission.userReport) {
        newLinks = newLinks.filter(l => l.key !== 'userreport');
    }

    if (!permission.documentReport) {
        newLinks = newLinks.filter(l => l.key !== 'documentreport');
    }

    if (!permission.activitiesLogReport) {
        newLinks = newLinks.filter(l => l.key !== 'activitiesreport');
    }

    if (!permission.expirationReport) {
        newLinks = newLinks.filter(l => l.key !== 'expirationreport');
    }

    let defaultPage = `${_pathname}/${newLinks[0].key}`;
    navLinkGroups[0].links = newLinks;

    const onLinkClick = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
        ev?.preventDefault();
        if (item) {
            history.push(item.url);
        }
    };

    const onRenderNav = () => {
        if (isSmallDevice || isLargeDevice) {
            return (
                <Panel
                    isOpen={isSideMenuOpen}
                    onDismiss={hideSideMenu}
                    type={PanelType.customNear}
                    customWidth={isLargeDevice ? '40%' : '70%'}
                    isLightDismiss
                >
                    <Nav
                        onLinkClick={onLinkClick}
                        selectedKey={selectedKey}
                        styles={navStyles}
                        groups={navLinkGroups}
                    />
                </Panel>
            );
        } else {
            return (
                <Stack tokens={stackTokens} styles={{ root: { height: 'calc(100vh - 190px)', width: 180, overflowY: 'auto' } }}>
                    <Nav
                        onLinkClick={onLinkClick}
                        selectedKey={selectedKey}
                        styles={navStyles}
                        groups={navLinkGroups}
                    />
                </Stack>
            );
        }
    };

    return (
        <Stack horizontal tokens={stackTokens}>
            {onRenderNav()}
            <PageContainer center={false} >
                <Switch>
                    <Route exact path={path} render={() => <Redirect to={defaultPage} />} />
                    {permission.requestReport && <Route path={`${path}/requestreport`} component={RequestReport} />}
                    {permission.userReport && <Route path={`${path}/userreport`} component={UserReport} />}
                    {permission.documentReport && <Route path={`${path}/documentreport`} component={DocumentReport} />}
                    {permission.activitiesLogReport && <Route path={`${path}/activitiesreport`} component={ActivitiesReport} />}
                    {permission.expirationReport && <Route path={`${path}/expirationreport`} component={ExpirationReport} />}
                </Switch>
            </PageContainer>
        </Stack>
    );
};