import { ApiService } from ".";
import authService from "../components/api-authorization/AuthorizeService";
import { IParameters, ISearchDocumentResult, ISearchParameters, ISearchResult } from "../models";

export class SearchDocumentService implements ApiService<ISearchDocumentResult> {
    get(parameters: ISearchParameters): Promise<ISearchDocumentResult> {
        throw new Error("Method not implemented.");
    }
    async search(parameters: ISearchParameters): Promise<ISearchResult<ISearchDocumentResult>> {
        const { numberPerPage, pageNumber } = parameters;
        try {
            const token = await authService.getAccessToken();
            const search: IParameters = {};
            Object.keys(parameters).forEach(key => {
                if (parameters[key] === undefined || parameters[key] === null) {
                    return;
                }
                search[`parameters.${key}`] = parameters[key];
            });

            const queryString = new URLSearchParams(search).toString();
            const response = await fetch(`api/Document/path/search?${queryString}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            });
            const json = await response.json();
            return json;
        } catch {
            return await Promise.resolve({
                total: 0,
                items: [],
                pageNumber,
                pageTotal: 0,
                numberPerPage,
                startIndex: 0,
                endIndex: 0,
            });
        }
    }
}
