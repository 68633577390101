import { ISpinnerProps, Spinner } from "@fluentui/react/lib/Spinner";
import { IStackProps, Stack } from "@fluentui/react/lib/Stack";
import React from "react";

export interface LoadingProps {
    stackProps?: IStackProps;
    spinnerProps?: ISpinnerProps;
}

export const Loading: React.FunctionComponent<LoadingProps> = (props) => {
    const { stackProps, spinnerProps } = props;

    const defaultStackProps: IStackProps = {
        grow: 1,
        horizontalAlign: 'center',
        verticalAlign: 'center',
        styles: { root: { height: 44 } },
        ...stackProps,
    }

    const defaultSpinnerProps: ISpinnerProps = {
        label: 'Loading...',
        ariaLive: 'assertive',
        labelPosition: 'right',
        styles: { root: { height: 44 } },
        ...spinnerProps,
    }

    return (
        <Stack {...defaultStackProps} >
            <Spinner {...defaultSpinnerProps} />
        </Stack>
    )
}
