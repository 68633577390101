const values: { [key: string]: string } = {
    'all': 'All',
    'draft': 'Draft',
    'waiting-vp-approve': 'Waiting VP/SVP/EVP Approve',
    'waiting-admin-verify': 'Waiting Admin Verify',
    'waiting-clgvp-approve': 'Waiting Legal VP Approve',
    'approved': 'Approved',
    'rejected': 'Rejected',
    'inactive': 'Inactive',
    'waiting-for-approval': 'Waiting for approval',
    'waiting-for-verify': 'Waiting for verify',
    'verified': 'Verified',
    'active': 'Active',
    'request': 'Request',
    'admin': 'Legal Staff',
    'public': 'Public',
    'no': 'No',
    'yes': 'Yes',
    'delete': 'Delete',
    'expired': 'Expired',

    // Document fields
    'signContractDate': 'Sign Contract Date',
    'numberofOriginal': 'Number of Original',
    'numberofCopy': 'Number of Copy',
    'documentLocation': 'Document Location',
    'province': 'Province',
    'barcode': 'Barcode',
    'docNo': 'Doc No.',
    'signingDate': 'Signing Date',
    'terms': 'Terms (Days)',
    'startDate': 'Start Date',
    'endDate': 'End Date',
    'expiredDate': 'Expired Date',
    'contractParties': 'Contract Parties',
    'disclosingParty': 'Disclosing Party',
    'receivingParty': 'Receiving Party',
    'abstract': 'Abstract',
    'remark': 'Remark',
    'docGroup': 'Document Group',
    'remarkGroup': 'Remark of Group',
    
    // Flow roles
    'admin-verify': 'Legal Administrator (Approver)',
    'clg-vp': 'Legal VP (Approver)',
    'user': 'User',

    // Default permission
    'requestToView': 'Request to view',
    'requestToPrint': 'Request to print',

    // Frontend features
    'abilityToSearch': 'Ability to search',
    'viewWithoutRequest': 'View without request',
    'printWithoutRequest': 'Print without request',
    'isClgStaff': 'Legal Staff',

    // Report
    'requestReport': 'Request Report',
    'userReport': 'User Report',
    'documentReport': 'Document Report',
    'activitiesLogReport': 'Activities log Report',
    'expirationReport': 'Expiration Report',

    // Backend
    'documentManagement': 'Document Management',
    'requestManagement': 'Request Management',
    'dataTypeManagement': 'Data type Management',
    'templateManagement': 'Template Management',
    'userManagement': 'User Management',
    'emailTemplateManagement': 'Email template Management',
    'downloadDocument': 'Download Soft Files',

    // Activity
    'sign-in': 'Sign in',
    'save-draft': 'Save draft',
    'sent-request': 'Sent request',
    'view-document': 'View documents',
    'print-document': 'Print documents',
    'download-document': 'Download Soft Files',
    'download-pdf': 'Download PDF',
    'remove-auth': 'Remove Authorization',
}

export const getDisplayValue = (key: string | null | undefined) => {
    if (!key) {
        return '...';
    }

    if (values[key]) {
        return values[key];
    }

    return key;
};

export const readableFileSize = (size: number) => {
    var units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    var i = 0;
    while(size >= 1024) {
        size /= 1024;
        ++i;
    }
    return size.toFixed(1) + ' ' + units[i];
};