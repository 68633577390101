import { DirectionalHint, ICalloutProps, Target } from "@fluentui/react/lib/Callout";
import { ContextualMenu, ContextualMenuItemType, IContextualMenuItem, IContextualMenuItemProps } from "@fluentui/react/lib/ContextualMenu";
import { IStackProps, Stack } from "@fluentui/react/lib/Stack";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ITextProps, Text } from "@fluentui/react/lib/Text";
import { FontWeights, mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Spinner } from "@fluentui/react/lib/Spinner";
import { ThemeContext } from "@fluentui/react/lib/Theme";
import { itemClassNames, SearchSuggestionItem } from "./SearchSuggestionItem";
import { useBoolean } from "@fluentui/react-hooks";
import { SearchDocumentService } from "../services";
import { isEmpty, numberWithCommas } from "../tools";
import { ISearchDocumentResult, ISearchResult } from "../models";
import { AppContext } from "../contexts";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";

export interface SearchSuggestionProps {
    target: Target;
    keyword: string;
    onOpened?: () => void;
    onCancel?: () => void;
}

const itemHeaderClassNames = mergeStyleSets(itemClassNames, {
    documentName: { fontWeight: FontWeights.bold },
    parties: { fontWeight: FontWeights.bold },
    date: { fontWeight: FontWeights.bold }
});

const menuItemProps: Partial<IContextualMenuItemProps> = {
    styles: {
        root: {
            height: 'auto',
        },
        linkContent: {
            whiteSpace: 'wrap'
        }
    }
};

const getDivider: (key: string) => IContextualMenuItem = (key) => ({
    key: `divider_${key}`,
    itemType: ContextualMenuItemType.Divider
});

const loadingItem: IContextualMenuItem = {
    key: 'loading',
    onClick: e => e?.preventDefault(),
    onRender: () => (
        <Stack
            grow={1}
            horizontalAlign='center'
            verticalAlign='center'
            styles={{ root: { height: 200 } }}
        >
            <Spinner label="Loading results" ariaLive="assertive" labelPosition="right" />
        </Stack>
    )
};

const notFoundItem: IContextualMenuItem = {
    key: 'notFound',
    onClick: e => e?.preventDefault(),
    onRender: () => (
        <Stack grow={1} horizontalAlign='center' verticalAlign='center' styles={{ root: { height: 200 } }}>
            <Text variant='large' >No result found</Text>
        </Stack>
    )
};

export const itemStyleMargin: string = '0 10px 0 10px';
const itemsStackProps: Partial<IStackProps> = {
    styles: { root: { margin: itemStyleMargin } }
};

const mapResultsToMenuItems = (results: ISearchResult<ISearchDocumentResult>) => {
    let items: IContextualMenuItem[] = results.items.map((doc, i) => {
        return {
            key: doc.key,
            title: doc.name || 'unknow',
            text: doc.name || 'unknow',
            href: `#quick-search-doc-item-${doc.key}`,
            onClick: e => e?.preventDefault(),
            onRenderContent: () => {
                return (
                    <SearchSuggestionItem doc={doc} stackProps={itemsStackProps} from='quickSearch' />
                );
            },
            itemProps: menuItemProps
        }
    });

    items = items
        .map((m, i) => i < items.length - 1 ? [m, getDivider(m.key)] : [m])
        .reduce((prev, curr) => prev.concat(curr), []);
    return items;
}

export const SearchSuggestion: React.FunctionComponent<SearchSuggestionProps> = (props) => {
    const theme = useContext(ThemeContext);
    const { setSearchKeyword, openSearchPanel, profile } = useContext(AppContext);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;
    const isLargeDevice = modalResponsiveMode === ResponsiveMode.large;

    const [results, setResults] = useState<ISearchResult<ISearchDocumentResult> | undefined>(undefined);
    const [pageNumber, setPageNumber] = useState<number>(1);

    const [hidden, { setFalse: onShowContextualMenu, setTrue: onHideContextualMenu }] = useBoolean(true);
    const [isLoading, { setFalse: onHideLoading, setTrue: onShowLoading }] = useBoolean(false);

    const { target, keyword, onOpened, onCancel, } = props;

    const onGetResult = useCallback((page: number) => {
        setPageNumber(page);
        onShowLoading();

        const service = new SearchDocumentService();
        const promise = service.search({
            keyword,
            pageNumber: page,
            numberPerPage: 20,
            siteId: profile?.site?.id,
        });

        promise.then(newResults => {
            onHideLoading();
            setResults(prev => {
                newResults.items = [...prev?.items || [], ...newResults.items]
                return newResults;
            });
        });
    }, [keyword, profile, setPageNumber, onShowLoading, onHideLoading]);

    useEffect(() => {
        setResults(undefined);

        if (!keyword) {
            onHideContextualMenu();
            return;
        }

        onShowContextualMenu();
        onGetResult(1);
    }, [keyword, onHideContextualMenu, onShowContextualMenu, onGetResult]);

    // Modified the Callout width to match with target element width
    const tagetElement = target as React.RefObject<HTMLElement>;
    const calloutProps: ICalloutProps = {
        directionalHint: DirectionalHint.bottomAutoEdge,
        directionalHintFixed: true,
    };

    if (tagetElement && tagetElement.current && tagetElement.current.offsetWidth) {
        const targetBoundingRect = tagetElement.current.getBoundingClientRect();
        const targetWidth = targetBoundingRect.width - 2; /* Accounts for 1px border */
        if (targetWidth) {
            calloutProps.calloutWidth = targetWidth;
        }
    }

    const getTextStyles = useCallback((p: ITextProps, isBold: boolean = false) => {
        return {
            root: {
                marginRight: 5,
                color: theme!.palette.themePrimary,
                fontWeight: isBold ? FontWeights.bold : FontWeights.regular
            }
        };
    }, [theme]);

    const linkAdvanceSearchItem: IContextualMenuItem = {
        key: 'advanceSearch',
        onClick: e => {
            e?.preventDefault();
            setSearchKeyword(keyword);
            openSearchPanel();

            if (onCancel) {
                onCancel()
            };
        },
        onRenderContent: () => {
            return (
                <Stack grow={1} horizontal styles={{ root: { margin: itemStyleMargin, overflowX: 'auto' } }}>
                    {keyword ? (
                        <>
                            <Text styles={p => getTextStyles(p)}>Do you want to search :</Text>
                            <Text styles={p => getTextStyles(p, true)}>"{keyword}"</Text>
                            <Text styles={p => getTextStyles(p)}>in Advance search ? Click here</Text>
                        </>
                    ) : (
                        <>
                            <Text styles={p => getTextStyles(p)}>Open Advance search Click here</Text>
                        </>
                    )}
                </Stack>
            );
        }
    };

    const headerItem: IContextualMenuItem = {
        key: 'header',
        onClick: e => e?.preventDefault(),
        onRenderContent: () => {
            return (
                <Stack grow={1} horizontal styles={{ root: { margin: itemStyleMargin } }}>
                    <Text className={itemHeaderClassNames.documentName}>Document name</Text>
                    <Text className={itemHeaderClassNames.parties}>Parties</Text>
                    <Text className={itemHeaderClassNames.date}>Date</Text>
                </Stack>
            );
        }
    };

    const pageTotal = results ? results.pageTotal : 0;
    const isLastPage = pageNumber === pageTotal;
    const total = results?.total || 0;

    const footerItem: IContextualMenuItem = {
        key: 'footer',
        onClick: e => {
            e?.preventDefault();
            if (isLastPage) {
                return;
            }

            onGetResult(pageNumber + 1);
        },
        onRenderContent: () => {
            return (
                <Stack grow={1} styles={{ root: { margin: itemStyleMargin, textAlign: 'center' } }}>
                    {isLastPage ? (
                        <Text styles={p => getTextStyles(p)}>Found {numberWithCommas(total)} results</Text>
                    ) : (
                        <Text styles={p => getTextStyles(p)}>Total {numberWithCommas(total)} Load more</Text>
                    )}
                </Stack>
            );
        }
    };

    const documentItems = results ? mapResultsToMenuItems(results) : [];
    const isEmptyItems = isEmpty(documentItems);
    const isNotFound = !hidden && isEmptyItems;

    const suggestionItems: IContextualMenuItem[] = isLoading && isEmptyItems ? [loadingItem] : (isNotFound
        ? [linkAdvanceSearchItem, getDivider('zone1'), notFoundItem]
        : [
            linkAdvanceSearchItem,
            getDivider('zone1'),
            headerItem,
            getDivider('zone2'),
            ...documentItems,
            getDivider('zone3'),
            ...(isLoading ? [loadingItem] : [footerItem]),
        ]
    );

    if (isSmallDevice) {
        // adjust callout
        calloutProps.calloutWidth = window.innerWidth - 15;
        calloutProps.styles = { root: { right: '0px !important' } };

        // remove header and zone1
        const headerIndex = suggestionItems.findIndex(item => item.key === 'header');
        if (headerIndex > -1) {
            suggestionItems.splice(headerIndex - 1, 2);
        }
    }

    if (isLargeDevice) {
        // adjust callout
        calloutProps.calloutWidth = window.innerWidth - 15;
    }

    const _onHideContextualMenu = (ev?: Event | React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | undefined, dismissAll?: boolean | undefined) => {
        if (ev && (ev as KeyboardEvent).code === 'Escape') {
            onHideContextualMenu();
        }
    };

    return (
        <ContextualMenu
            shouldFocusOnMount={false}
            items={suggestionItems}
            hidden={hidden}
            target={target}
            onDismiss={_onHideContextualMenu}
            calloutProps={calloutProps}
            directionalHint={DirectionalHint.bottomCenter}
            directionalHintFixed
            onMenuOpened={onOpened}
        />
    )
}
