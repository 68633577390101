import React, { ElementType } from 'react'
import { Component } from 'react'
import { Route, Redirect, RouteComponentProps } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants'
import authService from './AuthorizeService'

export type AuthorizeRouteProps = Partial<RouteComponentProps<{ site: string, id: string }>>;

export interface AuthorizeRouteState {
    path: string;
    component: ElementType;
}

export default class AuthorizeRoute extends Component<AuthorizeRouteState & AuthorizeRouteProps, { ready: boolean, authenticated: boolean }> {
    _subscription: number | undefined;

    constructor(props: AuthorizeRouteState) {
        super(props);

        this.state = {
            ready: false,
            authenticated: false
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription!);
    }

    render() {
        const { ready, authenticated } = this.state;
        const { location } = this.props;
        let pathname = '';
        let search = '';
        let hash = '';
        if (location) {
            pathname = location.pathname;
            search = location.search;
            hash = location.hash;
        }

        var link = document.createElement("a");
        link.href = this.props.path;
        const returnUrl = `${link.protocol}//${link.host}${pathname}${search}${hash}`;
        const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`
        if (!ready) {
            return <div></div>;
        } else {
            const { component: Component, ...rest } = this.props;
            return <Route {...rest}
                render={(props) => {
                    if (authenticated) {
                        return <Component {...props} />
                    } else {
                        return <Redirect to={redirectUrl} />
                    }
                }} />
        }
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        this.setState({ ready: true, authenticated });
    }

    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false });
        await this.populateAuthenticationState();
    }
}
