import { Icon } from "@fluentui/react";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { Text } from "@fluentui/react/lib/Text";
import { useTheme } from "@fluentui/react/lib/Theme";
import React from "react";

export interface IDocumentPathLabelProps {
    name?: string;
    showIcon?: boolean;
    smallText?: boolean;
    dataTypeName?: string;
    dataNameName?: string;
}

const stackTokens: IStackTokens = { childrenGap: 10 };

export const DocumentPathLabel: React.FunctionComponent<IDocumentPathLabelProps> = (props) => {
    const theme = useTheme();
    const { showIcon = false, smallText, dataTypeName, dataNameName } = props;

    return (
        <Stack tokens={stackTokens} horizontal verticalAlign='center' wrap>
            <Text variant={smallText ? 'small' : 'medium'}>{dataTypeName} :</Text>
            <Text variant={smallText ? 'small' : 'medium'} styles={{ root: { color: theme.palette.themePrimary } }}>
                {dataNameName}
            </Text>
            {showIcon && <Icon iconName='ChevronRight' />}
        </Stack>
    );
};