import { IBreadcrumbItem } from "@fluentui/react/lib/Breadcrumb";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { Debug } from "../../../components/Debug";
import { NavigationPane } from "../../../components/NavigationPane";
import { listBreadcrumbItems } from "./TemplateList";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { TextField } from "@fluentui/react/lib/TextField";
import { Text } from "@fluentui/react/lib/Text";
import { Checkbox } from "@fluentui/react/lib/Checkbox";
import { Label } from "@fluentui/react/lib/Label";
import { useCallback } from "react";
import { getDisplayValue, isEmpty } from "../../../tools";
import { ITagData, MultiplePicker } from "../../../components/MultiplePicker";
import { IDataType, IDocumentFieldDefault, IDocumentFields, ISaveResponse, ITemplate } from "../../../models";
import { DataTypeService, TemplateService } from "../../../services";
import { useMemo } from "react";
import { cloneDeep } from "lodash";
import { AppContext } from "../../../contexts";
import { DialogType } from "@fluentui/react/lib/Dialog";
import { MessageBar, MessageBarType } from "@fluentui/react/lib/MessageBar";
import { useBoolean } from "@fluentui/react-hooks";
import { OverlayLoading } from "../../../components/OverlayLoading";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";

export type IFormMode = 'new' | 'edit' | 'editdraft' | 'readonly';

export interface ITemplateValues {
    mode: IFormMode;
    selectedDataTypeTags: (ITagData | null)[];
    data: ITemplate;
}

const stackTokens: IStackTokens = { childrenGap: 10 };
const horizonStackTokens: IStackTokens = { childrenGap: 60 };

const initialValues: ITemplateValues = {
    mode: 'readonly',
    selectedDataTypeTags: [],
    data: {
        id: null,
        key: '-1',
        name: null,
        documentFields: {
            signContractDate: true,
            numberofOriginal: true,
            numberofCopy: true,
            documentLocation: true,
            province: true,
            barcode: true,
            docNo: true,
            signingDate: true,
            terms: true,
            startDate: true,
            endDate: true,
            expiredDate: true,
            contractParties: true,
            disclosingParty: true,
            receivingParty: true,
            abstract: true,
            remark: true,
            docGroup: true,
            remarkGroup: true,
        },
        dataTypes: null,
        canEditDataType: true,
    }
};

const mapDataTypeToTagData = (dataType: IDataType): ITagData => {
    return { key: dataType.key, name: dataType.name!, data: dataType };
};

export const TemplateForm: React.FunctionComponent<RouteComponentProps<{ site: string, id: string }>> = (props) => {
    const { profile, showDialog } = useContext(AppContext);
    const [values, setValues] = useState<ITemplateValues>({ ...cloneDeep(initialValues) });
    const [originalValues, setOriginalValues] = useState<ITemplate | undefined>(undefined);
    const [dataTypeTags, setDataTypeTags] = useState<ITagData[]>([]);
    const [isLoading, { setFalse: hideLoading, setTrue: showLoading }] = useBoolean(false);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;

    const service = useMemo(() => new TemplateService(), []);
    const dataTypeService = useMemo(() => new DataTypeService(), []);
    const { match: { params: { site, id } }, history, location: { state } } = props;
    const isReadonly = values.mode === 'readonly';

    useEffect(() => {
        let isMounted = true;

        if (!id || id === 'new') {
            setValues({
                ...cloneDeep(initialValues),
                mode: 'new',
            });
            return;
        }

        const promise = service.get({ id });
        promise.then(data => {
            if (!isMounted || !data || !data.id) {
                setValues({ ...cloneDeep(initialValues), mode: 'readonly' });
                setOriginalValues(undefined);
                return;
            }

            data.documentFields = cloneDeep({ ...initialValues.data.documentFields, ...data.documentFields });

            setValues({
                ...cloneDeep(initialValues),
                data,
                selectedDataTypeTags: data.dataTypes ? data.dataTypes.map(mapDataTypeToTagData) : [],
                mode: 'edit',
            });

            setOriginalValues(cloneDeep(data));
        });

        return () => {
            setValues(cloneDeep(initialValues));
            setOriginalValues(undefined);
        };
    }, [id, service]);

    useEffect(() => {
        let isMounted = true;
        const promise = dataTypeService.search({
            pageNumber: 1,
            numberPerPage: 9999,
            status: 'active',
            siteId: profile?.site?.id,
        }); // TODO
        promise.then(results => {
            if (!isMounted) {
                setDataTypeTags([]);
                return;
            }

            setDataTypeTags(results.items.map(dt => ({ key: dt.key, name: dt.name!, data: dt })));
        });

        return () => {
            isMounted = false;
            setValues(cloneDeep(initialValues));
            setDataTypeTags([]);
            setOriginalValues(undefined);
        }
    }, [dataTypeService, profile]);

    const listLink = `/site/${site}/administrator/template`;

    const onClickListLink = (ev?: React.MouseEvent<HTMLElement>) => {
        ev?.preventDefault();
        history.push(listLink, { from: 'form', values: state });
    };

    const breadcrumbItems: IBreadcrumbItem[] = [
        { ...listBreadcrumbItems, href: listLink, onClick: onClickListLink },
        {
            key: values.mode,
            text: originalValues?.name || (values.mode === 'new' ? 'New' : '...'),
        }
    ];

    const onChangeTextField = useCallback(<K extends keyof ITemplate>(newValue: ITemplate[K] | null, fieldName: K) => {
        const newValues: ITemplateValues = { ...values };
        newValues.data[fieldName] = newValue as ITemplate[K];
        setValues(newValues);
    }, [values]);

    const onCheckDocumentField = useCallback(<K extends keyof IDocumentFields>(checked: IDocumentFields[K], fieldName: K) => {
        const newDocumentFields = { ...values.data.documentFields };
        newDocumentFields[fieldName] = checked;
        const newData = { ...values.data, documentFields: newDocumentFields };
        setValues(prev => ({ ...prev, data: newData }));
    }, [values.data]);

    const onChangeDocumentField = useCallback(<K extends keyof IDocumentFieldDefault>(value: IDocumentFieldDefault[K], fieldName: K) => {
        const newDocumentFields = { ...values.data.documentFields };
        newDocumentFields[fieldName] = value;
        const newData = { ...values.data, documentFields: newDocumentFields };
        setValues(prev => ({ ...prev, data: newData }));
    }, [values.data]);

    const onDataTypePickerChange = (items: (ITagData | null)[]) => {
        setValues(prev => ({ ...prev, selectedDataTypeTags: items }));
    };

    const onClickReset = () => {
        if (!originalValues) {
            return;
        }

        setValues({ ...values, data: cloneDeep(originalValues) });
    };

    const onSaveResponse = (action: string, saved: ISaveResponse<ITemplate>, isBackToList: boolean = true) => {
        if (saved.isError) {
            showDialog({
                title: `${action} failed`,
                dialogContentProps: {
                    type: DialogType.normal,
                }
            }, () => {
                return (
                    <>
                        {saved.errorMessage &&
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {saved.errorMessage}
                            </MessageBar>
                        }
                        {saved.errorMessages && saved.errorMessages.map((err, i) => (
                            <MessageBar key={i}
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {err}
                            </MessageBar>
                        ))}
                    </>
                );
            });

            return;
        } else {
            onClickListLink();
        }
    };

    const onValidateForm = (data: ITemplate) => {
        const errorMessages: string[] = [];
        if (!data.name) {
            errorMessages.push('Template name is required');
        }

        if (!data.dataTypes || isEmpty(data.dataTypes)) {
            errorMessages.push('Document path is required');
        }

        if (!isEmpty(errorMessages)) {
            showDialog({
                title: `Invalid form`,
                dialogContentProps: {
                    type: DialogType.normal,
                }
            }, () => {
                return (
                    <>
                        {errorMessages && errorMessages.map((err, i) => (
                            <MessageBar key={i}
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {err}
                            </MessageBar>
                        ))}
                    </>
                );
            });

            return false;
        }

        return true;
    };

    const onClickSave = async () => {
        const selectedDataTypes = values.selectedDataTypeTags.filter(t => t != null).map(t => t!.data as IDataType);
        const newData = { ...values.data };
        newData.dataTypes = selectedDataTypes;

        if (!onValidateForm(newData)) {
            return;
        }

        showLoading();

        setValues(prev => ({ ...prev, data: newData }));
        if (values.mode === 'new') {
            const data = { ...newData, site: profile?.site };
            await service.create(data, saved => onSaveResponse('Save data', saved));
        }

        if (values.mode === 'edit') {
            await service.update(id, newData, saved => onSaveResponse('Save data', saved));
        }

        hideLoading();
    };

    return (
        <Stack tokens={stackTokens}>
            <NavigationPane items={breadcrumbItems || []} />

            <Stack tokens={horizonStackTokens} horizontal wrap={isSmallDevice}>
                <Stack grow tokens={stackTokens} styles={{ root: { maxWidth: isSmallDevice ? undefined : 500 } }}>
                    <TextField
                        label='Template name'
                        value={values.data.name || ''}
                        onChange={(e, value) => onChangeTextField(value || null, 'name')}
                        required
                    />

                    <Label>Document Fields</Label>

                    <Stack horizontal horizontalAlign='space-between'>
                        <Text>{getDisplayValue('signContractDate')}</Text>
                        <Checkbox
                            disabled={isReadonly}
                            checked={values.data.documentFields!['signContractDate']}
                            onChange={(e, checked) => onCheckDocumentField(checked || false, 'signContractDate')}
                        />
                    </Stack>

                    <Stack horizontal horizontalAlign='space-between'>
                        <Text>{getDisplayValue('numberofOriginal')}</Text>
                        <Checkbox
                            disabled={isReadonly}
                            checked={values.data.documentFields!['numberofOriginal']}
                            onChange={(e, checked) => onCheckDocumentField(checked || false, 'numberofOriginal')}
                        />
                    </Stack>

                    <Stack horizontal horizontalAlign='space-between'>
                        <Text>{getDisplayValue('numberofCopy')}</Text>
                        <Checkbox
                            disabled={isReadonly}
                            checked={values.data.documentFields!['numberofCopy']}
                            onChange={(e, checked) => onCheckDocumentField(checked || false, 'numberofCopy')}
                        />
                    </Stack>

                    <Stack horizontal horizontalAlign='space-between'>
                        <Text>{getDisplayValue('documentLocation')}</Text>
                        <Checkbox
                            disabled={isReadonly}
                            checked={values.data.documentFields!['documentLocation']}
                            onChange={(e, checked) => onCheckDocumentField(checked || false, 'documentLocation')}
                        />
                    </Stack>

                    <Stack horizontal horizontalAlign='space-between'>
                        <Text>{getDisplayValue('province')}</Text>
                        <Checkbox
                            disabled={isReadonly}
                            checked={values.data.documentFields!['province']}
                            onChange={(e, checked) => onCheckDocumentField(checked || false, 'province')}
                        />
                    </Stack>

                    <Stack horizontal horizontalAlign='space-between'>
                        <Text>{getDisplayValue('barcode')}</Text>
                        <Checkbox
                            disabled={isReadonly}
                            checked={values.data.documentFields!['barcode']}
                            onChange={(e, checked) => onCheckDocumentField(checked || false, 'barcode')}
                        />
                    </Stack>

                    <Stack horizontal horizontalAlign='space-between'>
                        <Text>{getDisplayValue('docNo')}</Text>
                        <Checkbox
                            disabled={isReadonly}
                            checked={values.data.documentFields!['docNo']}
                            onChange={(e, checked) => onCheckDocumentField(checked || false, 'docNo')}
                        />
                    </Stack>

                    <Stack horizontal horizontalAlign='space-between'>
                        <Text>{getDisplayValue('signingDate')}</Text>
                        <Checkbox
                            disabled={isReadonly}
                            checked={values.data.documentFields!['signingDate']}
                            onChange={(e, checked) => onCheckDocumentField(checked || false, 'signingDate')}
                        />
                    </Stack>

                    <Stack horizontal horizontalAlign='space-between'>
                        <Text>{getDisplayValue('terms')}</Text>
                        <Checkbox
                            disabled={isReadonly}
                            checked={values.data.documentFields!['terms']}
                            onChange={(e, checked) => onCheckDocumentField(checked || false, 'terms')}
                        />
                    </Stack>

                    <Stack horizontal horizontalAlign='space-between'>
                        <Text>{getDisplayValue('startDate')}</Text>
                        <Checkbox
                            disabled={isReadonly}
                            checked={values.data.documentFields!['startDate']}
                            onChange={(e, checked) => onCheckDocumentField(checked || false, 'startDate')}
                        />
                    </Stack>

                    <Stack horizontal horizontalAlign='space-between'>
                        <Text>{getDisplayValue('endDate')}</Text>
                        <Checkbox
                            disabled={isReadonly}
                            checked={values.data.documentFields!['endDate']}
                            onChange={(e, checked) => onCheckDocumentField(checked || false, 'endDate')}
                        />
                    </Stack>

                    <Stack horizontal horizontalAlign='space-between'>
                        <Text>{getDisplayValue('expiredDate')}</Text>
                        <Checkbox
                            disabled={isReadonly}
                            checked={values.data.documentFields!['expiredDate']}
                            onChange={(e, checked) => onCheckDocumentField(checked || false, 'expiredDate')}
                        />
                    </Stack>

                    <Stack horizontal horizontalAlign='space-between'>
                        <Text>{getDisplayValue('contractParties')}</Text>
                        <Checkbox
                            disabled={isReadonly}
                            checked={values.data.documentFields!['contractParties']}
                            onChange={(e, checked) => onCheckDocumentField(checked || false, 'contractParties')}
                        />
                    </Stack>

                    <Stack horizontal horizontalAlign='space-between'>
                        <Text>{getDisplayValue('disclosingParty')}</Text>
                        <Checkbox
                            disabled={isReadonly}
                            checked={values.data.documentFields!['disclosingParty']}
                            onChange={(e, checked) => onCheckDocumentField(checked || false, 'disclosingParty')}
                        />
                    </Stack>

                    <Stack horizontal horizontalAlign='space-between'>
                        <Text>{getDisplayValue('receivingParty')}</Text>
                        <Checkbox
                            disabled={isReadonly}
                            checked={values.data.documentFields!['receivingParty']}
                            onChange={(e, checked) => onCheckDocumentField(checked || false, 'receivingParty')}
                        />
                    </Stack>

                    <Stack horizontal horizontalAlign='space-between'>
                        <Text>{getDisplayValue('abstract')}</Text>
                        <Checkbox
                            disabled={isReadonly}
                            checked={values.data.documentFields!['abstract']}
                            onChange={(e, checked) => onCheckDocumentField(checked || false, 'abstract')}
                        />
                    </Stack>

                    <Stack horizontal horizontalAlign='space-between'>
                        <Text>{getDisplayValue('remark')}</Text>
                        <Checkbox
                            disabled={isReadonly}
                            checked={values.data.documentFields!['remark']}
                            onChange={(e, checked) => onCheckDocumentField(checked || false, 'remark')}
                        />
                    </Stack>

                    <Stack horizontal horizontalAlign='space-between'>
                        <Text>{getDisplayValue('docGroup')}</Text>
                        <Checkbox
                            disabled={isReadonly}
                            checked={values.data.documentFields!['docGroup']}
                            onChange={(e, checked) => onCheckDocumentField(checked || false, 'docGroup')}
                        />
                    </Stack>

                    <Stack horizontal horizontalAlign='space-between'>
                        <Text>{getDisplayValue('remarkGroup')}</Text>
                        <Checkbox
                            disabled={isReadonly}
                            checked={values.data.documentFields!['remarkGroup']}
                            onChange={(e, checked) => onCheckDocumentField(checked || false, 'remarkGroup')}
                        />
                    </Stack>

                    <TextField
                        label='Default Document Location'
                        value={values.data.documentFields?.defaultDocumentLocation || ''}
                        onChange={(e, value) => onChangeDocumentField(value || null, 'defaultDocumentLocation')}
                        disabled={!values.data.documentFields!["documentLocation"] || isReadonly}
                    />

                    <TextField
                        label='Default Province'
                        value={values.data.documentFields?.defaultProvince || ''}
                        onChange={(e, value) => onChangeDocumentField(value || null, 'defaultProvince')}
                        disabled={!values.data.documentFields!["province"] || isReadonly}
                    />
                </Stack>

                <Stack grow tokens={stackTokens} styles={{ root: { maxWidth: 400 } }}>
                    <Label required>Document path</Label>
                    <MultiplePicker
                        buttonText='Add Data Type'
                        items={dataTypeTags}
                        onChange={onDataTypePickerChange}
                        selectedItems={values.selectedDataTypeTags}
                        disabled={!values.data.canEditDataType || isReadonly}
                    />
                </Stack>
            </Stack>

            <Stack horizontal tokens={stackTokens}>
                {!isReadonly && <PrimaryButton text='Save' onClick={onClickSave} />}
                {(values.mode === 'edit' || values.mode === 'editdraft') && <DefaultButton text='Reset' onClick={onClickReset} />}
                <DefaultButton text='Cancel' onClick={() => onClickListLink()} />
            </Stack>

            <Debug object={values} />

            <OverlayLoading isVisibled={isLoading} />
        </Stack>
    )
};