import React from 'react';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { Layout } from './components/Layout';
import { Route, Switch } from 'react-router';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { AppContextProvider } from './contexts';
import { Site } from './components/Site';
import { NoPermission, PageNotFound, Welcome } from './pages';

initializeIcons("/lib/fluent-ui/icons/");

export const App: React.FunctionComponent = () => {
  return (
    <AppContextProvider>
      <Layout>
        <Switch>
          <Route exact path='/' component={Welcome} />
          <Route exact path='/site' component={Welcome} />
          <AuthorizeRoute path='/site/:site' component={Site} />
          <Route path='/pagenotfound' component={PageNotFound} />
          <Route path='/nopermission' component={NoPermission} />
          <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
        </Switch>
      </Layout>
    </AppContextProvider>
  )
};