import React, { useContext, useRef } from 'react';
import { CommandBar, ICommandBarItemProps, ICommandBarStyles } from '@fluentui/react/lib/CommandBar';
import { AppContext } from '../contexts';
import { CommandButton, DefaultButton, IButtonProps, IButtonStyles, PrimaryButton } from '@fluentui/react/lib/Button';
import { FontWeights } from '@fluentui/react/lib/Styling';
import { useHistory, useLocation } from 'react-router-dom';
import logo from '../assets/images/logo.svg';
import accountSvg from '../assets/icons/account.svg';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { useBoolean } from '@fluentui/react-hooks';
import { QuickSearch } from './QuickSearch';
import { isMatchPage } from '../tools';
import { IMenuItem, ISearchParameters, ISite } from '../models';
import { Callout } from '@fluentui/react/lib/Callout';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import { DialogFooter, DialogType } from '@fluentui/react/lib/Dialog';
import { Picker } from './picker';
import { useMemo } from 'react';
import { SiteService } from '../services';
import { useTheme } from '@fluentui/react/lib/Theme';
import { ResponsiveMode, useResponsiveMode } from '@fluentui/react/lib/ResponsiveMode';
import { cloneDeep } from 'lodash';

export interface IAuthenticate {
    isAuthenticated: boolean;
    userName: string | null
}


const activeButtonStyles: Partial<IButtonStyles> = {
    label: {
        fontWeight: FontWeights.bold
    }
};

const stackTokens: IStackTokens = { childrenGap: 10 };
const navbarHeight = 120;

const initialAccountButton = {
    key: 'account',
    text: 'Account',
    iconOnly: true,
    className: 'account-button',
    buttonStyles: {
        root: {
            width: 44,
            height: navbarHeight,
            margin: 0,
            background: `url(${accountSvg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '60% 60%',
            backgroundPosition: 'center center',
        }
    },
};

export const NavMenu: React.FunctionComponent = () => {
    const theme = useTheme();
    const { menuItems, profile, showDialog, hideDialog } = useContext(AppContext);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;

    const [hideSearchBox, { toggle: toggleHideSearchBox }] = useBoolean(true);
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);

    const { pathname } = useLocation();
    const history = useHistory();
    const service = useMemo(() => new SiteService(), []);
    const loginPath = `${ApplicationPaths.Login}`;
    const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };

    const commandBarStyles: Partial<ICommandBarStyles> = {
        root: {
            height: navbarHeight
        },
        primarySet: {
            flexGrow: hideSearchBox ? 1 : 0
        },
        secondarySet: {
            flexGrow: hideSearchBox
                ? isSmallDevice ? 1 : 0
                : 1,
            '& .ms-OverflowSet-item:first-child': {
                flexGrow: 1,
            }
        },
    };

    const searchButtonStyles: Partial<IButtonStyles> = {
        root: {
            border: `1px solid ${theme.palette.themePrimary}`,
            maxHeight: '50%',
            alignSelf: 'center'
        }
    };

    const onClickLogin = () => {
        history.push(loginPath);
    };

    const onClickLogout = () => {
        history.push(logoutPath);
    };

    if (!profile) {
        return (
            <CommandBar
                styles={commandBarStyles}
                items={[]}
                farItems={[{ ...initialAccountButton, onClick: onClickLogin }]}
            />
        );
    }

    const { site, user } = profile;

    const siteName = site ? site.name! : 'HQ';
    let logoUrl = `/site/${siteName}`;
    if (menuItems && menuItems?.some(item => item.name === 'dashboard')) {
        logoUrl += '/dashboard';
    }

    const onClickLogo = (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
        ev?.preventDefault();
        history.push(logoUrl);
    };

    const brandItemSet: ICommandBarItemProps[] = [
        {
            key: 'logo',
            buttonStyles: {
                root: {
                    width: 91,
                    height: 80,
                    background: `url(${logo})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100% 100%',
                    alignSelf: 'center',
                },
            },
            onClick: onClickLogo,
        },
        {
            key: 'split',
            onRender: () => (
                <Stack verticalAlign='center' styles={{ root: { margin: '0 10px 0 10px' } }}>
                    <Stack styles={{ root: { borderRight: `2px solid ${theme.palette.white}`, height: '60%' } }}></Stack>
                </Stack>
            ),
            onClick: e => e?.preventDefault()
        }
    ];

    const onClickLink = (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, menu?: IMenuItem) => {
        ev?.preventDefault();
        if (!menu) {
            return;
        }

        history.push(`/site/${siteName}/${menu.path}`);
    };

    const pageMenuItems: ICommandBarItemProps[] = menuItems ? menuItems.map(m => {
        return {
            key: m.name,
            text: m.title.toUpperCase(),
            onClick: (e) => onClickLink(e, m),
            buttonStyles: site && isMatchPage(pathname, site.name!, m.name) ? activeButtonStyles : undefined,
        };
    }) : [];

    const _items = hideSearchBox ? [...brandItemSet, ...pageMenuItems] : [...brandItemSet];

    const searchBoxItemSet: ICommandBarItemProps[] = [
        {
            key: 'searchBox',
            onClick: e => e?.preventDefault(),
            onRender: () => {
                return (
                    <Stack grow={1} verticalAlign='center' verticalFill styles={{ root: { margin: '0 10px 0 10px' } }}>
                        <QuickSearch onCancel={() => toggleHideSearchBox()} />
                    </Stack>
                );
            }
        },
        {
            key: 'searchCancel',
            buttonStyles: {
                root: {
                    flexGrow: 1
                }
            },
            onRender: () => {
                return (
                    <Stack grow={1} verticalAlign='center' verticalFill>
                        <DefaultButton text="Cancel" onClick={() => toggleHideSearchBox()} />
                    </Stack>
                );
            }
        }
    ];

    const searchIconItem: ICommandBarItemProps = {
        key: 'searchIcon',
        text: 'Quick Search',
        iconProps: { iconName: 'Search' },
        buttonStyles: searchButtonStyles,
        onClick: e => {
            e?.preventDefault();
            toggleHideSearchBox();
        },
    };

    const maxUserNameLength = 15;
    let userName = user?.name || '';
    userName = userName.length! > maxUserNameLength
        ? `${userName.substring(0, maxUserNameLength)}...`
        : userName;

    const iconMenuItems: ICommandBarItemProps[] = [
        {
            key: 'userName',
            text: userName,
            buttonStyles: {
                root: {
                    width: 150,
                    maxWidth: 150,
                }
            },
            onRender: () => <Stack verticalAlign='center' styles={{ root: { marginLeft: 10 } }}>{userName}</Stack>
        },
        {
            ...initialAccountButton,
            onClick: toggleIsCalloutVisible
        },
    ];

    const _farItems = hideSearchBox ? [searchIconItem, ...iconMenuItems] : [...searchBoxItemSet, ...iconMenuItems];

    let overflowButtonProps: IButtonProps = {};

    if (isSmallDevice) {
        // remove username
        const userNameIndex = _farItems.findIndex(item => item.key === 'userName');
        if (userNameIndex > -1) {
            _farItems.splice(userNameIndex, 1);
        }

        // remove search text label and add brand to far item
        const searchIconIndex = _farItems.findIndex(item => item.key === 'searchIcon');
        if (searchIconIndex > -1) {
            _farItems[searchIconIndex].text = '';

            const brandItem = cloneDeep(brandItemSet[0]);
            brandItem.buttonStyles = {
                root: {
                    width: 91,
                    height: 80,
                    background: `url(${logo})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100% 100%',
                    alignSelf: 'center',
                }
            };

            _farItems.splice(searchIconIndex, 0, brandItem);
        }

        // remove brand and split from item
        _items.splice(0, 2);

        // change overflow icon
        overflowButtonProps = { menuIconProps: { iconName: 'CollapseMenu' } };
    }

    const onMapActiveFilter = (filter: string, defaultFilter: ISearchParameters): ISearchParameters => {
        return {
            ...defaultFilter,
            status: 'active',
        };
    };

    const onClickSwitchSite = () => {
        showDialog({
            title: 'Switch site',
            dialogContentProps: {
                type: DialogType.normal,
            }
        }, () => {
            let selectedSite: ISite | null = null;
            const onChangeSite = (data: ISite | null) => {
                selectedSite = data;
            };

            const onChangeSiteSubmit = () => {
                if (selectedSite && profile && profile.site?.name !== selectedSite.name) {
                    const newSite = `/site/${selectedSite.name}/dashboard`;
                    window.location.replace(newSite);
                }
            };

            return (
                <>
                    <Picker
                        pickOnlyOne
                        service={service}
                        onMapFilter={onMapActiveFilter}
                        onChange={data => onChangeSite((data && data[0]) ? data[0].data! : null)}
                    />
                    <DialogFooter styles={{ actionsRight: { textAlign: 'left' } }}>
                        <PrimaryButton
                            onClick={onChangeSiteSubmit} text="Submit"
                        />
                        <DefaultButton onClick={hideDialog} text="Cancel" />
                    </DialogFooter>
                </>
            );
        });
    };

    return (
        <header>
            <CommandBar
                styles={commandBarStyles}
                items={isSmallDevice ? [] : _items}
                overflowItems={isSmallDevice ? _items : []}
                farItems={_farItems}
                overflowButtonProps={overflowButtonProps}
            />
            {isCalloutVisible && (
                <Callout
                    gapSpace={0}
                    target={`button.account-button`}
                    onDismiss={toggleIsCalloutVisible}
                    setInitialFocus
                    calloutWidth={300}
                    styles={{
                        root: {
                            padding: 15,
                        }
                    }}
                >
                    <Stack grow tokens={stackTokens}>
                        <Stack styles={{ root: { marginLeft: 10 } }}>
                            <Stack horizontal horizontalAlign='space-between' verticalAlign='center'>
                                <Text variant='large' styles={{ root: { fontWeight: FontWeights.bold } }}>{site?.name}</Text>
                                {!user && <CommandButton text='login' onClick={onClickLogin} />}
                                {user && <CommandButton text='logout' onClick={onClickLogout} />}
                            </Stack>

                            <Text variant='large' styles={{ root: { fontWeight: FontWeights.bold } }}>
                                {user?.given_name?.toUpperCase()} {user?.family_name?.toUpperCase()}
                            </Text>
                            <Text title={user?.name} styles={{ root: { width: 250 } }} block nowrap>{user?.name}</Text>
                        </Stack>
                        <CommandButton text='Switch site...' onClick={onClickSwitchSite} />
                    </Stack>
                </Callout>
            )}
        </header>
    );
};
