import { IBreadcrumbItem } from "@fluentui/react/lib/Breadcrumb";
import { IStackStyles, IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import React, { useCallback, useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { Debug } from "../../../components/Debug";
import { NavigationPane } from "../../../components/NavigationPane";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { TextField } from "@fluentui/react/lib/TextField";
import { getDisplayValue, isEmpty } from "../../../tools";
import { IActiveStatus, IGroup, IGroupFlowRole, IGroupPermission, ISaveResponse } from "../../../models";
import { GroupService } from "../../../services";
import { ChoiceGroup, IChoiceGroupOption, IChoiceGroupOptionStyles, IChoiceGroupStyles } from "@fluentui/react/lib/ChoiceGroup";
import { listBreadcrumbItems } from "./GroupList";
import { useMemo } from "react";
import { cloneDeep } from "lodash";
import { AppContext } from "../../../contexts";
import { DialogType } from "@fluentui/react/lib/Dialog";
import { ITextStyles, Text } from "@fluentui/react/lib/Text";
import { Label } from "@fluentui/react/lib/Label";
import { DefaultEffects, useTheme } from "@fluentui/react/lib/Theme";
import { Pivot, PivotItem } from "@fluentui/react/lib/Pivot";
import { UserList } from "./UserList";
import { MessageBar, MessageBarType } from "@fluentui/react/lib/MessageBar";
import { useBoolean } from "@fluentui/react-hooks";
import { OverlayLoading } from "../../../components/OverlayLoading";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";

export type IFormMode = 'new' | 'edit' | 'readonly';

export interface IGroupValues {
    mode: IFormMode;
    data: IGroup;
}

const stackTokens: IStackTokens = { childrenGap: 10 };

const containerStackStyles: IStackStyles = {
    root: {
        paddingTop: 20,
        paddingBottom: 20,
    },
};

const permissionChoiceGroupStyles: Partial<IChoiceGroupStyles> = {
    flexContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
}

const choiceGroupOptionStyles: Partial<IChoiceGroupOptionStyles> = {
    choiceFieldWrapper: {
        width: '100%',
    }
};

const statusOptions: IChoiceGroupOption[] = [
    { key: 'active', text: getDisplayValue('active') },
    { key: 'inactive', text: getDisplayValue('inactive') },
];

const permissionOptions: IChoiceGroupOption[] = [
    { key: 'yes', text: '', styles: { root: { marginTop: 0 } } },
    { key: 'no', text: '', styles: { root: { marginTop: 0 } } },
];

const initialValues: IGroupValues = {
    mode: 'readonly',
    data: {
        id: null,
        key: '-1',
        name: null,
        status: 'active',
        flowRole: 'user',
        permission: {
            // Default permission
            requestToView: true,
            requestToPrint: true,

            // Frontend features
            abilityToSearch: true,
            viewWithoutRequest: false,
            printWithoutRequest: false,
            isClgStaff: false,

            // Report
            requestReport: false,
            userReport: false,
            documentReport: false,
            activitiesLogReport: false,
            expirationReport: false,

            // Backend
            documentManagement: false,
            requestManagement: false,
            dataTypeManagement: false,
            templateManagement: false,
            userManagement: false,
            emailTemplateManagement: false,
            downloadDocument: false,
        },
    }
};

export const GroupForm: React.FunctionComponent<RouteComponentProps<{ site: string, id: string }>> = (props) => {
    const theme = useTheme();
    const { profile, showDialog } = useContext(AppContext);
    const [values, setValues] = useState<IGroupValues>(cloneDeep(initialValues));
    const [originalValues, setOriginalValues] = useState<IGroup | undefined>(undefined);
    const [isLoading, { setFalse: hideLoading, setTrue: showLoading }] = useBoolean(false);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;
    const isLargeDevice = modalResponsiveMode === ResponsiveMode.large || modalResponsiveMode === ResponsiveMode.xLarge;

    const service = useMemo(() => new GroupService(), []);
    const { match: { params: { site, id } }, history, location: { state } } = props;
    const readonly = values.mode === 'readonly';

    useEffect(() => {
        let isMounted = true;

        if (!id || id === 'new') {
            setValues({
                ...cloneDeep(initialValues),
                mode: 'new',
            });
            return;
        }

        const promise = service.get({ id });
        promise.then(data => {
            if (!isMounted || !data || !data.id) {
                setValues({ ...cloneDeep(initialValues), mode: 'readonly' });
                setOriginalValues(undefined);
                return;
            }

            setValues({
                ...cloneDeep(initialValues),
                data,
                mode: 'edit',
            });

            setOriginalValues(cloneDeep(data));
        });

        return () => {
            setValues(cloneDeep(initialValues));
            setOriginalValues(undefined);
        };
    }, [id, service, profile]);

    const listLink = `/site/${site}/administrator/user/groupuser`;

    const onClickListLink = (ev?: React.MouseEvent<HTMLElement>) => {
        ev?.preventDefault();
        history.push(listLink, { from: 'form', values: state });
    };

    const breadcrumbItems: IBreadcrumbItem[] = [
        { ...listBreadcrumbItems, href: listLink, onClick: onClickListLink },
        {
            key: 'form',
            text: originalValues?.name || (values.mode === 'new' ? 'New' : '...'),
        }
    ];

    const onChangeTextField = useCallback(<K extends keyof IGroup>(newValue: IGroup[K] | null, fieldName: K) => {
        const newValues: IGroupValues = { ...values };
        newValues.data[fieldName] = newValue as IGroup[K];
        setValues(newValues);
    }, [values]);

    const onChangeChoiceField = <K extends keyof IGroup>(newValue?: IGroup[K] | null, fieldName?: K) => {
        if (!fieldName) {
            return;
        }

        const newValues: IGroupValues = { ...values };
        newValues.data[fieldName] = newValue as IGroup[K];
        setValues(newValues);
    };

    const onChangePermission = <K extends keyof IGroupPermission>(newValue?: IGroupPermission[K], permissionName?: K) => {
        if (!permissionName) {
            return;
        }

        const data = { ...values.data };
        const permission = data.permission!;
        permission[permissionName] = newValue as IGroupPermission[K];
        data.permission = permission;
        setValues(prev => ({ ...prev, data: data }));
    };

    const onClickReset = () => {
        setValues({ ...values, data: cloneDeep(originalValues!) });
    };

    const onClickCancel = () => {
        onClickListLink();
    };

    const onSaveResponse = (action: string, saved: ISaveResponse<IGroup>, isBackToList: boolean = true) => {
        if (saved.isError) {
            showDialog({
                title: `${action} failed`,
                dialogContentProps: {
                    type: DialogType.normal,
                }
            }, () => {
                return (
                    <>
                        {saved.errorMessage &&
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {saved.errorMessage}
                            </MessageBar>
                        }
                        {saved.errorMessages && saved.errorMessages.map((err, i) => (
                            <MessageBar key={i}
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {err}
                            </MessageBar>
                        ))}
                    </>
                );
            });

            return;
        } else {
            onClickListLink();
        }
    };

    const onValidateForm = () => {
        const errorMessages: string[] = [];
        if (!values.data.name) {
            errorMessages.push('Name is required');
        }

        if (!isEmpty(errorMessages)) {
            showDialog({
                title: `Invalid form`,
                dialogContentProps: {
                    type: DialogType.normal,
                }
            }, () => {
                return (
                    <>
                        {errorMessages && errorMessages.map((err, i) => (
                            <MessageBar key={i}
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {err}
                            </MessageBar>
                        ))}
                    </>
                );
            });

            return false;
        }

        return true;
    };

    const onClickSave = async () => {
        if (!onValidateForm()) {
            return;
        }

        showLoading();

        if (values.mode === 'new') {
            const data = { ...values.data, site: profile?.site }
            await service.create(data, saved => onSaveResponse('Save data', saved));
        }

        if (values.mode === 'edit') {
            await service.update(id, values.data, saved => onSaveResponse('Save data', saved));
        }

        hideLoading();
    };

    const permissionStackStyles: Partial<IStackStyles> = {
        root: {
            borderBottom: `1px solid ${theme?.palette.neutralQuaternaryAlt}`,
            height: 35,
            paddingLeft: 15
        }
    };

    const sectionHeaderTextStyles: Partial<ITextStyles> = {
        root: {
            color: theme.palette.themePrimary,
            padding: '0 4px 0 4px'
        }
    };

    const permission = values.data.permission!;
    const userCount = values.data.userCount || 0;

    const flowRoleOptions: IChoiceGroupOption[] = [
        {
            key: 'admin-verify',
            text: '',
            styles: choiceGroupOptionStyles,
            onRenderField: (props, render) => {
                return (
                    <Stack
                        tokens={stackTokens}
                        horizontal
                        verticalAlign='center'
                        horizontalAlign='space-between'
                        styles={permissionStackStyles}
                    >
                        <Stack>
                            <Text>{getDisplayValue('admin-verify')}</Text>
                        </Stack>
                        <Stack styles={{ root: { width: 100 } }} horizontalAlign='center'>
                            {render!(props)}
                        </Stack>
                    </Stack>
                );
            },
        },
        {
            key: 'clg-vp',
            text: '',
            styles: choiceGroupOptionStyles,
            onRenderField: (props, render) => {
                return (
                    <Stack
                        tokens={stackTokens}
                        horizontal
                        verticalAlign='center'
                        horizontalAlign='space-between'
                        styles={permissionStackStyles}
                    >
                        <Stack>
                            <Text>{getDisplayValue('clg-vp')}</Text>
                        </Stack>
                        <Stack styles={{ root: { width: 100 } }} horizontalAlign='center'>
                            {render!(props)}
                        </Stack>
                    </Stack>
                );
            },
        },
        {
            key: 'user',
            text: '',
            styles: choiceGroupOptionStyles,
            onRenderField: (props, render) => {
                return (
                    <Stack
                        tokens={stackTokens}
                        horizontal
                        verticalAlign='center'
                        horizontalAlign='space-between'
                        styles={permissionStackStyles}
                    >
                        <Stack><Text>{getDisplayValue('user')}</Text></Stack>
                        <Stack styles={{ root: { width: 100 } }} horizontalAlign='center'>
                            {render!(props)}
                        </Stack>
                    </Stack>
                );
            },
        },
    ];

    const choiceWidth = isSmallDevice ? 100 : 200;

    return (
        <Stack tokens={stackTokens}>
            <NavigationPane items={breadcrumbItems || []} />
            <Pivot>
                <PivotItem headerText="Group detail">
                    <Stack horizontal wrap={isSmallDevice} tokens={stackTokens} styles={containerStackStyles}>
                        <Stack tokens={stackTokens} styles={{ root: { minWidth: isSmallDevice ? '100%' : isLargeDevice ? '80%' : `1000px` } }}>
                            <Stack styles={{ root: { maxWidth: isSmallDevice ? '100%' : 500 } }}>
                                <TextField
                                    label='Name'
                                    disabled={readonly}
                                    value={values.data.name || ''}
                                    onChange={(e, value) => onChangeTextField(value || null, 'name')}
                                    required
                                />
                            </Stack>

                            <Stack horizontal styles={{ root: { boxShadow: DefaultEffects.elevation4, height: 44, padding: '0 4px 0 4px' } }} verticalAlign='center'>
                                <Stack grow>
                                    <Label>Role</Label>
                                </Stack>
                                <Stack horizontal horizontalAlign='center' styles={{ root: { width: 100 } }}>
                                    <Label>Select</Label>
                                </Stack>
                            </Stack>

                            <Stack>
                                <ChoiceGroup
                                    selectedKey={values.data.flowRole || 'user'}
                                    options={flowRoleOptions}
                                    disabled={readonly}
                                    onChange={(e, o) => onChangeChoiceField(o?.key as IGroupFlowRole, 'flowRole')}
                                />
                            </Stack>
                            <Stack horizontal styles={{ root: { boxShadow: DefaultEffects.elevation4, height: 44, padding: '0 4px 0 4px' } }} verticalAlign='center'>
                                <Stack grow>
                                    <Label>Menu / Features</Label>
                                </Stack>
                                <Stack horizontal horizontalAlign='space-around' styles={{ root: { width: choiceWidth } }}>
                                    <Label>Yes</Label>
                                    <Label>No</Label>
                                </Stack>
                            </Stack>

                            <Text styles={sectionHeaderTextStyles}>Frontend Features</Text>

                            <Stack horizontal styles={permissionStackStyles} verticalAlign='center'>
                                <Stack grow>
                                    <Text>{getDisplayValue('viewWithoutRequest')}</Text>
                                </Stack>
                                <Stack styles={{ root: { width: choiceWidth } }}>
                                    <ChoiceGroup
                                        selectedKey={permission.viewWithoutRequest ? 'yes' : 'no'}
                                        options={permissionOptions}
                                        disabled={readonly}
                                        styles={permissionChoiceGroupStyles}
                                        onChange={(e, o) => onChangePermission((o && o.key === 'yes') ? true : false, 'viewWithoutRequest')}
                                    />
                                </Stack>
                            </Stack>
                            <Stack horizontal styles={permissionStackStyles} verticalAlign='center'>
                                <Stack grow>
                                    <Text>{getDisplayValue('printWithoutRequest')}</Text>
                                </Stack>
                                <Stack styles={{ root: { width: choiceWidth } }}>
                                    <ChoiceGroup
                                        selectedKey={permission.printWithoutRequest ? 'yes' : 'no'}
                                        options={permissionOptions}
                                        disabled={readonly}
                                        styles={permissionChoiceGroupStyles}
                                        onChange={(e, o) => onChangePermission((o && o.key === 'yes') ? true : false, 'printWithoutRequest')}
                                    />
                                </Stack>
                            </Stack>
                            <Stack horizontal styles={permissionStackStyles} verticalAlign='center'>
                                <Stack grow>
                                    <Text>{getDisplayValue('downloadDocument')}</Text>
                                </Stack>
                                <Stack styles={{ root: { width: choiceWidth } }}>
                                    <ChoiceGroup
                                        selectedKey={permission.downloadDocument ? 'yes' : 'no'}
                                        options={permissionOptions}
                                        disabled={readonly}
                                        styles={permissionChoiceGroupStyles}
                                        onChange={(e, o) => onChangePermission((o && o.key === 'yes') ? true : false, 'downloadDocument')}
                                    />
                                </Stack>
                            </Stack>
                            <Stack horizontal styles={permissionStackStyles} verticalAlign='center'>
                                <Stack grow>
                                    <Text>{getDisplayValue('isClgStaff')}</Text>
                                </Stack>
                                <Stack styles={{ root: { width: choiceWidth } }}>
                                    <ChoiceGroup
                                        selectedKey={permission.isClgStaff ? 'yes' : 'no'}
                                        options={permissionOptions}
                                        disabled={readonly}
                                        styles={permissionChoiceGroupStyles}
                                        onChange={(e, o) => onChangePermission((o && o.key === 'yes') ? true : false, 'isClgStaff')}
                                    />
                                </Stack>
                            </Stack>

                            <Text styles={sectionHeaderTextStyles}>Report</Text>

                            <Stack horizontal styles={permissionStackStyles} verticalAlign='center'>
                                <Stack grow>
                                    <Text>{getDisplayValue('requestReport')}</Text>
                                </Stack>
                                <Stack styles={{ root: { width: choiceWidth } }}>
                                    <ChoiceGroup
                                        selectedKey={permission.requestReport ? 'yes' : 'no'}
                                        options={permissionOptions}
                                        disabled={readonly}
                                        styles={permissionChoiceGroupStyles}
                                        onChange={(e, o) => onChangePermission((o && o.key === 'yes') ? true : false, 'requestReport')}
                                    />
                                </Stack>
                            </Stack>
                            <Stack horizontal styles={permissionStackStyles} verticalAlign='center'>
                                <Stack grow>
                                    <Text>{getDisplayValue('userReport')}</Text>
                                </Stack>
                                <Stack styles={{ root: { width: choiceWidth } }}>
                                    <ChoiceGroup
                                        selectedKey={permission.userReport ? 'yes' : 'no'}
                                        options={permissionOptions}
                                        disabled={readonly}
                                        styles={permissionChoiceGroupStyles}
                                        onChange={(e, o) => onChangePermission((o && o.key === 'yes') ? true : false, 'userReport')}
                                    />
                                </Stack>
                            </Stack>
                            <Stack horizontal styles={permissionStackStyles} verticalAlign='center'>
                                <Stack grow>
                                    <Text>{getDisplayValue('documentReport')}</Text>
                                </Stack>
                                <Stack styles={{ root: { width: choiceWidth } }}>
                                    <ChoiceGroup
                                        selectedKey={permission.documentReport ? 'yes' : 'no'}
                                        options={permissionOptions}
                                        disabled={readonly}
                                        styles={permissionChoiceGroupStyles}
                                        onChange={(e, o) => onChangePermission((o && o.key === 'yes') ? true : false, 'documentReport')}
                                    />
                                </Stack>
                            </Stack>
                            <Stack horizontal styles={permissionStackStyles} verticalAlign='center'>
                                <Stack grow>
                                    <Text>{getDisplayValue('activitiesLogReport')}</Text>
                                </Stack>
                                <Stack styles={{ root: { width: choiceWidth } }}>
                                    <ChoiceGroup
                                        selectedKey={permission.activitiesLogReport ? 'yes' : 'no'}
                                        options={permissionOptions}
                                        disabled={readonly}
                                        styles={permissionChoiceGroupStyles}
                                        onChange={(e, o) => onChangePermission((o && o.key === 'yes') ? true : false, 'activitiesLogReport')}
                                    />
                                </Stack>
                            </Stack>
                            <Stack horizontal styles={permissionStackStyles} verticalAlign='center'>
                                <Stack grow>
                                    <Text>{getDisplayValue('expirationReport')}</Text>
                                </Stack>
                                <Stack styles={{ root: { width: choiceWidth } }}>
                                    <ChoiceGroup
                                        selectedKey={permission.expirationReport ? 'yes' : 'no'}
                                        options={permissionOptions}
                                        disabled={readonly}
                                        styles={permissionChoiceGroupStyles}
                                        onChange={(e, o) => onChangePermission((o && o.key === 'yes') ? true : false, 'expirationReport')}
                                    />
                                </Stack>
                            </Stack>

                            <Text styles={sectionHeaderTextStyles}>Backend</Text>

                            <Stack horizontal styles={permissionStackStyles} verticalAlign='center'>
                                <Stack grow>
                                    <Text>{getDisplayValue('documentManagement')}</Text>
                                </Stack>
                                <Stack styles={{ root: { width: choiceWidth } }}>
                                    <ChoiceGroup
                                        selectedKey={permission.documentManagement ? 'yes' : 'no'}
                                        options={permissionOptions}
                                        disabled={readonly}
                                        styles={permissionChoiceGroupStyles}
                                        onChange={(e, o) => onChangePermission((o && o.key === 'yes') ? true : false, 'documentManagement')}
                                    />
                                </Stack>
                            </Stack>
                            <Stack horizontal styles={permissionStackStyles} verticalAlign='center'>
                                <Stack grow>
                                    <Text>{getDisplayValue('requestManagement')}</Text>
                                </Stack>
                                <Stack styles={{ root: { width: choiceWidth } }}>
                                    <ChoiceGroup
                                        selectedKey={permission.requestManagement ? 'yes' : 'no'}
                                        options={permissionOptions}
                                        disabled={readonly}
                                        styles={permissionChoiceGroupStyles}
                                        onChange={(e, o) => onChangePermission((o && o.key === 'yes') ? true : false, 'requestManagement')}
                                    />
                                </Stack>
                            </Stack>
                            <Stack horizontal styles={permissionStackStyles} verticalAlign='center'>
                                <Stack grow>
                                    <Text>{getDisplayValue('templateManagement')}</Text>
                                </Stack>
                                <Stack styles={{ root: { width: choiceWidth } }}>
                                    <ChoiceGroup
                                        selectedKey={permission.templateManagement ? 'yes' : 'no'}
                                        options={permissionOptions}
                                        disabled={readonly}
                                        styles={permissionChoiceGroupStyles}
                                        onChange={(e, o) => onChangePermission((o && o.key === 'yes') ? true : false, 'templateManagement')}
                                    />
                                </Stack>
                            </Stack>
                            <Stack horizontal styles={permissionStackStyles} verticalAlign='center'>
                                <Stack grow>
                                    <Text>{getDisplayValue('dataTypeManagement')}</Text>
                                </Stack>
                                <Stack styles={{ root: { width: choiceWidth } }}>
                                    <ChoiceGroup
                                        selectedKey={permission.dataTypeManagement ? 'yes' : 'no'}
                                        options={permissionOptions}
                                        disabled={readonly}
                                        styles={permissionChoiceGroupStyles}
                                        onChange={(e, o) => onChangePermission((o && o.key === 'yes') ? true : false, 'dataTypeManagement')}
                                    />
                                </Stack>
                            </Stack>
                            <Stack horizontal styles={permissionStackStyles} verticalAlign='center'>
                                <Stack grow>
                                    <Text>{getDisplayValue('userManagement')}</Text>
                                </Stack>
                                <Stack styles={{ root: { width: choiceWidth } }}>
                                    <ChoiceGroup
                                        selectedKey={permission.userManagement ? 'yes' : 'no'}
                                        options={permissionOptions}
                                        disabled={readonly}
                                        styles={permissionChoiceGroupStyles}
                                        onChange={(e, o) => onChangePermission((o && o.key === 'yes') ? true : false, 'userManagement')}
                                    />
                                </Stack>
                            </Stack>
                            <Stack horizontal styles={permissionStackStyles} verticalAlign='center'>
                                <Stack grow>
                                    <Text>{getDisplayValue('emailTemplateManagement')}</Text>
                                </Stack>
                                <Stack styles={{ root: { width: choiceWidth } }}>
                                    <ChoiceGroup
                                        selectedKey={permission.emailTemplateManagement ? 'yes' : 'no'}
                                        options={permissionOptions}
                                        disabled={readonly}
                                        styles={permissionChoiceGroupStyles}
                                        onChange={(e, o) => onChangePermission((o && o.key === 'yes') ? true : false, 'emailTemplateManagement')}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack >
                            <ChoiceGroup
                                selectedKey={values.data.status || 'active'}
                                options={statusOptions}
                                disabled={readonly}
                                label="Status"
                                onChange={(e, o) => onChangeChoiceField(o?.key as IActiveStatus, 'status')}
                            />
                        </Stack>
                    </Stack>
                </PivotItem>
                {userCount &&
                    <PivotItem headerText={`Users (${userCount})`}>
                        <Stack horizontal wrap={isSmallDevice || isLargeDevice} tokens={stackTokens} styles={containerStackStyles}>
                            <UserList {...props}
                                defaultValues={{
                                    group: values.data,
                                    status: 'all',
                                }}
                                hideGroup
                                hideStatus
                            />
                        </Stack>
                    </PivotItem>
                }
            </Pivot>

            <Stack horizontal tokens={stackTokens}>
                {!readonly && <PrimaryButton text='Save' onClick={onClickSave} />}
                {values.mode === 'edit' && <DefaultButton text='Reset' onClick={onClickReset} />}
                <DefaultButton text='Cancel' onClick={onClickCancel} />
            </Stack>

            <Debug object={values} />

            <OverlayLoading isVisibled={isLoading} />
        </Stack>
    )
};