import { Theme } from "@fluentui/react/lib/Theme";
import { IApproveStatus, IApproveStatusKey, IRequestStatusKeys, IRequestStatusValues } from "../models";

export const getRequestStatusColor = (theme: Theme, status: IRequestStatusKeys | IRequestStatusValues | null) => {
    switch (status) {
        case 'draft':
        case 'Draft':
            return theme.palette.neutralTertiary;
        case 'waiting-vp-approve':
        case 'Waiting VP/SVP/EVP Approve':
        case 'waiting-admin-verify':
        case 'Waiting Admin Verify':
        case 'waiting-clgvp-approve':
        case 'Waiting Legal VP Approve':
            return theme.palette.yellow;
        case 'approved':
        case 'Approved':
            return theme.semanticColors.successIcon;
        case 'rejected':
        case 'Rejected':
            return theme.semanticColors.errorIcon;
        case 'inactive':
        case 'Inactive':
        case 'expired':
        case 'Expired':
        case 'delete':
        case 'Delete':
            return theme.palette.neutralSecondaryAlt;
        default:
            break;
    }
    return '';
};

export const getApproveStatusColor = (theme: Theme, status: IApproveStatusKey | IApproveStatus) => {
    switch (status) {
        case 'waiting-for-approval':
        case 'Waiting for approval':
        case 'waiting-for-verify':
        case 'Waiting for verify':
            return theme.palette.yellow;
        case 'approved':
        case 'Approved':
        case 'verified':
        case 'Verified':
            return theme.semanticColors.successIcon;
        case 'rejected':
        case 'Rejected':
            return theme.semanticColors.errorIcon;
        default:
            break;
    }
    return '';
};

export const getDaysColor = (theme: Theme, day: number) => {
    if (day >= 20) {
        return theme.semanticColors.errorIcon;
    }

    if (day >= 10) {
        return theme.palette.yellow;
    }

    return '';
};