import { PrimaryButton } from "@fluentui/react/lib/Button";
import { IStackProps, IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { IDataType } from "../models";
import { isEmpty, now } from "../tools";
import { DataTypeMultipleFilter, IDataTypeFilterItem } from "./DataTypeMultipleFilter";
import { IPickerItem } from "./picker";

export interface IFilterValues {
    [type: string]: string[];
}

export interface IDataTypeFilterSetProps {
    stackProps?: IStackProps;
    onChange?: (values: IFilterValues | null) => void;
    renderButtonOptional?: () => JSX.Element;
}

const stackTokens: IStackTokens = { childrenGap: 10 };

export const DataTypeFilterSet: React.FunctionComponent<IDataTypeFilterSetProps> = (props) => {
    const [filterNames, setFilterNames] = useState<string[]>([]);
    const [removedFilterNames, setRemovedFilterNames] = useState<string[]>([]);
    const [filterValues, setFilterValues] = useState<IFilterValues | null>();

    const selectedDataTypeKeys = filterValues ? Object.keys(filterValues).map(k => k) : [];

    const { stackProps, onChange, renderButtonOptional } = props;

    useEffect(() => {
        return () => {
            setFilterValues(undefined);
            setFilterNames([]);
            setRemovedFilterNames([]);
        }
    }, []);

    const _updateValues = (values: IFilterValues | null) => {
        values = values && Object.keys(values).length ? values : null;
        setFilterValues(values);

        if (onChange) {
            onChange(values);
        }
    }

    const onAddFilters = () => {
        const name = now().join('-');
        setFilterNames([...filterNames, `filter-${name}`]);
    };

    const onRemoveFilter = (filterItem: IDataTypeFilterItem) => {
        setRemovedFilterNames([...removedFilterNames, filterItem.name]);

        if (!filterValues || !filterItem || !filterItem.typeItem) {
            return;
        }

        let newFilters = { ...filterValues };
        if (newFilters[filterItem.typeItem.key]) {
            delete newFilters[filterItem.typeItem.key];
        }

        _updateValues(newFilters);

        if (onChange) {
            onChange(Object.keys(newFilters).length ? newFilters : null)
        }
    };

    const onFilterChange = (filterItem: IDataTypeFilterItem, shouleRemove?: boolean, prevTypeItem?: IPickerItem<IDataType>) => {
        if (!filterItem.typeItem && !prevTypeItem) {
            return;
        }

        const values = filterValues ? cloneDeep(filterValues) : {};
        if (!filterItem.typeItem && prevTypeItem && values[prevTypeItem.data!.id!]) {
            delete values[prevTypeItem.data!.id!];
            _updateValues(values);
            return;
        }

        if (filterItem.typeItem && !values[filterItem.typeItem.data!.id!]) {
            values[filterItem.typeItem.data!.id!] = [];
        }

        values[filterItem.typeItem!.data!.id!] = filterItem.nameItems && !isEmpty(filterItem.nameItems)
            ? filterItem.nameItems.map(item => item.data!.id!)
            : [];

        _updateValues(values);
    }

    const onRenderFilters = (filterName: string, index: number) => {
        return (
            <DataTypeMultipleFilter
                key={filterName}
                name={filterName}
                stackProps={{ ...stackProps }}
                onRemove={onRemoveFilter}
                onChange={onFilterChange}
                excludeTypeKeys={selectedDataTypeKeys}
                index={index}
            />
        );
    };

    const filters = filterNames ? filterNames.filter(fn => !removedFilterNames.some(n => n === fn)) : [];

    return (
        <Stack tokens={stackTokens}>
            {filters.map((n, i) => onRenderFilters(n, i))}
            <Stack horizontal tokens={stackTokens}>
                <PrimaryButton text="Add filters" onClick={onAddFilters} />
                {renderButtonOptional && renderButtonOptional()}
            </Stack>
        </Stack>
    );
};