import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router";
import { PageContainer } from "../../../components/PageContainer";
import { preparePath } from "../../../tools";
import { DataNameForm } from "./DataNameForm";
import { DataNameList } from "./DataNameList";
import { DataTypeForm } from "./DataTypeForm";
import { DataTypeList } from "./DataTypeList";

export const DataType: React.FunctionComponent<RouteComponentProps> = props => {
    const { match: { path }, location: { pathname } } = props;

    return (
        <PageContainer center={false} >
            <Switch>
                <Route exact path={path} component={DataTypeList} />
                <Route path={`${path}/new`} component={DataTypeForm} exact />
                <Route path={`${path}/edit/:id`} component={DataTypeForm} />
                <Route exact path={`${path}/:typeId`} render={url => <Redirect to={`${preparePath(pathname)}/dataname`} />} />
                <Route exact path={`${path}/:typeId/dataname`} component={DataNameList} />
                <Route path={`${path}/:typeId/dataname/new`} component={DataNameForm} />
                <Route path={`${path}/:typeId/dataname/:id`} component={DataNameForm} />
            </Switch>
        </PageContainer>
    );
};