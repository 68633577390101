import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import { PageContainer } from "../../../components/PageContainer";
import { DocumentForm } from "./DocumentForm";
import { DocumentList } from "./DocumentList";

export const Documents: React.FunctionComponent<RouteComponentProps> = props => {
    const { match: { path } } = props;

    return (
        <PageContainer center={false} >
            <Switch>
                <Route exact path={path} component={DocumentList} />
                <Route path={`${path}/new`} component={DocumentForm} />
                <Route path={`${path}/:id`} component={DocumentForm} />
            </Switch>
        </PageContainer>
    );
};