import { IActiveStatus, IEmployee, ISaveResponse, ISearchParameters, ISiteAdmin } from "../../../models";
import React, { useContext, useEffect, useRef, useState } from "react";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react/lib/ChoiceGroup";
import { getDisplayValue, isEmpty } from "../../../tools";
import { RouteComponentProps } from "react-router";
import { useMemo } from "react";
import { EmployeeService, SiteAdminService } from "../../../services";
import { cloneDeep } from "lodash";
import { IBreadcrumbItem } from "@fluentui/react/lib/Breadcrumb";
import { listBreadcrumbItems } from "./SiteAdminList";
import { NavigationPane } from "../../../components/NavigationPane";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { Debug } from "../../../components/Debug";
import { IPickerItem, Picker } from "../../../components/picker";
import { AppContext } from "../../../contexts";
import { DialogType } from "@fluentui/react/lib/Dialog";
import { MessageBar, MessageBarType } from "@fluentui/react/lib/MessageBar";
import { useBoolean } from "@fluentui/react-hooks";
import { OverlayLoading } from "../../../components/OverlayLoading";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";

export type IFormMode = 'new' | 'edit' | 'readonly';

export interface ISiteAdminValues {
    mode: IFormMode;
    data: ISiteAdmin;
}

const stackTokens: IStackTokens = { childrenGap: 10 };

const statusOptions: IChoiceGroupOption[] = [
    { key: 'active', text: getDisplayValue('active') },
    { key: 'inactive', text: getDisplayValue('inactive') },
];

const initialValues: ISiteAdminValues = {
    mode: 'readonly',
    data: {
        id: null,
        key: '-1',
        name: null,
        employee: null,
        status: 'active',
    }
};

export const SiteAdminForm: React.FunctionComponent<RouteComponentProps<{ site: string, id: string }>> = (props) => {
    const { showDialog } = useContext(AppContext);
    const [values, setValues] = useState<ISiteAdminValues>(initialValues);
    const [originalValues, setOriginalValues] = useState<ISiteAdmin | undefined>(undefined);
    const [isLoading, { setFalse: hideLoading, setTrue: showLoading }] = useBoolean(false);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;
    const isLargeDevice = modalResponsiveMode === ResponsiveMode.large || modalResponsiveMode === ResponsiveMode.xLarge;

    const service = useMemo(() => new SiteAdminService(), []);
    const employeeService = useMemo(() => new EmployeeService(), []);
    const { match: { params: { site, id } }, history, location: { state } } = props;
    const listLink = `/site/${site}/sitesetup/siteadmin`;
    const readonly = values.mode === 'readonly';
    const name = originalValues ? originalValues.employee?.name : '';
    const selectedEmployee: IPickerItem<IEmployee> | null = values.data.employee
        ? { key: values.data.employee.id!, name: values.data.employee.name!, data: values.data.employee }
        : null;

    useEffect(() => {
        let isMounted = true;

        if (!id || id === 'new') {
            setValues({
                ...cloneDeep(initialValues),
                mode: 'new',
            });
            return;
        }

        const promise = service.get({ id });
        promise.then(data => {
            if (!isMounted || !data || !data.id) {
                setValues({ ...cloneDeep(initialValues), mode: 'readonly' });
                setOriginalValues(undefined);
                return;
            }

            setValues({
                ...cloneDeep(initialValues),
                data,
                mode: 'edit',
            });

            setOriginalValues({ ...data });
        });

        return () => {
            setValues(cloneDeep(initialValues));
            setOriginalValues(undefined);
        };
    }, [id, service]);

    const onClickListLink = (ev?: React.MouseEvent<HTMLElement>) => {
        ev?.preventDefault();
        history.push(listLink, { from: 'form', values: state });
    };

    const breadcrumbItems: IBreadcrumbItem[] = [
        { ...listBreadcrumbItems, href: listLink, onClick: onClickListLink },
        {
            key: values.mode,
            text: name || (values.mode === 'new' ? 'New' : '...'),
        }
    ];

    const onChangeChoiceField = <K extends keyof ISiteAdmin>(newValue?: ISiteAdmin[K] | null, fieldName?: K) => {
        if (!fieldName) {
            return;
        }

        const newValues: ISiteAdminValues = { ...values };
        newValues.data[fieldName] = newValue as ISiteAdmin[K];
        setValues(newValues);
    };

    const onEmployeeChange = (employee?: IEmployee | null) => {
        const data = { ...values.data };
        if (employee) {
            setValues(prev => ({
                ...prev,
                data: {
                    ...data,
                    name: employee.name,
                    employee: employee,
                }
            }));
        } else {
            setValues(prev => ({
                ...prev,
                data: {
                    ...data,
                    name: null,
                    employee: null,
                }
            }));
        }
    };

    const onClickReset = () => {
        if (!originalValues) {
            return;
        }

        setValues({ ...values, data: originalValues });
    };

    const onClickCancel = () => {
        onClickListLink();
    };

    const onSaveResponse = (action: string, saved: ISaveResponse<ISiteAdmin>, isBackToList: boolean = true) => {
        if (saved.isError) {
            showDialog({
                title: `${action} failed`,
                dialogContentProps: {
                    type: DialogType.normal,
                }
            }, () => {
                return (
                    <>
                        {saved.errorMessage &&
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {saved.errorMessage}
                            </MessageBar>
                        }
                        {saved.errorMessages && saved.errorMessages.map((err, i) => (
                            <MessageBar key={i}
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {err}
                            </MessageBar>
                        ))}
                    </>
                );
            });

            return;
        } else {
            onClickListLink();
        }
    };

    const onValidateForm = () => {
        const errorMessages: string[] = [];
        if (!values.data.employee) {
            errorMessages.push('User name is required');
        }

        if (!isEmpty(errorMessages)) {
            showDialog({
                title: `Invalid form`,
                dialogContentProps: {
                    type: DialogType.normal,
                }
            }, () => {
                return (
                    <>
                        {errorMessages && errorMessages.map((err, i) => (
                            <MessageBar key={i}
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {err}
                            </MessageBar>
                        ))}
                    </>
                );
            });

            return false;
        }

        return true;
    };

    const onClickSave = async () => {
        if (!onValidateForm()) {
            return;
        }

        showLoading();

        if (values.mode === 'new') {
            await service.create(values.data, saved => onSaveResponse('Save data', saved));
        }

        if (values.mode === 'edit') {
            await service.update(id, values.data, saved => onSaveResponse('Save data', saved));
        }

        hideLoading();
    };

    const onMapEmployeeFilter = (filter: string, defaultFilter: ISearchParameters): ISearchParameters => {
        return {
            ...defaultFilter,
            excludeSiteAdmin: true,
        };
    };

    return (
        <Stack tokens={stackTokens}>
            <NavigationPane items={breadcrumbItems || []} />

            <Stack horizontal wrap={isSmallDevice} tokens={stackTokens} >
                <Stack tokens={stackTokens} styles={{ root: { minWidth: isSmallDevice ? '100%' : isLargeDevice ? '80%' : '1000px' } }}>
                    <Stack styles={{ root: { maxWidth: isSmallDevice ? '100%' : 500 } }}>
                        <Picker
                            pickOnlyOne
                            service={employeeService}
                            onMapFilter={onMapEmployeeFilter}
                            label='User name'
                            placeholder='Search by ID , Name'
                            selectedItems={selectedEmployee ? [selectedEmployee] : []}
                            onChange={data => onEmployeeChange((data && data[0]) ? data[0].data : null)}
                            required
                            minimumTextLength={3}
                        />
                    </Stack>
                </Stack>
                <Stack >
                    <ChoiceGroup
                        selectedKey={values.data.status || 'active'}
                        options={statusOptions}
                        disabled={readonly}
                        label="Status"
                        onChange={(e, o) => onChangeChoiceField(o?.key as IActiveStatus, 'status')}
                    />
                </Stack>
            </Stack>

            <Stack horizontal tokens={stackTokens}>
                {!readonly && <PrimaryButton text='Save' onClick={onClickSave} />}
                {values.mode === 'edit' && <DefaultButton text='Reset' onClick={onClickReset} />}
                <DefaultButton text='Cancel' onClick={onClickCancel} />
            </Stack>

            <Debug object={values} />

            <OverlayLoading isVisibled={isLoading} />
        </Stack>
    )
};