import { INavLink, INavLinkGroup, INavStyles, Nav } from "@fluentui/react/lib/Nav";
import { SearchBox } from "@fluentui/react/lib/SearchBox";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import React, { useRef } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router";
import { AllRequest } from "./AllRequest/AllRequest";
import { Documents } from "./Document/Document";
import { User } from "./User/User";
import { useBoolean } from "@fluentui/react-hooks";
import { useState } from "react";
import { isEmpty, preparePath } from "../../tools";
import { Template } from "./Template/Template";
import { DataType } from "./DataType/DataType";
import { Report } from "./Report/Report";
import { EmailTemplate } from "./EmailTemplate/EmailTemplate";
import { useContext } from "react";
import { AppContext } from "../../contexts";
import { cloneDeep } from "lodash";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";
import { DefaultPalette } from "@fluentui/react/lib/Styling";
import { Panel, PanelType } from "@fluentui/react/lib/Panel";

const navStyles: Partial<INavStyles> = {
    root: {
        overflowY: 'auto',
    },
    chevronIcon: {
        transform: 'rotate(-90deg)', // rotate(-180deg)
        '.ms-Nav-chevronButton[aria-expanded="true"] &': {
            transform: 'rotate(0deg)',
        }
    },
    compositeLink: {
        '& button.ms-Button--action': {
            color: DefaultPalette.black,
        }
    }
};

const stackTokens: IStackTokens = { childrenGap: 10 };

export const Administrator: React.FunctionComponent<RouteComponentProps<{ site: string }>> = props => {
    const { profile, isAppLoading, isSideMenuOpen, hideSideMenu } = useContext(AppContext);
    const { match: { path, params: { site } }, location: { pathname }, history } = props;
    const [isUserExplanded, { toggle: toggleUser }] = useBoolean(false);
    const [isReportExplanded, { toggle: toggleReport }] = useBoolean(false);
    const [filterdGroups, setFilteredGroups] = useState<INavLinkGroup[] | undefined>(undefined);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;
    const isLargeDevice = modalResponsiveMode === ResponsiveMode.large;

    if (isAppLoading) {
        return null;
    }

    const pageLink = `/site/${site}/administrator`;
    const _pathname = preparePath(pathname);
    const pathArray = _pathname.split('/');
    const selectedKey = (pathArray[4] === 'user' || pathArray[4] === 'report')
        ? pathArray[5]
        : pathArray[4];

    const permission = profile?.permission;
    if (!permission) {
        return <Redirect to="/nopermission" />;
    }

    const permissionReports: string[] = [];
    if (permission.requestReport) {
        permissionReports.push('requestreport');
    }

    if (permission.userReport) {
        permissionReports.push('userreport');
    }

    if (permission.documentReport) {
        permissionReports.push('documentreport');
    }

    if (permission.activitiesLogReport) {
        permissionReports.push('activitiesreport');
    }

    if (permission.expirationReport) {
        permissionReports.push('expirationreport');
    }

    if (
        !permission.documentManagement &&
        !permission.requestManagement &&
        !permission.dataTypeManagement &&
        !permission.templateManagement &&
        !permission.userManagement &&
        !permission.emailTemplateManagement &&
        !permissionReports.length
    ) {
        return <Redirect to="/nopermission" />;
    }

    const navLinkGroups: INavLinkGroup[] = [
        {
            links: [
                {
                    key: 'document',
                    name: 'Documents',
                    url: `${pageLink}/document`,
                },
                {
                    key: 'allrequest',
                    name: 'All Requests',
                    url: `${pageLink}/allrequest`,
                },
                {
                    key: 'template',
                    name: 'Templates',
                    url: `${pageLink}/template`,
                },
                {
                    key: 'datatype',
                    name: 'Data Types',
                    url: `${pageLink}/datatype`,
                },
                {
                    key: 'user',
                    name: 'Users',
                    url: '',
                    isExpanded: isUserExplanded,
                    onClick: toggleUser,
                    links: [
                        {
                            key: 'userlist',
                            name: 'User List',
                            url: `${pageLink}/user/userlist`,
                        },
                        {
                            key: 'groupuser',
                            name: 'Group User',
                            url: `${pageLink}/user/groupuser`,
                        },
                    ],
                },
                {
                    key: 'report',
                    name: 'Report',
                    url: '',
                    isExpanded: isReportExplanded,
                    onClick: toggleReport,
                    links: [
                        {
                            key: 'requestreport',
                            name: 'Request Report',
                            url: `${pageLink}/report/requestreport`,
                        },
                        {
                            key: 'userreport',
                            name: 'User Report',
                            url: `${pageLink}/report/userreport`,
                        },
                        {
                            key: 'documentreport',
                            name: 'Document Report',
                            url: `${pageLink}/report/documentreport`,
                        },
                        {
                            key: 'activitiesreport',
                            name: 'Activities Log Report',
                            url: `${pageLink}/report/activitiesreport`,
                        },
                        {
                            key: 'expirationreport',
                            name: 'Expiration Report',
                            url: `${pageLink}/report/expirationreport`,
                        },
                    ],
                },
                {
                    key: 'email',
                    name: 'Email',
                    url: `${pageLink}/email`,
                },
            ],
        },
    ];

    let newLinks = cloneDeep(navLinkGroups[0].links);

    if (!permission.documentManagement) {
        newLinks = newLinks.filter(l => l.key !== 'document');
    }

    if (!permission.requestManagement) {
        newLinks = newLinks.filter(l => l.key !== 'allrequest');
    }

    if (!permission.templateManagement) {
        newLinks = newLinks.filter(l => l.key !== 'template');
    }

    if (!permission.dataTypeManagement) {
        newLinks = newLinks.filter(l => l.key !== 'datatype');
    }

    if (!permission.userManagement) {
        newLinks = newLinks.filter(l => l.key !== 'user');
    }

    if (!permissionReports.length) {
        newLinks = newLinks.filter(l => l.key !== 'report');
    } else {
        const reportIndex = newLinks.findIndex(l => l.key === 'report');
        if (reportIndex !== -1) {
            if (!permission.requestReport) {
                newLinks[reportIndex].links = newLinks[reportIndex].links?.filter(l => l.key !== 'requestreport');
            }

            if (!permission.userReport) {
                newLinks[reportIndex].links = newLinks[reportIndex].links?.filter(l => l.key !== 'userreport');
            }

            if (!permission.documentReport) {
                newLinks[reportIndex].links = newLinks[reportIndex].links?.filter(l => l.key !== 'documentreport');
            }

            if (!permission.activitiesLogReport) {
                newLinks[reportIndex].links = newLinks[reportIndex].links?.filter(l => l.key !== 'activitiesreport');
            }

            if (!permission.expirationReport) {
                newLinks[reportIndex].links = newLinks[reportIndex].links?.filter(l => l.key !== 'expirationreport');
            }
        }
    }

    if (!permission.emailTemplateManagement) {
        newLinks = newLinks.filter(l => l.key !== 'email');
    }

    let defaultPage = `${_pathname}/${newLinks[0].key}`;
    navLinkGroups[0].links = newLinks;

    const onLinkClick = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
        ev?.preventDefault();
        if (item && !(item.key === 'user' || item.key === 'report')) {
            history.push(item.url);
        }
    };

    const onSearch = (searchValue: string) => {
        if (!searchValue) {
            setFilteredGroups(undefined);
            return;
        }

        const value = searchValue.toLocaleLowerCase();

        const links: INavLink[] = [];
        for (const link of navLinkGroups[0].links) {
            const newLink: INavLink = { ...link };

            if (newLink.links && !isEmpty(newLink.links)) {
                for (const subLink of newLink.links) {
                    if (subLink.name?.toLocaleLowerCase().includes(value)) {
                        subLink.name = `${newLink.name} / ${subLink.name}`;
                        links.push(subLink);
                    }
                }

            } else {
                if (newLink.name?.toLocaleLowerCase().includes(value)) {
                    links.push(newLink);
                }
            }
        }

        setFilteredGroups([{ links }]);
    };

    const onRenderNav = () => {
        if (isSmallDevice || isLargeDevice) {
            return (
                <Panel
                    isOpen={isSideMenuOpen}
                    onDismiss={hideSideMenu}
                    type={PanelType.customNear}
                    customWidth={isLargeDevice ? '40%' : '70%'}
                    isLightDismiss
                >
                    <Nav
                        onLinkClick={onLinkClick}
                        selectedKey={selectedKey}
                        styles={navStyles}
                        groups={filterdGroups ? filterdGroups : navLinkGroups}
                    />
                </Panel>
            );
        } else {
            return (
                <Stack tokens={stackTokens} styles={{ root: { height: 'calc(100vh - 190px)', width: 180, overflowY: 'auto' } }}>
                    <SearchBox
                        placeholder='Seach menus'
                        underlined
                        styles={{ root: { margin: '10px 0 0 7px', } }}
                        onSearch={onSearch}
                        onClear={() => setFilteredGroups(undefined)}
                    />
                    <Nav
                        onLinkClick={onLinkClick}
                        selectedKey={selectedKey}
                        styles={navStyles}
                        groups={filterdGroups ? filterdGroups : navLinkGroups}
                    />
                </Stack>
            );
        }
    };

    return (
        <Stack horizontal tokens={stackTokens}>
            {onRenderNav()}
            <Switch>
                <Route exact path={path} render={() => <Redirect to={defaultPage} />} />
                {permission.documentManagement && <Route path={`${path}/document`} component={Documents} />}
                {permission.requestManagement && <Route path={`${path}/allrequest`} component={AllRequest} />}
                {permission.templateManagement && <Route path={`${path}/template`} component={Template} />}
                {permission.dataTypeManagement && <Route path={`${path}/datatype`} component={DataType} />}
                {permission.userManagement && <Route path={`${path}/user`} component={User} />}
                {permissionReports.length && <Route path={`${path}/report`} component={Report} />}
                {permission.emailTemplateManagement && <Route path={`${path}/email`} component={EmailTemplate} />}
            </Switch>
        </Stack>
    );
};