import { IDialogProps } from "@fluentui/react/lib/Dialog";
import { Profile } from "oidc-client";
import React, { createContext, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { ApplicationPaths } from "../components/api-authorization/ApiAuthorizationConstants";
import authService from "../components/api-authorization/AuthorizeService";
import { IEmployee, IGroupPermission, IMenuItem, IPdfViewer, ISearchDocumentResult, ISite } from "../models";
import { EmployeeService } from "../services";
import { isEmpty } from "../tools";

export interface IUserProfile {
    user: Profile | null;
    employee: IEmployee | null;
    site: ISite | null;
    permission: IGroupPermission | null;
    role: string;
};

export interface IAppState {
    profile: IUserProfile | null;
    menuItems: IMenuItem[] | undefined;
    isAppLoading: boolean;
    isSearchPanelOpen: boolean;
    searchKeyword: string | undefined;
    documentDraftItems: ISearchDocumentResult[];
    isDialogVisible: boolean;
    dialogProps: IDialogProps | undefined;
    dialogContent: React.ReactNode | null;
    isPdfViewerVisible: boolean;
    pdfViewer: IPdfViewer | null;
    debug: boolean;
    isSideMenuOpen: boolean;
    setSite: (site: ISite) => void;
    getMenu: (name: string) => IMenuItem | undefined;
    openSearchPanel: () => void;
    dismissSearchPanel: () => void;
    setSearchKeyword: (keyword: string | undefined) => void;
    setMenuItems: (menuItems: IMenuItem[]) => void;
    showAppLoading: () => void;
    hideAppLoading: () => void;
    updateDraftDocument: (docs: ISearchDocumentResult[]) => void;
    clearDraftDocuments: () => void;
    showDialog: (props: IDialogProps, onRenderContent?: () => React.ReactNode | null) => void;
    hideDialog: () => void;
    setProfile: (profile: IUserProfile) => void;
    showPdfViewer: (viewer: IPdfViewer) => void;
    hidePdfViewer: () => void;
    showSideMenu: () => void;
    hideSideMenu: () => void;
}

const initState: IAppState = {
    menuItems: undefined,
    isAppLoading: true,
    isSearchPanelOpen: false,
    searchKeyword: undefined,
    documentDraftItems: [],
    isDialogVisible: false,
    dialogProps: undefined,
    dialogContent: null,
    profile: null,
    isPdfViewerVisible: false,
    pdfViewer: null,
    debug: false,
    isSideMenuOpen: false,
    setSite: (site: ISite) => { },
    getMenu: (name: string) => undefined,
    openSearchPanel: () => { },
    dismissSearchPanel: () => { },
    setSearchKeyword: (keyword: string | undefined) => { },
    setMenuItems: (menuItems: IMenuItem[]) => { },
    showAppLoading: () => { },
    hideAppLoading: () => { },
    updateDraftDocument: (docs: ISearchDocumentResult[]) => { },
    clearDraftDocuments: () => { },
    showDialog: (props: IDialogProps, onRenderContent?: () => React.ReactNode | null) => { },
    hideDialog: () => { },
    setProfile: (profile: IUserProfile) => { },
    showPdfViewer: (viewer: IPdfViewer) => { },
    hidePdfViewer: () => { },
    showSideMenu: () => { },
    hideSideMenu: () => { },
};

export const AppContext = createContext<IAppState>(initState);
const queryString = new URLSearchParams(window.location.search);
const isDebug: any = queryString.get('debug');

export const AppContextProvider: React.FunctionComponent = ({ children }) => {
    const history = useHistory();
    const { pathname } = useLocation();
    const [state, setState] = useState<IAppState>(initState);

    const { menuItems } = state;
    const setProfile = (profile: IUserProfile) => setState(prev => ({ ...prev, profile }));
    const getMenu = (name: string) => menuItems ? menuItems.find(m => m.name === name) : undefined;
    const setSearchKeyword = (keyword: string | undefined) => setState(prev => ({ ...prev, searchKeyword: keyword }));
    const setMenuItems = (items: IMenuItem[]) => setState(prev => ({ ...prev, menuItems: [...items] }));
    const openSearchPanel = () => setState(prev => ({ ...prev, isSearchPanelOpen: true }));
    const dismissSearchPanel = () => setState(prev => ({ ...prev, isSearchPanelOpen: false, searchKeyword: undefined }));
    const showAppLoading = useCallback(() => setState(prev => ({ ...prev, isAppLoading: true })), []);
    const hideAppLoading = useCallback(() => setState(prev => ({ ...prev, isAppLoading: false })), []);
    const clearDraftDocuments = () => setState(prev => ({ ...prev, documentDraftItems: [] }));
    const updateDraftDocument = (docs: ISearchDocumentResult[]) => setState(prev => ({ ...prev, documentDraftItems: [...docs] }));

    const showSideMenu = () => setState(prev => ({ ...prev, isSideMenuOpen: true }));
    const hideSideMenu = () => setState(prev => ({ ...prev, isSideMenuOpen: false }));

    const showDialog = (props: IDialogProps, onRenderContent?: () => React.ReactNode | null) => {
        return setState(prev => ({
            ...prev,
            isDialogVisible: true,
            dialogProps: props,
            dialogContent: onRenderContent ? onRenderContent() : null,
        }));
    };
    const hideDialog = () => setState(prev => ({ ...prev, isDialogVisible: false }));

    const showPdfViewer = (viewer: IPdfViewer) => setState(prev => ({ ...prev, isPdfViewerVisible: true, pdfViewer: viewer }));
    const hidePdfViewer = () => setState(prev => ({ ...prev, isPdfViewerVisible: false, pdfViewer: null }));

    useEffect(() => {
        let subscription = authService.subscribe(() => initial());

        showAppLoading();

        const initial = async () => {
            try {
                const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);

                if (!isAuthenticated || !user || window.location.href.includes("authentication")) {
                    hideAppLoading();
                    return;
                }

                const service = new EmployeeService();
                let results = await service.getConfiguration(window.location.href);

                if (!results) {
                    // Try 5 time
                    let count = 5;
                    while (count > 0) {
                        results = await service.getConfiguration(window.location.href);
                        if (results) {
                            break;
                        }
                        count--;
                    }
                }

                const enterURL = encodeURIComponent(window.location.href);

                if (!results) {
                    history.push(`/nopermission?url=${enterURL}&reason=noconf`);
                    hideAppLoading();
                    return;
                }

                if (
                    results.employee === null ||
                    results.site === null ||
                    results.permission === null ||
                    results.redirectUrl === null ||
                    results.role === null
                ) {
                    history.replace('/pagenotfound');
                }

                if (isEmpty(results.menuItems)) {
                    history.replace(`/nopermission?url=${enterURL}&reason=nomenu`);
                }

                setState(prev => ({
                    ...prev,
                    profile: {
                        user: user,
                        site: results!.site,
                        permission: results!.permission,
                        employee: results!.employee,
                        role: results!.role,
                    },
                    menuItems: results!.menuItems,
                }));

                history.replace(results.redirectUrl);
                hideAppLoading();
            } catch (error) {
                console.log(error);
                history.replace(ApplicationPaths.Login);
            }
        };

        initial();

        return () => {
            authService.unsubscribe(subscription);
        }
    }, [history, showAppLoading, hideAppLoading]);

    useEffect(() => {
        setState(prev => {
            const { profile } = prev;
            if (!profile) {
                return { ...prev };
            }
            if (!profile.site) {
                return { ...prev };
            }

            const newRequestPageUrl = `/site/${profile.site.name}/myrequest/new`;
            if (pathname.startsWith(newRequestPageUrl)) {
                return { ...prev, }
            }

            return { ...prev, documentDraftItems: [] };
        });
    }, [pathname]);

    return (
        <AppContext.Provider value={{
            ...state,
            debug: isDebug ? true : false,
            getMenu,
            setProfile,
            openSearchPanel,
            dismissSearchPanel,
            setSearchKeyword,
            setMenuItems,
            showAppLoading,
            hideAppLoading,
            updateDraftDocument,
            clearDraftDocuments,
            showDialog,
            hideDialog,
            showPdfViewer,
            hidePdfViewer,
            showSideMenu,
            hideSideMenu,
        }}>
            {children}
        </AppContext.Provider>
    );
};
