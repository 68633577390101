import { Breadcrumb, IBreadcrumbItem, IBreadcrumbStyles } from "@fluentui/react/lib/Breadcrumb";
import { DefaultButton, IButtonStyles, IconButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { ITextStyles, Text } from "@fluentui/react/lib/Text";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { FontSizes, FontWeights, useTheme } from "@fluentui/react/lib/Theme";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";
import { getRequestStatusColor } from "../../tools/color";
import { getDateRange, isEmpty, mapRequestDocToSearchDoc, onFormatDate } from "../../tools";
import { ITextFieldProps, TextField } from "@fluentui/react/lib/TextField";
import { EmployeeService, RequestService } from "../../services";
import { IEmployee, IRequestDocumentItem, IRequest, IRequestStatusKeys, ISaveResponse, ISearchDocumentResult, ISearchParameters } from "../../models";
import { DetailsList, IColumn, SelectionMode, Selection } from "@fluentui/react/lib/DetailsList";
import { AppContext } from "../../contexts";
import { DocumentPathButton } from "../../components/DocumentPathButton";
import { DialogFooter, DialogType } from "@fluentui/react/lib/Dialog";
import { Debug } from "../../components/Debug";
import { getDisplayValue } from "../../tools/value";
import { ProcessLog } from "../../components/ProcessLog";
import { cloneDeep } from "lodash";
import { IPickerItem, Picker } from "../../components/picker";
import { MarqueeSelection } from "@fluentui/react/lib/MarqueeSelection";
import { PageContainer } from "../../components/PageContainer";
import { DatePicker } from "../../components/DatePicker";
import { MessageBar, MessageBarType } from "@fluentui/react/lib/MessageBar";
import { useForceUpdate, useBoolean } from "@fluentui/react-hooks";
import { addYears } from "@fluentui/date-time-utilities";
import { OverlayLoading } from "../../components/OverlayLoading";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";

export type IFormMode = 'new' | 'readonly' | 'edit' | 'draftEdit';

export interface IMyRequestValues {
    mode: IFormMode;
    data: IRequest;
    autoViewId: string | null;
}

const stackTokens: IStackTokens = { childrenGap: 10 };

const breadcrumbStyles: Partial<IBreadcrumbStyles> = {
    root: {
        margin: '0 0 10px 0',
    },
    itemLink: {
        fontSize: FontSizes.medium,
        fontWeight: FontWeights.regular,
    },
    item: {
        fontSize: FontSizes.medium,
        '& .ms-TooltipHost': {
            fontWeight: FontWeights.regular,
        }
    },
};

const textBoldStyles: Partial<ITextStyles> = {
    root: {
        fontWeight: FontWeights.bold,
        minWidth: 80
    }
};

const initialValues: IMyRequestValues = {
    mode: 'readonly',
    data: {
        id: null,
        key: '-1',
        requestDate: null,
        requestNo: null,
        requestBy: null,
        requestFor: null,
        requestFors: [],
        startDate: null,
        endDate: null,
        documents: [],
        objective: null,
        status: null,
        vpApprover: null,
        adminApprover: null,
        clgVpApprover: null,
        expiredDate: null,
    },
    autoViewId: null,
};

const mapDocsToRequestItems = (docs: ISearchDocumentResult[]): IRequestDocumentItem[] => {
    return docs.map(doc => {
        const { id, pathId, name, parties, date, path: documentPath, abstract } = doc;
        return {
            id: null,
            key: '-1',
            docId: id,
            pathId,
            name,
            parties,
            path: documentPath,
            date,
            requestType: 'view',
            permission: ['preview'],
            approve: true,
            abstract
        }
    });
};

export const MyRequestForm: React.FunctionComponent<RouteComponentProps<{ site: string, id: string }>> = (props) => {
    const theme = useTheme();
    const {
        profile,
        documentDraftItems,
        openSearchPanel,
        clearDraftDocuments,
        updateDraftDocument,
        showDialog,
        hideDialog,
        showPdfViewer,
    } = useContext(AppContext);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;
    const isLargeDevice = modalResponsiveMode === ResponsiveMode.large;

    const [values, setValues] = useState<IMyRequestValues>(cloneDeep({ ...initialValues }));
    const [reload, setReload] = useState<boolean>(false);
    const [originalValues, setOriginalValues] = useState<IRequest | undefined>(undefined);
    const [selectedItems, setSelectedItems] = useState<IRequestDocumentItem[]>([]);
    const [isLoading, { setFalse: hideLoading, setTrue: showLoading }] = useBoolean(false);
    const forceUpdate = useForceUpdate();

    const service = useMemo(() => new RequestService(), []);
    const employeeService = useMemo(() => new EmployeeService(), []);
    const { match: { params: { site, id } }, history, location: { state, search } } = props;
    const listLink = `/site/${site}/myrequest`;

    const detailDisabled = values.mode === 'readonly' || values.mode === 'edit';

    const startDate = values.data.startDate ? new Date(values.data.startDate!) : null;
    const endDate = values.data.endDate ? new Date(values.data.endDate!) : null;
    const expiredDate = values.data.expiredDate ? new Date(values.data.expiredDate!) : null;
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    const dateRange = getDateRange(startDate, endDate);
    const days = dateRange?.toString() ?? '';
    const pickerSelected: IPickerItem<IEmployee>[] = [];
    const isOwner = profile?.employee?.id === values.data.requestFor?.id;
    const isExpired = expiredDate && values.data.status === 'approved' && expiredDate <= today;
    const minDate = startDate ? new Date(new Date(startDate).setHours(0, 0, 0, 0)) : null;
    const maxDate = minDate ? addYears(minDate, 5) : null;

    if (values.data.requestFors && !isEmpty(values.data.requestFors)) {
        for (let i = 0; i < values.data.requestFors.length; i++) {
            const requestor = values.data.requestFors[i];
            pickerSelected.push({
                key: requestor.key,
                name: requestor.name || 'Error username',
                data: requestor,
            });
        }
    }

    const selection = useMemo(() => new Selection({
        onSelectionChanged: () => {
            const selectionCount = selection.getSelectedCount();
            if (selectionCount > 0) {
                const newSelectedItems = selection.getSelection() as IRequestDocumentItem[];
                setSelectedItems([...newSelectedItems]);
            } else {
                setSelectedItems([]);
            }
            forceUpdate();
        },
    }), [forceUpdate]);

    useEffect(() => {
        let isMounted = true;
        const employee = profile?.employee;
        if (!employee) {
            setValues(cloneDeep({ ...initialValues }));
            return;
        }

        async function initial() {
            if (!employee) {
                setValues(cloneDeep({ ...initialValues }));
                return;
            }

            const { data } = { ...initialValues };
            const newData: IRequest = cloneDeep({
                ...data,
                requestBy: employee,
                requestFors: [employee],
                startDate: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
                status: 'draft',
                requestNo: 'Draft',
            });

            setValues(cloneDeep({
                ...initialValues,
                data: newData,
                mode: 'new',
            }));
        }

        if (!id) {
            initial();
        } else {
            const promise = service.get({ id });
            promise.then(data => {
                if (!isMounted || !data) {
                    setValues(cloneDeep({ ...initialValues }));
                    setOriginalValues(undefined);
                    return;
                }

                let mode: IFormMode = 'readonly';
                if (data.status === 'draft' && profile?.employee?.id === data.requestBy?.id) {
                    mode = 'draftEdit';
                }

                let autoViewId: string | null = null;
                if (!reload && data.status === 'approved') {
                    const queryString = new URLSearchParams(search);
                    let autoView: string | null = queryString.get('autoview') as string;
                    if (autoView
                        && data.documents?.some(
                            doc => doc.id === autoView &&
                                doc.approve &&
                                (doc.requestType === 'view' || doc.requestType === 'print'))) {
                        autoViewId = decodeURIComponent(autoView);
                    }
                }

                setValues(cloneDeep({ ...initialValues, data: data, mode, autoViewId }));
                setOriginalValues(cloneDeep({ ...data }));
            });
        }

        setReload(false);

        return () => {
            isMounted = false;
            setValues(cloneDeep({ ...initialValues }));
            setOriginalValues(undefined);
            setReload(false);
        };

    }, [id, service, employeeService, profile, reload, search]);

    useEffect(() => {
        if (!values.data.requestBy || values.data.status !== 'draft') {
            return;
        }

        const newDocs = mapDocsToRequestItems(documentDraftItems);
        setValues(prev => {
            if (prev.mode === 'readonly') {
                return { ...prev };
            }

            const { data } = prev;
            const oldDocs = data.documents || [];
            const appendDocs: IRequestDocumentItem[] = newDocs.filter(n => !oldDocs.some(o => o.pathId === n.pathId));
            data.documents = [...oldDocs, ...appendDocs];
            return { ...prev, data };
        });
    }, [documentDraftItems, values.data.requestBy, values.data.status, values.mode]);

    useEffect(() => {
        if (values.autoViewId && !isExpired && isOwner && values.data.documents) {
            const index = values.data.documents?.findIndex(doc => doc.id === values.autoViewId);
            if (index !== -1) {
                const item = values.data.documents[index];
                showPdfViewer({
                    mode: 'view',
                    documentId: item.docId,
                    pathId: item.pathId,
                    requestId: id,
                    permission: 'view',
                });
            }
        }

        if (values.autoViewId) {
            setValues(prev => ({ ...prev, autoViewId: null }));
        }

        return () => {
            setValues(prev => ({ ...prev, autoViewId: null }));
        };
    }, [id, values.autoViewId, values.data.documents, isExpired, isOwner, showPdfViewer]);

    const backToList = (ev?: any) => {
        if (ev) {
            ev.preventDefault();
        }

        hideDialog();
        clearDraftDocuments();
        history.push(listLink, { from: 'form', values: state });
    };

    const onLeavePage = (ev: React.MouseEvent<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement | HTMLSpanElement>) => {
        ev.preventDefault();
        showDialog({
            title: 'Please confirm',
            dialogContentProps: {
                type: DialogType.normal,
                subText: 'Do you want to leave this page ?'
            }
        }, () => {
            return (
                <>
                    <DialogFooter styles={{ actionsRight: { textAlign: 'left' } }}>
                        <DefaultButton onClick={backToList} text="Yes" />
                        <DefaultButton onClick={hideDialog} text="No" />
                    </DialogFooter>
                </>
            );
        });
    };

    const breadcrumbItems: IBreadcrumbItem[] = [
        {
            key: 'list',
            text: 'My Request',
            href: listLink,
            onClick: backToList,
        },
        {
            key: values.mode,
            text: originalValues?.requestNo || (values.mode === 'new' ? 'New' : '...'),
        }
    ];

    const textStatusStyles = (status: IRequestStatusKeys | null): Partial<ITextStyles> => ({
        root: {
            fontWeight: FontWeights.bold,
            color: getRequestStatusColor(theme, status),
        }
    });

    const onDateFromChange = (date: Date | null | undefined) => {
        const data = { ...values.data, startDate: date ? new Date(date.setHours(0, 0, 0, 0)).toISOString() : null };
        setValues(prev => ({ ...prev, data }));
    }

    const onDateToChange = (date: Date | null | undefined) => {
        const data = { ...values.data, endDate: date ? new Date(date.setHours(0, 0, 0, 0)).toISOString() : null };
        setValues(prev => ({ ...prev, data }));
    }

    const onObjectiveChange = (ev: React.FormEvent<HTMLElement>, newValue?: string) => {
        const data = { ...values.data, objective: newValue ? newValue : null };
        setValues(prev => ({ ...prev, data }))
    }

    const datePickerTextFieldProps: ITextFieldProps = {
        onRenderLabel: (textFieldProps, render) => render
            ? render({ ...textFieldProps, required: values.mode === 'readonly' ? false : true })
            : null,
    }

    const getDocKey = (item: IRequestDocumentItem): string => {
        return item.pathId!;
    }

    const onRequestorChange = (items: IPickerItem<IEmployee>[] | undefined) => {
        const selectedUsers = items ? items.map(i => i.data!) : [];
        const data = { ...values.data, requestFors: selectedUsers };
        setValues(prev => ({ ...prev, data }))
    };

    const onRemoveClick = () => {
        if (selectedItems.length > 0) {
            const { data } = { ...values };
            data.documents = values.data.documents!.filter(item => !selectedItems.some(i => i.pathId === item.pathId));
            if (data.documents) {
                const newDraftDocuments = data.documents.map(mapRequestDocToSearchDoc);
                updateDraftDocument(newDraftDocuments);
            } else {
                updateDraftDocument([]);
            }
        }

        selection.setAllSelected(false);
        setSelectedItems([]);
    };

    const onClickAdd = () => {
        const { data } = { ...values };
        if (data.documents) {
            const newDraftDocuments = data.documents.map(mapRequestDocToSearchDoc);
            updateDraftDocument(newDraftDocuments);
        } else {
            updateDraftDocument([]);
        }

        openSearchPanel();
    };

    const buttonStyles: Partial<IButtonStyles> = {
        root: {
            width: 22,
            minWidth: 22,
            maxWidth: 22,
            height: 22,
            minHeight: 22,
            maxHeight: 22,
            padding: 0,
        },
        icon: {
            fontSize: FontSizes.size12,
        }
    };

    const onClickViewPdf = (item: IRequestDocumentItem) => {
        showPdfViewer({
            mode: 'view',
            documentId: item.docId,
            pathId: item.pathId,
            requestId: id,
            permission: 'view',
        });
    };

    const onClickPrintPdf = (item: IRequestDocumentItem) => {
        showPdfViewer({
            mode: 'print',
            documentId: item.docId,
            pathId: item.pathId,
            requestId: id,
            permission: 'view-print',
        });
    };

    const onViewClick = (item: IRequestDocumentItem, index: number) => {
        if (values.data.status === 'approved') {
            onClickViewPdf(item);
            return;
        }
    };

    const onPrintClick = (item: IRequestDocumentItem, index: number) => {
        setSelectedItems([]);
        selection.setAllSelected(false);
        if (values.data.status === 'approved') {
            onClickPrintPdf(item);
            return;
        }

        const nextType = !item.requestType || item.requestType === 'view' ? 'print' : 'view';
        setValues(prev => {
            const data = { ...prev.data };
            const docs = data.documents ? [...data.documents] : []
            if (docs[index]) {
                docs[index].requestType = nextType;
                data.documents = [...docs];
                return { ...prev, data };
            }

            return { ...prev, };
        });
    };

    let columns: IColumn[] = [
        {
            key: 'type',
            name: 'Request Type',
            fieldName: 'requestType',
            minWidth: 130,
            maxWidth: 130,
            styles: {
                root: {
                    padding: 0
                }
            },
            onRender: (item: IRequestDocumentItem, index?: number) => {
                if (!index && index !== 0) {
                    return <></>
                }

                const print = item && item.requestType === 'print';
                const view = item && (item.requestType === 'print' || item.requestType === 'view');

                if (!item.approve) {
                    return (
                        <Text
                            data-selection-disabled
                            data-selection-toggle={false}
                            styles={{ root: { color: theme.semanticColors.errorIcon } }}
                        >
                            Rejected
                        </Text>
                    );
                }

                if (values.data.status === 'approved' && isOwner && !isExpired) {
                    return (
                        <Stack horizontal tokens={stackTokens} data-selection-disabled data-selection-toggle={false}>
                            {view && <IconButton
                                iconProps={{ iconName: 'View' }}
                                onClick={() => onViewClick(item, index)}
                                styles={buttonStyles}
                                data-selection-disabled
                                data-selection-toggle={false}
                            />}
                            {print && <IconButton
                                iconProps={{ iconName: 'Print' }}
                                onClick={() => onPrintClick(item, index)}
                                styles={buttonStyles}
                                data-selection-disabled
                                data-selection-toggle={false}
                            />}
                        </Stack>
                    )
                }

                if (values.mode === 'readonly') {
                    return (
                        <Stack horizontal tokens={stackTokens} data-selection-disabled data-selection-toggle={false}>
                            {view && <DefaultButton
                                disabled
                                iconProps={{ iconName: 'View' }}
                                styles={buttonStyles}
                                data-selection-disabled
                                data-selection-toggle={false}
                            />}
                            {print && <DefaultButton
                                disabled
                                toggle
                                iconProps={{ iconName: 'Print' }}
                                onClick={() => onPrintClick(item, index)}
                                styles={buttonStyles}
                                data-selection-disabled
                                data-selection-toggle={false}
                            />}
                        </Stack>
                    );
                }

                return (
                    <Stack horizontal tokens={stackTokens} data-selection-disabled data-selection-toggle={false}>
                        <DefaultButton
                            primary={view}
                            iconProps={{ iconName: 'View' }}
                            styles={buttonStyles}
                            data-selection-disabled
                            data-selection-toggle={false}
                        />
                        <DefaultButton
                            primary={print}
                            toggle
                            iconProps={{ iconName: 'Print' }}
                            onClick={() => onPrintClick(item, index)}
                            styles={buttonStyles}
                            data-selection-disabled
                            data-selection-toggle={false}
                        />
                    </Stack>
                );
            }
        },
        {
            key: 'name',
            name: 'Document name',
            fieldName: 'name',
            minWidth: 250,
            isMultiline: true,
            onRender: (item: IRequestDocumentItem) => {
                return <span title={item.abstract || item.name!}>{item.name}</span>;
            }
        },
        {
            key: 'parties',
            name: 'Parties',
            fieldName: 'parties',
            minWidth: 250,
            isMultiline: true,
        },
        {
            key: 'date',
            name: 'Date',
            fieldName: 'date',
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: IRequestDocumentItem) => {
                return <>{item.date && onFormatDate(new Date(item.date))}</>;
            }
        },
        {
            key: 'path',
            name: 'Document path',
            fieldName: 'path',
            minWidth: 140,
            maxWidth: 140,
            onRender: (item: IRequestDocumentItem) => {
                if (!item.path) {
                    return <></>;
                }

                return (
                    <DocumentPathButton path={item.path} styles={{ root: { height: 'auto' } }} />
                )
            },
        },
    ];

    const onSaveResponse = (action: string, saved: ISaveResponse<IRequest>, isBackToList: boolean = true) => {
        if (saved.isError) {
            showDialog({
                title: `${action} failed`,
                dialogContentProps: {
                    type: DialogType.normal,
                }
            }, () => {
                return (
                    <>
                        {saved.errorMessage &&
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {saved.errorMessage}
                            </MessageBar>
                        }
                        {saved.errorMessages && saved.errorMessages.map((err, i) => (
                            <MessageBar key={i}
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {err}
                            </MessageBar>
                        ))}
                    </>
                );
            });

            return;
        } else {
            if (isBackToList) {
                backToList();
                return;
            }

            if (id) {
                setReload(true);
            } else {
                backToList();
            }
        }
    };

    const onValidateForm = () => {
        const errorMessages: string[] = [];
        if (!values.data.startDate) {
            errorMessages.push('Date from is required');
        }

        if (!values.data.endDate) {
            errorMessages.push('Date to is required');
        }

        if (!values.data.objective) {
            errorMessages.push('Objective of Request is required');
        }

        if (!values.data.requestFors || isEmpty(values.data.requestFors)) {
            errorMessages.push('Request for is required');
        }

        if (!values.data.documents || isEmpty(values.data.documents)) {
            errorMessages.push('Document list is required');
        }

        if (parseInt(days) < 0) {
            errorMessages.push('Invalid start/end date');
        }

        if (values.data.endDate) {
            const endDate = new Date(values.data.endDate);

            if (maxDate && endDate > maxDate) {
                errorMessages.push('Maximum date range is 5 years');
            }

            if (minDate && endDate < minDate) {
                errorMessages.push('Maximum date range is 5 years');
            }
        }

        if (!isEmpty(errorMessages)) {
            showDialog({
                title: `Invalid form`,
                dialogContentProps: {
                    type: DialogType.normal,
                }
            }, () => {
                return (
                    <>
                        {errorMessages && errorMessages.map((err, i) => (
                            <MessageBar key={i}
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {err}
                            </MessageBar>
                        ))}
                    </>
                );
            });

            return false;
        }

        return true;
    };

    const validate = async () => {
        const { data } = values;
        let isValid = true;
        await service.validate({ ...data, site: profile?.site }, validated => {
            if (validated.isError) {
                isValid = false;

                showDialog({
                    title: `Invalid request`,
                    dialogContentProps: {
                        type: DialogType.normal,
                    }
                }, () => {
                    return (
                        <>
                            {validated.errorMessages && validated.errorMessages.map((err, i) => (
                                <MessageBar key={i}
                                    messageBarType={MessageBarType.error}
                                    isMultiline={true}
                                >
                                    {err}
                                </MessageBar>
                            ))}
                        </>
                    );
                });
            }
        });

        return isValid;
    }

    const onSave = async () => {
        const { data } = values;
        if (!data.requestFors || isEmpty(data.requestFors)) {
            return;
        }

        showLoading();

        const responses: ISaveResponse<IRequest>[] = [];

        const endDate = new Date(values.data.endDate!);
        const expiredDate = new Date(endDate);
        expiredDate.setDate(endDate.getDate() + 1);

        let index = 0;
        const requestDate = new Date();
        for (const requestFor of data.requestFors) {
            const submitValue: IRequest = {
                ...data,
                id: null,
                requestFors: null,
                requestFor,
                requestDate: requestDate.toISOString(),
                vpApprover: requestFor.head!,
                status: 'waiting-vp-approve',
                expiredDate: expiredDate.toISOString(),
                department: requestFor.organization,
                isVpSendMail: false,
            };

            if (data.id) {
                if (index === 0) {
                    // Update existing draft to new status for first requester
                    await service.update(data.id, { ...submitValue, id: data.id }, saved => responses.push(saved));
                } else {
                    // Otherwise
                    const newData = { ...submitValue, site: profile?.site };
                    await service.create(newData, saved => responses.push(saved));
                }
            } else {
                // Create new for all requester
                const newData = { ...submitValue, site: profile?.site };
                await service.create(newData, saved => responses.push(saved));
            }

            index++;
        }

        let hasError = false;
        let arrayErrorMessages: string[] = [];
        let arraySuccessMessages: string[] = [];
        for (const response of responses) {
            if (response.isError) {
                hasError = true;
                arrayErrorMessages.push(response.errorMessage || '');
            } else {
                arraySuccessMessages.push(response.successMessage || '');
            }
        }

        const saved: ISaveResponse<IRequest> = {
            isError: hasError,
            errorMessages: arrayErrorMessages,
            successMessages: arraySuccessMessages,
            data: null,
        };

        onSaveResponse('Sent request(s)', saved);

        hideLoading();
    };

    const onClickViewLog = () => {
        showDialog({
            title: 'Proccess log',
            minWidth: 500,
            dialogContentProps: {
                type: DialogType.normal,
            }
        }, () => {
            return <ProcessLog item={values.data} displayOnly />;
        });
    };

    const onSendRequest = async () => {
        if (!onValidateForm()) {
            return;
        }

        showLoading();

        const isValid = await validate();
        if (!isValid) {
            hideLoading();
            return;
        }

        hideLoading();

        showDialog({
            title: 'Proccess log',
            minWidth: 500,
            dialogContentProps: {
                type: DialogType.normal,
            }
        }, () => {
            const onClickSubmit = () => {
                onSave();
                hideLoading();
            };

            return (
                <>
                    <ProcessLog item={values.data} />
                    <DialogFooter styles={{ actionsRight: { textAlign: 'left' } }}>
                        <PrimaryButton disabled={isLoading} onClick={onClickSubmit} text="Submit" />
                        <DefaultButton onClick={hideDialog} text="Cancel" />
                    </DialogFooter>
                </>
            );
        });
    };

    const onSaveDraft = async () => {
        if (!onValidateForm()) {
            return;
        }

        const { requestFors, requestBy, startDate, endDate, documents, objective, requestNo } = values.data;
        const submitValues: IRequest = {
            id: null,
            key: '-1',
            requestDate: new Date().toISOString(),
            requestNo,
            requestBy,
            requestFor: null,
            requestFors,
            startDate,
            endDate,
            documents,
            objective,
            status: 'draft',
            vpApprover: null,
            adminApprover: null,
            clgVpApprover: null,
            site: profile?.site,
        };

        showLoading();

        if (values.mode === 'new') {
            await service.create(submitValues, saved => onSaveResponse('Save draft', saved));
        }

        if (id && values.mode === 'draftEdit') {
            await service.update(id, { ...submitValues, id: id }, saved => onSaveResponse('Save draft', saved));
        }

        hideLoading();
    };

    const onDeleteDraft = async () => {
        showLoading();

        if (id && values.mode === 'draftEdit') {
            const submitValues = { ...values.data };
            submitValues.id = id;
            submitValues.status = 'delete';
            await service.update(id, submitValues, saved => onSaveResponse('Delete draft', saved));
        }

        hideLoading();
    };

    const onConfirmDeleteDraft = async () => {
        showDialog({
            title: 'Please confirm',
            dialogContentProps: {
                type: DialogType.normal,
                subText: 'Do you want to delete this draft ?'
            }
        }, () => {
            const onConfirmed = () => {
                onDeleteDraft();
                hideDialog();
            };

            return (
                <>
                    <DialogFooter styles={{ actionsRight: { textAlign: 'left' } }}>
                        <DefaultButton onClick={onConfirmed} text="Yes" />
                        <DefaultButton onClick={hideDialog} text="No" />
                    </DialogFooter>
                </>
            );
        });
    };

    const onRecallClick = async () => {
        const { data } = values;
        const submitValue: IRequest = {
            ...data,
            requestNo: 'Draft',
            requestFor: null,
            requestFors: [data.requestFor!],
            status: 'draft',
            vpApprover: null,
            isVpSendMail: false,
        };

        showLoading();

        await service.update(id, submitValue, saved => onSaveResponse('Recall', saved, false));

        hideLoading();
    };

    const onRemindClick = async () => {
        showLoading();

        await service.remind(id, { ...values.data }, sent => {
            var isError = sent.isError;
            showDialog({
                title: `Remind email ${isError ? 'failed' : 'success'}`,
                dialogContentProps: {
                    type: DialogType.normal,
                }
            }, () => {
                return (
                    <>
                        {isError && sent.errorMessage &&
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {sent.errorMessage}
                            </MessageBar>
                        }
                        {!isError && sent.successMessage &&
                            <MessageBar
                                messageBarType={MessageBarType.success}
                                isMultiline={true}
                            >
                                {sent.successMessage}
                            </MessageBar>
                        }
                    </>
                );
            });
        });

        hideLoading();
    };

    const onMapFilterRequestFors = (filter: string, defaultFilter: ISearchParameters): ISearchParameters => {
        return {
            ...defaultFilter,
            isIncludeOrg: true,
            isIncludeHead: true,
        };
    };

    let canRecall = false;
    if (values.data.requestBy && profile?.employee!.id === values.data.requestBy.id) {
        canRecall = true;
    }

    return (
        <PageContainer>
            <Breadcrumb items={breadcrumbItems} styles={breadcrumbStyles} />
            <Stack tokens={stackTokens} styles={{ root: { margin: '0 10px 0 0', minHeight: 25, borderBottom: `1px solid ${theme.palette.themePrimary}` } }}>
                <Stack tokens={stackTokens} horizontal wrap={isSmallDevice} verticalAlign='center' styles={{ root: { marginLeft: 5 } }}>
                    <Stack>
                        <Text variant='large' styles={textBoldStyles} >REQUEST DETAIL</Text>
                    </Stack>
                    <Stack tokens={stackTokens} grow={1} horizontal>
                        <Text styles={textBoldStyles} >Request by</Text>
                        <Text>{values.data.requestBy?.name}</Text>
                    </Stack>
                    <Stack tokens={stackTokens} horizontal verticalAlign='center'>
                        {values.mode === 'readonly' &&
                            <IconButton iconProps={{ iconName: 'WaitlistConfirm' }} onClick={onClickViewLog} />
                        }
                        <Text styles={textStatusStyles(values.data.status)}>{getDisplayValue(values.data.status)}</Text>
                    </Stack>
                </Stack>
            </Stack>

            <Stack styles={{ root: { margin: '0 10px 10px 0' } }}>
                <Stack tokens={stackTokens} horizontal wrap={isSmallDevice} styles={{ root: { marginLeft: 5 } }}>
                    <Stack grow={1} tokens={stackTokens} styles={{ root: { maxWidth: isSmallDevice ? undefined : '50%' } }}>
                        <Stack tokens={stackTokens} horizontal wrap={isSmallDevice || isLargeDevice}>
                            <Stack grow={1}>
                                <DatePicker
                                    label='Date from'
                                    placeholder="Date from"
                                    textField={datePickerTextFieldProps}
                                    onSelectDate={onDateFromChange}
                                    value={startDate ? startDate : undefined}
                                    disabled={detailDisabled}
                                />
                            </Stack>
                            <Stack grow={1}>
                                <DatePicker
                                    label='Date to (maximum 5 year)'
                                    placeholder="Date to"
                                    textField={datePickerTextFieldProps}
                                    onSelectDate={onDateToChange}
                                    value={endDate ? endDate : undefined}
                                    disabled={detailDisabled}
                                />
                            </Stack>
                            <TextField label='Day(s)'
                                disabled
                                styles={{ root: { maxWidth: 100 } }}
                                value={days}
                                readOnly
                                onChange={e => e.preventDefault()}
                            />
                        </Stack>
                        <Stack>
                            {values.mode === 'readonly' &&
                                <TextField label='Request for'
                                    onChange={e => e.preventDefault()}
                                    disabled
                                    value={values.data.requestFor?.name || ''}
                                />
                            }
                            {(values.mode === 'new' || values.mode === 'draftEdit') &&
                                <Picker
                                    service={employeeService}
                                    placeholder='Search name or ID'
                                    onChange={onRequestorChange}
                                    selectedItems={pickerSelected}
                                    label='Request for (Support for multiple users)'
                                    required
                                    onMapFilter={onMapFilterRequestFors}
                                    minimumTextLength={3}
                                />
                            }
                        </Stack>
                    </Stack>
                    <Stack tokens={stackTokens} grow={1} styles={{ root: { maxWidth: isSmallDevice ? undefined : '50%' } }}>
                        <TextField
                            disabled={detailDisabled}
                            required={values.mode === 'readonly' ? false : true}
                            label="Objective of Request"
                            multiline rows={5}
                            onChange={onObjectiveChange}
                            value={values.data.objective || ''}
                        />
                    </Stack>
                </Stack>
            </Stack>

            <Stack tokens={stackTokens} styles={{ root: { margin: '0 10px 0 0', minHeight: 25, borderBottom: `1px solid ${theme.palette.themePrimary}` } }}>
                <Stack tokens={stackTokens} horizontal verticalAlign='center' styles={{ root: { marginLeft: 5 } }}>
                    <Stack>
                        <Text variant='large' styles={textBoldStyles} >DOCUMENT REQUEST LIST</Text>
                    </Stack>
                </Stack>
            </Stack>

            {values.data.status === 'draft' &&
                <Stack tokens={stackTokens} horizontal styles={{ root: { margin: '0 10px 0 0' } }}>
                    <DefaultButton text="Add" iconProps={{ iconName: 'Add' }} onClick={onClickAdd} />
                    <DefaultButton
                        text="Remove"
                        disabled={!selectedItems.length}
                        iconProps={{ iconName: 'Delete' }}
                        onClick={onRemoveClick}
                    />
                </Stack>
            }

            <Stack styles={{ root: { margin: '0 10px 0 0' } }}>
                {values.data.documents && !isEmpty(values.data.documents)
                    ? (
                        <MarqueeSelection selection={selection} isEnabled={false}>
                            <DetailsList
                                items={values.data.documents}
                                columns={columns}
                                selectionMode={
                                    values.data.status === 'draft'
                                        ? SelectionMode.multiple
                                        : SelectionMode.none
                                }
                                getKey={getDocKey}
                                setKey="multiple"
                                isHeaderVisible={true}
                                selection={selection}
                            />
                        </MarqueeSelection>
                    )
                    : (
                        <Text>No document found</Text>
                    )}
            </Stack>

            {values.data.remark &&
                <Stack styles={{ root: { maxWidth: isSmallDevice ? undefined : '50%' } }}>
                    <TextField
                        label='Reason / Remark (VP/SVP/EVP)'
                        multiline
                        value={values.data.remark || ''}
                        onChange={e => e.preventDefault()}
                        disabled
                    />
                </Stack>
            }

            {values.data.adminRemark &&
                <Stack styles={{ root: { maxWidth: isSmallDevice ? undefined : '50%' } }}>
                    <TextField
                        label='Reason / Remark (Administrator)'
                        multiline
                        value={values.data.adminRemark || ''}
                        onChange={e => e.preventDefault()}
                        disabled
                    />
                </Stack>
            }

            {values.data.clgVpRemark &&
                <Stack styles={{ root: { maxWidth: isSmallDevice ? undefined : '50%' } }}>
                    <TextField
                        label='Reason / Remark (Legal VP)'
                        multiline
                        value={values.data.clgVpRemark || ''}
                        onChange={e => e.preventDefault()}
                        disabled
                    />
                </Stack>
            }

            <Stack tokens={stackTokens} horizontal styles={{ root: { margin: '0 10px 5px 0', paddingTop: 20 } }}>
                {(values.mode === 'new' || values.mode === 'draftEdit') &&
                    <DefaultButton text="Save draft" onClick={onSaveDraft} />
                }
                {(values.mode === 'new' || values.mode === 'draftEdit') &&
                    <PrimaryButton text="Send" onClick={onSendRequest} />
                }
                {values.data.status === 'waiting-vp-approve' &&
                    <>
                        {canRecall &&
                            <DefaultButton text="Recall" onClick={onRecallClick} />
                        }
                        <DefaultButton text="Remind" onClick={onRemindClick} />
                    </>
                }

                <DefaultButton text="Cancel" onClick={onLeavePage} />

                {values.mode === 'draftEdit' &&
                    <DefaultButton text="Delete draft" onClick={onConfirmDeleteDraft} />
                }
            </Stack>

            <Debug object={values} label='values' />
            <Debug object={documentDraftItems} label='documentDraftItems' />
            <Debug object={selectedItems} label='selectedItems' />

            <OverlayLoading isVisibled={isLoading} />
        </PageContainer>
    );
};
