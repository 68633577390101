import { IBreadcrumbItem } from "@fluentui/react/lib/Breadcrumb";
import { ActionButton, CommandButton, IButtonStyles, IconButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react/lib/Dropdown";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { TextField } from "@fluentui/react/lib/TextField";
import { useBoolean } from "@fluentui/react-hooks";
import React, { useCallback, useContext, useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { RouteComponentProps } from "react-router";
import { DataTypeFilterSet, IFilterValues } from "../../../components/DataTypeFilterSet";
import { DatePicker } from "../../../components/DatePicker";
import { NavigationPane } from "../../../components/NavigationPane";
import { IActiveStatus, IDocumentResult, ISearchResult } from "../../../models";
import { getDisplayValue, isEmpty, onFormatDate } from "../../../tools";
import { Loading } from "../../../components/Loading";
import { DetailsList, IColumn, SelectionMode } from "@fluentui/react/lib/DetailsList";
import { Text } from "@fluentui/react/lib/Text";
import { MarqueeSelection, Selection } from "@fluentui/react/lib/MarqueeSelection";
import { DocumentPathButton } from "../../../components/DocumentPathButton";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@fluentui/react/lib/Link";
import { TextEditInline } from "../../../components/TextEditInline";
import { AppContext } from "../../../contexts";
import { DocumentService, PdfService } from "../../../services";
import { useMemo } from "react";
import { cloneDeep } from "lodash";
import { Pagination } from "../../../components/Pagination";
import { Debug } from "../../../components/Debug";
import { DialogType } from "@fluentui/react/lib/Dialog";
import { MessageBar, MessageBarType } from "@fluentui/react/lib/MessageBar";
import { Overlay } from "@fluentui/react/lib/Overlay";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";

export interface IDocumentSearchValues {
    keyword?: string;
    signContractDate?: string | null;
    status?: IActiveStatus;
    type: string | null;
    filters?: string[] | null;
    pageNumber: number;
    numberPerPage: number;
    sortedBy: keyof IDocumentResult;
    sortedType: 'asc' | 'desc';
}

export const listBreadcrumbItems: IBreadcrumbItem = {
    key: 'list',
    text: 'Documents',
};

const stackTokens: IStackTokens = { childrenGap: 10 };

const numberPerPageOptions: IDropdownOption<number>[] = [
    { key: '20', text: '20', data: 20 },
    { key: '50', text: '50', data: 50 },
    { key: '100', text: '100', data: 100 },
    { key: '200', text: '200', data: 200 },
];

const initialStatus: IActiveStatus = 'active';
const statusOptions: IDropdownOption<IActiveStatus>[] = [
    { key: 'all', text: getDisplayValue('all'), data: 'all' },
    { key: 'active', text: getDisplayValue('active'), data: 'active' },
    { key: 'inactive', text: getDisplayValue('inactive'), data: 'inactive' },
];

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: '100%' },
};

const viewButtonStyles: Partial<IButtonStyles> = {
    root: {
        height: 'auto',
    }
};

const initialValues: IDocumentSearchValues = {
    pageNumber: 1,
    numberPerPage: 20,
    status: initialStatus,
    filters: null,
    type: null,
    sortedBy: 'lineno',
    sortedType: 'asc'
};

export const DocumentList: React.FunctionComponent<RouteComponentProps> = props => {
    const {
        profile,
        showPdfViewer,
        showDialog,
    } = useContext(AppContext);

    const [searchValues, setSearchValues] = useState<IDocumentSearchValues>(initialValues);
    const [results, setResults] = useState<ISearchResult<IDocumentResult> | undefined>(undefined);
    const [selectedItems, setSelectedItems] = useState<IDocumentResult[]>([]);
    const [isLoading, { setFalse: hideLoading, setTrue: showLoading }] = useBoolean(false);
    const [isFileLoading, { setFalse: hideFileLoading, setTrue: showFileLoading }] = useBoolean(false);
    const [zipSelected, setZipSelected] = useState<string[]>([]);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;
    const isLargeDevice = modalResponsiveMode === ResponsiveMode.large || modalResponsiveMode === ResponsiveMode.xLarge;

    const service = useMemo(() => new DocumentService(), []);
    const pdfService = useMemo(() => new PdfService(), []);
    const { match: { url }, history, location: { state } } = props;
    const newUrl = `${url}/new`;
    const selectedPageIndex: number = searchValues.pageNumber - 1;
    const items = results ? results.items : [];

    const onGetResults = useCallback((values: IDocumentSearchValues, isMounted?: () => boolean) => {
        setSearchValues({ ...values, });
        setSelectedItems([]);

        showLoading();

        const submitValue = {
            keyword: values.keyword || null,
            status: values.status,
            signContractDate: values.signContractDate,
            filters: (values.filters && !isEmpty(values.filters))
                ? encodeURIComponent(JSON.stringify(values.filters))
                : null,
            pageNumber: values.pageNumber,
            numberPerPage: values.numberPerPage,
            sortedBy: values.sortedBy,
            sortedType: values.sortedType,
            siteId: profile?.site?.id,
        };

        const promise = service.searchResult(submitValue);

        promise.then(results => {
            if (isMounted && !isMounted()) {
                setResults(undefined);
                hideLoading();
                return
            }

            setResults(prev => ({ ...prev, ...results }));
            hideLoading();
        });
    }, [profile, service, setSearchValues, setResults, showLoading, hideLoading]);

    useEffect(() => {
        let isMounted = true;
        const locationState = state as { from: string, values: IDocumentSearchValues };
        let newValues = cloneDeep({ ...initialValues });
        if (locationState && locationState.values && locationState.from && locationState.from === 'form') {
            locationState.values.filters = null;
            newValues = { ...newValues, ...locationState.values };
        }

        onGetResults(newValues, () => isMounted);

        return () => {
            isMounted = false;
            setSearchValues(initialValues);
            setResults(undefined);
        };
    }, [state, onGetResults]);

    useEffect(() => {
        setSearchValues(prev => {
            var newSearchValue = cloneDeep(prev);
            if (newSearchValue.filters) {
                newSearchValue.filters = null;
            }
            return newSearchValue;
        });

    }, [modalResponsiveMode]);

    const getEditLink = (id: string) => ({ pathname: `${url}/${id}`, state: searchValues });

    const selection = new Selection({
        onSelectionChanged: () => {
            const selectionCount = selection.getSelectedCount();
            if (selectionCount > 0) {
                const newSelectedItems = selection.getSelection() as IDocumentResult[];
                setSelectedItems([...newSelectedItems]);
            } else {
                setSelectedItems([]);
            }
        },
    });

    const onClickViewPdf = (item: IDocumentResult) => {
        showPdfViewer({
            mode: 'all',
            documentId: item.id,
            pathId: null,
            permission: 'view-print',
        });
    };

    const onClickDownloadPdf = async (item: IDocumentResult) => {
        showFileLoading();

        await pdfService.downloadPdf(item.id!, err => {
            showDialog({
                title: 'Error',
                dialogContentProps: {
                    type: DialogType.normal,
                    subText: err,
                }
            });
        });

        hideFileLoading();
    };

    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const sortedBy = column.key as keyof IDocumentResult;
        const sortedType = sortedBy !== searchValues.sortedBy
            ? 'asc'
            : column.isSortedDescending ? 'asc' : 'desc';

        onGetResults({ ...searchValues, sortedBy, sortedType });
    }

    const _columns: IColumn[] = [
        {
            key: 'lineno',
            name: 'Line No.',
            fieldName: 'lineno',
            minWidth: 80,
            maxWidth: 80,
            onColumnClick: onColumnClick,
            onRender: (item: IDocumentResult) => {
                const lineno = item.lineno ? item.lineno : '';
                return <TextEditInline isNumber text={lineno} onChange={v => onChangeLineNo(item, v)} />;
            }
        },
        {
            key: 'name',
            name: 'Document name',
            fieldName: 'name',
            minWidth: 250,
            isMultiline: true,
            onColumnClick: onColumnClick,
            onRender: (item: IDocumentResult) => {
                return item.id && (
                    <Link title={item.abstract || item.name!} as={RouterLink} to={getEditLink(item.id)}>{item.name}</Link>
                )

            }
        },
        {
            key: 'type',
            name: 'Document type',
            fieldName: 'type',
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onColumnClick: onColumnClick,
        },

        {
            key: 'path',
            name: 'Document path',
            fieldName: 'path',
            minWidth: 130,
            maxWidth: 130,
            isMultiline: true,
            onRender: (item: IDocumentResult) => {
                if (!item.paths) {
                    return <></>;
                }
                return item.paths.map((p, i) => {
                    return <DocumentPathButton key={i} smallButtonLabel={(i + 1).toString()} path={p} isSmallButton />
                });
            }
        },
        {
            key: 'signContractDate',
            name: 'Sign Contract Date',
            fieldName: 'signContractDate',
            minWidth: 140,
            maxWidth: 140,
            onColumnClick: onColumnClick,
            onRender: (item: IDocumentResult) => {
                return <>{item.signContractDate && onFormatDate(new Date(item.signContractDate))}</>;
            }
        },
        {
            key: 'barcode',
            name: 'Barcode',
            fieldName: 'barcode',
            minWidth: 100,
            maxWidth: 100,
            onColumnClick: e => e.preventDefault(),
            onRender: (item: IDocumentResult) => {
                const barcode = item.barcode ? item.barcode : '';
                return <TextEditInline text={barcode} onChange={v => onChangeBarcode(item, v)} />;
            }
        },
        {
            key: 'view',
            name: 'View',
            minWidth: 80,
            maxWidth: 80,
            isMultiline: true,
            onRender: (item: IDocumentResult) => {
                return item.hasPdf && (
                    <Stack horizontal>
                        <IconButton
                            onClick={() => onClickViewPdf(item)}
                            styles={viewButtonStyles}
                            iconProps={{ iconName: 'View' }}
                        />
                        <IconButton
                            onClick={() => onClickDownloadPdf(item)}
                            styles={viewButtonStyles}
                            iconProps={{ iconName: 'Download' }}
                        />
                    </Stack>
                )
            }
        },
    ];

    let columns = cloneDeep(_columns);
    for (const column of columns) {
        if (column.key === searchValues.sortedBy) {
            column.isSorted = true;
            column.isSortedDescending = searchValues.sortedType === 'desc' ? true : false;
        } else {
            column.isSorted = false;
            column.isSortedDescending = false;
        }
    }

    const onChange = useCallback(<K extends keyof IDocumentSearchValues>(newValue: IDocumentSearchValues[K] | null, fieldName: K) => {
        if (!fieldName) {
            return;
        }
        const newValues: IDocumentSearchValues = { ...searchValues };
        newValues[fieldName] = newValue as IDocumentSearchValues[K];
        setSearchValues(newValues);
    }, [searchValues]);

    const onChangeLineNo = async (doc: IDocumentResult, newValue: string | undefined) => {
        if (newValue && Number(newValue) && Number(newValue) % 1 === 0) {
            showLoading();
            await service.changeLineNo(doc.id!, Number(newValue).toString(), () => {
                hideLoading();
                onGetResults({ ...searchValues });
            });
        } else {
            showDialog({
                title: `Error`,
                dialogContentProps: {
                    type: DialogType.normal,
                }
            }, () => {
                return (
                    <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                        Invalid Line No.
                    </MessageBar>
                );
            });
        }
    };

    const onChangeBarcode = async (doc: IDocumentResult, newValue: string | undefined) => {
        showLoading();
        await service.changeBarcode(doc.id!, newValue || '', () => {
            hideLoading();
            onGetResults({ ...searchValues });
        });
    };

    const onCreateClick = (ev: React.MouseEvent<HTMLHtmlElement>) => {
        ev.preventDefault();
        history.push(newUrl, searchValues);
    };

    const onSearchEnter = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.code === 'Enter' || ev.code === 'NumpadEnter') {
            onGetResults({ ...searchValues, pageNumber: 1, });
        }
    };

    const onSearchClick = () => {
        onGetResults({ ...searchValues, pageNumber: 1, });
    };

    const onSignContractDateChange = (date: Date | null | undefined) => {
        setSearchValues(prev => ({ ...prev, signContractDate: date ? new Date(date.setHours(0, 0, 0, 0)).toISOString() : null }))
    }

    const onFiltersChange = (filterValues: IFilterValues | null) => {

        const arrays = !filterValues
            ? null
            : Object.values(filterValues).map(value => value);

        const filters = !arrays ? null : ([] as string[]).concat.apply([], arrays);

        setSearchValues(prev => ({ ...prev, filters }));
    };

    const onNumberPerPageChange = (ev: React.FormEvent<HTMLElement>, option?: IDropdownOption<number> | undefined) => {
        const newNumberPerPage: number = option && option?.data ? option.data : searchValues.numberPerPage;
        onGetResults({ ...searchValues, pageNumber: 1, numberPerPage: newNumberPerPage });
    };

    const onSelectedPageIndex = (index: number) => {
        onGetResults({ ...searchValues, pageNumber: index + 1 });
    };

    const getDocKey = (item: IDocumentResult, index?: number): string => {
        return item.key;
    }

    const onClickAddZip = () => {
        const docIds = selectedItems ? selectedItems.filter(d => d.hasPdf).map(d => d.id!) : [];
        setZipSelected(prev => {
            const newZipDate = [...new Set([...cloneDeep(prev), ...docIds])];
            return [...newZipDate];
        });
    }

    const onClickDownloadZip = async () => {
        if (!zipSelected || isEmpty(zipSelected)) {
            return;
        }

        showFileLoading();

        await pdfService.downloadMultiplePdfs(zipSelected, err => {
            showDialog({
                title: 'Error',
                dialogContentProps: {
                    type: DialogType.normal,
                    subText: err,
                }
            });
        });

        hideFileLoading();
    };

    const onClickRemoveZip = () => {
        setZipSelected([]);
    };

    const onClickInactive = async () => {
        const docIds = selectedItems ? selectedItems.map(d => d.id!) : [];
        if (docIds && !isEmpty(docIds)) {
            showLoading();
            await service.inactives(docIds, () => {
                hideLoading();
                onGetResults({ ...searchValues });
            });
        }
    };

    const onClickActive = async () => {
        const docIds = selectedItems ? selectedItems.map(d => d.id!) : [];
        if (docIds && !isEmpty(docIds)) {
            showLoading();
            await service.actives(docIds, () => {
                hideLoading();
                onGetResults({ ...searchValues });
            });
        }
    };

    const hasSelected = selectedItems && !isEmpty(selectedItems);

    const onRenderAddToZip = () => {
        const validSelectedItems = hasSelected || zipSelected.length;
        if (isSmallDevice || isLargeDevice) {
            return (
                <Stack tokens={stackTokens} horizontal>
                    <CommandButton text='Add to ZIP' onClick={onClickAddZip} disabled={!validSelectedItems} />
                    <CommandButton
                        text={`ZIP (${zipSelected.length})`}
                        iconProps={{ iconName: 'Download' }}
                        onClick={onClickDownloadZip}
                        disabled={!zipSelected.length || !validSelectedItems}
                    />
                    <CommandButton
                        iconProps={{ iconName: 'CalculatorMultiply' }}
                        onClick={onClickRemoveZip}
                        disabled={!validSelectedItems}
                    />
                </Stack>
            );
        }
        else {
            return validSelectedItems && (
                <Stack tokens={stackTokens} horizontal>
                    <CommandButton text='Add to ZIP' onClick={onClickAddZip} />
                    <CommandButton
                        text={`ZIP (${zipSelected.length})`}
                        iconProps={{ iconName: 'Download' }}
                        onClick={onClickDownloadZip}
                        disabled={!zipSelected.length}
                    />
                    <CommandButton iconProps={{ iconName: 'CalculatorMultiply' }} onClick={onClickRemoveZip} />
                </Stack>
            );
        }
    };

    return (
        <Stack>
            <NavigationPane items={[listBreadcrumbItems] || []} />
            <Stack horizontal wrap={isSmallDevice || isLargeDevice} tokens={stackTokens} styles={{ root: { minHeight: 120 } }} >
                <Stack
                    horizontal
                    wrap={isSmallDevice || isLargeDevice}
                    tokens={stackTokens}
                    styles={{ root: { width: isSmallDevice || isLargeDevice ? '100%' : 500 } }}
                    horizontalAlign='space-between'
                >
                    <Stack>
                        <ActionButton iconProps={{ iconName: 'Add' }} href={newUrl} onClick={onCreateClick}>
                            Create
                        </ActionButton>
                        <Stack horizontal>
                            <CommandButton text='Inactive' iconProps={{ iconName: 'Delete' }} onClick={onClickInactive} disabled={!hasSelected} />
                            <CommandButton text='Active' iconProps={{ iconName: 'CheckMark' }} onClick={onClickActive} disabled={!hasSelected} />
                        </Stack>
                        {!(isSmallDevice || isLargeDevice) && onRenderAddToZip()}
                    </Stack>
                    <Stack tokens={stackTokens} styles={{ root: { paddingLeft: 20 } }} >
                        <Dropdown
                            placeholder="Status"
                            options={statusOptions}
                            styles={dropdownStyles}
                            selectedKey={searchValues.status}
                            onChange={(e, o) => onChange(o?.data, 'status')}
                        />
                        <DatePicker
                            placeholder="Sign Contract Date"
                            value={searchValues.signContractDate ? new Date(searchValues.signContractDate) : undefined}
                            onSelectDate={onSignContractDateChange}
                        />
                    </Stack>

                    {(isSmallDevice || isLargeDevice) && onRenderAddToZip()}
                </Stack>

                <Stack tokens={stackTokens} grow>
                    <TextField
                        onChange={(e, value) => onChange(value, 'keyword')}
                        placeholder="Search document name, parties and hashtag"
                        iconProps={{ iconName: 'Search' }}
                        onKeyDown={onSearchEnter}
                        value={searchValues.keyword || ''}
                    />
                    <Stack styles={{ root: { maxWidth: 500 } }}>
                        <DataTypeFilterSet onChange={onFiltersChange} stackProps={{ tokens: stackTokens }} />
                    </Stack>
                </Stack>

                <PrimaryButton text="Search" onClick={onSearchClick} />
            </Stack>

            {(!isLoading && results && items && !isEmpty(items)) ? (
                <>
                    <MarqueeSelection selection={selection} isEnabled={false}>
                        <Stack grow={1}>
                            <DetailsList
                                items={items}
                                columns={columns}
                                getKey={getDocKey}
                                setKey="multiple"
                                selectionMode={SelectionMode.multiple}
                                selection={selection}
                            />
                        </Stack>
                    </MarqueeSelection>

                    <Stack tokens={stackTokens} horizontal>
                        <Pagination
                            selectedPageIndex={selectedPageIndex}
                            onPageChange={onSelectedPageIndex}
                            pageCount={results.pageTotal}
                            itemsPerPage={searchValues.numberPerPage}
                            totalItemCount={results.total}
                            numberPerPageOptions={numberPerPageOptions}
                            defaultSelectedKey={searchValues.numberPerPage?.toString()}
                            onNumberPerPageChange={onNumberPerPageChange}
                        />
                    </Stack>
                </>
            ) : isLoading ? <Loading /> : <Text>No Result</Text>}

            {isFileLoading &&
                <Overlay>
                    <Stack styles={{ root: { height: '100%' } }} verticalAlign='center'>
                        <Loading />
                    </Stack>
                </Overlay>
            }

            <Debug object={zipSelected} label='selected zip' />
            <Debug object={searchValues} label='search values' />
            <Debug object={selectedItems} label='selected items' />
        </Stack>
    );
};