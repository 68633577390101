import { IRequestStatusKeys } from "../models";

export const flows: IRequestStatusKeys[] = [
    'draft',
    'waiting-vp-approve',
    'waiting-admin-verify',
    'waiting-clgvp-approve',
    'approved',
];

export const getNextStatus = (current: IRequestStatusKeys) => {
    const currentIndex = flows.findIndex(f => f === current);
    const nexStatus = flows[currentIndex + 1];
    return nexStatus;
};

export const getPrevStatus = (current: IRequestStatusKeys) => {
    const currentIndex = flows.findIndex(f => f === current);
    const nexStatus = flows[currentIndex - 1];
    return nexStatus;
};