import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { TextField } from "@fluentui/react/lib/TextField";
import React, { useState } from "react";
import { IRequest } from "../models";

export type IProcessLogAction = 'change-vp' | 'reject';

export interface IInactiveRequestDialogProps {
    item: IRequest;
    onSubmit?: (item: IRequest) => void;
    onDismiss?: () => void;
    disabled?: boolean;
}

const stackTokens: IStackTokens = { childrenGap: 10 };

export const InactiveRequestDialog: React.FunctionComponent<IInactiveRequestDialogProps> = (props) => {
    const { item, onSubmit, onDismiss, disabled } = props;
    const [state, setState] = useState<IRequest>(item);

    const _onSubmit = () => {
        if (onSubmit) {
            onSubmit(state);
        }

        if (onDismiss) {
            onDismiss();
        }
    }

    let canSubmit = true;
    if (!state.inactiveRemark) {
        canSubmit = false;
    }

    return (
        <Stack tokens={stackTokens}>
            <TextField
                label='Reason / Remark'
                multiline
                value={state.inactiveRemark || ''}
                onChange={(e, value) => setState(prev => ({ ...prev, inactiveRemark: value || null }))}
                disabled={disabled}
            />
            <Stack horizontal tokens={stackTokens}>
                <PrimaryButton
                    disabled={!canSubmit || disabled}
                    onClick={_onSubmit} text="Yes"
                />
                <DefaultButton onClick={onDismiss} text="No" />
            </Stack>
        </Stack>
    );
};