import { Link } from "@fluentui/react/lib/Link";
import { useBoolean } from "@fluentui/react-hooks";
import React from "react";
import { useState } from "react";
import { IconButton } from "@fluentui/react/lib/Button";
import { TextField } from "@fluentui/react/lib/TextField";
import { isInt } from "../tools";

export interface ITextEditInlineProps {
    text: string;
    isNumber?: boolean;
    maxNumber?: number;
    onChange: (value: string | undefined, prevValue: string | undefined) => void;
}

export const TextEditInline: React.FunctionComponent<ITextEditInlineProps> = (props) => {
    const { text: prevValue, isNumber, maxNumber, onChange } = props;
    const [value, setValue] = useState<string | undefined>(prevValue);
    const [isEditMode, { toggle }] = useBoolean(false);

    const onClickAccept = () => {
        onChange(value, prevValue);
        toggle();
    };

    const onClickCancel = () => {
        setValue(prevValue);
        toggle();
    };

    const onInputChange = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const element = ev.target as HTMLInputElement;
        const value = element.value;
        setValue(value);
    };

    let isValid = true;
    if (isNumber && !isInt(value, 1, maxNumber)) {
        isValid = false;
    }

    const onKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.code === 'Escape') {
            onClickCancel();
        }

        if (isValid && value && (ev.code === 'Enter' || ev.code === 'NumpadEnter')) {
            onClickAccept();
        }
    };

    return isEditMode ? (
        <span>
            <TextField autoFocus underlined value={value} onChange={onInputChange} onKeyDown={onKeyDown} />
            <IconButton disabled={!isValid} iconProps={{ iconName: 'Accept' }} onClick={onClickAccept} />
            <IconButton iconProps={{ iconName: 'CalculatorMultiply' }} onClick={onClickCancel} />
        </span>

    ) : (
        <Link onClick={toggle} >{value || '-'}</Link>
    );
};