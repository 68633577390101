import { IButtonStyles, PrimaryButton } from "@fluentui/react/lib/Button"
import { IStackProps, IStackTokens, Stack } from "@fluentui/react/lib/Stack"
import { Text } from "@fluentui/react/lib/Text"
import React, { useContext } from "react"
import { useHistory, useRouteMatch } from "react-router"
import { AppContext } from "../../contexts"
import { CardHeader } from "./CardHeader"

const buttonStyles: Partial<IButtonStyles> = {
    root: {
        height: 80,
        width: 80,
    }
};

const stackTokens: IStackTokens = { childrenGap: 10 };

export const GetStartCard: React.FunctionComponent<IStackProps> = (props) => {
    const { openSearchPanel } = useContext(AppContext);
    const history = useHistory();
    const { params: { site } } = useRouteMatch<{ site: string }>();

    const myRequestListLink = `/site/${site}/myrequest/new`;

    const onOpenNewRequest = (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();
        history.push(myRequestListLink);
    }
    
    return (
        <Stack {...props}>
            <CardHeader text='Getting Started' />
            <Stack grow={1} tokens={stackTokens} >
                <Stack tokens={stackTokens} horizontal verticalAlign='center'>
                    <PrimaryButton title='Advance Search' styles={buttonStyles} iconProps={{ iconName: 'Search' }} onClick={openSearchPanel} />
                    <Text>Search about documents you looking for view or print and Advance Search</Text>
                </Stack>
                <Stack tokens={stackTokens} horizontal verticalAlign='center'>
                    <PrimaryButton title='New Request' styles={buttonStyles} iconProps={{ iconName: 'Send' }} href={myRequestListLink} onClick={onOpenNewRequest} />
                    <Text>Create requests which need permission to access documents</Text>
                </Stack>
            </Stack>
        </Stack>
    )
};