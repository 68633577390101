import { IBreadcrumbItem } from "@fluentui/react/lib/Breadcrumb";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { Debug } from "../../../components/Debug";
import { NavigationPane } from "../../../components/NavigationPane";
import { listBreadcrumbItems } from "./DataTypeList";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { TextField } from "@fluentui/react/lib/TextField";
import { getDisplayValue, isEmpty, isInt } from "../../../tools";
import { IActiveStatus, IDataType, ISaveResponse } from "../../../models";
import { DataTypeService } from "../../../services";
import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react/lib/ChoiceGroup";
import { useMemo } from "react";
import { cloneDeep } from "lodash";
import { useContext } from "react";
import { AppContext } from "../../../contexts";
import { DialogType } from "@fluentui/react/lib/Dialog";
import { MessageBar, MessageBarType } from "@fluentui/react/lib/MessageBar";
import { useBoolean } from "@fluentui/react-hooks";
import { OverlayLoading } from "../../../components/OverlayLoading";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";

export type IFormMode = 'new' | 'edit' | 'readonly';

export interface IDataTypeValues {
    mode: IFormMode;
    data: IDataType;
}

const stackTokens: IStackTokens = { childrenGap: 10 };

const statusOptions: IChoiceGroupOption[] = [
    { key: 'active', text: getDisplayValue('active') },
    { key: 'inactive', text: getDisplayValue('inactive') },
];

const initialValues: IDataTypeValues = {
    mode: 'readonly',
    data: {
        id: null,
        key: '-1',
        name: null,
        lineno: null,
        dataNameCount: 0,
        status: 'active',
    }
};

export const DataTypeForm: React.FunctionComponent<RouteComponentProps<{ site: string, id: string }>> = (props) => {
    const { profile, showDialog } = useContext(AppContext);
    const [values, setValues] = useState<IDataTypeValues>(cloneDeep(initialValues));
    const [originalValues, setOriginalValues] = useState<IDataType | undefined>(undefined);
    const [maxLineNo, setMaxLineNo] = useState<string>("0");
    const [isLoading, { setFalse: hideLoading, setTrue: showLoading }] = useBoolean(false);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;
    const isLargeDevice = modalResponsiveMode === ResponsiveMode.large || modalResponsiveMode === ResponsiveMode.xLarge;

    const { match: { params: { site, id } }, history, location: { state } } = props;
    const service = useMemo(() => new DataTypeService(), []);
    const readonly = values.mode === 'readonly';

    useEffect(() => {
        let isMounted = true;

        async function initial() {
            try {
                var nextLineNo = await service.getNextLineNo();
                if (!nextLineNo) {
                    setValues(cloneDeep(initialValues));
                    setOriginalValues(undefined);
                    setMaxLineNo("0");
                    return;
                }

                if (!id || id === 'new') {
                    var initialData = cloneDeep(initialValues.data);
                    initialData.lineno = nextLineNo;

                    setMaxLineNo(nextLineNo);

                    setValues({
                        ...cloneDeep(initialValues),
                        data: initialData,
                        mode: 'new',
                    });

                    return;
                }

                const promise = service.get({ id });
                promise.then(data => {
                    if (!isMounted || !data || !data.id) {
                        setValues({ ...cloneDeep(initialValues), mode: 'readonly' });
                        setOriginalValues(undefined);
                        setMaxLineNo("0");
                        return;
                    }

                    setMaxLineNo((parseInt(nextLineNo!) - 1).toString());

                    setValues({
                        ...cloneDeep(initialValues),
                        data,
                        mode: 'edit',
                    });

                    setOriginalValues(cloneDeep(data));
                });

            } catch (error) {
                console.error(error);
            }
        }

        initial();

        return () => {
            setValues(cloneDeep(initialValues));
            setOriginalValues(undefined);
            setMaxLineNo("0");
        };
    }, [id, service]);

    const listLink = `/site/${site}/administrator/datatype`;

    const onClickListLink = (ev?: React.MouseEvent<HTMLElement>) => {
        ev?.preventDefault();
        history.push(listLink, { from: 'form', values: state });
    };

    const breadcrumbItems: IBreadcrumbItem[] = [
        { ...listBreadcrumbItems, href: listLink, onClick: onClickListLink },
        {
            key: 'form',
            text: originalValues?.name || (values.mode === 'new' ? 'New' : '...'),
        }
    ];

    const onChangeTextField = <K extends keyof IDataType>(newValue?: IDataType[K] | null, fieldName?: K) => {
        if (!fieldName) {
            return;
        }

        const newValues: IDataTypeValues = { ...values };
        newValues.data[fieldName] = newValue as IDataType[K];
        setValues(newValues);
    };

    const onChangeChoiceField = <K extends keyof IDataType>(newValue?: IDataType[K] | null, fieldName?: K) => {
        if (!fieldName) {
            return;
        }

        const newValues: IDataTypeValues = { ...values };
        newValues.data[fieldName] = newValue as IDataType[K];
        setValues(newValues);
    };

    const onClickReset = () => {
        if (!originalValues) {
            return;
        }

        setValues({ ...values, data: cloneDeep(originalValues) });
    };

    const onClickCancel = () => {
        onClickListLink();
    };

    const onSaveResponse = (action: string, saved: ISaveResponse<IDataType>, isBackToList: boolean = true) => {
        if (saved.isError) {
            showDialog({
                title: `${action} failed`,
                dialogContentProps: {
                    type: DialogType.normal,
                }
            }, () => {
                return (
                    <>
                        {saved.errorMessage &&
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {saved.errorMessage}
                            </MessageBar>
                        }
                        {saved.errorMessages && saved.errorMessages.map((err, i) => (
                            <MessageBar key={i}
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {err}
                            </MessageBar>
                        ))}
                    </>
                );
            });

            return;
        } else {
            onClickListLink();
        }
    };

    const onValidateForm = () => {
        const errorMessages: string[] = [];
        if (!values.data.name) {
            errorMessages.push('Name is required');
        }

        if (!values.data.lineno) {
            errorMessages.push('Line no is required');
        }

        if (values.data.lineno) {
            const max = parseInt(maxLineNo, 10);
            if (isNaN(values.data.lineno as any)) {
                errorMessages.push('Line no is required number only');
            } else if (!isInt(values.data.lineno, 1, max)) {
                errorMessages.push(`Invalid line no (maximum : ${maxLineNo})`);
            }
        }

        if (!isEmpty(errorMessages)) {
            showDialog({
                title: `Invalid form`,
                dialogContentProps: {
                    type: DialogType.normal,
                }
            }, () => {
                return (
                    <>
                        {errorMessages && errorMessages.map((err, i) => (
                            <MessageBar key={i}
                                messageBarType={MessageBarType.error}
                                isMultiline={true}
                            >
                                {err}
                            </MessageBar>
                        ))}
                    </>
                );
            });

            return false;
        }

        return true;
    };

    const onClickSave = async () => {
        if (!onValidateForm()) {
            return;
        }

        showLoading();

        if (values.mode === 'new') {
            const data = { ...values.data, site: profile?.site };
            await service.create(data, saved => onSaveResponse('Save data', saved));
        }

        if (values.mode === 'edit') {
            await service.update(id, values.data, saved => onSaveResponse('Save data', saved));
        }

        hideLoading();
    };

    return (
        <Stack tokens={stackTokens}>
            <NavigationPane items={breadcrumbItems || []} />

            <Stack horizontal wrap={isSmallDevice} tokens={stackTokens} >
                <Stack tokens={stackTokens} styles={{ root: { minWidth: isSmallDevice ? '100%' : isLargeDevice ? '80%' : '1000px' } }}>
                    <Stack styles={{ root: { maxWidth: isSmallDevice ? '100%' : 500 } }}>
                        <TextField
                            label='Name'
                            disabled={readonly}
                            value={values.data.name || ''}
                            onChange={(e, value) => onChangeTextField(value, 'name')}
                            required
                        />
                        <TextField
                            label='Line No.'
                            disabled={readonly}
                            value={values.data.lineno || ''}
                            onChange={(e, value) => onChangeTextField(value, 'lineno')}
                            required
                        />
                    </Stack>
                </Stack>
                <Stack >
                    <ChoiceGroup
                        selectedKey={values.data.status || ''}
                        options={statusOptions}
                        disabled={readonly}
                        label="Status"
                        onChange={(e, o) => onChangeChoiceField((o?.key as IActiveStatus) || 'active', 'status')}
                    />
                </Stack>
            </Stack>

            <Stack horizontal tokens={stackTokens}>
                {!readonly && <PrimaryButton text='Save' onClick={onClickSave} />}
                {values.mode === 'edit' && <DefaultButton text='Reset' onClick={onClickReset} />}
                <DefaultButton text='Cancel' onClick={onClickCancel} />
            </Stack>

            <Debug object={values} />

            <OverlayLoading isVisibled={isLoading} />
        </Stack>
    )
};