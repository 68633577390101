import { IButtonStyles, PrimaryButton } from "@fluentui/react/lib/Button"
import { IStackProps, IStackTokens, Stack } from "@fluentui/react/lib/Stack"
import { Text } from "@fluentui/react/lib/Text"
import React from "react"
import { useHistory, useRouteMatch } from "react-router";
import { CardHeader } from ".";

const buttonStyles: Partial<IButtonStyles> = {
    root: {
        height: 80,
        width: 80,
    }
};

const stackTokens: IStackTokens = { childrenGap: 10 };

export const DatabaseCard: React.FunctionComponent<IStackProps> = (props) => {
    const { params: { site } } = useRouteMatch<{ site: string }>();
    const history = useHistory();

    const databaseLink = `/site/${site}/database`;

    const onClick = (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();
        history.push(databaseLink);
    }

    return (
        <Stack {...props}>
            <CardHeader text='Database By Group Menu' />
            <Stack grow={1} tokens={stackTokens}>
                <Stack tokens={stackTokens} horizontal verticalAlign='center'>
                    <PrimaryButton title='Document Database' styles={buttonStyles} iconProps={{ iconName: 'GroupedList' }} href={databaseLink} onClick={onClick} />
                    <Text>Search document database by group menu</Text>
                </Stack>
            </Stack>
        </Stack>
    )
};