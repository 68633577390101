export const now: () => number[] = () => {
    const date = new Date();
    const d = date.getDate();
    const m = date.getMonth() + 1;
    const y = date.getFullYear();
    const h = date.getHours();
    const mm = date.getMinutes();
    const ss = date.getSeconds();
    const ms = date.getMilliseconds();
    return [d, m, y, h, mm, ss, ms];
};

const paddDigit = (n: number) => n || n === 0 ? (n.toString().length < 2 ? `0${n}` : n.toString()) : '';

export const onFormatDate = (date?: Date | null | undefined): string => {
    const dateString = !date ? '' : paddDigit(date.getDate()) + '/' + paddDigit((date.getMonth() + 1)) + '/' + date.getFullYear();
    return dateString;
};

export const onFormatDateTime = (date?: Date | null | undefined): string => {
    if (!date) {
        return '';
    }

    const dateString = onFormatDate(date);
    if (!dateString) {
        return '';
    }

    const timeString = [paddDigit(date.getHours()), paddDigit(date.getMinutes()), paddDigit(date.getSeconds())].join(':');
    return `${dateString} ${timeString}`;
};

export const getDateRange = (dateFrom: Date | null | undefined, dateTo?: Date | null | undefined): number | null => {
    if (!dateFrom || !dateTo) {
        return null;
    }

    // To calculate the time difference of two dates
    var diffTime = dateTo.getTime() - dateFrom.getTime();

    // To calculate the no. of days between two dates
    return Math.ceil(diffTime / (1000 * 3600 * 24));
}

export const onParseDateFromString = (newValue: string, prevValue?: Date | null | undefined): Date | null => {
    const previousValue = prevValue || new Date(); 
    const newValueParts = (newValue || '').trim().split('/');
    const day =
        newValueParts.length > 0 ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10))) : previousValue.getDate();
    const month =
        newValueParts.length > 1
            ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
            : previousValue.getMonth();
    let year = newValueParts.length > 2 ? parseInt(newValueParts[2], 10) : previousValue.getFullYear();
    if (year < 100) {
        year += previousValue.getFullYear() - (previousValue.getFullYear() % 100);
    }
    return new Date(year, month, day);
};