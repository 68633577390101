import { DefaultButton } from "@fluentui/react/lib/Button";
import { Panel, PanelType } from "@fluentui/react/lib/Panel";
import { IStackProps, IStackStyles, IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { Text } from "@fluentui/react/lib/Text";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useEffect } from "react";
import { PageContainer } from "../components/PageContainer";
import { IDocument, IRenderTree, ISearchDocumentResult, ISearchResult } from "../models";
import { DocumentService, SearchDocumentService } from "../services";
import { isEmpty } from "../tools";
import { useBoolean } from "@fluentui/react-hooks";
import { DocumentPathTree } from "../components/DocumentPathTree";
import { DocumentPathLabel } from "../components/DocumentPathLabel";
import { Loading } from "../components/Loading";
import { IColumnStyles, ISuggestionItemProps, SearchSuggestionItem } from "../components/SearchSuggestionItem";
import { useTheme } from "@fluentui/react/lib/Theme";
import { useContext } from "react";
import { AppContext } from "../contexts";
import { DetailsList, IColumn, SelectionMode } from "@fluentui/react/lib/DetailsList";
import { cloneDeep } from "lodash";
import { Pagination } from "../components/Pagination";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";
import { mergeStyles } from "@fluentui/react/lib/Styling";

export interface IDocPath {
    docId: string;
    path: string[]
}

export interface ISearchValues {
    pathIds: string[] | null;
    pageNumber: number;
    numberPerPage: number;
    sortedBy: keyof IDocument;
    sortedType: 'asc' | 'desc';
}

const stackTokens: IStackTokens = { childrenGap: 10 };

const columnStyles: IColumnStyles = {
    sequence: { width: 40, minWidth: 40, maxWidth: 40, textAlign: 'Right', padding: '0 8px 0 12px' },
    documentName: { flexGrow: 1, padding: '0 8px 0 12px' },
    parties: { width: 500, minWidth: 500, maxWidth: 500, padding: '0 8px 0 12px' },
    date: { width: 180, minWidth: 180, maxWidth: 180, padding: '0 8px 0 12px' },
};

const numberPerPageOptions: IDropdownOption<number>[] = [
    { key: '20', text: '20', data: 20 },
    { key: '50', text: '50', data: 50 },
    { key: '100', text: '100', data: 100 },
    { key: '200', text: '200', data: 200 },
];

const initialValues: ISearchValues = {
    pathIds: null,
    pageNumber: 1,
    numberPerPage: 20,
    sortedBy: 'lineno',
    sortedType: 'asc'
};

const className1 = mergeStyles({ width: '100%' });
const className2 = mergeStyles({ overflowY: 'hidden', overflowX: 'auto' });
const className3 = mergeStyles(className1, { minWidth: (40 + 500 + 500 + 180 + (4 * 20)) });

export const Database: React.FunctionComponent = () => {
    const theme = useTheme();
    const { profile } = useContext(AppContext);
    const [treeResults, setTreeResults] = useState<ISearchResult<IRenderTree> | undefined>(undefined);
    const [searchValues, setSearchValues] = useState<ISearchValues>(cloneDeep({ ...initialValues }));
    const [results, setResults] = useState<ISearchResult<ISearchDocumentResult> | undefined>(undefined);
    const [expanded, setExpanded] = useState<string[]>([]);
    const [selected, setSelected] = useState<string[]>([]);
    const [pathSelected, setPathSelected] = useState<IRenderTree | undefined>(undefined);
    const [isOpenPanel, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    const [isLoading, { setTrue: showLoading, setFalse: hideLoading }] = useBoolean(false);
    const [isTreeLoading, { setTrue: startLoadTree, setFalse: endLoadTree }] = useBoolean(false);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;

    const service = useMemo(() => new SearchDocumentService(), []);
    const selectedPageIndex: number = searchValues.pageNumber - 1;

    const onGetResults = useCallback((values: ISearchValues, isMounted?: (() => boolean) | undefined) => {
        setSearchValues({ ...values });

        if (!values.pathIds) {
            setResults(undefined);
            return;
        }

        showLoading();

        const submitValue = {
            tree: true,
            pathIds: JSON.stringify(values.pathIds),
            siteId: profile?.site?.id,
            pageNumber: values.pageNumber,
            numberPerPage: values.numberPerPage,
            sortedBy: values.sortedBy,
            sortedType: values.sortedType,
        };

        const promise = service.search(submitValue);

        promise.then(results => {
            if (isMounted && !isMounted()) {
                setResults(undefined);
                hideLoading();
                return;
            }

            setResults(results);
            hideLoading();
        });
    }, [service, profile, setSearchValues, showLoading, hideLoading]);

    useEffect(() => {
        (async function () {
            startLoadTree();

            const service = new DocumentService();
            const results = await service.treeResult({
                siteId: profile?.site?.id,
            });

            setTreeResults(results);

            endLoadTree();
        })();

        return () => {
            setTreeResults(undefined);
        };
    }, [profile, startLoadTree, endLoadTree]);

    useEffect(() => {
        let isMounted = true;
        if (pathSelected) {
            onGetResults(cloneDeep({ ...initialValues, pathIds: pathSelected.pathIds }), () => isMounted);
        }

        return () => {
            isMounted = false;
        };
    }, [pathSelected, onGetResults]);

    const trees = treeResults ? treeResults.items : null;

    const handleToggle = useCallback((event: React.ChangeEvent<{}>, nodeIds: string[]) => {
        if (event.cancelable) {
            event.preventDefault();
        }

        setExpanded(nodeIds);
    }, []);

    const handleSelect = useCallback((event: React.ChangeEvent<{}>, nodeIds: string[]) => {
        if (event.cancelable) {
            event.preventDefault();
        }

        setSelected(nodeIds);
    }, []);

    const onClickShowDocument = (nodes: IRenderTree) => {
        setPathSelected(nodes);
        dismissPanel();
    }

    const renderPathSelected = (pathTree: IRenderTree) => {
        const { pathItems } = pathTree;
        if (!pathItems || isEmpty(pathItems)) {
            return <></>;
        }

        return (
            <Stack wrap tokens={stackTokens} horizontal verticalAlign='center'>
                {pathItems.map((p, i) => (
                    <DocumentPathLabel
                        key={i}
                        dataTypeName={p.dataType.name!}
                        dataNameName={p.dataName!.name!}
                        showIcon={pathItems.length - 1 !== i}
                    />
                ))}
            </Stack>
        );
    };

    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const sortedBy = column.key as keyof IDocument;
        const sortedType = sortedBy !== searchValues.sortedBy
            ? 'asc'
            : column.isSortedDescending ? 'asc' : 'desc';

        onGetResults({ ...searchValues, sortedBy, sortedType });
    };

    const _columns: IColumn[] = [
        {
            key: 'no',
            name: 'No.',
            fieldName: 'no',
            minWidth: 40,
            maxWidth: 40,
        },
        {
            key: 'name',
            name: 'Document name',
            fieldName: 'name',
            minWidth: 500,
            onColumnClick: onColumnClick,
        }, {
            key: 'parties',
            name: 'Parties.',
            fieldName: 'parties',
            minWidth: 500,
            maxWidth: 500,
        }, {
            key: 'signContractDate',
            name: 'Date',
            fieldName: 'date',
            minWidth: 180,
            maxWidth: 180,
            onColumnClick: onColumnClick,
        },
    ];

    let columns = cloneDeep(_columns);
    for (const column of columns) {
        if (column.key === searchValues.sortedBy) {
            column.isSorted = true;
            column.isSortedDescending = searchValues.sortedType === 'desc' ? true : false;
        } else {
            column.isSorted = false;
            column.isSortedDescending = false;
        }
    }

    const onNumberPerPageChange = (ev: React.FormEvent<HTMLElement>, option?: IDropdownOption<number> | undefined) => {
        const newNumberPerPage: number = option && option?.data ? option.data : searchValues.numberPerPage;
        onGetResults({ ...searchValues, pageNumber: 1, numberPerPage: newNumberPerPage });
    };

    const onSelectedPageIndex = (index: number) => {
        onGetResults({ ...searchValues, pageNumber: index + 1 });
    };

    const renderResult = () => {
        if (!results || isEmpty(results.items)) {
            return <Text>No Result</Text>;
        }

        const itemStackProps: IStackProps = {
            styles: {
                root: {
                    margin: '10px 0 10px 0',
                    borderBottom: `1px solid ${theme?.palette.neutralQuaternaryAlt}`
                }
            }
        };

        const itemProps: ISuggestionItemProps[] = results.items.map((doc, i) => {
            return {
                key: i,
                isShowSequence: true,
                sequence: (i + 1),
                stackProps: itemStackProps,
                doc
            }
        })

        if (isSmallDevice) {
            return (
                <div className={className1}>

                    {itemProps.map((p, i) => <SearchSuggestionItem key={i} {...p} styles={columnStyles} />)}

                    <Stack tokens={stackTokens} horizontal>
                        <Pagination
                            selectedPageIndex={selectedPageIndex}
                            onPageChange={onSelectedPageIndex}
                            pageCount={results.pageTotal}
                            itemsPerPage={searchValues.numberPerPage}
                            totalItemCount={results.total}
                            numberPerPageOptions={numberPerPageOptions}
                            defaultSelectedKey={searchValues.numberPerPage?.toString()}
                            onNumberPerPageChange={onNumberPerPageChange}
                        />
                    </Stack>
                </div>
            );
        } else {
            return (
                <div className={className2}>
                    <div className={className3}>

                        <DetailsList
                            items={[]}
                            columns={columns}
                            setKey="multiple"
                            selectionMode={SelectionMode.none}
                        />

                        {itemProps.map((p, i) => <SearchSuggestionItem key={i} {...p} styles={columnStyles} />)}

                        <Stack tokens={stackTokens} horizontal>
                            <Pagination
                                selectedPageIndex={selectedPageIndex}
                                onPageChange={onSelectedPageIndex}
                                pageCount={results.pageTotal}
                                itemsPerPage={searchValues.numberPerPage}
                                totalItemCount={results.total}
                                numberPerPageOptions={numberPerPageOptions}
                                defaultSelectedKey={searchValues.numberPerPage?.toString()}
                                onNumberPerPageChange={onNumberPerPageChange}
                            />
                        </Stack>
                    </div>
                </div>
            );
        }
    };

    const databaseButtonText = isTreeLoading ? 'Loading...' : 'Database';

    const stackStyles: IStackStyles = {
        root: isSmallDevice ? { overflow: 'hidden', width: '100%' } : {}
    };

    const innerStackStyles: IStackStyles = {
        root: isSmallDevice ? { overflowX: 'scroll' } : {}
    };

    return (
        <PageContainer>
            <Stack horizontal>
                <DefaultButton
                    text={databaseButtonText}
                    iconProps={{ iconName: 'BulletedTreeList' }}
                    onClick={openPanel}
                    disabled={isTreeLoading}
                />
                {isTreeLoading && <Spinner size={SpinnerSize.xSmall} />}
            </Stack>

            {pathSelected ?
                <>
                    <Stack styles={{ root: { padding: '10px 5px ' } }}>
                        {renderPathSelected(pathSelected)}
                    </Stack>
                    <Stack grow={1}>
                        {isLoading ? (
                            <Loading />
                        ) : (
                            <Stack styles={{ root: { padding: '10px 5px' } }}>
                                {renderResult()}
                            </Stack>
                        )}
                    </Stack>
                </>
                :
                <Text>No path selected</Text>
            }

            <Panel
                headerText="Document path"
                isOpen={isOpenPanel}
                onDismiss={dismissPanel}
                type={PanelType.custom}
                customWidth={isSmallDevice ? '100%' : '90%'}
                allowTouchBodyScroll
            >
                {(trees && !isEmpty(trees)) &&
                    <Stack styles={stackStyles}>
                        <Stack styles={innerStackStyles}>
                            <Stack styles={{ root: { minWidth: 1000 } }}>
                                <DocumentPathTree
                                    trees={trees}
                                    expanded={expanded}
                                    selected={selected}
                                    handleToggle={handleToggle}
                                    handleSelect={handleSelect}
                                    onClickShowDocument={onClickShowDocument}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                }
            </Panel>
        </PageContainer>
    );
};

