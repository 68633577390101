import { INavLink, INavLinkGroup, INavStyles, Nav } from "@fluentui/react/lib/Nav";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { DefaultPalette } from "@fluentui/react/lib/Styling";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router";
import React, { useContext, useRef } from "react";
import { preparePath } from "../../tools";
import { Site } from "./Site/Site";
import { SiteAdmin } from "./SiteAdmin/SiteAdmin";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";
import { Panel, PanelType } from "@fluentui/react/lib/Panel";
import { AppContext } from "../../contexts";

const navStyles: Partial<INavStyles> = {
    root: {
        overflowY: 'auto',
    },
    chevronIcon: {
        transform: 'rotate(-90deg)', // rotate(-180deg)
        '.ms-Nav-chevronButton[aria-expanded="true"] &': {
            transform: 'rotate(0deg)',
        }
    },
    compositeLink: {
        '& button.ms-Button--action': {
            color: DefaultPalette.black,
        }
    }
};

const stackTokens: IStackTokens = { childrenGap: 10 };

export const SiteSetup: React.FunctionComponent<RouteComponentProps<{ site: string }>> = props => {
    const { isSideMenuOpen, hideSideMenu } = useContext(AppContext);
    const { match: { path, params: { site } }, location: { pathname }, history } = props;

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;
    const isLargeDevice = modalResponsiveMode === ResponsiveMode.large;

    const pageLink = `/site/${site}/sitesetup`;
    const _pathname = preparePath(pathname);
    const pathArray = _pathname.split('/');
    const selectedKey = pathArray[4];

    const navLinkGroups: INavLinkGroup[] = [
        {
            links: [
                {
                    key: 'site',
                    name: 'Site',
                    url: `${pageLink}/site`,
                },
                {
                    key: 'siteadmin',
                    name: 'Super Admin',
                    url: `${pageLink}/siteadmin`,
                },
            ],
        },
    ];

    const onLinkClick = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
        ev?.preventDefault();
        if (item) {
            history.push(item.url);
        }
    };

    const onRenderNav = () => {
        if (isSmallDevice || isLargeDevice) {
            return (
                <Panel
                    isOpen={isSideMenuOpen}
                    onDismiss={hideSideMenu}
                    type={PanelType.customNear}
                    customWidth={isLargeDevice ? '40%' : '70%'}
                    isLightDismiss
                >
                    <Nav
                        onLinkClick={onLinkClick}
                        selectedKey={selectedKey}
                        styles={navStyles}
                        groups={navLinkGroups}
                    />
                </Panel>
            );
        } else {
            return (
                <Stack tokens={stackTokens} styles={{ root: { height: 'calc(100vh - 190px)', width: 180, overflowY: 'auto' } }}>
                    <Nav
                        onLinkClick={onLinkClick}
                        selectedKey={selectedKey}
                        styles={navStyles}
                        groups={navLinkGroups}
                    />
                </Stack>
            );
        }
    };

    return (
        <Stack horizontal tokens={stackTokens}>
            {onRenderNav()}
            <Switch>
                <Route exact path={path} render={() => <Redirect to={`${_pathname}/site`} />} />
                <Route path={`${path}/site`} component={Site} />
                <Route path={`${path}/siteadmin`} component={SiteAdmin} />
            </Switch>
        </Stack>
    );
};