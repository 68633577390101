import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router";
import { PageContainer } from "../../../components/PageContainer";
import { preparePath } from "../../../tools";
import { ActivitiesReport } from "./ActivitiesReport";
import { DocumentReport } from "./DocumentReport";
import { ExpirationReport } from "./ExpirationReport";
import { RequestReport } from "./RequestReport";
import { UserReport } from "./UserReport";

export const Report: React.FunctionComponent<RouteComponentProps> = (props) => {
    const { match: { path }, location: { pathname } } = props;
    const _pathname = preparePath(pathname);

    return (
        < PageContainer center={false} >
            <Switch>
                <Route exact path={path} render={() => <Redirect to={`${_pathname}/requestreport`} />} />
                <Route path={`${path}/requestreport`} component={RequestReport} />
                <Route path={`${path}/userreport`} component={UserReport} />
                <Route path={`${path}/documentreport`} component={DocumentReport} />
                <Route path={`${path}/activitiesreport`} component={ActivitiesReport} />
                <Route path={`${path}/expirationreport`} component={ExpirationReport} />
            </Switch>
        </PageContainer >
    );
};