import React from "react";
import { useContext } from "react";
import { Redirect } from "react-router";
import { ApplicationPaths } from "../components/api-authorization/ApiAuthorizationConstants";
import { AppContext } from "../contexts";

export const Welcome: React.FunctionComponent = () => {
    const { profile, isAppLoading } = useContext(AppContext);

    if (isAppLoading) {
        return null;
    }

    if (profile) {
        const { site } = profile;
        const homePageUrl = `/site/${site?.name}/dashboard`;
        return <Redirect to={homePageUrl} />;
    }

    const loginPath = `${ApplicationPaths.Login}`;

    return (
        <Redirect to={loginPath} />
    );
};