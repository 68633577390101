import { Overlay } from "@fluentui/react/lib/Overlay";
import { Stack } from "@fluentui/react/lib/Stack";
import React from "react";
import { Loading } from "./Loading";

export interface IOverlayLoadingProps {
    isVisibled?: boolean;
}

export const OverlayLoading: React.FunctionComponent<IOverlayLoadingProps> = props => {
    const { isVisibled } = props;
    return isVisibled ? (
        <Overlay>
            <Stack styles={{ root: { height: '100%' } }} verticalAlign='center'>
                <Loading />
            </Stack>
        </Overlay>
    ) : null;
};