import { IPaginationProps as IFuiPaginationProps, IPaginationProps, Pagination as FuiPagination } from "@fluentui/react-experiments";
import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";
import { FontSizes, FontWeights } from "@fluentui/react/lib/Styling";
import React, { useRef } from "react";
import { numberWithCommas } from "../tools";

export interface IPaginationPropxs extends IFuiPaginationProps {
    numberPerPageOptions?: IDropdownOption<any>[];
    defaultSelectedKey?: string;
    onNumberPerPageChange?: (ev: React.FormEvent<HTMLElement>, option?: IDropdownOption<number> | undefined) => void;
}

export const Pagination: React.FunctionComponent<IPaginationPropxs> = (props) => {
    const { numberPerPageOptions, defaultSelectedKey, onNumberPerPageChange } = props;

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;

    const onRenderVisibleItemLabel = (props: IPaginationProps | undefined): JSX.Element | null => {
        if (!props) {
            return null;
        }

        if (props.itemsPerPage && props.totalItemCount) {
            const leftItemIndex = props.selectedPageIndex! * props.itemsPerPage + 1;
            const rightItemsIndex = Math.min((props.selectedPageIndex! + 1) * props.itemsPerPage, props.totalItemCount);
            const visibleItemLabel = `${leftItemIndex} ${props.strings!.divider} ${rightItemsIndex} ${props.strings!.of} ${numberWithCommas(props.totalItemCount)}`;
            return (
                <>
                    {visibleItemLabel}
                </>
            );
        }

        return null;
    };

    return (
        <>
            <FuiPagination
                {...props}
                format={'buttons'}
                firstPageIconProps={{ iconName: 'ChevronLeftEnd6' }}
                previousPageIconProps={{ iconName: 'ChevronLeftSmall' }}
                nextPageIconProps={{ iconName: 'ChevronRightSmall' }}
                lastPageIconProps={{ iconName: 'ChevronRightEnd6' }}
                numberOfPageButton={isSmallDevice ? 3 : undefined}
                styles={{
                    root: {
                        flexDirection: isSmallDevice ? 'column' : 'row',
                        '& > div': {
                            display: 'flex',
                            '& span.ms-Button-flexContainer': {
                                fontWeight: FontWeights.regular,
                            },
                            '& i.ms-Button-icon': {
                                fontWeight: FontWeights.regular,
                                fontSize: FontSizes.medium,
                            },
                            '& div.ms-TooltipHost > div': {
                                display: 'none'
                            }
                        },
                    }
                }}

                onRenderVisibleItemLabel={onRenderVisibleItemLabel}
            />
            <Dropdown
                styles={{ root: { width: isSmallDevice ? '100%' : undefined } }}
                options={numberPerPageOptions || []}
                defaultSelectedKey={defaultSelectedKey}
                onChange={onNumberPerPageChange}
            />
        </>
    );
};