import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { DialogFooter } from "@fluentui/react/lib/Dialog";
import { Icon, IIconStyles } from "@fluentui/react/lib/Icon";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";
import { IStackStyles, IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { ITextStyles, Text } from "@fluentui/react/lib/Text";
import { TextField } from "@fluentui/react/lib/TextField";
import { useTheme } from "@fluentui/react/lib/Theme";
import React, { useMemo, useRef, useState } from "react";
import { IEmployee, IRequest, ISearchParameters, IUser } from "../models";
import { EmployeeService, UserService } from "../services";
import { getNextStatus, isEmpty, onFormatDateTime } from "../tools";
import { IPickerItem, Picker } from "./picker";

export type IProcessLogAction = 'change-vp' | 'reject';

export interface IProcessLogProps {
    item: IRequest;
    displayOnly?: boolean;
    action?: IProcessLogAction;
    onSubmit?: (item: IRequest) => void;
    onDismiss?: () => void;
    disabled?: boolean;
}

export interface IProcessLogSectionStyles {
    iconName: string;
    iconStyles: Partial<IIconStyles>;
    textStyles: Partial<ITextStyles>;
}

const stackTokens: IStackTokens = { childrenGap: 10 };
const iconNextStepName = 'PageRight';
const iconSuccessName = 'SkypeCircleCheck';
const iconRejectName = 'SkypeCircleMinus';

export const ProcessLog: React.FunctionComponent<IProcessLogProps> = (props) => {
    const theme = useTheme();
    const { item, displayOnly, action, onSubmit, onDismiss, disabled } = props;
    const [state, setState] = useState<IRequest>(item);
    const employeeService = useMemo(() => new EmployeeService(), []);
    const userService = useMemo(() => new UserService(), []);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;
    const isLargeDevice = modalResponsiveMode === ResponsiveMode.large;

    const selectedVp: IPickerItem<IEmployee> | null = state.vpApprover
        ? { key: state.vpApprover.id!, name: state.vpApprover.name!, data: state.vpApprover }
        : null;

    const isRequested = item.status !== 'draft';
    const isVpApproved = item.vpApprover && item.vpApproveDate;
    const isVerified = item.adminApprover && item.adminApproveDate
    const isClgVpApproved = item.clgVpApprover && item.clgVpApproveDate;
    const isReject = action === 'reject';
    const isChangeVp = action === 'change-vp';

    const nextStepColor = theme.semanticColors.successIcon;
    const waitingColor = theme.palette.neutralTertiary;
    const rejectedColor = theme.semanticColors.errorIcon;

    const iconEnabledStyles: Partial<IIconStyles> = {
        root: {
            color: nextStepColor,
        }
    };

    const iconDisabledStyles: Partial<IIconStyles> = {
        root: {
            color: waitingColor,
        }
    };

    const iconRejectedStyles: Partial<IIconStyles> = {
        root: {
            color: rejectedColor,
        }
    };

    const textWaitingStyles: Partial<ITextStyles> = {
        root: {
            color: waitingColor,
        }
    };

    const requesterStyles: IProcessLogSectionStyles = {
        iconName: isRequested ? iconSuccessName : iconNextStepName,
        iconStyles: iconEnabledStyles,
        textStyles: {},
    };

    const vpApproverStyles: IProcessLogSectionStyles = {
        iconName: isVpApproved ? iconSuccessName : iconNextStepName,
        iconStyles: isRequested ? iconEnabledStyles : iconDisabledStyles,
        textStyles: !isRequested ? textWaitingStyles : {},
    };

    const adminApproverStyles: IProcessLogSectionStyles = {
        iconName: isVerified ? iconSuccessName : iconNextStepName,
        iconStyles: isVpApproved ? iconEnabledStyles : iconDisabledStyles,
        textStyles: !isVpApproved ? textWaitingStyles : {},
    };

    const clgVpApproverStyles: IProcessLogSectionStyles = {
        iconName: isClgVpApproved ? iconSuccessName : iconNextStepName,
        iconStyles: isVerified ? iconEnabledStyles : iconDisabledStyles,
        textStyles: !isVerified ? textWaitingStyles : {},
    };

    const nextStatus = getNextStatus(item.status!);
    let canSubmit = true;
    if (nextStatus === 'waiting-clgvp-approve' && !state.clgVpApprover && !isReject) {
        canSubmit = false;
    }

    if (isChangeVp && !state.vpApprover) {
        canSubmit = false;
    }

    const onVpChange = (items: IPickerItem<IEmployee>[] | undefined) => {
        const vpApprover = items && items[0] ? items[0].data || null : null;
        setState(prev => ({ ...prev, vpApprover }));
    };

    const onClgVpChange = (items: IPickerItem<IUser>[] | undefined) => {
        const clgVpApprover = items && items[0] ? items[0].data?.employee || null : null;
        setState(prev => ({ ...prev, clgVpApprover }));
    };

    const onMapClgVpFilers = (filter: string, defaultFilter: ISearchParameters) => {
        return { ...defaultFilter, role: 'clg-vp' };
    };

    const _onSubmit = () => {
        if (onSubmit) {
            onSubmit(state);
        }

        if (onDismiss) {
            onDismiss();
        }
    }

    let actionVpText = 'VP/SVP/EVP approve by';
    let actionAdminText = 'Verify by';
    let actionClgVpText = 'Legal VP approve by';

    if (isReject) {
        if (item.status === 'waiting-vp-approve') {
            actionVpText = 'VP/SVP/EVP reject by';
            vpApproverStyles.iconStyles = iconRejectedStyles;

            if (!state.remark) {
                canSubmit = false;
            }
        }

        if (item.status === 'waiting-admin-verify') {
            actionAdminText = 'Admin reject by';
            adminApproverStyles.iconStyles = iconRejectedStyles;

            if (!state.adminRemark) {
                canSubmit = false;
            }
        }

        if (item.status === 'waiting-clgvp-approve') {
            actionClgVpText = 'Legal VP reject by';
            clgVpApproverStyles.iconStyles = iconRejectedStyles

            if (!state.clgVpRemark) {
                canSubmit = false;
            }
        }
    }

    if (item.status === 'rejected') {
        if (item.clgVpApproveDate) {
            actionClgVpText = 'Legal VP reject by';
            clgVpApproverStyles.iconName = iconRejectName;
            clgVpApproverStyles.iconStyles = iconRejectedStyles;
        } else if (item.adminApproveDate) {
            actionAdminText = 'Admin reject by';
            adminApproverStyles.iconName = iconRejectName;
            adminApproverStyles.iconStyles = iconRejectedStyles;
            clgVpApproverStyles.textStyles = textWaitingStyles;
            clgVpApproverStyles.iconStyles = iconDisabledStyles;
        } else if (item.vpApproveDate) {
            actionVpText = 'VP/SVP/EVP reject by';
            vpApproverStyles.iconName = iconRejectName;
            vpApproverStyles.iconStyles = iconRejectedStyles;
            adminApproverStyles.textStyles = textWaitingStyles;
            adminApproverStyles.iconStyles = iconDisabledStyles;
            clgVpApproverStyles.textStyles = textWaitingStyles;
            clgVpApproverStyles.iconStyles = iconDisabledStyles;
        }
    }

    const stackStyles: IStackStyles = {
        root: isSmallDevice || isLargeDevice ? { overflow: 'hidden', width: '100%' } : {}
    };

    const innerStackStyles: IStackStyles = {
        root: isSmallDevice || isLargeDevice ? { overflowX: 'scroll' } : {}
    };

    const contentStackStyles: IStackStyles = {
        root: isSmallDevice || isLargeDevice ? { minWidth: 600, } : {}
    };

    return (
        <>
            <Stack tokens={stackTokens} >
                <Stack tokens={stackTokens} styles={stackStyles}>
                    <Stack tokens={stackTokens} styles={innerStackStyles}>
                        <Stack tokens={stackTokens} styles={contentStackStyles}>
                            <Stack tokens={stackTokens} horizontal>
                                <Stack styles={{ root: { width: 20 } }}>
                                    <Icon styles={requesterStyles.iconStyles} iconName={requesterStyles.iconName} />
                                </Stack>
                                <Stack styles={{ root: { width: 150, minWidth: 150 } }}><Text>Send request by</Text></Stack>
                                <Stack grow styles={{ root: { minWidth: 160 } }}><Text>{state.requestBy?.name!}</Text></Stack>
                                {(state.status !== 'draft' && state.requestDate) &&
                                    <Stack styles={{ root: { width: 150 } }}>
                                        <Text>{onFormatDateTime(new Date(state.requestDate))}</Text>
                                    </Stack>
                                }
                            </Stack>

                            {(state.requestFors && !isEmpty(state.requestFors)) && state.requestFors.map((rf, i) => {
                                return (
                                    <Stack tokens={stackTokens} horizontal key={i}>
                                        <Stack styles={{ root: { width: 20 } }}>
                                            <Icon styles={iconDisabledStyles} iconName='PageRight' />
                                        </Stack>
                                        <Stack styles={{ root: { width: 150, minWidth: 150 } }}>
                                            <Text styles={textWaitingStyles}>{rf.head?.name}</Text>
                                        </Stack>
                                        <Stack grow={1} styles={{ root: { minWidth: 160 } }}><Text>{rf.name}</Text></Stack>
                                    </Stack>
                                )
                            })}

                            {state.status !== 'draft' && <Stack tokens={stackTokens} horizontal>
                                <Stack styles={{ root: { width: 20 } }}>
                                    <Icon styles={vpApproverStyles.iconStyles} iconName={vpApproverStyles.iconName} />
                                </Stack>
                                <Stack styles={{ root: { width: 150, minWidth: 150 } }}>
                                    <Text styles={vpApproverStyles.textStyles}>{actionVpText}</Text>
                                </Stack>
                                <Stack grow={1} styles={{ root: { minWidth: 160 } }}><Text>{state.vpApprover?.name || '-'}</Text></Stack>
                                {state.vpApproveDate &&
                                    <Stack styles={{ root: { width: 150 } }}>
                                        <Text>{onFormatDateTime(new Date(state.vpApproveDate))}</Text>
                                    </Stack>
                                }
                            </Stack>}

                            <Stack tokens={stackTokens} horizontal>
                                <Stack styles={{ root: { width: 20 } }}>
                                    <Icon styles={adminApproverStyles.iconStyles} iconName={adminApproverStyles.iconName} />
                                </Stack>
                                <Stack styles={{ root: { width: 150, minWidth: 150 } }}>
                                    <Text styles={adminApproverStyles.textStyles}>{actionAdminText}</Text>
                                </Stack>
                                <Stack grow={1} styles={{ root: { minWidth: 160 } }}><Text>{state.adminApprover?.name || 'Administrator'}</Text></Stack>
                                {state.adminApproveDate &&
                                    <Stack styles={{ root: { width: 150 } }}>
                                        <Text>{onFormatDateTime(new Date(state.adminApproveDate))}</Text>
                                    </Stack>
                                }
                            </Stack>

                            <Stack tokens={stackTokens} horizontal>
                                <Stack styles={{ root: { width: 20 } }}>
                                    <Icon styles={clgVpApproverStyles.iconStyles} iconName={clgVpApproverStyles.iconName} />
                                </Stack>
                                <Stack styles={{ root: { width: 150, minWidth: 150 } }}>
                                    <Text styles={clgVpApproverStyles.textStyles}>{actionClgVpText}</Text>
                                </Stack>
                                <Stack grow={1} styles={{ root: { minWidth: 160 } }}><Text>{state.clgVpApprover?.name || '-'}</Text></Stack>
                                {state.clgVpApproveDate &&
                                    <Stack styles={{ root: { width: 150 } }}>
                                        <Text>{onFormatDateTime(new Date(state.clgVpApproveDate))}</Text>
                                    </Stack>
                                }
                            </Stack>

                        </Stack>
                    </Stack>
                </Stack>


                {!displayOnly && state.status === 'waiting-vp-approve' && isChangeVp &&
                    <Stack styles={{ root: { paddingTop: 10 } }}>
                        <Picker
                            pickOnlyOne
                            service={employeeService}
                            placeholder='Select VP/SVP/EVP (Search by ID , Name)'
                            pickerStyles={{ root: { width: '100%' } }}
                            displayOnFocus
                            selectedItems={selectedVp ? [selectedVp] : []}
                            onChange={onVpChange}
                            minimumTextLength={3}
                            disabled={disabled}
                        />
                    </Stack>
                }

                {!displayOnly && state.status !== 'draft' &&
                    <>
                        {state.status === 'waiting-admin-verify' && !isReject &&
                            <Stack styles={{ root: { paddingTop: 10 } }}>
                                <Picker
                                    pickOnlyOne
                                    service={userService}
                                    placeholder='Select Legal VP'
                                    pickerStyles={{ root: { width: '100%' } }}
                                    displayOnFocus
                                    onChange={onClgVpChange}
                                    onMapFilter={onMapClgVpFilers}
                                    disabled={disabled}
                                />
                            </Stack>
                        }

                        {state.status === 'waiting-vp-approve' && !isChangeVp &&
                            <Stack styles={{ root: { paddingTop: 10 } }}>
                                <TextField
                                    label='Reason / Remark'
                                    multiline
                                    value={state.remark || ''}
                                    onChange={(e, value) => setState(prev => ({ ...prev, remark: value || null }))}
                                    disabled={disabled}
                                />
                            </Stack>
                        }

                        {state.status === 'waiting-vp-approve' && isChangeVp &&
                            <Stack styles={{ root: { paddingTop: 10 } }}>
                                <TextField
                                    label='Reason / Remark'
                                    multiline
                                    value={state.adminRemark || ''}
                                    onChange={(e, value) => setState(prev => ({ ...prev, adminRemark: value || null }))}
                                    disabled={disabled}
                                />
                            </Stack>
                        }

                        {state.status === 'waiting-admin-verify' &&
                            <Stack styles={{ root: { paddingTop: 10 } }}>
                                <TextField
                                    label='Reason / Remark'
                                    multiline
                                    value={state.adminRemark || ''}
                                    onChange={(e, value) => setState(prev => ({ ...prev, adminRemark: value || null }))}
                                    disabled={disabled}
                                />
                            </Stack>
                        }

                        {state.status === 'waiting-clgvp-approve' &&
                            <Stack styles={{ root: { paddingTop: 10 } }}>
                                <TextField
                                    label='Reason / Remark'
                                    multiline
                                    value={state.clgVpRemark || ''}
                                    onChange={(e, value) => setState(prev => ({ ...prev, clgVpRemark: value || null }))}
                                    disabled={disabled}
                                />
                            </Stack>
                        }
                    </>
                }
            </Stack>

            {!displayOnly && onSubmit &&
                <DialogFooter styles={{ actionsRight: { textAlign: 'left' } }}>
                    <PrimaryButton
                        disabled={!canSubmit || disabled}
                        onClick={_onSubmit} text="Submit"
                    />
                    <DefaultButton onClick={onDismiss} text="Cancel" />
                </DialogFooter>
            }
        </>
    );
};