import React, { useContext } from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import { AppContext } from "../contexts";
import { Administrator, Dashboard, Database, MyApprove, MyRequest, Report, SiteSetup, UserGuide } from "../pages";

export const Site: React.FunctionComponent<RouteComponentProps<{ site: string }>> = (props) => {
    const { profile } = useContext(AppContext);
    const { match: { path } } = props

    if (!profile || !profile.employee) {
        return null;
    }

    return (
        <Switch>
            <Route path={path} component={Dashboard} exact />
            <Route path={`${path}/dashboard`} component={Dashboard} />
            <Route path={`${path}/database`} component={Database} />
            <Route path={`${path}/myrequest`} component={MyRequest} />
            <Route path={`${path}/myapprove`} component={MyApprove} />
            <Route path={`${path}/administrator`} component={Administrator} />
            <Route path={`${path}/userguide`} component={UserGuide} />
            <Route path={`${path}/report`} component={Report} />
            <Route path={`${path}/sitesetup`} component={SiteSetup} />
        </Switch>
    )
};
