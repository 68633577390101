import React from 'react';
import { Text } from '@fluentui/react/lib/Text';
import { IStackStyles, Stack } from '@fluentui/react/lib/Stack';
import { DefaultPalette } from '@fluentui/react/lib/Theme';
import img from '../assets/images/ptt-stripe.png';

const stackStyles: IStackStyles = {
  root: {
    height: 100,
    textAlign: 'center',
    borderTop: `1px solid ${DefaultPalette.neutralQuaternaryAlt}`,
    background: `url(${img})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  }
};

export const Footer: React.FunctionComponent = () => {
  return (
    <Stack verticalAlign='end' styles={stackStyles}>
      <Text styles={{ root: { paddingBottom: 10 } }}>
        Copyright © PTT Exploration and Production Plc. All Rights Reserved
      </Text>
    </Stack>
  )
}
