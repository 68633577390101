import { IBreadcrumbItem } from "@fluentui/react/lib/Breadcrumb";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { DetailsList, IColumn, SelectionMode } from "@fluentui/react/lib/DetailsList";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { TextField } from "@fluentui/react/lib/TextField";
import { Text } from "@fluentui/react/lib/Text";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link as RouterLink, RouteComponentProps } from "react-router-dom";
import { useBoolean } from "@fluentui/react-hooks";
import { Link } from "@fluentui/react/lib/Link";
import { IEmailTemplate, ISearchResult } from "../../../models";
import { isEmpty, preparePath } from "../../../tools";
import { cloneDeep } from "lodash";
import { EmailTemplateService } from "../../../services";
import { NavigationPane } from "../../../components/NavigationPane";
import { Debug } from "../../../components/Debug";
import { Pagination } from "../../../components/Pagination";
import { Loading } from "../../../components/Loading";
import { AppContext } from "../../../contexts";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";

export interface IEmailTemplateSearchValues {
    keyword?: string;
    pageNumber: number;
    numberPerPage: number;
    sortedBy: keyof IEmailTemplate;
    sortedType: 'asc' | 'desc';
}

export const listBreadcrumbItems: IBreadcrumbItem = {
    key: 'list',
    text: 'Email Template',
};

const stackTokens: IStackTokens = { childrenGap: 10 };

const numberPerPageOptions: IDropdownOption<number>[] = [
    { key: '20', text: '20', data: 20 },
    { key: '50', text: '50', data: 50 },
    { key: '100', text: '100', data: 100 },
    { key: '200', text: '200', data: 200 },
];

const initialValues: IEmailTemplateSearchValues = {
    pageNumber: 1,
    numberPerPage: 20,
    sortedBy: 'name',
    sortedType: 'asc'
};

export const EmailTemplateList: React.FunctionComponent<RouteComponentProps> = (props) => {
    const { profile } = useContext(AppContext);
    const [searchValues, setSearchValues] = useState<IEmailTemplateSearchValues>(initialValues);
    const [results, setResults] = useState<ISearchResult<IEmailTemplate> | undefined>(undefined);
    const [isLoading, { setFalse: hideLoading, setTrue: showLoading }] = useBoolean(false);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;
    const isLargeDevice = modalResponsiveMode === ResponsiveMode.large;

    const { match: { url }, location: { state } } = props;

    const _url = preparePath(url);
    const service = useMemo(() => new EmailTemplateService(), []);
    const items = results ? results.items : [];
    const selectedPageIndex: number = searchValues.pageNumber - 1;

    const onGetResults = useCallback((values: IEmailTemplateSearchValues, isMounted?: () => boolean) => {
        setSearchValues({ ...values });

        showLoading();

        const submitValue = {
            ...values,
            siteId: profile?.site?.id,
        };

        const promise = service.search(submitValue);

        promise.then(results => {
            if (isMounted && !isMounted()) {
                setResults(undefined);
                hideLoading();
                return
            }

            setResults(prev => ({ ...prev, ...results }));
            hideLoading();
        });
    }, [profile, service, setSearchValues, setResults, showLoading, hideLoading]);

    useEffect(() => {
        let isMounted = true;
        const locationState = state as { from: string, values: IEmailTemplateSearchValues };
        let newValues = cloneDeep({ ...initialValues });
        if (locationState && locationState.from && locationState.from === 'form') {
            newValues = { ...newValues, ...locationState.values };
        }
        onGetResults({ ...newValues }, () => isMounted);

        return () => {
            isMounted = false;
            setSearchValues(initialValues);
            setResults(undefined);
        }
    }, [state, onGetResults]);

    const onChange = <K extends keyof IEmailTemplateSearchValues>(newValue?: IEmailTemplateSearchValues[K] | null, fieldName?: K) => {
        if (!fieldName) {
            return;
        }

        const newValues: IEmailTemplateSearchValues = { ...searchValues };
        newValues[fieldName] = newValue as IEmailTemplateSearchValues[K];
        setSearchValues(newValues);
    };

    const onSearchEnter = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.code === 'Enter' || ev.code === 'NumpadEnter') {
            onGetResults({ ...searchValues, pageNumber: 1 });
        }
    };

    const onSearchClick = () => {
        onGetResults({ ...searchValues, pageNumber: 1 });
    };

    const onNumberPerPageChange = (ev: React.FormEvent<HTMLElement>, option?: IDropdownOption<number> | undefined) => {
        const newNumberPerPage: number = option && option?.data ? option.data : searchValues.numberPerPage;
        onGetResults({ ...searchValues, pageNumber: 1, numberPerPage: newNumberPerPage });
    };

    const onSelectedPageIndex = (index: number) => {
        onGetResults({ ...searchValues, pageNumber: index + 1 });
    };

    const getKey = (item: IEmailTemplate, index?: number): string => {
        return item.key;
    };

    const getEditLink = (id: string) => ({ pathname: `${_url}/${id}`, state: searchValues });
    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const sortedBy = column.key as keyof IEmailTemplate;
        const sortedType = sortedBy !== searchValues.sortedBy
            ? 'asc'
            : column.isSortedDescending ? 'asc' : 'desc';

        onGetResults({ ...searchValues, sortedBy, sortedType });
    }

    const _columns: IColumn[] = [
        {
            key: 'name',
            name: 'Email template',
            fieldName: 'name',
            minWidth: 300,
            maxWidth: 300,
            onColumnClick: onColumnClick,
            onRender: (item: IEmailTemplate) => {
                return item.id && (<Link as={RouterLink} to={getEditLink(item.id)}>{item.name}</Link>)

            }
        },
        {
            key: 'description',
            name: 'Description',
            fieldName: 'description',
            minWidth: 130,
            isMultiline: true,
            onColumnClick: onColumnClick,
        },
    ];

    let columns = cloneDeep(_columns);
    for (const column of columns) {
        if (column.key === searchValues.sortedBy) {
            column.isSorted = true;
            column.isSortedDescending = searchValues.sortedType === 'desc' ? true : false;
        } else {
            column.isSorted = false;
            column.isSortedDescending = false;
        }
    }

    return (
        <Stack>
            <NavigationPane items={[listBreadcrumbItems] || []} />
            <Stack horizontal wrap={isSmallDevice || isLargeDevice} tokens={stackTokens} verticalAlign='center'>
                <Stack tokens={stackTokens}
                    horizontal styles={{ root: { width: isSmallDevice || isLargeDevice ? '100%' : 500 } }}
                    verticalAlign='center'
                    horizontalAlign='space-between'
                >
                </Stack>
                <Stack grow>
                    <TextField
                        placeholder="Search email name"
                        iconProps={{ iconName: 'Search' }}
                        onKeyDown={onSearchEnter}
                        onChange={(e, value) => onChange(value, 'keyword')}
                        value={searchValues.keyword || ''}
                    />
                </Stack>

                <PrimaryButton text="Search" onClick={onSearchClick} />
            </Stack>

            {(!isLoading && results && items && !isEmpty(items)) ? (
                <>
                    <Stack grow={1}>
                        <DetailsList
                            items={items}
                            columns={columns}
                            getKey={getKey}
                            setKey="multiple"
                            selectionMode={SelectionMode.none}
                        />
                    </Stack>

                    <Stack tokens={stackTokens} horizontal>
                        <Pagination
                            selectedPageIndex={selectedPageIndex}
                            onPageChange={onSelectedPageIndex}
                            pageCount={results.pageTotal}
                            itemsPerPage={searchValues.numberPerPage}
                            totalItemCount={results.total}
                            numberPerPageOptions={numberPerPageOptions}
                            defaultSelectedKey={searchValues.numberPerPage?.toString()}
                            onNumberPerPageChange={onNumberPerPageChange}
                        />
                    </Stack>
                </>
            ) : isLoading ? <Loading /> : <Text>No Result</Text>}

            <Debug object={searchValues} />
        </Stack>
    );
};