import { IconButton } from "@fluentui/react/lib/Button";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";
import { IStackProps, IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import React, { useRef, useState } from "react";
import { IDataName, IDataType, ISearchParameters } from "../models";
import { DataNameService, DataTypeService } from "../services";
import { IPickerItem, Picker } from "./picker";

export interface IDataTypeFilterItem {
    name: string;
    typeItem: IPickerItem<IDataType> | undefined;
    nameItems: IPickerItem<IDataName>[] | undefined;
}

export interface DataTypeMultipleFilterProps {
    name: string;
    stackProps?: IStackProps;
    typeSelected?: IPickerItem<IDataType>;
    excludeTypeKeys?: string[];
    index?: number;
    onRemove?: (filterItem: IDataTypeFilterItem) => void;
    onTypeSelect?: (item: IPickerItem<IDataType> | undefined) => IPickerItem<IDataType> | Promise<IPickerItem<IDataType>> | null;
    onTypeChange?: (items: IPickerItem<IDataType>[] | undefined) => void | undefined;
    onNameSelect?: (item: IPickerItem<IDataName> | undefined) => IPickerItem<IDataName> | Promise<IPickerItem<IDataName>> | null;
    onNameChange?: (items: IPickerItem<IDataName>[] | undefined) => void | undefined;
    onChange?: (filterItem: IDataTypeFilterItem, shouldRemove?: boolean, prevTypeItem?: IPickerItem<IDataType> | undefined) => void;
}

const className1 = mergeStyles({ alignSelf: 'center', width: 30 });
const stackTokens: IStackTokens = { childrenGap: 10 };

export const DataTypeMultipleFilter: React.FunctionComponent<DataTypeMultipleFilterProps> = (props) => {
    const { stackProps, excludeTypeKeys, onRemove, name, onChange, index } = props;
    const [typeItem, setTypeItem] = useState<IPickerItem<IDataType> | undefined>(undefined);
    const [nameItems, setNameItems] = useState<IPickerItem<IDataName>[] | undefined>(undefined);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;

    // Services
    const dataTypeService = new DataTypeService();
    const dataNameService = new DataNameService();

    const isFirst = index && index !== 0 ? false : true;

    const onMapTypeResults: (results: IDataType[]) => IPickerItem<IDataType>[] = (results) => {
        let mapped = results.map(d => ({
            key: d.key,
            name: d.name!,
            data: d,
        }));

        if (excludeTypeKeys) {
            mapped = mapped.filter(i => !excludeTypeKeys.some(k => k === i.key));
        }

        return mapped;
    }

    const onMapNameResults: (results: IDataName[]) => IPickerItem<IDataName>[] = (results) => {
        return results.map(d => ({
            key: d.key,
            name: d.name!,
            data: d,
        }));
    }

    const _onTypeChange: (items: IPickerItem<IDataType>[] | undefined) => void | undefined = (items) => {
        const item = items && items.length ? items[0] : undefined;

        if (onChange) {
            onChange({ name, typeItem: item, nameItems: undefined }, !item, typeItem);
        }

        setTypeItem(item);
        setNameItems([]);
    };

    const _onNameChange: (items: IPickerItem<IDataName>[] | undefined) => void | undefined = (items) => {
        if (onChange) {
            onChange({ name, typeItem: typeItem, nameItems: items }, false, typeItem);
        }

        setNameItems(items);
    };

    const _onRemove = () => {
        if (onRemove) {
            onRemove({ name, typeItem, nameItems });
        }
    };

    const onMapDataNameFilter = (filter: string, defaultFilter: ISearchParameters) => {
        const { data: type } = typeItem || { data: null };
        return { ...defaultFilter, typeId: type ? type.id : null };
    };

    return (
        <Stack tokens={stackTokens} wrap={isSmallDevice} {...stackProps} horizontal>
            <Stack tokens={stackTokens} horizontal styles={{ root: { width: isSmallDevice ? '100%' : undefined } }}>
                <div className={className1}>{!isFirst ? 'and' : ''}</div>
                <Picker
                    service={dataTypeService}
                    onMapResults={onMapTypeResults}
                    pickOnlyOne
                    placeholder='Select data type'
                    onChange={_onTypeChange}
                    selectedItems={typeItem ? [typeItem] : []}
                />
            </Stack>
            <Stack tokens={stackTokens} horizontal>
                <Picker
                    service={dataNameService}
                    onMapResults={onMapNameResults}
                    onMapFilter={onMapDataNameFilter}
                    placeholder='Select data name'
                    onChange={_onNameChange}
                    disabled={!typeItem}
                    selectedItems={nameItems ? [...nameItems] : []}
                />
                <IconButton iconProps={{ iconName: 'Delete' }} onClick={_onRemove} />
            </Stack>
        </Stack>
    )
};