import { ApiService } from ".";
import authService from "../components/api-authorization/AuthorizeService";
import { IParameters, ISearchParameters, ISearchResult, IPdfViewer, ISaveResponse } from "../models";
import { getCookie } from "../tools";

export class PdfService implements ApiService<any> {
    get(parameters: IParameters): Promise<any> {
        throw new Error("Method not implemented.");
    }
    search(parameters: ISearchParameters): Promise<ISearchResult<any>> {
        throw new Error("Method not implemented.");
    }

    async uploadPdf(id: string, file: File, callback?: (response: ISaveResponse<any>) => void): Promise<void> {
        try {
            const token = await authService.getAccessToken();
            const formData = new FormData();
            formData.append('file', file);
            const response = await fetch(`api/pdf/upload/${id}/pdf`, {
                method: 'POST',
                headers: !token ? {} : {
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
                },
                body: formData,
            });
            const json = await response.json();

            if (!response.ok) {
                throw new Error(`Status: ${json.status} ${json.title}`);
            }

            if (callback) {
                callback(json);
            }
        }
        catch (err: any) {
            if (callback) {
                callback({
                    isError: true,
                    errorMessage: typeof err === 'string' ? err : err.message
                });
            }
        }
    }

    async getTempPdfFileName(viewer: IPdfViewer, callback?: (message: string) => void): Promise<string | null> {
        const token = await authService.getAccessToken();
        const docId = viewer.documentId;
        let queryString = '';
        queryString += `?pathId=${viewer.pathId || ''}`;
        queryString += `&mode=${viewer.mode || ''}`;

        if (viewer.requestId) {
            queryString += `&requestId=${viewer.requestId || ''}`;
        }

        const response = await fetch(`api/pdf/path/${docId}${queryString}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        });

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            const err: ISaveResponse<any> = await response.json();
            if (callback) {
                callback(err.errorMessage || 'Unknown error while getting a pdf');
            }

            return null;
        }

        const text = await response.text();
        return text;
    }

    async downloadPdf(docId: string, callback?: (message: string) => void): Promise<void> {
        const token = await authService.getAccessToken();
        const response = await fetch(`api/pdf/download/${docId}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        });

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            const err: ISaveResponse<any> = await response.json();
            if (callback) {
                callback(err.errorMessage || 'Unknown error while downloading pdf');
            }

            return;
        }

        const filename = this.getFileName(response, 'document.pdf');
        const blob = await response.blob();
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode!.removeChild(link);
    }

    async downloadMultiplePdfs(docIds: string[], callback?: (message: string) => void): Promise<void> {
        const token = await authService.getAccessToken();
        const query = new URLSearchParams();
        for (let i = 0; i < docIds.length; i++) {
            query.append("docIds", docIds[i]);
        }

        const queryString = query.toString();
        const response = await fetch(`api/pdf/multiple/download?${queryString}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        });

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            const err: ISaveResponse<any> = await response.json();
            if (callback) {
                callback(err.errorMessage || 'Unknown error while getting pdf(s)');
            }

            return;
        }

        const filename = this.getFileName(response, 'documents.zip');
        const blob = await response.blob();
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode!.removeChild(link);
    }

    getFileName(response: Response, defaultName: string) {
        let filename = defaultName;
        var disposition = response.headers.get('content-disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }

        return filename;
    }
}