import { IStackProps, Stack } from "@fluentui/react/lib/Stack";
import { Text } from "@fluentui/react/lib/Text";
import React, { useContext } from "react";
import { AppContext } from "../contexts";

export interface DebugProps extends IStackProps {
    object: any;
    label?: string;
}

export const Debug: React.FunctionComponent<DebugProps> = (props) => {
    const { debug } = useContext(AppContext);

    if (!debug) {
        return null;
    }

    return (
        <Stack {...props}>
            {props.label && <Text>{props.label}</Text>}
            <pre>{JSON.stringify(props.object, undefined, 2)}</pre>
        </Stack>
    )
};