import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import { PageContainer } from "../../../components/PageContainer";
import { SiteForm } from "./SiteForm";
import { SiteList } from "./SiteList";

export const Site: React.FunctionComponent<RouteComponentProps> = props => {
    const { match: { path } } = props;

    return (
        <PageContainer center={false} >
            <Switch>
                <Route exact path={path} component={SiteList} />
                <Route path={`${path}/new`} component={SiteForm} />
                <Route path={`${path}/:id`} component={SiteForm} />
            </Switch>
        </PageContainer>
    );
};