import { IBreadcrumbItem } from "@fluentui/react/lib/Breadcrumb";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { IStackTokens, Stack } from "@fluentui/react/lib/Stack";
import { TextField } from "@fluentui/react/lib/TextField";
import { useBoolean } from "@fluentui/react-hooks";
import React, { useCallback, useContext, useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { RouteComponentProps } from "react-router";
import { DataTypeFilterSet, IFilterValues } from "../../../components/DataTypeFilterSet";
import { DatePicker } from "../../../components/DatePicker";
import { NavigationPane } from "../../../components/NavigationPane";
import { IActiveStatus, IDocumentResult, ISearchResult, IUserReport } from "../../../models";
import { getDisplayValue, isEmpty, onFormatDate } from "../../../tools";
import { Loading } from "../../../components/Loading";
import { DetailsList, IColumn, SelectionMode } from "@fluentui/react/lib/DetailsList";
import { Text } from "@fluentui/react/lib/Text";
import { AppContext } from "../../../contexts";
import { ReportService } from "../../../services";
import { useMemo } from "react";
import { cloneDeep } from "lodash";
import { Pagination } from "../../../components/Pagination";
import { Debug } from "../../../components/Debug";
import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react/lib/ChoiceGroup";
import { DialogType } from "@fluentui/react/lib/Dialog";
import { OverlayLoading } from "../../../components/OverlayLoading";
import { ResponsiveMode, useResponsiveMode } from "@fluentui/react/lib/ResponsiveMode";

export interface IUserReportSearchValues {
    keyword?: string;
    dateFrom?: string | null;
    dateTo?: string | null;
    status?: IActiveStatus;
    filters?: string[] | null;
    pageNumber: number;
    numberPerPage: number;
    sortedBy: keyof IUserReport;
    sortedType: 'asc' | 'desc';
}

export const listBreadcrumbItems: IBreadcrumbItem = {
    key: 'list',
    text: 'User Report',
};

const stackTokens: IStackTokens = { childrenGap: 10 };

const numberPerPageOptions: IDropdownOption<number>[] = [
    { key: '20', text: '20', data: 20 },
    { key: '50', text: '50', data: 50 },
    { key: '100', text: '100', data: 100 },
    { key: '200', text: '200', data: 200 },
];

const statusOptions: IChoiceGroupOption[] = [
    { key: 'all', text: getDisplayValue('all') },
    { key: 'active', text: getDisplayValue('active') },
    { key: 'inactive', text: getDisplayValue('inactive') },
];

const initialValues: IUserReportSearchValues = {
    pageNumber: 1,
    numberPerPage: 20,
    status: 'all',
    filters: null,
    sortedBy: 'userName',
    sortedType: 'asc'
};

export const UserReport: React.FunctionComponent<RouteComponentProps> = props => {
    const { profile, showDialog } = useContext(AppContext);
    const [searchValues, setSearchValues] = useState<IUserReportSearchValues>(initialValues);
    const [results, setResults] = useState<ISearchResult<IUserReport> | undefined>(undefined);
    const [isLoading, { setFalse: hideLoading, setTrue: showLoading }] = useBoolean(false);
    const [isFileLoading, { setFalse: hideFileLoading, setTrue: showFileLoading }] = useBoolean(false);

    const rootRef = useRef<HTMLDivElement>(null);
    const modalResponsiveMode = useResponsiveMode(rootRef);
    const isSmallDevice = modalResponsiveMode === ResponsiveMode.small || modalResponsiveMode === ResponsiveMode.medium;

    const service = useMemo(() => new ReportService(), []);
    const selectedPageIndex: number = searchValues.pageNumber - 1;
    const items = results ? results.items : [];

    const onGetResults = useCallback((values: IUserReportSearchValues, isMounted?: () => boolean) => {
        setSearchValues({ ...values });

        showLoading();

        const submitValue = {
            keyword: values.keyword || null,
            status: values.status,
            dateFrom: values.dateFrom,
            dateTo: values.dateTo,
            filters: values.filters ? encodeURIComponent(JSON.stringify(values.filters)) : null,
            pageNumber: values.pageNumber,
            numberPerPage: values.numberPerPage,
            sortedBy: values.sortedBy,
            sortedType: values.sortedType,
            siteId: profile?.site?.id,
        };

        const promise = service.userSearch(submitValue);

        promise.then(results => {
            if (isMounted && !isMounted()) {
                setResults(undefined);
                hideLoading();
                return
            }

            setResults(prev => ({ ...prev, ...results }));
            hideLoading();
        });
    }, [profile, service, setSearchValues, setResults, showLoading, hideLoading]);

    useEffect(() => {
        let isMounted = true;
        let newValues = cloneDeep({ ...initialValues });
        onGetResults({ ...newValues }, () => isMounted);

        return () => {
            isMounted = false;
            setSearchValues(initialValues);
            setResults(undefined);
        };
    }, [onGetResults]);

    const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const sortedBy = column.key as keyof IUserReport;
        const sortedType = sortedBy !== searchValues.sortedBy
            ? 'asc'
            : column.isSortedDescending ? 'asc' : 'desc';

        onGetResults({ ...searchValues, sortedBy, sortedType });
    }

    const _columns: IColumn[] = [
        {
            key: 'userName',
            name: 'User',
            fieldName: 'userName',
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onColumnClick: onColumnClick,
        },
        {
            key: 'documentName',
            name: 'Document name',
            fieldName: 'documentName',
            minWidth: 250,
            isMultiline: true,
            onColumnClick: onColumnClick,
        },
        {
            key: 'documentType',
            name: 'Document type',
            fieldName: 'documentType',
            minWidth: 150,
            maxWidth: 150,
            isMultiline: true,
            onColumnClick: onColumnClick,
        },
        {
            key: 'documentPath',
            name: 'Document Path',
            fieldName: 'documentPath',
            minWidth: 200,
            maxWidth: 200,
            onRender: (item: IUserReport) => {
                var pathText = item.documentPath ? item.documentPath.join(" > ") : '';
                return <span title={pathText}>{pathText}</span>;
            }
        },
        {
            key: 'signContractDate',
            name: 'Sign Contract Date',
            fieldName: 'signContractDate',
            minWidth: 140,
            maxWidth: 140,
            onColumnClick: onColumnClick,
            onRender: (item: IUserReport) => {
                return <>{item.signContractDate && onFormatDate(new Date(item.signContractDate))}</>;
            }
        },
        {
            key: 'status',
            name: 'Status',
            fieldName: 'status',
            minWidth: 90,
            maxWidth: 90,
            isMultiline: true,
            onColumnClick: onColumnClick,
            onRender: (item: IUserReport) => {
                return <>{getDisplayValue(item.status)}</>;
            }
        },
        {
            key: 'requestStartDate',
            name: 'Request Date From',
            fieldName: 'requestStartDate',
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: IUserReport) => {
                return <>{item.requestStartDate && onFormatDate(new Date(item.requestStartDate))}</>;
            }
        },
        {
            key: 'requestEndDate',
            name: 'Request Date To',
            fieldName: 'requestEndDate',
            minWidth: 130,
            maxWidth: 130,
            onRender: (item: IUserReport) => {
                return <>{item.requestEndDate && onFormatDate(new Date(item.requestEndDate))}</>;
            }
        },
        {
            key: 'requestType',
            name: 'Request Type',
            fieldName: 'requestType',
            minWidth: 130,
        },
    ];

    let columns = cloneDeep(_columns);
    for (const column of columns) {
        if (column.key === searchValues.sortedBy) {
            column.isSorted = true;
            column.isSortedDescending = searchValues.sortedType === 'desc' ? true : false;
        } else {
            column.isSorted = false;
            column.isSortedDescending = false;
        }
    }

    const onChange = useCallback(<K extends keyof IUserReportSearchValues>(newValue: IUserReportSearchValues[K] | null, fieldName: K) => {
        if (!fieldName) {
            return;
        }
        const newValues: IUserReportSearchValues = { ...searchValues };
        newValues[fieldName] = newValue as IUserReportSearchValues[K];
        setSearchValues(newValues);
    }, [searchValues]);

    const onChangeChoiceField = useCallback(<K extends keyof IUserReportSearchValues>(newValue: IUserReportSearchValues[K] | null, fieldName: K) => {
        const newValues: IUserReportSearchValues = { ...searchValues };
        newValues[fieldName] = newValue as IUserReportSearchValues[K];
        setSearchValues(newValues);
    }, [searchValues]);

    const onSearchEnter = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.code === 'Enter' || ev.code === 'NumpadEnter') {
            onGetResults({ ...searchValues, pageNumber: 1 });
        }
    };

    const onSearchClick = () => {
        onGetResults({ ...searchValues, pageNumber: 1 });
    };

    const onDateFromChange = (date: Date | null | undefined) => {
        setSearchValues(prev => ({ ...prev, dateFrom: date ? new Date(date.setHours(0, 0, 0, 0)).toISOString() : null }))
    }

    const onDateToChange = (date: Date | null | undefined) => {
        setSearchValues(prev => ({ ...prev, dateTo: date ? new Date(date.setHours(0, 0, 0, 0)).toISOString() : null }))
    }

    const onFiltersChange = (filterValues: IFilterValues | null) => {
        const arrays = !filterValues
            ? null
            : Object.values(filterValues).map(value => value);

        const filters = !arrays ? null : ([] as string[]).concat.apply([], arrays);

        setSearchValues(prev => ({ ...prev, filters }));
    };

    const onNumberPerPageChange = (ev: React.FormEvent<HTMLElement>, option?: IDropdownOption<number> | undefined) => {
        const newNumberPerPage: number = option && option?.data ? option.data : searchValues.numberPerPage;
        onGetResults({ ...searchValues, pageNumber: 1, numberPerPage: newNumberPerPage });
    };

    const onSelectedPageIndex = (index: number) => {
        onGetResults({ ...searchValues, pageNumber: index + 1 });
    };

    const getKey = (item: IDocumentResult, index?: number): string => {
        return item.key;
    }

    const onExportClick = async () => {
        const submitValue = {
            keyword: searchValues.keyword || null,
            status: searchValues.status,
            dateFrom: searchValues.dateFrom,
            dateTo: searchValues.dateTo,
            filters: searchValues.filters ? encodeURIComponent(JSON.stringify(searchValues.filters)) : null,
            sortedBy: searchValues.sortedBy,
            sortedType: searchValues.sortedType,
            siteId: profile?.site?.id,
        };

        showFileLoading();

        await service.userExport(submitValue, errorMessage => {
            showDialog({
                title: 'Error',
                dialogContentProps: {
                    type: DialogType.normal,
                    subText: errorMessage,
                }
            });
        });

        hideFileLoading();
    };

    return (
        <Stack>
            <NavigationPane items={[listBreadcrumbItems] || []} />
            <Stack horizontal wrap={isSmallDevice} tokens={stackTokens} styles={{ root: { minHeight: 120 } }} >

                <Stack tokens={stackTokens} horizontal wrap={isSmallDevice} horizontalAlign='space-between' styles={{ root: { width: 200 } }} >
                    <ChoiceGroup
                        selectedKey={searchValues.status || 'active'}
                        options={statusOptions}
                        label="Document Status"
                        onChange={(e, o) => onChangeChoiceField((o?.key as IActiveStatus) || 'active', 'status')}
                    />
                </Stack>

                <Stack tokens={stackTokens} grow>
                    <Stack tokens={stackTokens} horizontal wrap={isSmallDevice}>
                        <DatePicker
                            placeholder="Date from"
                            value={searchValues.dateFrom ? new Date(searchValues.dateFrom) : undefined}
                            onSelectDate={onDateFromChange}
                        />
                        <DatePicker
                            placeholder="Date to"
                            value={searchValues.dateTo ? new Date(searchValues.dateTo) : undefined}
                            onSelectDate={onDateToChange}
                        />
                        <Stack grow>
                            <TextField
                                onChange={(e, value) => onChange(value, 'keyword')}
                                placeholder="Search document name"
                                iconProps={{ iconName: 'Search' }}
                                onKeyDown={onSearchEnter}
                            />
                        </Stack>
                    </Stack>
                    <DataTypeFilterSet onChange={onFiltersChange} stackProps={{ tokens: stackTokens }} />
                </Stack>

                <PrimaryButton text="Search" onClick={onSearchClick} />
                <PrimaryButton text="Export" onClick={onExportClick} disabled={isEmpty(items)} />
            </Stack>

            {(!isLoading && results && items && !isEmpty(items)) ? (
                <>
                    <Stack grow={1}>
                        <DetailsList
                            items={items}
                            columns={columns}
                            getKey={getKey}
                            setKey="multiple"
                            selectionMode={SelectionMode.none}
                        />
                    </Stack>

                    <Stack tokens={stackTokens} horizontal>
                        <Pagination
                            selectedPageIndex={selectedPageIndex}
                            onPageChange={onSelectedPageIndex}
                            pageCount={results.pageTotal}
                            itemsPerPage={searchValues.numberPerPage}
                            totalItemCount={results.total}
                            numberPerPageOptions={numberPerPageOptions}
                            defaultSelectedKey={searchValues.numberPerPage?.toString()}
                            onNumberPerPageChange={onNumberPerPageChange}
                        />
                    </Stack>
                </>
            ) : isLoading ? <Loading /> : <Text>No Result</Text>}

            <Debug object={searchValues} label='search values' />

            <OverlayLoading isVisibled={isFileLoading} />
        </Stack>
    );
};